/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import { API_URL } from '../settings';
import { objectToParams } from '../utils/misc';

export const messagesURL = (params: object = {}) => {
    const queryString = objectToParams(params);
    return `${API_URL}/messages${queryString}`;
};

export const messagesCountUnseenURL = () => {
    return `${API_URL}/messages/count-unseen`;
};

export const messageURL = (id: string) => {
    return `${API_URL}/messages/${id}`;
};
