/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { Component, Fragment } from 'react';
import axios from 'axios';
import { ICON, SvgIcon } from './SvgIcon';
import {
    TranslationContextInterface,
    withTranslationContext,
} from '../controllers/translation/TranslationContext';
import { ApiFile } from '../../constants/types';

interface OwnProps extends TranslationContextInterface {
    onModalClose: Function;
    imgDownloadUrl?: string | null;
    imageFile?: ApiFile;
    imgLabel?: string | null;
}

interface OwnState {
    scale: string;
    imgObj: any;
    isFetching: boolean;
}

const initialState: OwnState = {
    scale: '',
    imgObj: null,
    isFetching: false,
};

const modalOutsideId = 'modal-outside';

class ImageShowModal extends Component<OwnProps, OwnState> {
    state = initialState;

    componentDidMount(): void {
        const { imgDownloadUrl, imageFile } = this.props;
        document.addEventListener('mousedown', this.handleOutsideClick);

        if (imgDownloadUrl) {
            const { isFetching } = this.state;
            
            if (isFetching) return;

            this.setState({
                isFetching: true,
            });

            axios.get(imgDownloadUrl, {
                responseType: 'arraybuffer',
            }).then(res => {
                const blob = new Blob([res.data], {
                    type: 'image/jpeg',
                });

                const objectURL = URL.createObjectURL(blob);
                this.setState({
                    imgObj: objectURL,
                    isFetching: false,
                });
            }).catch(() => {
                this.setState({
                    isFetching: false,
                });
            });
        } else if (imageFile) {
            this.setState({
                imgObj: imageFile.fileString,
            });
        }
    }

    componentWillUnmount(): void {
        document.removeEventListener('mousedown', this.handleOutsideClick);
    }

    handleOutsideClick = (e: any) => {
        const { target } = e;
        const { id } = target;

        if (id === modalOutsideId) {
            this.onClose();
        }
    };

    onClose = () => {
        const { onModalClose } = this.props;
        onModalClose();
    };

    onClickChangeScale = () => {
        const { scale, imgObj, isFetching } = this.state;
        let newScale = '';
        
        if (imgObj === null && !isFetching) return;

        if (scale === newScale) {
            newScale = '1';
        }

        this.setState({
            scale: newScale,
        });
    }

    render() {
        const { imgLabel, t } = this.props;
        const { scale, imgObj, isFetching } = this.state;

        return (
            <Fragment>
                { scale === '' && (
                    <div id={modalOutsideId} className="app-screen__modal" data-testid="image-show-modal">
                        <div className="app-screen__modal__container">
                            <div className="app-screen__modal__container__box">
                                <SvgIcon callback={this.onClose} icon={ICON.CROSS} />
                                <div className="app-screen__modal__container__box__content image-show">
                                    <div className="image-container" onClick={() => { this.onClickChangeScale(); }}>
                                        {imgObj === null && isFetching && (
                                            t('modalImage.loading')
                                        )}
                                        {imgObj === null && !isFetching ? (
                                            t('modalImage.imgNotFound')
                                        ) : (
                                            <img src={imgObj} alt="" />
                                        )}
                                    </div>
                                    {imgLabel && (
                                        <p>{imgLabel}</p>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                {scale !== '' && (
                    <div className="app-screen__modal-zoom">
                        <div className="image-container">
                            <SvgIcon callback={this.onClose} icon={ICON.CROSS} />
                            <img className={`image-zoom${scale}`} src={imgObj} alt="" onClick={() => { this.onClickChangeScale(); }} />
                        </div>
                    </div>
                )}
            </Fragment>
            
        );
    }
}

export default withTranslationContext(ImageShowModal);
