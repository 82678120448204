/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { Component } from 'react';
import axios from 'axios';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { isEqual } from 'lodash';
import { TranslationContextInterface, withTranslationContext } from '../../controllers/translation/TranslationContext';
import TableCell, { TableCellActions, TableCellType } from '../../elements/TableCell';
import { usersURL } from '../../../services/users';
import { UsersTabsEnum } from '../../../constants/tabs';
import TablePaging from '../../elements/TablePaging';
import {
    ListOrder, MatchParams, RESULTS_PER_PAGE,
} from '../../../constants/misc';
import Loader from '../../elements/Loader';
import { UserRoles } from '../../../constants/authorization';
import TableHeader from '../../elements/TableHeader';
import { VideoContextInterface, withVideoContext } from '../../controllers/video/VideoContext';
import {
    USER_INSURANCE_EDIT_ROUTE,
    USER_MECHANIC_EDIT_ROUTE,
    USERS_ADMIN_LIST_ROUTE,
    USERS_COLLABORATOR_LIST_ROUTE,
    USERS_EDIT_ROUTE,
    USERS_INSURANCE_LIST_ROUTE,
    USERS_MECHANIC_LIST_ROUTE,
    USERS_GLASS_PROVIDER_LIST_ROUTE,
    USER_GLASS_PROVIDER_EDIT_ROUTE,
} from '../../../constants/routes';
import {
    AuthenticationContextInterface,
    withAuthenticationContext,
} from '../../controllers/authentication/AuthenticationContext';
import { User } from '../../../constants/types';
import withQueryParams, { WithQueryParamsProps } from '../../hocs/withQueryParams';

interface OwnProps extends RouteComponentProps<MatchParams>, AuthenticationContextInterface, TranslationContextInterface,
    VideoContextInterface, WithQueryParamsProps {
    tabItem: string | undefined;
    hasNewUsers: boolean;
}

interface OwnState {
    isFetching: boolean;
    data: User[];
    totalResults: number;
    actions: TableCellActions[];
    isSocketConnecting: boolean;
}

const initialState = {
    data: [],
    isFetching: false,
    totalResults: 0,
    actions: [],
    isSocketConnecting: false,
};

class UsersListScreen extends Component<OwnProps, OwnState> {
    state = initialState;

    componentDidMount() {
        const { location, onQueryParamsChange } = this.props;
        onQueryParamsChange(location.search);
    }

    componentDidUpdate(prevProps: Readonly<OwnProps>, prevState: Readonly<OwnState>, snapshot?: any) {
        const {
            listParams, tabItem: oldTab, location, onQueryParamsChange,
        } = this.props;
        const {
            listParams: oldListParams, tabItem, location: oldLocation,
        } = prevProps;

        const hasTabChanged: boolean = tabItem !== oldTab;

        if (location.search !== oldLocation.search) {
            onQueryParamsChange(location.search);
        }

        if (hasTabChanged || !isEqual(listParams, oldListParams)) {
            this.loadList();
        }
    }

    onUserClick = (id: number, role: string) => {
        const { history } = this.props;

        switch (role) {
            case UserRoles.MECHANIC:
                history.push(`${USER_MECHANIC_EDIT_ROUTE}/${id}`);
                break;
            case UserRoles.INSURANCE_USER:
                history.push(`${USER_INSURANCE_EDIT_ROUTE}/${id}`);
                break;
            case UserRoles.ADMIN:
            case UserRoles.EXTERNAL_ADMIN:
            case UserRoles.COLLABORATOR:
                history.push(`${USERS_EDIT_ROUTE}/${role}/${id}`);
                break;
            case UserRoles.GLASS_PROVIDER:
                history.push(`${USER_GLASS_PROVIDER_EDIT_ROUTE}/${id}`);
                break;
            default:
        }
    };

    onEditClick = (id: number) => {
        // todo
    };

    onDeleteClick = (id: number) => {
        // todo
    };

    onMessageClick = (id: number) => {
        // todo
    };

    loadList = async () => {
        const {
            tabItem,
            match,
            listParams,
        } = this.props;
        const { isFetching } = this.state;
        const { path } = match;

        let tabItemSelected = UsersTabsEnum.ALL;
        switch (path) {
            case USERS_MECHANIC_LIST_ROUTE:
                tabItemSelected = UsersTabsEnum.MECHANIC;
                break;
            case USERS_INSURANCE_LIST_ROUTE:
                tabItemSelected = UsersTabsEnum.INSURANCE_USER;
                break;
            case USERS_ADMIN_LIST_ROUTE:
                tabItemSelected = UsersTabsEnum.ADMIN;
                break;
            case USERS_COLLABORATOR_LIST_ROUTE:
                tabItemSelected = UsersTabsEnum.COLLABORATOR;
                break;
            case USERS_GLASS_PROVIDER_LIST_ROUTE:
                tabItemSelected = UsersTabsEnum.GLASS_PROVIDER;
                break;
            default:
        }

        if (isFetching || tabItem !== tabItemSelected) return;

        try {
            let userRole = '';

            if (tabItem !== UsersTabsEnum.ALL) {
                userRole = tabItem || '';
            }

            const { data, headers } = await axios.get(usersURL({
                _q: listParams?.search || '',
                _page: listParams ? listParams.page - 1 : 0,
                _limit: RESULTS_PER_PAGE,
                _sort: listParams?.sort || 'createdDate',
                _order: listParams?.order || ListOrder.Desc,
                userRole,
            }));

            const newTotalResults: number = parseInt(headers['x-total-count']);

            this.setState({
                data,
                totalResults: newTotalResults,
                isFetching: false,
            });
        } catch (error) {
            this.setState({
                isFetching: false,
                data: [],
                totalResults: 0,
            });
        }
    };

    render() {
        const {
            t, listParams,
        } = this.props;
        const {
            data, totalResults, isFetching,
        } = this.state;
        const hasData: boolean = data && data.length > 0;

        const { onlineInsuranceUsers, onlineFluxeUsers, onlineMechanicUsers } = this.props;
        const onlineUsers = onlineFluxeUsers.concat(onlineInsuranceUsers.concat(onlineMechanicUsers));
        return (
            <div data-testid="user-list-screen">
                {isFetching && (
                    <div className="loader-wrapper">
                        <Loader />
                    </div>
                )}
                <div className="app-screen__table">
                    <div className="app-screen__table__content">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>
                                        <TableHeader
                                            text={t('users.list.headers.status')}
                                            sort={listParams?.sort}
                                            order={listParams?.order}
                                            field="inactive"
                                            sortable
                                        />
                                    </th>
                                    <th>
                                        <TableHeader
                                            text={t('users.list.headers.name')}
                                            sort={listParams?.sort}
                                            order={listParams?.order}
                                            field="name"
                                            sortable
                                        />
                                    </th>
                                    <th>
                                        <TableHeader
                                            text={t('users.list.headers.email')}
                                            sort={listParams?.sort}
                                            order={listParams?.order}
                                            field="email"
                                            sortable
                                        />
                                    </th>
                                    <th>
                                        <TableHeader
                                            text={t('users.list.headers.type')}
                                            sort={listParams?.sort}
                                            order={listParams?.order}
                                            field="role"
                                            sortable
                                        />
                                    </th>
                                    <th>
                                        <TableHeader
                                            text=""
                                            sortable={false}
                                        />
                                    </th>
                                </tr>
                            </thead>
                            { hasData ? (
                                <tbody>
                                    { data.map(user => {
                                        const {
                                            id, name, email, inactive, role, registered,
                                        } = user;

                                        const isOnline = onlineUsers.includes(id);

                                        const roleUppercase: string = String(role).toUpperCase();
                                        return (
                                            <tr data-testid="user-list-screen-user" key={id} onClick={() => this.onUserClick(Number(id), role)}>
                                                {(registered === false) ? (
                                                    <TableCell
                                                        type={TableCellType.STATUS}
                                                        value={registered}
                                                        name="pending"
                                                        title={t('users.pending')}
                                                    />
                                                ) : (
                                                    inactive ? (
                                                        <TableCell
                                                            type={TableCellType.STATUS}
                                                            value={inactive}
                                                            title={t('users.inactive')}
                                                        />
                                                    ) : (
                                                        <TableCell
                                                            type={TableCellType.STATUS}
                                                            value={isOnline}
                                                            name="online"
                                                            title={isOnline ? t('users.online') : t('users.offline')}
                                                        />
                                                    )
                                                )}
                                                <TableCell
                                                    value={name}
                                                />
                                                <TableCell
                                                    value={email}
                                                />
                                                <TableCell
                                                    value={t(`users.roles.${roleUppercase}`)}
                                                    cellClass="bold-text"
                                                />
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            ) : (
                                <tbody className="no-data">
                                    <tr>
                                        <td colSpan={5}>{t('global.noData')}</td>
                                    </tr>
                                </tbody>
                            )}
                        </table>
                        {hasData && (
                            <TablePaging
                                currentPage={listParams?.page || 1}
                                limit={RESULTS_PER_PAGE}
                                totalResults={totalResults}
                            />
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

export default withVideoContext(withAuthenticationContext(withRouter(withTranslationContext(withQueryParams(UsersListScreen)))));
