/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { CSSProperties } from 'react';
import { ReactSVG } from 'react-svg';

import iconVisibilityFilled from '../../assets/images/icon-visibility_filled.svg';
import iconVisibilityInvisible from '../../assets/images/icon-visibility_invisible.svg';
import iconProcesses from '../../assets/images/icon-processes.svg';
import iconMessages from '../../assets/images/icon-messages.svg';
import iconCircle from '../../assets/images/icon-ellipse.svg';
import iconUsers from '../../assets/images/icon-users.svg';
import iconChevronUp from '../../assets/images/icon-chevron_up.svg';
import iconChevronDown from '../../assets/images/icon-chevron_down.svg';
import iconSearch from '../../assets/images/icon-search.svg';
import iconLeftArrow from '../../assets/images/icon-left_arrow.svg';
import iconRightArrow from '../../assets/images/icon-right_arrow.svg';
import iconCross from '../../assets/images/icon-cross.svg';
import iconTrash from '../../assets/images/icon-trash.svg';
import iconPencil from '../../assets/images/icon-pencil.svg';
import iconMessage from '../../assets/images/icon-message.svg';
import iconAttachment from '../../assets/images/icon-attachment.svg';
import iconList from '../../assets/images/icon-list.svg';
import iconPlus from '../../assets/images/icon-plus.svg';
import iconTrendingUp from '../../assets/images/icon-trending_up.svg';
import iconCar from '../../assets/images/icon-car.svg';
import iconAccountBox from '../../assets/images/icon-account_box.svg';
import iconBuild from '../../assets/images/icon-build.svg';
import iconVideoCam from '../../assets/images/icon-video.svg';
import iconCalendar from '../../assets/images/icon-calendar.svg';
import iconCarCrash from '../../assets/images/icon-car_crash.svg';
import iconWarning from '../../assets/images/icon-warning.svg';
import iconAttachHorizontal from '../../assets/images/icon-attach_horizontal.svg';
import iconCamera from '../../assets/images/icon-camera.svg';
import iconPaste from '../../assets/images/icon-paste.svg';
import iconViewList from '../../assets/images/icon-view_list.svg';
import iconViewGrid from '../../assets/images/icon-view_grid.svg';
import iconRobot from '../../assets/images/icon-robot.svg';
import iconRedCrossCircle from '../../assets/images/icon-cross_red_circle.svg';
import iconGreenCheckCircle from '../../assets/images/icon-check_green_circle.svg';
import iconVideoCamCalling from '../../assets/images/icon-video_cam.svg';
import iconMicrophoneOff from '../../assets/images/icon-microphone_off.svg';
import iconMicrophoneOn from '../../assets/images/icon-microphone_on.svg';
import iconExpand from '../../assets/images/icon-expand.svg';
import iconDiminish from '../../assets/images/icon-diminish.svg';
import iconPrint from '../../assets/images/icon-print.svg';
import iconChecked from '../../assets/images/icon-checked.svg';
import iconUnchecked from '../../assets/images/icon-unchecked.svg';
import iconFilter from '../../assets/images/icon-filter.svg';
import iconPDF from '../../assets/images/pdf.svg';
import iconDispute from '../../assets/images/dispute.svg';
import iconZoomOut from '../../assets/images/icon-zoom_out.svg';
import iconLoader from '../../assets/images/loader.svg';
import iconBrokenGlassControl from '../../assets/images/icon-broken-glass-control.svg';
import iconReload from '../../assets/images/icon-reload.svg';

interface OwnProps {
    icon: string;
    className?: string;
    callback?: Function;
}

export enum ICON {
    PASSWORD_FILLED = 'PASSWORD_FILLED',
    PASSWORD_INVISIBLE = 'PASSWORD_INVISIBLE',
    PROCESSES = 'PROCESSES',
    BROKEN_GLASS_CONTROL = 'BROKEN_GLASS_CONTROL',
    MESSAGES = 'MESSAGES',
    DISPUTE= 'DISPUTE',
    CIRCLE = 'CIRCLE',
    USERS = 'USERS',
    CHEVRON_UP = 'CHEVRON_UP',
    CHEVRON_DOWN = 'CHEVRON_DOWN',
    SEARCH = 'SEARCH',
    CROSS = 'CROSS',
    LEFT_ARROW = 'LEFT_ARROW',
    RIGHT_ARROW = 'RIGHT_ARROW',
    MESSAGE = 'MESSAGE',
    PENCIL = 'PENCIL',
    TRASH = 'TRASH',
    ATTACHMENT = 'ATTACHMENT',
    LIST = 'LIST',
    PLUS = 'PLUS',
    TRENDING_UP = 'TRENDING_UP',
    CAR = 'CAR',
    BUILD = 'BUILD',
    ACCOUNT_BOX = 'ACCOUNT_BOX',
    VIDEO_CAM = 'VIDEO_CAM',
    CALENDAR = 'CALENDAR',
    CAR_CRASH = 'CAR_CRASH',
    WARNING = 'WARNING',
    ATTACH_HORIZONTAL = 'ATTACH_HORIZONTAL',
    CAMERA = 'CAMERA',
    PASTE = 'PASTE',
    VIEW_GRID = 'VIEW_GRID',
    VIEW_LIST = 'VIEW_LIST',
    ROBOT = 'ROBOT',
    MICROPHONE_OFF = 'MICROPHONE_OFF',
    CROSS_RED_CIRCLE = 'CROSS_RED_CIRCLE',
    CHECK_GREEN_CIRCLE = 'CHECK_GREEN_CIRCLE',
    VIDEO_CAM_CALLING = 'VIDEO_CAM_CALLING',
    MICROPHONE_ON = 'MICROPHONE_ON',
    EXPAND = 'EXPAND',
    DIMINISH = 'DIMINISH',
    PRINT = 'PRINT',
    CHECKED = 'CHECKED',
    UNCHECKED = 'UNCHECKED',
    FILTER = 'FILTER',
    PDF = 'PDF',
    ZOOM_OUT = 'ZOOM_OUT',
    LOADER = 'LOADER',
    RELOAD = 'RELOAD',
}

export const SvgIcon = (props: OwnProps) => {
    const getIcon = (): any => {
        const icons = {
            [ICON.PASSWORD_FILLED]: iconVisibilityFilled,
            [ICON.PROCESSES]: iconProcesses,
            [ICON.BROKEN_GLASS_CONTROL]: iconBrokenGlassControl,
            [ICON.MESSAGES]: iconMessages,
            [ICON.CIRCLE]: iconCircle,
            [ICON.USERS]: iconUsers,
            [ICON.CHEVRON_DOWN]: iconChevronDown,
            [ICON.CHEVRON_UP]: iconChevronUp,
            [ICON.PASSWORD_INVISIBLE]: iconVisibilityInvisible,
            [ICON.SEARCH]: iconSearch,
            [ICON.CROSS]: iconCross,
            [ICON.LEFT_ARROW]: iconLeftArrow,
            [ICON.RIGHT_ARROW]: iconRightArrow,
            [ICON.MESSAGE]: iconMessage,
            [ICON.PENCIL]: iconPencil,
            [ICON.TRASH]: iconTrash,
            [ICON.ATTACHMENT]: iconAttachment,
            [ICON.LIST]: iconList,
            [ICON.PLUS]: iconPlus,
            [ICON.TRENDING_UP]: iconTrendingUp,
            [ICON.CAR]: iconCar,
            [ICON.BUILD]: iconBuild,
            [ICON.ACCOUNT_BOX]: iconAccountBox,
            [ICON.VIDEO_CAM]: iconVideoCam,
            [ICON.CALENDAR]: iconCalendar,
            [ICON.CAR_CRASH]: iconCarCrash,
            [ICON.WARNING]: iconWarning,
            [ICON.ATTACH_HORIZONTAL]: iconAttachHorizontal,
            [ICON.PASTE]: iconPaste,
            [ICON.CAMERA]: iconCamera,
            [ICON.VIEW_GRID]: iconViewGrid,
            [ICON.VIEW_LIST]: iconViewList,
            [ICON.ROBOT]: iconRobot,
            [ICON.MICROPHONE_OFF]: iconMicrophoneOff,
            [ICON.MICROPHONE_ON]: iconMicrophoneOn,
            [ICON.CHECK_GREEN_CIRCLE]: iconGreenCheckCircle,
            [ICON.CROSS_RED_CIRCLE]: iconRedCrossCircle,
            [ICON.VIDEO_CAM_CALLING]: iconVideoCamCalling,
            [ICON.EXPAND]: iconExpand,
            [ICON.DIMINISH]: iconDiminish,
            [ICON.PRINT]: iconPrint,
            [ICON.UNCHECKED]: iconUnchecked,
            [ICON.CHECKED]: iconChecked,
            [ICON.FILTER]: iconFilter,
            [ICON.PDF]: iconPDF,
            [ICON.DISPUTE]: iconDispute,
            [ICON.ZOOM_OUT]: iconZoomOut,
            [ICON.LOADER]: iconLoader,
            [ICON.RELOAD]: iconReload,
        } as Record<string, string>;
        
        return icons[props.icon];
    };

    const getStyles = (): CSSProperties => {
        let styles: CSSProperties = {
            pointerEvents: 'none',
        };

        if (props.callback) {
            styles = {
                cursor: 'pointer',
            };
        }

        return styles;
    };

    const onClick = () => {
        if (props.callback) {
            props.callback();
        }
    };

    return (
        <ReactSVG
            wrapper="span"
            onClick={onClick}
            className={`svg-icon ${props.className}`}
            src={getIcon()}
            style={getStyles()}
        />
    );
};
