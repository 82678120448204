/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import { API_URL } from '../settings';
import { objectToParams } from '../utils/misc';

export const statisticsURL = (params: object = {}) => {
    const queryString = objectToParams(params);
    return `${API_URL}/statistics${queryString}`;
};

export const statisticsYearsURL = () => {
    return `${API_URL}/statistics/years`;
};
