/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { Component } from 'react';
import axios from 'axios';
import { isEqual } from 'lodash';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { ListOrder, MatchParams, RESULTS_PER_PAGE } from '../../../constants/misc';
import {
    AuthorizationContextInterface,
    withAuthorizationContext,
} from '../../controllers/authorization/AuthorizationContext';
import { TranslationContextInterface, withTranslationContext } from '../../controllers/translation/TranslationContext';
import TableCell, { TableCellActions, TableCellType } from '../../elements/TableCell';
import { PreInspection } from '../../../constants/types';
import { PreInspectionsTabsEnum } from '../../../constants/tabs';
import { preInspectionsURL } from '../../../services/preInspections';
import Loader from '../../elements/Loader';
import TableHeader from '../../elements/TableHeader';
import TablePaging from '../../elements/TablePaging';
import FilterModal from '../../elements/FilterModal';
import { generateInt } from '../../../utils/misc';
import {
    PRE_INSPECTIONS_COMPLETE_LIST_ROUTE,
    PRE_INSPECTIONS_DETAILS_ROUTE,
    PRE_INSPECTIONS_EDIT_ROUTE,
    PRE_INSPECTIONS_GLASS_LIST_ROUTE,
} from '../../../constants/routes';
import { checkPermission } from '../../../utils/authorization';
import { PermissionKey } from '../../../constants/authorization';
import withQueryParams, { WithQueryParamsProps } from '../../hocs/withQueryParams';

interface OwnProps extends RouteComponentProps<MatchParams>, AuthorizationContextInterface, TranslationContextInterface, WithQueryParamsProps {
    tabItem: string;
}

/**
 * @typedef {Object} OwnState
 * @property {boolean} isFetching
 * @property {PreInspection} [data]
 * @property {number} [totalResults]
 * @property {number} _limit
 * @property {TableCellActions} [actions]
 * @property {boolean} showFilterModal
 */
interface OwnState {
    isFetching: boolean;
    data: PreInspection[];
    totalResults: number;
    actions: TableCellActions[];
    showFilterModal: boolean;
}

const initialState: OwnState = {
    data: [],
    isFetching: false,
    totalResults: 0,
    actions: [],
    showFilterModal: false,
};

/**
 * shows the pre inspection list screen
 * @extends {Component<Props, State>}
 */
class PreInspectionsListScreen extends Component<OwnProps, OwnState> {
    state = initialState;

    componentDidMount() {
        const { location, onQueryParamsChange } = this.props;
        onQueryParamsChange(location.search);
    }

    componentDidUpdate(prevProps: Readonly<OwnProps>, prevState: Readonly<OwnState>, snapshot?: any) {
        const {
            listParams, tabItem: oldTab, location, onQueryParamsChange,
        } = this.props;
        const {
            listParams: oldListParams, tabItem, location: oldLocation,
        } = prevProps;

        const hasTabChanged: boolean = tabItem !== oldTab;

        if (location.search !== oldLocation.search) {
            onQueryParamsChange(location.search);
        }

        if (hasTabChanged || !isEqual(listParams, oldListParams)) {
            this.loadList();
        }
    }

    onEditClick = (id: number) => {
        // todo
    };

    onDeleteClick = (id: number) => {
        // todo
    };

    onMessageClick = (id: number) => {
        // todo
    };

    onPreInspectionClick = (id: number): void => {
        const {
            history, permissions,
        } = this.props;

        if (checkPermission(permissions, [PermissionKey.ALL, PermissionKey.EDIT_PRE_INSPECTIONS])) {
            history.push(`${PRE_INSPECTIONS_EDIT_ROUTE}/${id}`);
        } else if (checkPermission(permissions, [PermissionKey.VIEW_PRE_INSPECTIONS_DETAILS])) {
            history.push(`${PRE_INSPECTIONS_DETAILS_ROUTE}/${id}`);
        }
    };

    /**
     * Show filter modal by date
     * @param {string} field
     */
    onFilterClick = (field: string) => {
        this.setState({
            showFilterModal: true,
        });
    }

    /**
     * Show filter submit
     * @param {number} startDate
     * @param {number} endDate
     */
    onFilterSubmit = (startDate: number, endDate: number) => {
        this.loadList(startDate, endDate);
    }

    loadList = async (startDate?: number, endDate?: number) => {
        const {
            listParams,
            tabItem,
            match,
        } = this.props;
        const { isFetching } = this.state;
        const { path } = match;

        let tabItemSelected = PreInspectionsTabsEnum.ALL;
        if (path === PRE_INSPECTIONS_GLASS_LIST_ROUTE) {
            tabItemSelected = PreInspectionsTabsEnum.GLASS;
        } else if (path === PRE_INSPECTIONS_COMPLETE_LIST_ROUTE) {
            tabItemSelected = PreInspectionsTabsEnum.COMPLETE;
        }

        if (isFetching || tabItemSelected !== tabItem) return;

        try {
            let type = '';
            if (tabItem !== PreInspectionsTabsEnum.ALL) {
                type = tabItem;
            }

            const queryParams = {
                _q: listParams?.search || '',
                _page: listParams ? listParams.page - 1 : 0,
                _limit: RESULTS_PER_PAGE,
                _sort: listParams?.sort || 'createdDate',
                _order: listParams?.order || ListOrder.Desc,
                startDate: startDate || null,
                endDate: endDate || null,
                type,
            };

            const { data, headers } = await axios.get(preInspectionsURL(queryParams));

            const newTotalResults: number = parseInt(headers['x-total-count']);

            this.setState({
                data,
                totalResults: newTotalResults,
                isFetching: false,
            });
        } catch (error) {
            this.setState({
                isFetching: false,
            });
        }
    };

    render() {
        const {
            t, listParams,
        } = this.props;
        const {
            data, totalResults, actions, isFetching, showFilterModal,
        } = this.state;
        const hasData: boolean = data && data.length > 0;

        return (
            <div>
                {isFetching && (
                    <div className="loader-wrapper">
                        <Loader />
                    </div>
                )}
                <div className="app-screen__table" data-testid="pre-inspection-list">
                    <div className="app-screen__table__content bigger">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>
                                        <TableHeader
                                            text={t('preInspections.list.headers.status')}
                                            sort={listParams?.sort}
                                            order={listParams?.order}
                                            field="status"
                                            sortable
                                        />
                                    </th>
                                    <th>
                                        <TableHeader
                                            text={t('preInspections.list.headers.type')}
                                            sort={listParams?.sort}
                                            order={listParams?.order}
                                            field="type"
                                            sortable
                                        />
                                    </th>
                                    <th>
                                        <TableHeader
                                            text={t('preInspections.list.headers.process')}
                                            sort={listParams?.sort}
                                            order={listParams?.order}
                                            field="uid"
                                            sortable
                                        />
                                    </th>
                                    <th>
                                        <TableHeader
                                            text={t('preInspections.list.headers.insurance')}
                                            sort={listParams?.sort}
                                            order={listParams?.order}
                                            field="insuranceCompany.name"
                                            sortable
                                        />
                                    </th>
                                    <th>
                                        <TableHeader
                                            text={t('preInspections.list.headers.date')}
                                            sort={listParams?.sort}
                                            order={listParams?.order}
                                            field="createdDate"
                                            sortable
                                            filterable
                                            onFilterClick={this.onFilterClick}
                                        />
                                    </th>
                                    <th>
                                        <TableHeader
                                            text={t('preInspections.list.headers.conclusionDate')}
                                            sort={listParams?.sort}
                                            order={listParams?.order}
                                            field="doneDate"
                                            sortable
                                        />
                                    </th>
                                    <th>
                                        <TableHeader
                                            text={t('preInspections.list.headers.licensePlate')}
                                            sort={listParams?.sort}
                                            order={listParams?.order}
                                            field="vehicleLicensePlate"
                                            sortable
                                        />
                                    </th>
                                    <th>
                                        <TableHeader
                                            text={t('preInspections.list.headers.brand')}
                                            sort={listParams?.sort}
                                            order={listParams?.order}
                                            field="vehicleBrand"
                                            sortable
                                        />
                                    </th>
                                    <th>
                                        <TableHeader
                                            text={t('preInspections.list.headers.model')}
                                            sort={listParams?.sort}
                                            order={listParams?.order}
                                            field="vehicleModel"
                                            sortable
                                        />
                                    </th>
                                    <th>
                                        <TableHeader
                                            text=""
                                            sortable={false}
                                        />
                                    </th>
                                </tr>
                            </thead>
                            { hasData ? (
                                <tbody>
                                    { data.map((preInspection: PreInspection) => {
                                        const {
                                            id,
                                            uid,
                                            status,
                                            insuranceCompany,
                                            vehicleLicensePlate,
                                            vehicleBrand,
                                            pciVehicleBrand,
                                            pciVehicleModel,
                                            vehicleModel,
                                            type,
                                            createdDate,
                                            doneDate,
                                        } = preInspection;

                                        let insuranceCompanyName = '';

                                        if (insuranceCompany) {
                                            insuranceCompanyName = insuranceCompany.name;
                                        }

                                        return (
                                            <tr key={id || generateInt(0, 50)} onClick={() => this.onPreInspectionClick(Number(id))}>
                                                <TableCell
                                                    type={TableCellType.PRE_INSPECTION_STATUS}
                                                    value={String(status)}
                                                    title={t(`enums.preInspectionStatus.${status}`)}
                                                />
                                                <TableCell
                                                    value={t(`enums.preInspectionType.${type}`)}
                                                />
                                                <TableCell
                                                    value={uid}
                                                />
                                                <TableCell
                                                    value={insuranceCompanyName || ' -- '}
                                                />
                                                <TableCell
                                                    type={TableCellType.DATE}
                                                    value={createdDate}
                                                />
                                                {doneDate ? (
                                                    <TableCell
                                                        type={TableCellType.DATE}
                                                        value={doneDate}
                                                    />
                                                ) : (
                                                    <TableCell
                                                        value="--"
                                                    />
                                                )}
                                                <TableCell
                                                    value={vehicleLicensePlate || ' -- '}
                                                />
                                                <TableCell
                                                    value={(pciVehicleBrand || vehicleBrand) || ' -- '}
                                                />
                                                <TableCell
                                                    value={(pciVehicleModel || vehicleModel) || ' -- '}
                                                />
                                                <TableCell
                                                    type={TableCellType.ACTIONS}
                                                    actions={actions}
                                                    value={createdDate}
                                                    onEdit={() => this.onEditClick(Number(id))}
                                                    onDelete={() => this.onDeleteClick(Number(id))}
                                                    onMessage={() => this.onMessageClick(Number(id))}
                                                />
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            ) : (
                                <tbody className="no-data">
                                    <tr>
                                        <td colSpan={10}>{t('global.noData')}</td>
                                    </tr>
                                </tbody>
                            )}
                        </table>
                        {hasData && (
                            <TablePaging
                                currentPage={listParams?.page || 1}
                                limit={RESULTS_PER_PAGE}
                                totalResults={totalResults}
                            />
                        )}
                    </div>
                </div>
                {showFilterModal && (
                    <FilterModal onModalClose={() => this.setState({ showFilterModal: false })} onSubmit={this.onFilterSubmit} />
                )}
            </div>
        );
    }
}

export default withAuthorizationContext(withRouter(withTranslationContext(withQueryParams(PreInspectionsListScreen))));
