/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { Component } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { TranslationContextInterface, withTranslationContext } from '../../controllers/translation/TranslationContext';
import { MatchParams, TabItem } from '../../../constants/misc';
import { MESSAGES_TABS_ITEM, MessagesTabsEnum } from '../../../constants/tabs';
import Tabs from '../../elements/Tabs';
import MessagesListScreen from './MessagesListScreen';
import {
    MESSAGES_RECEIVED_LIST_ROUTE,
    MESSAGES_SENT_LIST_ROUTE,
    NOT_FOUND_ROUTE,
} from '../../../constants/routes';
import {
    AuthenticationContextInterface,
    withAuthenticationContext,
} from '../../controllers/authentication/AuthenticationContext';
import { UserRoles } from '../../../constants/authorization';
import withQueryParams, { WithQueryParamsProps } from '../../hocs/withQueryParams';

interface OwnProps extends RouteComponentProps<MatchParams>, TranslationContextInterface,
    AuthenticationContextInterface, WithQueryParamsProps {}

interface OwnState {
    tabItemSelected?: string;
    tabItems: TabItem[];
}

const initialState: OwnState = {
    tabItemSelected: MessagesTabsEnum.RECEIVED,
    tabItems: [],
};

class MessagesScreen extends Component<OwnProps, OwnState> {
    state = initialState;

    componentDidMount(): void {
        const {
            user, history,
        } = this.props;
        let role = '';

        if (user) role = user.role;

        if (role === UserRoles.INSURANCE_USER) {
            history.push(NOT_FOUND_ROUTE);
            return;
        }
        this.prepare();
    }

    componentDidUpdate(prevProps: Readonly<OwnProps>, prevState: Readonly<OwnState>, snapshot?: any): void {
        const { match: oldMatch } = prevProps;
        const { match } = this.props;
        const { path: oldPath } = oldMatch;
        const { path } = match;

        if (path !== oldPath) {
            let tabItemSelected = MessagesTabsEnum.RECEIVED;
            if (path === MESSAGES_SENT_LIST_ROUTE) {
                tabItemSelected = MessagesTabsEnum.SENT;
            }

            this.setState({ tabItemSelected });
        }
    }

    onTabChange = (newTab: string) => {
        const { history } = this.props;
        switch (newTab) {
            case MessagesTabsEnum.RECEIVED:
                history.push(MESSAGES_RECEIVED_LIST_ROUTE);
                break;
            case MessagesTabsEnum.SENT:
                history.push(MESSAGES_SENT_LIST_ROUTE);
                break;
            default:
        }

        this.setState({
            tabItemSelected: newTab,
        });
    };

    prepare = async () => {
        const { t, match } = this.props;
        const { path } = match;
        const tabItems: TabItem[] = [];

        let tabItemSelected = MessagesTabsEnum.RECEIVED;
        if (path === MESSAGES_SENT_LIST_ROUTE) {
            tabItemSelected = MessagesTabsEnum.SENT;
        }

        Object.keys(MESSAGES_TABS_ITEM).forEach(k => {
            const i = MESSAGES_TABS_ITEM[Number(k)];

            const item: TabItem = {
                value: i,
                text: t(`messages.list.tabItems.${i}`),
            };

            tabItems.push(item);
        });

        this.setState({
            tabItems,
            tabItemSelected,
        });
    };

    render() {
        const { tabItems, tabItemSelected } = this.state;

        return (
            <div>
                <Tabs
                    tabsItems={tabItems}
                    onTabChange={this.onTabChange}
                    withSearch
                    tabSelected={tabItemSelected}
                />
                <MessagesListScreen
                    tabItem={tabItemSelected}
                />
            </div>
        );
    }
}

export default withAuthenticationContext(withTranslationContext(withQueryParams(withRouter(MessagesScreen))));
