/**
 *
 * @Copyright 2022 VOID SOFTWARE, S.A.
 *
 */

import React, { ComponentType, createContext, FC } from 'react';
import {
    ListResponse,
    User,
    UserOptionsResponse,
} from '../../../constants/types';
import { KeyedObject } from '../../../constants/misc';
import { ConnectedUsersController } from './UsersController';

export interface UsersContext {
    getUsers(parameters?: KeyedObject): Promise<ListResponse<User>>;
    getUsersOptions(parameters?: KeyedObject): Promise<UserOptionsResponse>;
    resendInvitesEmails(onSuccess: () => void, onFailure: (errorCode: number) => void): Promise<void>;
}

export const usersContextDefaultValue = {
    getUsers: async (): Promise<ListResponse<User>> => ({ results: [], total: 0 }),
    getUsersOptions: async (): Promise<UserOptionsResponse> => ({ options: [], users: [] }),
    resendInvitesEmails: async (): Promise<void> => {},
};

const UsersContextInstance = createContext<UsersContext | null>(usersContextDefaultValue);

export const UsersContextProvider = UsersContextInstance.Provider;
export const UsersContextConsumer = UsersContextInstance.Consumer;

export const withUsersContext = <P extends object>(
    Component: ComponentType<P>,
): FC<Omit<P, keyof UsersContext>> => props => (
    <ConnectedUsersController>
        <UsersContextConsumer>
            { ctx => <Component {...props as P} {...ctx} />}
        </UsersContextConsumer>
    </ConnectedUsersController>
    );
