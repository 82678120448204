
/**
 *
 * @Copyright 2022 VOID SOFTWARE, S.A.
 *
 */

import { API_URL } from '../settings';

export const getGlassDamages = (fileId: number): string => {
    return `${API_URL}/files/${fileId}/damage`;
};

export const getGlassDamage = (fileId: number, damageId: number): string => {
    return `${API_URL}/files/${fileId}/damage/${damageId}`;
};
