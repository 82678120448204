/**
 *
 * @Copyright 2022 VOID SOFTWARE, S.A.
 *
 */

import React, { Component } from 'react';
import Select, { ValueType } from 'react-select';
import { MenuPlacement } from 'react-select/src/types';

import ErrorMessage from './ErrorMessage';
import { SelectOption } from '../../constants/misc';

interface OwnProps {
    name: string;
    inputName: string;
    value: string | number;
    searchValue: string;
    onChange(name: string, optValue: string): void;
    onSearchInputChange(name: string, value: string): void;
    label?: string;
    containerStyles?: string;
    fieldStyles?: string;
    options: Array<SelectOption>;
    errors?: any;
    labelTitle?: string;
    menuPlacement?: MenuPlacement;
}

class FormSelectField extends Component<OwnProps> {
    onChange = (opt: ValueType<SelectOption, false>): void => {
        const { onChange, name } = this.props;

        if (!opt) {
            onChange(name, '');
            return;
        }

        onChange(name, String(opt.value));
    };

    renderErrors = () => {
        const { name, errors } = this.props;

        return (
            <ErrorMessage errors={errors} field={name} />
        );
    };

    render() {
        const {
            value, label, containerStyles, fieldStyles, options, errors, labelTitle,
            onSearchInputChange, searchValue, inputName, name, menuPlacement,
        } = this.props;
        const containerClass = containerStyles || '';
        const fieldClass = fieldStyles || '';

        return (
            <div className={`field-container ${containerClass}`}>
                {label && (
                    <div
                        className="field-container__label"
                        title={labelTitle || ''}
                    >
                        {label}
                    </div>
                )}
                <div className={`field-container__select-field ${fieldClass}`} data-testid={`select-wrapper-${name}`}>
                    <Select
                        onChange={this.onChange}
                        onInputChange={newValue => onSearchInputChange(inputName, newValue)}
                        value={options.find(opt => String(opt.value) === String(value))}
                        inputValue={searchValue}
                        options={options}
                        name={name}
                        className="searchable-select"
                        classNamePrefix="searchable-select"
                        placeholder=""
                        menuPlacement={menuPlacement}
                    />
                </div>
                <p>{errors && this.renderErrors()}</p>
            </div>
        );
    }
}

export default FormSelectField;
