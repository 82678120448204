/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

export enum UsersTabsEnum {
    ALL = 'ALL',
    MECHANIC = 'MECHANIC',
    ADMIN = 'ADMIN',
    INSURANCE_USER = 'INSURANCE_USER',
    COLLABORATOR = 'COLLABORATOR',
    GLASS_PROVIDER = 'GLASS_PROVIDER',
}

export const USERS_TABS_ITEM: string[] = [
    'ALL',
    'ADMIN',
    'MECHANIC',
    'INSURANCE_USER',
    'COLLABORATOR',
    'GLASS_PROVIDER',
];

export enum CasualtiesTabsEnum {
    ALL = 'ALL',
    CANCELED = 'CANCELED',
    DONE = 'DONE',
    ONGOING = 'ONGOING',
}

export const CASUALTIES_TABS_ITEM: string[] = [
    'ALL',
    'ONGOING',
    'DONE',
    'CANCELED',
];

export enum CasualtyFormTabsEnum {
    REQUEST = 'REQUEST',
    BUDGET = 'BUDGET',
    DOCUMENTS = 'DOCUMENTS',
    MESSAGES = 'MESSAGES',
}

export const CASUALTY_FORM_TABS_ITEM: string[] = [
    'REQUEST',
    'BUDGET',
    'DOCUMENTS',
    'MESSAGES',
];

export const CASUALTY_FORM_TABS_ITEM_INSURANCE: string[] = [
    'REQUEST',
    'BUDGET',
    'DOCUMENTS',
];

export enum MessagesTabsEnum {
    RECEIVED = 'RECEIVED',
    SENT = 'SENT',
}

export const MESSAGES_TABS_ITEM: string[] = [
    'RECEIVED',
    'SENT',
];

export const BROKEN_GLASS_CONTROL_TABS_ITEM: string[] = [
    'ALL',
    'PENDING',
    'ONGOING',
    'DONE',
];

export enum BrokenGlassControlTabsEnum {
    ALL= 'ALL',
    PENDING= 'PENDING',
    ONGOING= 'ONGOING',
    DONE= 'DONE',
}

export enum PreInspectionsTabsEnum {
    ALL = 'ALL',
    COMPLETE = 'COMPLETE',
    GLASS = 'GLASS',
}

export const PRE_INSPECTIONS_TABS_ITEM: string[] = [
    'ALL',
    'COMPLETE',
    'GLASS',
];
