/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { Component } from 'react';
import { get } from 'lodash';
import moment from 'moment';
import { TranslationContextInterface, withTranslationContext } from '../controllers/translation/TranslationContext';
import { ICON, SvgIcon } from './SvgIcon';
import FormDatePicker from './FormDatePicker';
import { getFormErrors, IFormError, VALIDATIONS } from '../../utils/validation';
import Button from './Button';

/**
 * @typedef {Object} OwnProps
 * @extends {TranslationContext}
 * @property {Function} onModalClose
 * @property {Function} onSubmit
 */
interface OwnProps extends TranslationContextInterface {
    onModalClose: Function;
    onSubmit: Function;
    startDate?: string;
    endDate?: string;
}

/**
 * @typedef {Object} OwnState
 * @property {string|number} startDate
 * @property {string|number} endDate
 * @property {object|null} formErrors
 */
interface OwnState {
    startDate: Date | null;
    endDate: Date | null;
    formErrors: object | null;
}

const initialState: OwnState = {
    startDate: null,
    endDate: null,
    formErrors: null,
};

/**
 * property handler for outside click
 */
const modalOutsideId = 'modal-outside';

/**
 * shows the filter modal
 * @extends {Component<Props, State>}
 */
class FilterModal extends Component<OwnProps, OwnState> {
    state = initialState;

    componentDidMount(): void {
        const { startDate, endDate } = this.props;

        this.setState({
            startDate: startDate ? new Date(startDate) : null,
            endDate: endDate ? new Date(endDate) : null,
        });

        document.addEventListener('mousedown', this.handleOutsideClick);
    }

    componentWillUnmount(): void {
        document.removeEventListener('mousedown', this.handleOutsideClick);
    }

    /**
     * handles outside click
     * @param {any} e
     */
    handleOutsideClick = (e: any) => {
        const { target } = e;
        const { id } = target;

        if (id === modalOutsideId) {
            this.onClose();
        }
    };

    /**
     * handles modal close
     */
    onClose = () => {
        const { onModalClose } = this.props;
        onModalClose();
    };

    /**
     * handles input date change
     * @param {string} name
     * @param {any} date
     */
    onDateChange = (name: string, date: any) => {
        this.setState({
            ...this.state,
            [name]: date,
        });
    }

    /**
     * handles field validations
     * @returns {boolean}
     */
    validateFields = (): boolean => {
        let errors: IFormError | null = getFormErrors(this.state, VALIDATIONS.FILTER_FORM);

        if (errors && Object.keys(errors).length === 0) errors = null;

        this.setState({ formErrors: errors ? { fields: errors } : errors });

        return errors === null;
    }

    /**
     * handles filter value submition
     */
    onSubmitClick = () => {
        const { startDate, endDate } = this.state;
        const { onSubmit, onModalClose } = this.props;
        if (this.validateFields()) {
            if (endDate && startDate && startDate > endDate) {
                this.setState({
                    formErrors: {
                        fields: {
                            endDate: [
                                {
                                    typeOfViolation: 'InvalidEndDate',
                                },
                            ],
                        },
                    },
                });
                return;
            }

            const startTime = moment(startDate?.toISOString()).unix() * 1000;
            const endTime = endDate ? moment(endDate?.toISOString()).add(1, 'hours').unix() * 1000 : undefined;
            
            onSubmit(startTime, endTime);
            onModalClose();
        }
    }

    render() {
        const { t } = this.props;
        const { startDate, endDate, formErrors } = this.state;

        return (
            <div id={modalOutsideId} className="app-screen__modal" data-testid="modal-outside-test">
                <div className="app-screen__modal__container filter-date">
                    <div className="app-screen__modal__container__box">
                        <SvgIcon callback={this.onClose} icon={ICON.CROSS} />
                        <div className="app-screen__modal__container__box__content">
                            <div>
                                <div className="filter-element">
                                    <FormDatePicker
                                        name="startDate"
                                        label={t('filter.startDate')}
                                        value={startDate ? new Date(startDate) : null}
                                        onChange={this.onDateChange}
                                        errors={get(formErrors, 'fields.startDate', null)}
                                    />
                                </div>
                                <div className="filter-element">
                                    <FormDatePicker
                                        name="endDate"
                                        label={t('filter.endDate')}
                                        value={endDate ? new Date(endDate) : null}
                                        onChange={this.onDateChange}
                                        errors={get(formErrors, 'fields.endDate', null)}
                                    />
                                </div>
                                <div className="filter-element bigger">
                                    <Button
                                        text={t('filter.submit')}
                                        type="button"
                                        styles="btn--savePdf"
                                        callback={this.onSubmitClick}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withTranslationContext(FilterModal);
