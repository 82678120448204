/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { get } from 'lodash';
import axios from 'axios';
import { MatchParams } from '../../../constants/misc';
import { TranslationContextInterface, withTranslationContext } from '../../controllers/translation/TranslationContext';
import { AuthenticationContextInterface, withAuthenticationContext } from '../../controllers/authentication/AuthenticationContext';
import { insuranceUserResetKeyURL, insuranceUserURL, usersToggleURL } from '../../../services/users';
import { NOT_FOUND_ROUTE, USER_INSURANCE_DETAILS_ROUTE } from '../../../constants/routes';
import Loader from '../../elements/Loader';
import { ICON, SvgIcon } from '../../elements/SvgIcon';
import Button from '../../elements/Button';
import FormTextField from '../../elements/FormTextField';
import { getFormErrors, IFormError, VALIDATIONS } from '../../../utils/validation';
import { displayNotification, NOTIFICATION_TYPE } from '../../../utils/notifs';
import displayConfirm from '../../elements/displayConfirm';
import { VideoContextInterface, withVideoContext } from '../../controllers/video/VideoContext';
import { UserRoles } from '../../../constants/authorization';
import GeneralModal from '../../elements/GeneralModal';
import { WEB_URL } from '../../../settings';

interface OwnProps extends RouteComponentProps<MatchParams>, TranslationContextInterface, VideoContextInterface, AuthenticationContextInterface {}

interface OwnState {
    id: string;
    name: string;
    email: string;
    nif: string;
    contact: string;
    role: string;
    callbackURL: string;
    isFetching: boolean;
    preparing: boolean;
    inactive: boolean;
    formErrors: any;
    hasModifications: boolean;
    viewingDetails: boolean;
    registered: boolean;
    isEditable: boolean;
    apiKey: string;
    showResetKeyModal: boolean;
}

const initialState: OwnState = {
    id: '',
    name: '',
    email: '',
    nif: '',
    contact: '',
    isFetching: false,
    preparing: false,
    inactive: false,
    callbackURL: '',
    role: '',
    formErrors: {},
    hasModifications: false,
    viewingDetails: false,
    registered: false,
    isEditable: false,
    apiKey: '',
    showResetKeyModal: false,
};

class InsuranceUserEditScreen extends Component<OwnProps, OwnState> {
    state = initialState;

    componentDidMount(): void {
        const { match, user } = this.props;
        const { params, path } = match;
        const { id } = params;

        let isEditable = false;

        if (user && (user.role === UserRoles.ADMIN || user.role === UserRoles.COLLABORATOR)) {
            isEditable = true;
        }

        if (id) {
            let viewingDetails = false;
            if (path.startsWith(USER_INSURANCE_DETAILS_ROUTE)) {
                viewingDetails = true;
            }
            this.setState({
                id,
                viewingDetails,
                isEditable,
            }, () => { this.prepare(); });
        }
    }

    onToggleClick = () => {
        const { t } = this.props;
        const { id, inactive, name } = this.state;

        let msg: string = t('editUser.deactivateMessage', { name });
        if (inactive) msg = t('editUser.activateMessage', { name });

        displayConfirm({
            acceptButtonText: t('global.buttons.accept'),
            onAccept: () => this.toggleRequest(id),
            onReject: () => {},
            rejectButtonText: t('global.buttons.reject'),
            containerStyle: 'remove-document',
            title: msg,
        });
    };

    onBackClick = () => {
        const { history } = this.props;
        history.goBack();
    };

    onSave = () => {
        if (this.validateForm()) {
            const fields = {
                name: String(this.state.name).trim(),
                email: String(this.state.email).trim(),
                contact: String(this.state.contact).trim(),
                nif: String(this.state.nif).trim(),
                callback: String(this.state.callbackURL).trim(),
            };

            const formData = new FormData();
            formData.append('userRequest', new Blob([JSON.stringify(fields)], {
                type: 'application/json',
            }));

            this.editUserRequest(formData);
        }
    };

    onInputChange = (e: React.FormEvent<HTMLInputElement>) => {
        this.setState({
            ...this.state,
            [e.currentTarget.name]: e.currentTarget.value,
            hasModifications: true,
        });
    };

    onClickDownloadCallbackJson = () => {
        const { t } = this.props;
        const {
            callbackURL,
        } = this.state;

        if (!callbackURL) {
            displayNotification(NOTIFICATION_TYPE.ERROR, t('editUser.errorCallbackNotDefined'));
            return;
        }
        this.downloadPVVJson();
        this.downloadPVCJson();
    }

    downloadPVVJson = () => {
        const link = document.createElement('a');
        link.href = `${WEB_URL}/files/fluxe-pvv-callback-example.json`;
        link.setAttribute('download', 'fluxe-pvv-callback-example.json');
        link.click();
    }

    downloadPVCJson = () => {
        const link = document.createElement('a');
        link.href = `${WEB_URL}/files/fluxe-pvc-callback-example.json`;
        link.setAttribute('download', 'fluxe-pvc-callback-example.json');
        link.click();
    }

    onResetApiKeyClick = () => {
        const { id, isFetching } = this.state;
        const { t } = this.props;

        if (isFetching) return;

        this.setState({
            isFetching: true,
        });

        axios.put(insuranceUserResetKeyURL(id)).then(() => {
            this.setState({
                isFetching: false,
                showResetKeyModal: false,
            }, () => this.prepare());
        }).catch(error => {
            displayNotification(NOTIFICATION_TYPE.ERROR, t('editUser.errorApiKey'));
            this.setState({
                isFetching: false,
                showResetKeyModal: false,
            });
        });
    }

    toggleRequest = async (id: number | string) => {
        const { t } = this.props;
        const { inactive: oldInactive } = this.state;

        this.setState({ isFetching: true });

        await axios.post(usersToggleURL(id))
            .then(response => {
                const { inactive } = response.data;

                if (inactive) displayNotification(NOTIFICATION_TYPE.SUCCESS, t('editUser.deactivateSuccess'));
                else displayNotification(NOTIFICATION_TYPE.SUCCESS, t('editUser.activateSuccess'));

                this.setState({
                    isFetching: false,
                    inactive,
                });
            })
            .catch(() => {
                if (oldInactive) displayNotification(NOTIFICATION_TYPE.ERROR, t('editUser.deactivateError'));
                else displayNotification(NOTIFICATION_TYPE.ERROR, t('editUser.activateError'));

                this.setState({ isFetching: false });
            });
    };

    editUserRequest = async (fields: {}) => {
        const { t, user } = this.props;
        const { id } = this.state;

        this.setState({ isFetching: true });

        const config = {
            headers: {
                'content-type': 'multipart/form-data',
            },
        };

        await axios.put(insuranceUserURL(id), fields, config)
            .then(() => {
                displayNotification(NOTIFICATION_TYPE.SUCCESS, t('editUser.successNotif'));
                this.setState({ isFetching: false });
                if (user && user.role === UserRoles.INSURANCE_USER) return;
                this.onBackClick();
            })
            .catch(error => {
                displayNotification(NOTIFICATION_TYPE.ERROR, t('editUser.errorNotif'));
                this.setState({ isFetching: false });
                if (error.response) {
                    this.handleResponse(error.response.data);
                }
            });
    };

    handleResponse = (formErrors: any = null) => {
        if (formErrors && Object.keys(formErrors).length > 0) {
            this.setState({
                formErrors,
                isFetching: false,
            });
        } else {
            this.setState({ isFetching: false });
        }
    };

    validateForm = () => {
        let errors: IFormError | null = getFormErrors(this.state, VALIDATIONS.USER_EDIT_FORM);

        if (errors && Object.keys(errors).length === 0) errors = null;

        this.setState({ formErrors: errors ? { fields: errors } : errors });
        return errors === null;
    };

    prepare = async () => {
        const { history } = this.props;
        const { id, preparing } = this.state;

        if (preparing) return;

        this.setState({ preparing: true });

        await axios.get(insuranceUserURL(id))
            .then(response => {
                const { data } = response;

                this.setState({
                    preparing: false,
                    name: data.name,
                    email: data.email,
                    contact: data.contact || '',
                    inactive: data.inactive,
                    nif: data.nif || '',
                    role: data.role,
                    registered: data.registered,
                    apiKey: data.apiKey,
                    callbackURL: data.callbackURL,
                });
            })
            .catch(error => {
                if (error.response) {
                    const { status } = error.response;
                    if (status === 403) {
                        history.push(NOT_FOUND_ROUTE);
                    }
                }
                this.setState({ preparing: false });
            });
    };

    render() {
        const { t, onlineInsuranceUsers } = this.props;
        const {
            id,
            preparing,
            isFetching,
            name,
            email,
            contact,
            nif,
            inactive,
            formErrors,
            role,
            hasModifications,
            viewingDetails,
            registered,
            isEditable,
            apiKey,
            callbackURL,
            showResetKeyModal,
        } = this.state;

        const showLoader: boolean = isFetching || preparing;
        const isOnline = onlineInsuranceUsers.includes(Number(id));

        return (
            <div>
                {showLoader && (
                    <div className="loader-wrapper">
                        <Loader />
                    </div>
                )}
                <div className="app-screen__form-edit-user">
                    <div className="app-screen__form-edit-user__top-container">
                        <div className="info-container">
                            <SvgIcon icon={ICON.LEFT_ARROW} callback={this.onBackClick} />
                            <div className="info">
                                <p className="title">{t('editUser.smallTitle')}</p>
                                <p className="user-name">{name}</p>
                                {registered === false ? (
                                    <div className="status-dot orange-circle" />
                                ) : (
                                    <div className={inactive ? 'status-dot red-circle' : `${isOnline ? ' status-dot green-circle' : 'status-dot yellow-circle'}`} />
                                )}
                            </div>
                        </div>
                        {!viewingDetails && (
                            <div className="buttons-container">
                                <Button
                                    text={t('global.buttons.saveModifications')}
                                    type="submit"
                                    styles="btn--purple"
                                    callback={this.onSave}
                                    disabled={!hasModifications}
                                />
                                {isEditable && (
                                    <Button
                                        text={inactive ? t('global.buttons.activate') : t('global.buttons.deactivate')}
                                        callback={this.onToggleClick}
                                        styles="btn--green"
                                        iconPosition="right"
                                    />
                                )}
                            </div>
                        )}
                    </div>
                    <div className="app-screen__form-edit-user__container">
                        <div className="app-screen__form-edit-user__container__content">
                            <h3>{t('editUser.subtitles.personalData')}</h3>
                            <div className="row">
                                <div className="col-sm-8">
                                    <FormTextField
                                        fieldStyles="with-roboto"
                                        label={t('editUser.labels.name')}
                                        name="name"
                                        value={name}
                                        onChange={this.onInputChange}
                                        errors={get(formErrors, 'fields.name', null)}
                                        disabled={viewingDetails || !isEditable}
                                    />
                                </div>
                                <div className="col-sm-4">
                                    <FormTextField
                                        fieldStyles="with-roboto"
                                        label={t('editUser.labels.type')}
                                        name="role"
                                        value={t(`enums.userRoles.${role}`)}
                                        disabled
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-4">
                                    <FormTextField
                                        fieldStyles="with-roboto"
                                        label={t('editUser.labels.nif')}
                                        name="nif"
                                        value={nif}
                                        onChange={this.onInputChange}
                                        errors={get(formErrors, 'fields.nif', null)}
                                        disabled={viewingDetails || !isEditable}
                                    />
                                </div>
                            </div>
                            <h3 className="subtitle-with-margin">{t('editUser.subtitles.contactData')}</h3>
                            <div className="row">
                                <div className="col-sm-4">
                                    <FormTextField
                                        fieldStyles="with-roboto"
                                        label={t('editUser.labels.contact')}
                                        name="contact"
                                        value={contact}
                                        onChange={this.onInputChange}
                                        errors={get(formErrors, 'fields.contact', null)}
                                        disabled={viewingDetails || !isEditable}
                                    />
                                </div>
                                <div className="col-sm-4">
                                    <FormTextField
                                        fieldStyles="with-roboto"
                                        label={t('editUser.labels.email')}
                                        name="email"
                                        value={email}
                                        disabled
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-6">
                                    <FormTextField
                                        fieldStyles="with-roboto"
                                        label={t('editUser.labels.apiKey')}
                                        name="apiKey"
                                        value={apiKey}
                                        onChange={() => {}}
                                        disabled
                                    />
                                </div>
                                <div className="col-sm-2">
                                    <Button
                                        text={t('editUser.labels.reset')}
                                        callback={() => {
                                            this.setState({
                                                showResetKeyModal: true,
                                            });
                                        }}
                                        styles="btn--dark-purple btn--full-width"
                                        iconPosition="right"
                                    />
                                </div>
                                <div className="col-sm-8">
                                    <FormTextField
                                        fieldStyles="with-roboto"
                                        label={t('editUser.labels.callbackURL')}
                                        name="callbackURL"
                                        value={callbackURL}
                                        onChange={this.onInputChange}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-8" />
                                <div className="col-sm-8">
                                    <div className="dispute-with-link">
                                        {t('editUser.labels.callbackDescription')}
                                        <span onClick={this.onClickDownloadCallbackJson}>{t('editUser.labels.callbackClickHere')}</span>
                                        {t('editUser.labels.callbackDescription2')}
                                        <span onClick={this.downloadPVVJson}>{t('editUser.labels.callbackGlass')}</span>
                                        {t('editUser.labels.callbackOr')}
                                        <span onClick={this.downloadPVCJson}>{t('editUser.labels.callbackComplete')}</span>.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {showResetKeyModal
                    && (
                        <GeneralModal
                            onModalClose={() => {
                                this.setState({
                                    showResetKeyModal: false,
                                });
                            }}
                            containerStyle="document-edit-container"
                            boxStyle="document-edit-box"
                            boxContentStyle="document-edit-box-content"
                        >
                            <div className="mt-10 text-bold">
                                {t('editUser.modal.description')}
                            </div>
                            <div className="text-small">
                                {t('editUser.modal.description1')}
                            </div>
                            <Button
                                text={t('global.buttons.ok')}
                                callback={this.onResetApiKeyClick}
                                styles="btn--purple"
                            />
                        </GeneralModal>
                    )}
                </div>
            </div>
        );
    }
}

export default withAuthenticationContext(withVideoContext(withTranslationContext(InsuranceUserEditScreen)));
