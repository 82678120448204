/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { Component } from 'react';
import axios from 'axios';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { TranslationContextInterface, withTranslationContext } from '../controllers/translation/TranslationContext';
import withPaging, { WithPagingProps } from '../hocs/withPaging';
import withSort, { WithSortProps } from '../hocs/withSort';
import { CasualtyRequest, Message } from '../../constants/types';
import Loader from './Loader';
import { RESULTS_PER_PAGE, MatchParams } from '../../constants/misc';
import TableHeader from './TableHeader';
import TableCell, { TableCellActions, TableCellType } from './TableCell';
import TablePaging from './TablePaging';
import { messagesURL } from '../../services/messages';
import MessageModal from './MessageModal';
import {
    AuthenticationContextInterface,
    withAuthenticationContext,
} from '../controllers/authentication/AuthenticationContext';
import { UserRoles } from '../../constants/authorization';
import { NOT_FOUND_ROUTE } from '../../constants/routes';

interface OwnProps extends TranslationContextInterface, WithPagingProps, WithSortProps, AuthenticationContextInterface, RouteComponentProps<MatchParams> {
    casualty: CasualtyRequest;
    count: number;
}

interface OwnState {
    isFetching: boolean;
    messages: Message[];
    _limit: number;
    totalResults: number;
    actions: TableCellActions[];
    viewingMessage: boolean;
    messageToView: Message | null;
}

const initialState: OwnState = {
    isFetching: false,
    messages: [],
    _limit: RESULTS_PER_PAGE,
    totalResults: 0,
    actions: [],
    viewingMessage: false,
    messageToView: null,
};

class CasualtyMessagesTab extends Component<OwnProps, OwnState> {
    state = initialState;

    componentDidMount(): void {
        const { user, history } = this.props;
        let role = '';

        if (user) role = user.role;

        if (role === UserRoles.INSURANCE_USER) {
            history.push(NOT_FOUND_ROUTE);
            return;
        }
        this.loadList();
    }

    componentDidUpdate(prevProps: Readonly<OwnProps>, prevState: Readonly<OwnState>, snapshot?: any) {
        const {
            currentPage, sortValue, sortOrder, count,
        } = this.props;
        const {
            currentPage: oldCurrentPage, sortOrder: oldSortOrder, sortValue: oldSortValue, count: oldCount,
        } = prevProps;

        const hasCountChanged: boolean = count !== oldCount;
        const hasPagingChanged: boolean = currentPage !== oldCurrentPage;
        const hasSortValueChanged: boolean = sortValue !== oldSortValue;
        const hasSortOrderChanged: boolean = sortOrder !== oldSortOrder;

        if (hasPagingChanged || hasSortOrderChanged || hasSortValueChanged || hasCountChanged) {
            this.loadList();
        }
    }

    onMessageClick = (msg: Message) => {
        this.setState({ viewingMessage: true, messageToView: msg });
    };

    onModalClose = () => {
        this.setState({ viewingMessage: false, messageToView: null });
    };

    onEditClick = (id: number) => {
        // todo
    };

    onDeleteClick = (id: number) => {
        // todo
    };

    loadList = async () => {
        const {
            sortValue,
            sortOrder,
            currentPage,
            casualty,
        } = this.props;
        const { isFetching, _limit } = this.state;

        if (isFetching) return;

        this.setState({ isFetching: true });

        try {
            let sort: string = sortValue;
            let order: string = sortOrder;

            if (!sort) {
                sort = 'createdDate';
                order = 'desc';
            }

            const { data, headers } = await axios.get(messagesURL({
                _page: currentPage - 1,
                _limit,
                _sort: sort,
                _order: order,
                casualtyId: casualty.id,
            }));

            const newTotalResults: number = parseInt(headers['x-total-count']);

            this.setState({
                messages: data,
                totalResults: newTotalResults,
                isFetching: false,
            });
        } catch (error) {
            this.setState({
                isFetching: false,
            });
        }
    };

    render() {
        const {
            t, sortValue, onSortChange, sortOrder, onPagingChange, currentPage,
        } = this.props;
        const {
            isFetching, messages, _limit, actions, totalResults, viewingMessage, messageToView,
        } = this.state;
        const hasData: boolean = messages && messages.length > 0;

        return (
            <div>
                {isFetching && (
                    <div className="loader-wrapper">
                        <Loader />
                    </div>
                )}
                <div className="app-screen__table">
                    <div className="app-screen__table__content">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>
                                        <TableHeader
                                            text={t('messages.list.headers.date')}
                                            sort={sortValue}
                                            order={sortOrder}
                                            field="createdDate"
                                            sortable
                                            onSortChange={onSortChange}
                                        />
                                    </th>
                                    <th>
                                        <TableHeader
                                            text={t('messages.list.headers.from')}
                                            sort={sortValue}
                                            order={sortOrder}
                                            field="from"
                                            sortable
                                            onSortChange={onSortChange}
                                        />
                                    </th>
                                    <th>
                                        <TableHeader
                                            text={t('messages.list.headers.to')}
                                            sort={sortValue}
                                            order={sortOrder}
                                            field="to"
                                            sortable
                                            onSortChange={onSortChange}
                                        />
                                    </th>
                                    <th>
                                        <TableHeader
                                            text={t('messages.list.headers.subject')}
                                            sort={sortValue}
                                            order={sortOrder}
                                            field="subject"
                                            sortable
                                            onSortChange={onSortChange}
                                        />
                                    </th>
                                    <th>
                                        <TableHeader
                                            text=""
                                            sortable={false}
                                        />
                                    </th>
                                </tr>
                            </thead>
                            { hasData ? (
                                <tbody>
                                    { messages.map(message => {
                                        const {
                                            id, from, to, subject, createdDate, seen,
                                        } = message;

                                        return (
                                            <tr data-testid="row-table-test" key={id} className={seen ? '' : 'not-seen'} onClick={() => this.onMessageClick(message)}>
                                                <TableCell
                                                    type={TableCellType.DATE}
                                                    value={createdDate}
                                                />
                                                <TableCell
                                                    value={from.name}
                                                />
                                                <TableCell
                                                    value={to.name}
                                                />
                                                <TableCell
                                                    value={subject}
                                                />
                                                <TableCell
                                                    type={TableCellType.ACTIONS}
                                                    actions={actions}
                                                    cellClass="actions-cell"
                                                    onEdit={() => this.onEditClick(Number(id))}
                                                    onDelete={() => this.onDeleteClick(Number(id))}
                                                />
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            ) : (
                                <tbody className="no-data" data-testid="casualty-message-no-data-test">
                                    <tr>
                                        <td colSpan={5}>{t('global.noData')}</td>
                                    </tr>
                                </tbody>
                            )}
                        </table>
                        {hasData && (
                            <TablePaging
                                currentPage={currentPage}
                                limit={_limit}
                                totalResults={totalResults}
                                onStartChange={onPagingChange}
                            />
                        )}
                    </div>
                </div>
                {(viewingMessage && messageToView) && <MessageModal onModalClose={this.onModalClose} messageToView={messageToView} />}
            </div>
        );
    }
}

export default withRouter(withAuthenticationContext(withPaging(withSort(withTranslationContext(CasualtyMessagesTab)))));
