/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

export type IErrorCode = {
    [key: number]: string;
}

export const ERROR_CODE: IErrorCode = {
    0: 'general',
    1: 'multiple',
    3: 'emailAlreadyInUse',
    5: 'invalidPasswordOrUsername',
    10: 'lockedUser',
    15: 'preInspectionIneligibleForThisOperation',
    17: 'setAPassword',
    19: 'preInspectionStillMissingData',
};
