/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import { API_URL } from '../settings';
import { objectToParams } from '../utils/misc';

export const preInspectionsURL = (params: object = {}) => {
    const queryString = objectToParams(params);
    return `${API_URL}/pre-inspections${queryString}`;
};

export const preInspectionCompleteURL = () => {
    return `${API_URL}/pre-inspections/complete`;
};

export const preInspectionCompleteUpdateURL = (id: string | number) => {
    return `${API_URL}/pre-inspections/complete/${id}/update`;
};

export const preInspectionURL = (id: string | number) => {
    return `${API_URL}/pre-inspections/${id}`;
};

export const preInspectionCompleteCancelURL = (id: string | number) => {
    return `${API_URL}/pre-inspections/complete/${id}/cancel`;
};

export const preInspectionCompleteCloseURL = (id: string | number) => {
    return `${API_URL}/pre-inspections/complete/${id}/close`;
};

export const preInspectionCompleteReopenURL = (id: string | number) => {
    return `${API_URL}/pre-inspections/complete/${id}/reopen`;
};

export const preInspectionResetTokenURL = (id: string | number) => {
    return `${API_URL}/pre-inspections/${id}/reset`;
};

export const preInspectionCompleteDocumentURL = (id: string | number, docId: string | number) => {
    return `${API_URL}/pre-inspections/complete/${id}/documents/${docId}`;
};

export const preInspectionCompleteResumeURL = (id: string | number) => {
    return `${API_URL}/pre-inspections/complete/${id}/documents/resume`;
};

export const preInspectionGlassURL = () => {
    return `${API_URL}/pre-inspections/glass`;
};

export const preInspectionGlassCancelURL = (id: string | number) => {
    return `${API_URL}/pre-inspections/glass/${id}/cancel`;
};

export const preInspectionGlassRestartURL = (id: string | number) => {
    return `${API_URL}/pre-inspections/glass/${id}/restart`;
};

export const preInspectionGlassCloseURL = (id: string | number) => {
    return `${API_URL}/pre-inspections/glass/${id}/close`;
};

export const preInspectionGlassReopenURL = (id: string | number) => {
    return `${API_URL}/pre-inspections/glass/${id}/reopen`;
};

export const preInspectionGlassUpdateURL = (id: string | number) => {
    return `${API_URL}/pre-inspections/glass/${id}/update`;
};

export const preInspectionGlassResumeURL = (id: string | number) => {
    return `${API_URL}/pre-inspections/glass/${id}/documents/resume`;
};

export const preInspectionVideoTokenURL = (id: string | number) => {
    return `${API_URL}/pre-inspections/${id}/video`;
};

export const preInspectionGlassReEvaluateURL = (id: string | number) => {
    return `${API_URL}/pre-inspections/glass/${id}/re-evaluate`;
};

export const preInspectionFileComments = (fileId: number, commentId?: number) => {
    return commentId ? `${API_URL}/files/${fileId}/comments/${commentId}` : `${API_URL}/files/${fileId}/comments`;
};
