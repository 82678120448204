/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { Component } from 'react';
import {
    TranslationContextInterface,
    withTranslationContext,
} from '../controllers/translation/TranslationContext';
import { ERROR_CODE } from '../../utils/errors';
import { IError } from '../../utils/validation';

export enum ERROR_MESSAGE_TYPE {
    FIELD = 'field',
    LOGIC = 'error',
}

interface OwnProps extends TranslationContextInterface {
    type?: ERROR_MESSAGE_TYPE;
    errors: IError[];
    field?: string;
}

class ErrorMessage extends Component<OwnProps> {
    getGeneralError = (error: IError) => {
        const { t } = this.props;
        const { errorCode } = error;
        let message = '';

        if (errorCode !== null && errorCode !== undefined) {
            message = t(`errors.${ERROR_CODE[errorCode]}`);
        }
        return message;
    };

    getFieldErrorMessage = (error: IError) => {
        const { t } = this.props;
        const {
            min, max, size, typeOfViolation,
        } = error;

        let message = '';

        if (typeOfViolation !== null) {
            switch (typeOfViolation) {
                case 'Size':
                    if (min && max) {
                        message = t('errors.Size', { min, max });
                    } else if (min) {
                        message = t('errors.SizeMin', { min });
                    } else if (max) {
                        message = t('errors.SizeMax', { max });
                    }
                    break;
                case 'SizeExact':
                    if (size) {
                        message = t('errors.SizeExact', { size });
                    }
                    break;
                case 'Max':
                    if (max) {
                        message = t('errors.Max', { max });
                    }
                    break;
                case 'Min':
                    if (min) {
                        message = t('errors.Min', { min });
                    }
                    break;
                case 'MinLength':
                    if (min) {
                        message = t('errors.MinLength', { min });
                    }
                    break;
                case 'MaxLength':
                    if (max) {
                        message = t('errors.MaxLength', { max });
                    }
                    break;
                case 'MaxLengthDecimal':
                    if (max) {
                        message = t('errors.MaxLengthDecimal', { max });
                    }
                    break;
                default:
                    message = t(`errors.${typeOfViolation}`);
                    break;
            }
        }

        return message;
    };

    getErrorMessage = (error: IError) => {
        const { type } = this.props;

        let message = '';

        if (type) {
            switch (type) {
                case ERROR_MESSAGE_TYPE.FIELD:
                    message = this.getFieldErrorMessage(error);
                    break;
                case ERROR_MESSAGE_TYPE.LOGIC:
                    message = this.getGeneralError(error);
                    break;
                default:
                    break;
            }
        } else {
            message = this.getFieldErrorMessage(error);
        }

        return message;
    };

    render() {
        const {
            field,
            errors,
        } = this.props;

        if (!errors) return null;
        const [error] = errors;

        return (
            <span
                data-field={field}
                className="field-error"
                data-testid="error-message-test"
            >
                {this.getErrorMessage(error)}
            </span>
        );
    }
}

export default withTranslationContext(ErrorMessage);
