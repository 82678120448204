/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { Component } from 'react';
import moment from 'moment';
import FileSaver from 'file-saver';
import { VideoContextInterface, withVideoContext } from '../controllers/video/VideoContext';
import { AuthenticationContextInterface, withAuthenticationContext } from '../controllers/authentication/AuthenticationContext';
import { SocketReceivedMessage, SocketReceivedMessageType, SocketSendMessageType } from '../../constants/socket_types';
import { UserRoles } from '../../constants/authorization';
import { ICON, SvgIcon } from './SvgIcon';
import { displayNotification, NOTIFICATION_TYPE } from '../../utils/notifs';
import { TranslationContextInterface, withTranslationContext } from '../controllers/translation/TranslationContext';
import Button from './Button';
import { STUN_SERVER_URL, TURN_SERVER_URL } from '../../settings';
import { asyncForEach } from '../../utils/misc';

interface OwnProps extends VideoContextInterface, AuthenticationContextInterface, TranslationContextInterface {
    userId?: number | null;
    isMakingCall: boolean;
    casualtyId?: number;
    casualtyUid?: string;
    mechanicName?: string | null;
    onCallTerminate(): void;
    uploadFile?(file: FormData, total: number): void;
    onConfigError(): void;
    pendingCandidates: Array<SocketReceivedMessage>;
    onVideoSizeChange?(): void;
    isPreInspection?: boolean;
}

interface OwnState {
    audioMuted: boolean;
    calling: boolean;
    isBig: boolean;
    showCancelCallBtn: boolean;
}

class VideoComponent extends Component<OwnProps, OwnState> {
    peerConnection: RTCPeerConnection | null = null;

    peerConnectionConfig: RTCConfiguration | null = null;

    pendingIceCandidates: Array<SocketReceivedMessage> = [];

    localStream: MediaStream;

    remoteStream: MediaStream;

    constructor(props: OwnProps) {
        super(props);
        const { sendSocketMessage } = props;

        this.remoteStream = new MediaStream();
        this.localStream = new MediaStream();

        this.state = {
            audioMuted: false,
            calling: false,
            isBig: false,
            showCancelCallBtn: false,
        };

        const signal = {
            messageType: SocketSendMessageType.GET_TURN_CREDENTIALS,
        };

        sendSocketMessage(JSON.stringify(signal));
    }

    componentDidMount(): void {
        const { turnCredential, isMakingCall, turnUsername } = this.props;

        this.peerConnectionConfig = {
            iceServers: [
                {
                    urls: [
                        String(STUN_SERVER_URL),
                    ],
                },
                {
                    urls: [
                        String(TURN_SERVER_URL),
                    ],
                    credential: String(turnCredential),
                    username: String(turnUsername),
                },
            ],
            iceCandidatePoolSize: 10,
        };

        if (isMakingCall) {
            this.makeCall();
            setTimeout(() => this.setState({ showCancelCallBtn: true }), 1000);
        } else {
            this.pendingIceCandidates = this.props.pendingCandidates;
            this.answerCall();
        }
    }

    componentDidUpdate(prevProps: Readonly<OwnProps>, prevState: Readonly<{}>, snapshot?: any): void {
        const {
            signalMessage: oldSignalMessage,
        } = prevProps;
        const {
            wasIceCandidateFound,
            callAnswered,
            signalMessage,
            declineCall,
            callDeclined,
        } = this.props;

        if (wasIceCandidateFound && signalMessage && signalMessage !== oldSignalMessage && signalMessage.messageType === SocketReceivedMessageType.ICE_CANDIDATE_FOUND) {
            this.addIceCandidate();
        }

        if (callAnswered && signalMessage && signalMessage !== oldSignalMessage && signalMessage.messageType === SocketReceivedMessageType.CALL_ANSWERED) {
            this.parseCallAnswer();
            return;
        }

        if (declineCall || callDeclined) {
            this.closePeer();
        }
    }

    componentWillUnmount(): void {
        this.onHangUpClick();
    }

    onPrintScreenClick = () => {
        const { isBig } = this.state;
        const canvas: HTMLCanvasElement | null = document.querySelector('#print-canvas');
        const remoteVideo: HTMLVideoElement | null = document.querySelector('#remote-video');
        const divVideoContainer: HTMLDivElement | null = document.querySelector('#video-container');

        if (canvas && remoteVideo && divVideoContainer) {
            const oldCanvasHeight = canvas.height;
            const oldCanvasWidth = canvas.width;

            if (isBig) {
                canvas.width = divVideoContainer.offsetWidth;
                canvas.height = divVideoContainer.offsetHeight * 1.5;
            } else {
                canvas.width = divVideoContainer.offsetWidth * 2.5;
                canvas.height = divVideoContainer.offsetHeight * 4;
            }

            const ctx = canvas.getContext('2d');

            if (ctx) {
                ctx.save();

                ctx.drawImage(remoteVideo, 0, 0, canvas.width, canvas.height);
                ctx.restore();
                canvas.toBlob(this.onSave);
                canvas.width = oldCanvasWidth;
                canvas.height = oldCanvasHeight;
            }
        }
    };

    onSave = (file: Blob | null) => {
        const { uploadFile, isPreInspection } = this.props;

        if (file) {
            const todayDate: string = moment().format('DD-MM-YYYY_HH:mm:ss');
            const fileName = `printScreen_${todayDate}.png`;

            if (isPreInspection) {
                FileSaver.saveAs(file, fileName);
            } else if (uploadFile) {
                const formData = new FormData();
                formData.append('file', file, fileName);
                formData.append('fileName', fileName);

                uploadFile(formData, 1);
            }
        }
    };

    onCancelCall = () => {
        const { sendSocketMessage, onCallTerminate, resetVideoFlags } = this.props;

        const signal = {
            messageType: SocketSendMessageType.CANCEL_OFFER,
        };

        sendSocketMessage(JSON.stringify(signal));
        onCallTerminate();

        if (!this.peerConnection) return;

        this.peerConnection.close();

        this.localStream.getTracks().forEach(track => {
            track.stop();
        });

        resetVideoFlags();
    };

    onMicrophoneClick = () => {
        const tracks = this.localStream.getAudioTracks();

        for (let i = 0; i < tracks.length; i++) {
            tracks[i].enabled = !tracks[i].enabled;

            this.setState({
                audioMuted: !tracks[i].enabled,
            });
        }
    };

    onToggleVideoSize = () => {
        const { onVideoSizeChange } = this.props;
        const { isBig } = this.state;

        this.setState({
            isBig: !isBig,
        });

        if (onVideoSizeChange) onVideoSizeChange();
    };

    onHangUpClick = () => {
        const { sendSocketMessage, onCallTerminate, resetVideoFlags } = this.props;
        const { calling } = this.state;

        let signal = {
            messageType: SocketSendMessageType.TERMINATE_OFFER,
        };

        if (calling) {
            signal = {
                messageType: SocketSendMessageType.CANCEL_OFFER,
            };
        }

        sendSocketMessage(JSON.stringify(signal));
        onCallTerminate();

        this.localStream.getTracks().forEach(track => {
            track.stop();
        });

        if (!this.peerConnection) return;

        this.peerConnection.close();

        resetVideoFlags();
    };

    openUserMedia = async () => {
        const { t, user } = this.props;
        const isFluxeUser = user && (user.role === UserRoles.ADMIN || user.role === UserRoles.EXTERNAL_ADMIN || user.role === UserRoles.COLLABORATOR);

        try {
            let hasVideo = true;
            if (isFluxeUser) {
                hasVideo = false;
            }

            let stream = null;

            const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
            if (isMobile) {
                stream = await navigator.mediaDevices.getUserMedia(
                    {
                        video: {
                            facingMode: {
                                exact: 'environment',
                            },
                        },
                        audio: true,
                    },
                );
            } else {
                stream = await navigator.mediaDevices.getUserMedia(
                    { video: hasVideo, audio: true },
                );
            }

            const localVideoElement = document.querySelector<HTMLVideoElement>('#local-video');
            if (localVideoElement) {
                localVideoElement.srcObject = stream;
            }

            this.localStream = stream;

            const remoteVideoElement = document.querySelector<HTMLVideoElement>('#remote-video');
            if (remoteVideoElement) {
                remoteVideoElement.srcObject = this.remoteStream;
            }

            return true;
        } catch (e) {
            if (isFluxeUser) {
                displayNotification(NOTIFICATION_TYPE.ERROR, t('errors.cannotOpenAudioSource'));
            } else {
                displayNotification(NOTIFICATION_TYPE.ERROR, t('errors.cannotOpenVideoAudioSource'));
            }
            return false;
        }
    };

    parseCallAnswer = async () => {
        const { signalMessage } = this.props;

        this.addIceCandidate();

        if (this.peerConnection && !this.peerConnection.currentRemoteDescription && signalMessage) {
            const answer = new RTCSessionDescription(signalMessage);
            await this.peerConnection.setRemoteDescription(answer);
        }

        this.setState({ calling: false });
    };

    addIceCandidate = async (pendingSignal?: SocketReceivedMessage) => {
        const { signalMessage } = this.props;

        const signal = pendingSignal || signalMessage;
        if (!signal) {
            return;
        }

        if (this.peerConnection === null) {
            this.pendingIceCandidates.push(signal);
            return;
        }

        if (!signal.candidate) return;
        try {
            await this.peerConnection.addIceCandidate(new RTCIceCandidate(signal.candidate));
        } catch (e) {
        }
    };

    stop = () => {
        const audioControlElement = document.getElementById('audio-control');
        if (audioControlElement) {
            (audioControlElement as HTMLAudioElement).pause();
            (audioControlElement as HTMLAudioElement).muted = true;
            (document.getElementById('audio-control') as HTMLAudioElement).currentTime = 0;
        }
    }

    answerCall = async () => {
        const { sendSocketMessage, incomingCallMessage } = this.props;
        this.stop();

        if (!incomingCallMessage) return;

        const ableToOpenMedia: boolean = await this.openUserMedia();

        if (!ableToOpenMedia || !this.peerConnectionConfig) {
            return;
        }

        this.peerConnection = new RTCPeerConnection(this.peerConnectionConfig);

        const that = this;

        this.peerConnection.addEventListener('iceconnectionstatechange', event => {
            if (this.peerConnection && (this.peerConnection.iceConnectionState === 'failed'
            || this.peerConnection?.iceConnectionState === 'disconnected')) {
                this.peerConnection.createOffer({ iceRestart: true })
                    .then(offer => {
                        return that.peerConnection!!.setLocalDescription(offer);
                    }).catch(() => {});
            }
        });

        this.addVideoToCall();
        this.shareICECandidates();
        this.listenToRemoteVideo();

        await this.peerConnection.setRemoteDescription(incomingCallMessage);

        const answer = await this.peerConnection.createAnswer({
            offerToReceiveAudio: true,
            offerToReceiveVideo: true,
        });

        await this.peerConnection.setLocalDescription(answer);

        await asyncForEach(this.pendingIceCandidates, async (obj: SocketReceivedMessage) => {
            await this.addIceCandidate(obj);
        });

        this.pendingIceCandidates = [];

        const answerSignal = {
            messageType: SocketSendMessageType.ACCEPT_OFFER,
            to: incomingCallMessage.from,
            type: answer.type,
            sdp: answer.sdp,
        };

        sendSocketMessage(JSON.stringify(answerSignal));
    };

    makeCall = async () => {
        const {
            sendSocketMessage,
            userId,
            user,
            casualtyId,
            casualtyUid,
            mechanicName,
            onConfigError,
        } = this.props;

        try {
            this.setState({ calling: true });

            let callTo = 0;
            let isAdminOrCollab = false;
            if (user && userId && (user.role === UserRoles.ADMIN || user.role === UserRoles.EXTERNAL_ADMIN || user.role === UserRoles.COLLABORATOR)) {
                callTo = userId;
                isAdminOrCollab = true;
            }

            const ableToOpenMedia: boolean = await this.openUserMedia();

            if (!ableToOpenMedia || !this.peerConnectionConfig) {
                return;
            }

            this.peerConnection = new RTCPeerConnection(this.peerConnectionConfig);

            this.addVideoToCall();
            this.shareICECandidates();

            const offer = await this.peerConnection.createOffer({
                offerToReceiveAudio: true,
                offerToReceiveVideo: true,
            });

            await this.peerConnection.setLocalDescription(offer);

            const offerSignal = {
                messageType: SocketSendMessageType.CREATE_OFFER,
                to: callTo,
                type: offer.type,
                sdp: offer.sdp,
                casualtyId,
                casualtyUid,
                mechanicName: !isAdminOrCollab && mechanicName ? mechanicName : null,
            };

            sendSocketMessage(JSON.stringify(offerSignal));

            this.listenToRemoteVideo();
        } catch (e) {
            onConfigError();
        }
    };

    listenToRemoteVideo = () => {
        if (!this.peerConnection) return;

        this.peerConnection.addEventListener('track', event => {
            if (event.streams.length === 0) return;
            event.streams[0].getTracks().forEach(track => {
                this.remoteStream.addTrack(track);
            });
        });
    };

    shareICECandidates = () => {
        const { sendSocketMessage } = this.props;
        if (this.peerConnection === null) return;

        this.peerConnection.addEventListener('icecandidate', event => {
            if (!event.candidate) return;

            const iceCandidate = {
                messageType: SocketSendMessageType.OFFER_ICE_CANDIDATE,
                candidate: event.candidate,
            };

            sendSocketMessage(JSON.stringify(iceCandidate));
        });
    };

    addVideoToCall = () => {
        this.localStream.getTracks().forEach(track => {
            if (!this.peerConnection) return;
            this.peerConnection.addTrack(track, this.localStream);
        });
    };

    closePeer = () => {
        this.localStream.getTracks().forEach(track => {
            track.stop();
        });

        if (!this.peerConnection) return;

        this.peerConnection.close();
    };

    render() {
        const {
            t, user, mechanicName, casualtyUid,
        } = this.props;
        const {
            audioMuted, calling, isBig, showCancelCallBtn,
        } = this.state;

        const isAdminOrCollaborator = user && (user.role === UserRoles.ADMIN || user.role === UserRoles.EXTERNAL_ADMIN || user.role === UserRoles.COLLABORATOR);

        const remoteClass = isAdminOrCollaborator ? 'video' : 'video hidden';
        const localClass = isAdminOrCollaborator ? 'video hidden' : 'video';
        const videoClass = isBig ? 'bigger' : 'smaller';

        return (
            <div className={`app-screen__video ${videoClass}`}>
                <div id="video-container" className="video-container">
                    <video id="remote-video" className={remoteClass} autoPlay playsInline style={isBig ? {} : { maxWidth: '130px' }} />
                    <video id="local-video" className={localClass} muted autoPlay playsInline style={isBig ? {} : { maxWidth: '130px' }} />
                    <canvas id="print-canvas" className="canvas" />
                    {calling ? (
                        <div className="calling-container">
                            <div className="calling-info">
                                <h3>{t('global.calling')}</h3>
                                {(isAdminOrCollaborator && mechanicName) ? (
                                    <p>{mechanicName}</p>
                                ) : (
                                    <p>{t('global.fluxeUser')}</p>
                                )}
                                <p className="small">{t('global.referring')}<b>{t('global.process', { processNumber: casualtyUid })}</b></p>
                            </div>
                            <Button
                                text={t('global.buttons.reject')}
                                styles="btn--purple btn--full-width"
                                callback={this.onCancelCall}
                                disabled={!showCancelCallBtn}
                            />
                        </div>
                    ) : (
                        <React.Fragment>
                            <div className="top-container" onClick={this.onToggleVideoSize}>
                                <SvgIcon icon={isBig ? ICON.DIMINISH : ICON.EXPAND} />
                            </div>
                            <div className="controls-container">
                                {isAdminOrCollaborator && (
                                    <Button
                                        text={isBig ? t('video.takePhoto') : undefined}
                                        callback={this.onPrintScreenClick}
                                        icon={ICON.PRINT}
                                        styles="btn--green"
                                        iconPosition="left"
                                    />
                                )}
                                <Button
                                    text={isBig ? t('video.mute') : undefined}
                                    callback={this.onMicrophoneClick}
                                    icon={audioMuted ? ICON.MICROPHONE_ON : ICON.MICROPHONE_OFF}
                                    styles="btn--green"
                                    iconPosition="left"
                                />
                                <Button
                                    text={isBig ? t('video.endCall') : undefined}
                                    callback={this.onHangUpClick}
                                    icon={ICON.VIDEO_CAM}
                                    styles="btn--purple"
                                    iconPosition="left"
                                />
                            </div>
                        </React.Fragment>
                    )}
                </div>
            </div>
        );
    }
}

export default withTranslationContext(withAuthenticationContext(withVideoContext(VideoComponent)));
