/**
 *
 * @Copyright 2022 VOID SOFTWARE, S.A.
 *
 */

import React, { createContext, ComponentType, FC } from 'react';

import { ConnectedAnnotationController } from './AnnotationController';
import { Box, ListData } from '../../../constants/types';
import { KeyedObject } from '../../../constants/misc';
 
export interface AnnotationContext {
    getAnnotations(fileId: number, isEditable: boolean): Promise<ListData<Box>>;
    getGlassDamages(fileId: number): Promise<ListData<Box>>;
    editAnnotation(box: Box, fileId: number): Promise<Box | null>;
    addAnnotation(box: Box, fileId: number): Promise<Box | null>;
    deleteAnnotation(box: Box, fileId: number): Promise<Box[]>;
    deleteGlassAIAnnotation(fileId: number, damageId: number): Promise<KeyedObject | null>;
}
  
export const annotationContextDefaultValue = {
    getAnnotations: async () => ({ list: [], failed: false }),
    getGlassDamages: async () => ({ list: [], failed: false }),
    editAnnotation: async () => null,
    addAnnotation: async () => null,
    deleteAnnotation: async () => [],
    deleteGlassAIAnnotation: async () => null,
};
 
const AnnotationContextInstance = createContext<AnnotationContext | null>(annotationContextDefaultValue);
 
// Export Provider and Consumer
 
export const AnnotationContextProvider = AnnotationContextInstance.Provider;
export const AnnotationContextConsumer = AnnotationContextInstance.Consumer;
 
/**
  * provides a context HOC that components can use to subscribe as consumers
  * @param Component
  * @returns {React.FC}
  */
export const withAnnotationContext = <P extends object>(
    Component: ComponentType<P>,
): FC<Omit<P, keyof AnnotationContext>> => props => (
    <ConnectedAnnotationController>
        <AnnotationContextConsumer>
            {ctx => <Component {...props as P} {...ctx} />}
        </AnnotationContextConsumer>
    </ConnectedAnnotationController>
    );
