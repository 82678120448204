/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { Component } from 'react';
import { ICON, SvgIcon } from './SvgIcon';
import ErrorMessage from './ErrorMessage';

interface OwnProps {
    value: string;
    name?: string;
    placeholder?: string;
    type?: string;
    onChange: Function;
    endIcon?: string,
    errors?: any,
}

interface OwnState {
    actualType: string,
    visibilityFilled: boolean,
}

const initialState = {
    actualType: 'text',
    visibilityFilled: true,
};

class EntryPageFormTextfield extends Component<OwnProps, OwnState> {
    state = initialState;

    componentDidMount(): void {
        const { type } = this.props;
        if (type) {
            this.setState({ actualType: type });
        }
    }

    onVisibilityClick = () => {
        const { actualType } = this.state;

        if (actualType === 'password') {
            this.setState({ actualType: 'text', visibilityFilled: false });
        } else if (actualType === 'text') {
            this.setState({ actualType: 'password', visibilityFilled: true });
        }
    };

    renderErrors = () => {
        const { name, errors } = this.props;

        return (
            <ErrorMessage errors={errors} field={name} />
        );
    };

    render() {
        const { placeholder, value, name, onChange, type, endIcon, errors } = this.props;
        const { actualType, visibilityFilled } = this.state;

        let icon: string = '';
        if (type === 'password') {
            icon = visibilityFilled ? ICON.PASSWORD_FILLED : ICON.PASSWORD_INVISIBLE;
        } else if (endIcon) {
            icon = endIcon;
        }

        return (
            <div className="form-field-container">
                <div className="entry-page__centered__fields__text-field">
                    <input
                        name={name}
                        type={actualType}
                        value={value}
                        placeholder={placeholder}
                        onChange={e => onChange(e)}
                        maxLength={50}
                    />
                    {endIcon && <SvgIcon icon={icon} callback={this.onVisibilityClick} />}
                </div>
                <p>{errors && this.renderErrors()}</p>
            </div>

        );
    }
}

export default EntryPageFormTextfield;
