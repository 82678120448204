/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { createContext, ComponentType, FC } from 'react';
import { ConnectedVideoController } from './VideoController';
import { SocketReceivedMessage } from '../../../constants/socket_types';

export interface VideoContextInterface {
    onlineFluxeUsers: Array<number>;
    onlineMechanicUsers: Array<number>;
    onlineInsuranceUsers: Array<number>;
    onlineGlassProviderUsers: Array<number>;
    sendSocketMessage(message: string): void;
    incomingCall: boolean;
    answerCall: boolean;
    wasIceCandidateFound: boolean;
    signalMessage: SocketReceivedMessage | null;
    incomingCallMessage: SocketReceivedMessage | null;
    notifyAnswerCall(): void;
    notifyDeclineCall(): void;
    updatePendingCandidates(hasCandidates: boolean, candidates: Array<SocketReceivedMessage>): void;
    callAnswered: boolean;
    messageToSend: string | null;
    declineCall: boolean;
    callDeclined: boolean;
    hasCandidates: boolean;
    candidates: Array<SocketReceivedMessage>;
    handleSocketMessage(signal: SocketReceivedMessage): void;
    resetVideoState(): void;
    resetVideoFlags(): void;
}

export const videoContextDefaultValue = {
    onlineFluxeUsers: [],
    onlineMechanicUsers: [],
    onlineInsuranceUsers: [],
    onlineGlassProviderUsers: [],
    sendSocketMessage: () => {},
    notifyAnswerCall: () => {},
    updatePendingCandidates: () => {},
    notifyDeclineCall: () => {},
    incomingCall: false,
    answerCall: false,
    wasIceCandidateFound: false,
    signalMessage: null,
    callAnswered: false,
    incomingCallMessage: null,
    messageToSend: null,
    declineCall: false,
    callDeclined: false,
    hasCandidates: false,
    candidates: [],
    handleSocketMessage: () => {},
    resetVideoState: () => {},
    resetVideoFlags: () => {},
};

const VideoContext = createContext<VideoContextInterface | null>(videoContextDefaultValue);

export const VideoContextProvider = VideoContext.Provider;
export const VideoContextConsumer = VideoContext.Consumer;

export const withVideoContext = <P extends object>(
    Component: ComponentType<P>,
): FC<Omit<P, keyof VideoContextInterface>> => props => (
    <ConnectedVideoController>
        <VideoContextConsumer>
            {ctx => <Component {...props as P} {...ctx} />}
        </VideoContextConsumer>
    </ConnectedVideoController>
    );
