/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { Component } from 'react';
import { SvgIcon } from './SvgIcon';

interface OwnProps {
    callback: ((event: React.MouseEvent<HTMLDivElement>) => void);
    checked: boolean;
}

class CheckBox extends Component<OwnProps> {

    icon() {
        if (this.props.checked){
            return <SvgIcon icon="CHECKED" />
        }else{
            return <span></span>
        }
    }

    render() {
        return (
            <div className="checkbox" onClick={this.props.callback}>
                <button
                    className="button">
                    <div className="check">
                        {this.icon()}
                    </div>

                    <div className="content">
                        {this.props.children}
                    </div>
                </button>
            </div>
        )
    }
}

export default CheckBox;
