/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { Component } from 'react';
import moment from 'moment';
import axios from 'axios';
import { TranslationContextInterface, withTranslationContext } from '../controllers/translation/TranslationContext';
import { ICON, SvgIcon } from './SvgIcon';
import FormTextField from './FormTextField';
import FormDatePicker from './FormDatePicker';
import FormSelectField from './FormSelectField';
import { SelectOption, BudgetDescription } from '../../constants/misc';
import Button from './Button';
import TableCell from './TableCell';
import { casualtyBudgetURL, casualtyBudgetUserURL } from '../../services/casualties';
import { BudgetValueItem, CasualtyRequest, EuroTaxBudget } from '../../constants/types';
import { displayNotification, NOTIFICATION_TYPE } from '../../utils/notifs';
import { validateString } from '../../utils/misc';
import { UserRoles } from '../../constants/authorization';

/**
 * @typedef {Object} OwnProps
 * @extends {TranslationContext}
 * @property {boolean} readOnly
 * @property {boolean} hasCreateResume
 * @property {CasualtyRequest} casualty
 * @property {string} role
 */
interface OwnProps extends TranslationContextInterface {
    readOnly: boolean;
    hasCreateResume: boolean;
    casualty: CasualtyRequest;
    role: string;
}

/**
 * @typedef {Object} OwnState
 * @property {string} id
 * @property {boolean} isFetching
 * @property {string} totalTime
 * @property {BudgetValueItem} [details]
 * @property {number} grandTotal
 * @property {BudgetValueItem} [aditionalValues]
 * @property {BudgetValueItem} [subtotalDetail]
 * @property {BudgetValueItem} [totalDetail]
 * @property {string} vin
 * @property {string | null} version
 * @property {string | null} displacement
 * @property {string | null} vehicleColor
 * @property {string | null} fuel
 * @property {string | null} power
 * @property {string | null} vehicleMileage
 * @property {boolean | null} vehicleImported
 * @property {string | null} vehicleLicenseDate
 * @property {string | null} vehicleInspectionDate
 * @property {string | null} mechanicEntryDate
 * @property {string | null} vehicleManufactureDate
 * @property {string | null} generalState
 * @property {string | null} mileageReadout
 * @property {string} currency
 * @property {SelectOption} [importedOptions]
 * @property {SelectOption} [simpleImportedOptions]
 * @property {SelectOption} [readOptions]
 * @property {number} mechanicRepairDays
 * @property {boolean | null} vehicleInspected
 * @property {boolean} vehicleOperative
 */
interface OwnState {
    id: string;
    isFetching: boolean;
    totalTime: string;
    details: BudgetValueItem[];
    grandTotal: number;
    aditionalValues: BudgetValueItem[];
    subtotalDetail: BudgetValueItem[];
    totalDetail: BudgetValueItem[];
    vin: string;
    version: string | null;
    displacement: string | null;
    vehicleColor: string | null;
    fuel: string | null;
    power: string | null;
    vehicleMileage: string | null;
    vehicleImported: boolean | null;
    vehicleLicenseDate: string | null;
    vehicleInspectionDate: string | null;
    mechanicEntryDate: string | null;
    vehicleManufactureDate: string | null;
    generalState: string | null;
    mileageReadout: string | null;
    currency: string;
    importedOptions: Array<SelectOption>;
    simpleImportedOptions: Array<SelectOption>;
    readOptions: Array<SelectOption>;
    mechanicRepairDays: number;
    vehicleInspected: boolean | null;
    vehicleOperative: boolean;
}

const initialState: OwnState = {
    id: '',
    isFetching: false,
    totalTime: '0',
    details: [],
    grandTotal: 0,
    aditionalValues: [],
    subtotalDetail: [],
    totalDetail: [],
    vin: '-',
    version: '',
    displacement: '',
    vehicleColor: '',
    fuel: '',
    power: '',
    vehicleMileage: '',
    vehicleImported: null,
    vehicleLicenseDate: '',
    vehicleInspectionDate: '',
    mechanicEntryDate: '',
    vehicleManufactureDate: '',
    generalState: '',
    mileageReadout: null,
    currency: '',
    importedOptions: [],
    simpleImportedOptions: [],
    readOptions: [],
    mechanicRepairDays: 0,
    vehicleInspected: null,
    vehicleOperative: true,
};

/**
 * shows the most recent valid budget of a casualty
 * @extends {Component<OwnProps, OwnState>}
 */
class CasualtyBudgetTab extends Component<OwnProps, OwnState> {
    state = initialState;

    private readonly yesKeyword: string = 'keywords.yes';

    private readonly noKeyword: string = 'keywords.no';

    private readonly noneKeyword: string = 'keywords.none';

    componentDidMount(): void {
        this.prepare();
    }

    componentWillReceiveProps(nextProps: OwnProps) {
        const { hasCreateResume: nextHasCreateResume } = nextProps;
        const { hasCreateResume } = this.props;
        if (nextHasCreateResume !== hasCreateResume) {
            this.onCreateNewClick();
        }
    }

    /**
     * asserts if object Part is from paint
     * @param {string} part
     * @returns {boolean}
     */
    isPaintPart(part: string): boolean {
        return part.startsWith('Paint_');
    }

    /**
     * asserts if object Part is not from paint
     * @param {string} part
     * @returns {boolean}
     */
    isPart(part: string): boolean {
        return part.startsWith('Part_') && !part.includes('Inclusive');
    }

    /**
     * set values on date form fields
     * @param {string} name
     * @param {any} date
     */
    onDateChange = (name: string, date: Date, value?: any) => {
        if (!value) {
            date.setHours(date.getHours() + 1);
        }
        this.setState({
            ...this.state,
            [name]: date,
        });
    };

    /**
     * set values on form fields
     * @param {React.FormEvent<HTMLInputElement>} e
     */
    onInputStateChange = (e: React.FormEvent<HTMLInputElement>) => {
        const { name } = e.currentTarget;
        const { value } = e.currentTarget;

        if (name === 'vehicleImported' || name === 'vehicleInspected' || name === 'vehicleOperative') {
            let valueSelected = null;
        
            if (value === 'no') {
                valueSelected = false;
            }

            if (value === 'yes') {
                valueSelected = true;
            }

            this.setState({
                ...this.state,
                [name]: valueSelected,
            });
            return;
        }

        this.setState({
            ...this.state,
            [name]: value,
        });
    };

    /**
     * handle the update budget information
     */
    updateBudget = () => {
        const { t, casualty } = this.props;
        const {
            id,
            isFetching,
            generalState,
            mechanicEntryDate,
            mechanicRepairDays,
            mileageReadout,
            vehicleColor,
            vehicleImported,
            vehicleInspectionDate,
            vehicleLicenseDate,
            vehicleManufactureDate,
            vehicleMileage,
            vehicleOperative,
            vehicleInspected,
        } = this.state;

        if (isFetching) return;

        this.setState({
            isFetching: true,
        });

        if (casualty) {
            axios.post(casualtyBudgetUserURL(String(casualty.id), id), {
                generalState: validateString(generalState),
                mechanicEntryDate: mechanicEntryDate ? moment(mechanicEntryDate).unix() * 1000 : null,
                mechanicRepairDays: validateString(mechanicRepairDays),
                mileageReadout: validateString(mileageReadout),
                vehicleColor: validateString(vehicleColor),
                vehicleImported,
                vehicleInspectionDate: vehicleInspectionDate ? moment(vehicleInspectionDate).unix() * 1000 : null,
                vehicleLicenseDate: vehicleLicenseDate ? moment(vehicleLicenseDate).unix() * 1000 : null,
                vehicleManufactureDate: vehicleManufactureDate ? moment(vehicleManufactureDate).unix() * 1000 : null,
                vehicleMileage: validateString(vehicleMileage),
                vehicleOperative,
                vehicleInspected,
            }).then(() => {
                this.setState({
                    isFetching: false,
                });
                displayNotification(
                    NOTIFICATION_TYPE.SUCCESS,
                    t('casualties.budget.updateSuccess'),
                );
            }).catch(() => {
                this.setState({
                    isFetching: false,
                });

                displayNotification(
                    NOTIFICATION_TYPE.ERROR,
                    t('casualties.budget.updateError'),
                );
            });
        }
    }

    prepare = async () => {
        const { isFetching } = this.state;
        const { casualty, t } = this.props;

        if (isFetching) return;

        this.setState({
            isFetching: true,
        });

        axios.get(casualtyBudgetURL(String(casualty.id))).then(response => {
            const { data } = response;

            if (data) {
                this.buildBudget(data);
            }
    
            this.setState({
                isFetching: false,
                simpleImportedOptions: [
                    {
                        value: 'yes',
                        label: t(this.yesKeyword),
                    },
                    {
                        value: 'no',
                        label: t(this.noKeyword),
                    },
                ],
                importedOptions: [
                    {
                        value: null,
                        label: t(this.noneKeyword),
                    },
                    {
                        value: 'yes',
                        label: t(this.yesKeyword),
                    },
                    {
                        value: 'no',
                        label: t(this.noKeyword),
                    },
                ],
                readOptions: [
                    {
                        value: 'REAL',
                        label: t('keywords.REAL'),
                    },
                    {
                        value: 'ESTIMATE',
                        label: t('keywords.ESTIMATED'),
                    },
                ],
            });
        }).catch(error => {
            this.setState({
                isFetching: false,
            });
        });
    }

    /**
     * handles the conversion from hours to days
     * @param {number} hours
     * @returns {number}
     */
    setRepairDays = (hours: number): number => {
        if (isNaN(hours)) return 0;

        return Math.floor(hours / 8);
    }

    /**
     * populates the budget objects with data from Eurotax API
     * @param {EuroTaxBudget} data
     */
    buildBudget = (data: EuroTaxBudget) => {
        if (data) {
            let version = '';
            let displacement = '';
            let vehicleColor = '';
            let fuel = '';
            let power = '';
            let vehicleLicenseDate = '';
            let vehicleManufactureDate = '';

            const { casualty } = this.props;
            const { vin } = casualty;
            const { vehicleInfo } = casualty || null;

            if (vehicleInfo) {
                const { colourCode, registrationDate, productionDate } = vehicleInfo;
                const { vehicles } = vehicleInfo;

                vehicleColor = data.vehicleColor ? data.vehicleColor : colourCode;
                vehicleLicenseDate = data.vehicleLicenseDate ? data.vehicleLicenseDate : (registrationDate || '');
                vehicleManufactureDate = data.vehicleManufactureDate ? data.vehicleManufactureDate : (productionDate || '');
                
                if (vehicles && vehicles.vehicle && vehicles.vehicle.length > 0) {
                    const v = vehicles.vehicle[0];
                    const {
                        engineKW, engineSize, fuelType, modelDescription,
                    } = v;

                    fuel = String(fuelType);
                    power = String(engineKW);
                    displacement = String(engineSize);
                    version = modelDescription || '';
                }
            }
            
            const {
                id,
                estimation,
                generalState,
                mechanicEntryDate,
                mileageReadout,
                vehicleImported,
                vehicleInspectionDate,
                vehicleOperative,
                vehicleMileage,
                mechanicRepairDays,
                vehicleInspected,
            } = data;

            const { calculation } = estimation || {};

            const {
                result, rate, position, calcSetting,
            } = calculation || {};

            const { currency } = calcSetting || { currency: '' };
            const { exclVatResults, exclVatStatisticResults, inclVatResults } = result || {};
            const { labourRate, discount, vat } = rate || {};

            const { painter } = labourRate || { painter: 0 };
            const {
                discMatPaint: rateMatPaint,
                discMatPaintSpecifier,
                discMatPart,
                discMatPartSpecifier,
                discWorkPaint: rateWorkPaint,
                discWorkPaintSpecifier,
                discWorkPart,
                discWorkPartSpecifier,
            } = discount || {};

            const { vatmaterial, vatlabour } = vat || { vatlabour: 0, vatmaterial: 0 };

            const {
                discMatPaint,
                discMatParts,
                discWorkPaint,
                discWorkParts,
                totRepCostExclVat,
                total1,
            } = exclVatResults || {};

            const {
                totalAmountAdditionalCosts,
                totalAmountLabourCosts,
                totalAmountPaint,
                totalAmountParts,
            } = exclVatStatisticResults || {};

            const {
                grandTotal,
                vatMat,
                vatWork,
            } = inclVatResults || {};

            let timePaint = 0;
            let timeMaterial = 0;
            const rateMatPaintDisc = discMatPaintSpecifier === '%' ? rateMatPaint : 0;
            const rateMatPartDisc = discMatPartSpecifier === '%' ? discMatPart : 0;
            const rateWorkPaintDisc = discWorkPaintSpecifier === '%' ? rateWorkPaint : 0;
            const rateWorkMatDisc = discWorkPartSpecifier === '%' ? discWorkPart : 0;

            if (position) {
                Object.keys(position).forEach(key => {
                    const item = position[Number(key)];

                    const { time, posType, overlapTime } = item;

                    if (this.isPaintPart(posType)) {
                        timePaint += time - overlapTime;
                    } else {
                        if (this.isPart(posType)) {
                            timeMaterial += time - overlapTime;
                        }
                    }
                });
            }
        
            const labourPaint = painter * timePaint;
            const laborWork = (totalAmountLabourCosts || 0) - labourPaint;
            let totalToPay = 0;

            let vatWorkAndMat: BudgetValueItem[] = [];
            if (vat?.vatlabour !== vat?.vatmaterial) {
                vatWorkAndMat = [
                    {
                        description: BudgetDescription.VAT_WORK,
                        labourHours: '-',
                        rate: vatlabour ? `${vatlabour}%` : '-',
                        value: String(vatWork || 0),
                    },
                    {
                        description: BudgetDescription.VAT_MAT,
                        labourHours: '-',
                        rate: vatmaterial ? `${vatmaterial}%` : '-',
                        value: String(vatMat || 0),
                    },
                ];
            }

            let vatTotal: BudgetValueItem[] = [];

            if (vat?.vatlabour === vat?.vatmaterial) {
                vatTotal = [
                    {
                        description: BudgetDescription.VAT_TOTAL,
                        labourHours: '-',
                        rate: totRepCostExclVat
                            ? String(`${parseFloat((((vatMat || 0) + (vatWork || 0)) / totRepCostExclVat * 100).toFixed(2))}%`)
                            : '-',
                        value: String((vatMat || 0) + (vatWork || 0)),
                    },
                ];
            }

            if (grandTotal) {
                totalToPay = grandTotal;
            }

            if (casualty.deductibleValue) {
                totalToPay -= casualty.deductibleValue;
            }
            
            this.setState({
                id,
                vin: vin || '-',
                vehicleColor,
                version,
                fuel,
                power,
                vehicleLicenseDate,
                vehicleManufactureDate,
                displacement,
                mechanicRepairDays: mechanicRepairDays > 0 ? mechanicRepairDays : this.setRepairDays(timePaint + timeMaterial),
                totalTime: String(timePaint + timeMaterial),
                grandTotal: grandTotal || 0,
                currency,
                generalState,
                mechanicEntryDate,
                mileageReadout: mileageReadout || 'REAL',
                vehicleImported,
                vehicleInspectionDate,
                vehicleOperative: vehicleOperative === null ? true : vehicleOperative,
                vehicleMileage,
                vehicleInspected,
                details: [
                    {
                        description: BudgetDescription.SURCHARGE_WORK_PARTS,
                        labourHours: String(timeMaterial || 0),
                        rate: '-',
                        labourRate: timeMaterial ? String((laborWork / timeMaterial).toFixed(2)) : '-',
                        value: String(laborWork.toFixed(2) || 0),
                    },
                    {
                        description: BudgetDescription.DISCOUNT_WORK_PARTS,
                        labourHours: '-',
                        rate: rateWorkMatDisc ? String(`${rateWorkMatDisc}%`) : '-',
                        value: String(discWorkParts || 0),
                    },
                    {
                        description: BudgetDescription.SURCHARGE_WORK_PAINT,
                        labourHours: timePaint ? String(timePaint) : '-',
                        rate: '-',
                        labourRate: timePaint ? String((labourPaint / timePaint).toFixed(2)) : '-',
                        value: String(labourPaint.toFixed(2) || 0),
                    },
                    {
                        description: BudgetDescription.DISCOUNT_WORK_PAINT,
                        labourHours: '-',
                        rate: rateWorkPaintDisc ? String(`${rateWorkPaintDisc}%`) : '-',
                        value: String(discWorkPaint || 0),
                    },
                    {
                        description: BudgetDescription.SURCHARGE_MAT_PAINT,
                        labourHours: '-',
                        rate: '-',
                        value: String(totalAmountPaint || 0),
                    },
                    {
                        description: BudgetDescription.DISCOUNT_MAT_PAINT,
                        labourHours: '-',
                        rate: rateMatPaintDisc ? String(`${rateMatPaintDisc}%`) : '-',
                        value: String(discMatPaint || 0),
                    },
                    {
                        description: BudgetDescription.SURCHARGE_MAT_PARTS,
                        labourHours: '-',
                        rate: '-',
                        value: String(totalAmountParts || 0),
                    },
                    {
                        description: BudgetDescription.DISCOUNT_MAT_PARTS,
                        labourHours: '-',
                        rate: rateMatPartDisc ? String(`${rateMatPartDisc}%`) : '-',
                        value: String(discMatParts || 0),
                    },
                ],
                aditionalValues: [
                    {
                        description: BudgetDescription.ADITIONAL_VALUES,
                        labourHours: '-',
                        rate: '-',
                        value: String(totalAmountAdditionalCosts || 0),
                    },
                ],
                subtotalDetail: [
                    {
                        description: BudgetDescription.TOTAL1,
                        labourHours: '-',
                        rate: '-',
                        value: String(total1 || 0),
                    },
                    {
                        description: BudgetDescription.DISC_OVERALL,
                        labourHours: '-',
                        rate: '-',
                        value: totRepCostExclVat && total1 ? String(total1 - totRepCostExclVat) : '0',
                    },
                ],
                totalDetail: [
                    {
                        description: BudgetDescription.TOTAL_REP_COST_EXCL_VAT,
                        labourHours: '-',
                        rate: '-',
                        value: String(totRepCostExclVat || 0),
                    },
                    ...vatWorkAndMat,
                    ...vatTotal,
                    {
                        description: BudgetDescription.TOTAL_WITH_IVA,
                        labourHours: '-',
                        rate: '-',
                        value: String(grandTotal || 0),
                    },
                    {
                        description: BudgetDescription.DEDUCTIBLE_VALUE,
                        labourHours: '-',
                        rate: '-',
                        value: String(casualty.deductibleValue || 0),
                    },
                    {
                        description: BudgetDescription.GRAND_TOTAL,
                        labourHours: '-',
                        rate: '-',
                        value: String(totalToPay),
                    },
                ],
            });
        }
        
        this.setState({
            isFetching: false,
        });
    }

    /**
     * handle the creation of a new budget for the casualty
     */
    onCreateNewClick = () => {
        const { isFetching } = this.state;
        const { casualty } = this.props;

        if (!casualty || isFetching) return;

        this.setState({
            isFetching: true,
        });

        axios.post(casualtyBudgetURL(String(casualty.id))).then(response => {
            this.setState({
                isFetching: false,
            });
            const { data } = response;
            
            if (data) {
                const { redirectUrl } = data;
                window.location.assign(redirectUrl);
            }
        }).catch(error => {
            this.setState({
                isFetching: false,
            });
        });
    };

    /**
     * render time in hours with one decimal place
     * @param {number} timeInHours
     * @returns {string}
     */
    parseTotalTime = (timeInHours: number): string => {
        if (isNaN(timeInHours)) return '-';

        return `${timeInHours.toFixed(1)} h`;
    }

    /**
     * render the budget information
     */
    renderResume = () => {
        const { t, readOnly } = this.props;
        const {
            grandTotal,
            details,
            aditionalValues,
            subtotalDetail,
            totalDetail,
            totalTime,
            vin,
            importedOptions,
            simpleImportedOptions,
            readOptions,
            mechanicRepairDays,
            vehicleColor,
            version,
            displacement,
            fuel,
            power,
            vehicleMileage,
            vehicleOperative,
            mechanicEntryDate,
            vehicleImported,
            vehicleLicenseDate,
            vehicleInspectionDate,
            vehicleManufactureDate,
            generalState,
            mileageReadout,
            vehicleInspected,
        } = this.state;
        const hasDetails = details && details.length > 0;

        return (
            <div className="budget-details">
                <div className="budget-details_header">
                    <div className="budget-details_header_row">
                        <div className="budget-details_header_row_left">
                            <div className="budget-details_header_row_left_element">
                                <div className="label">
                                    {t('casualties.budget.number')}
                                </div>
                                <div className="value">
                                    {vin}
                                </div>
                            </div>
                            <div className="budget-details_header_row_left_element">
                                <div className="label">
                                    {t('casualties.budget.repairTime')}
                                </div>
                                <div className="value">
                                    {this.parseTotalTime(Number(totalTime))}
                                </div>
                            </div>
                        </div>
                        <div className="budget-details_header_row_right">
                            <div className="budget-details_header_row_right_element">
                                <div className="label">
                                    {t('casualties.budget.totalToPay')}
                                </div>
                                <div className="value">
                                    {grandTotal}€
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="budget-details_header_row">
                        <div className="budget-details_header_row_left">
                            <div className="budget-details_header_row_left_element">
                                <div className="label">
                                    {t('casualties.budget.version')}
                                </div>
                                <div className="value">
                                    {version}
                                </div>
                            </div>
                            <div className="budget-details_header_row_left_element">
                                <div className="label">
                                    {t('casualties.budget.displacement')}
                                </div>
                                <div className="value">
                                    {displacement}
                                </div>
                            </div>
                            <div className="budget-details_header_row_left_element">
                                <div className="label">
                                    {t('casualties.budget.fuel')}
                                </div>
                                <div className="value">
                                    {fuel}
                                </div>
                            </div>
                            <div className="budget-details_header_row_left_element">
                                <div className="label">
                                    {t('casualties.budget.power')}
                                </div>
                                <div className="value">
                                    {power}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="budget-details_body">
                    <div className="budget-details_body_left">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>{t('casualties.budget.table.headers.description')}</th>
                                    <th className="center">{t('casualties.budget.table.headers.laborRate')}</th>
                                    <th className="center">{t('casualties.budget.table.headers.hours')}</th>
                                    <th className="center">{t('casualties.budget.table.headers.percentage')}</th>
                                    <th className="center">{t('casualties.budget.table.headers.value')}</th>
                                </tr>
                            </thead>
                            {hasDetails ? (
                                <tbody>
                                    {details.map(item => {
                                        const {
                                            description, labourHours, rate, value, labourRate,
                                        } = item;
                                        const { currency } = this.state;
                                        const valueFormatter = new Intl.NumberFormat('pt-pt', {
                                            style: 'currency',
                                            currency,
                                        });

                                        return (
                                            <tr key={description}>
                                                <TableCell
                                                    value={t(`enums.budgetDetails.${description}`)}
                                                />
                                                <TableCell
                                                    value={labourRate ? `${labourRate} €` : '-'}
                                                />
                                                <TableCell
                                                    value={this.parseTotalTime(Number(labourHours))}
                                                />
                                                <TableCell
                                                    value={rate}
                                                    cellClass="padding-r-10-cell"
                                                />
                                                <TableCell
                                                    value={valueFormatter.format(parseFloat(value))}
                                                />
                                            </tr>
                                        );
                                    })}
                                    {aditionalValues.map(item => {
                                        const {
                                            description, labourHours, rate, value, labourRate,
                                        } = item;
                                        const { currency } = this.state;
                                        const valueFormatter = new Intl.NumberFormat('pt-pt', {
                                            style: 'currency',
                                            currency,
                                        });
                                        return (
                                            <tr key={description}>
                                                <TableCell
                                                    value={t(`enums.budgetDetails.${description}`)}
                                                />
                                                <TableCell
                                                    value={labourRate ? `${labourRate} €` : '-'}
                                                />
                                                <TableCell
                                                    value={labourHours}
                                                />
                                                <TableCell
                                                    value={rate}
                                                />
                                                <TableCell
                                                    value={valueFormatter.format(parseFloat(value))}
                                                />
                                            </tr>
                                        );
                                    })}
                                    {subtotalDetail.map(item => {
                                        const {
                                            description, labourHours, rate, value, labourRate,
                                        } = item;
                                        const { currency } = this.state;
                                        const valueFormatter = new Intl.NumberFormat('pt-pt', {
                                            style: 'currency',
                                            currency,
                                        });
                                        return (
                                            <tr key={description} className="m-bigger c-dark">
                                                <TableCell
                                                    value={t(`enums.budgetDetails.${description}`)}
                                                />
                                                <TableCell
                                                    value={labourRate ? `${labourRate} €` : '-'}
                                                />
                                                <TableCell
                                                    value={labourHours}
                                                />
                                                <TableCell
                                                    value={rate}
                                                />
                                                <TableCell
                                                    value={valueFormatter.format(parseFloat(value))}
                                                />
                                            </tr>
                                        );
                                    })}
                                    {totalDetail.map(item => {
                                        const {
                                            description, labourHours, rate, value, labourRate,
                                        } = item;
                                        const { currency } = this.state;
                                        const valueFormatter = new Intl.NumberFormat('pt-pt', {
                                            style: 'currency',
                                            currency,
                                        });
                                        return (
                                            <tr key={description} className="m-bigger">
                                                <TableCell
                                                    value={t(`enums.budgetDetails.${description}`)}
                                                />
                                                <TableCell
                                                    value={labourRate ? `${labourRate} €` : '-'}
                                                />
                                                <TableCell
                                                    value={labourHours}
                                                />
                                                <TableCell
                                                    value={rate}
                                                />
                                                <TableCell
                                                    value={valueFormatter.format(parseFloat(value))}
                                                />
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            ) : (
                                <tbody className="no-data">
                                    <tr>
                                        <td colSpan={4}>{t('global.noData')}</td>
                                    </tr>
                                </tbody>
                            )}
                        </table>
                    </div>
                    <div className="budget-details_body_right">
                        <div className="form-element">
                            <div className="item m-r">
                                <FormDatePicker
                                    name="vehicleInspectionDate"
                                    label={t('casualties.budget.inspectionDate')}
                                    value={vehicleInspectionDate ? new Date(vehicleInspectionDate) : null}
                                    onChange={(name: string, date: Date) => this.onDateChange(name, date, vehicleInspectionDate)}
                                    disabled={readOnly}
                                />
                            </div>
                            <div className="item m-l">
                                {readOnly ? (
                                    <FormTextField
                                        containerStyles="inspected"
                                        label={t('preInspections.form.labels.vehicleInspection')}
                                        name="vehicleInspected"
                                        onChange={this.onInputStateChange}
                                        value={vehicleInspected === null ? t(this.noneKeyword) : (vehicleInspected ? t(this.yesKeyword) : t(this.noKeyword))}
                                        disabled
                                    />
                                ) : (
                                    <FormSelectField
                                        options={importedOptions}
                                        name="vehicleInspected"
                                        value={vehicleInspected === null ? 'none' : (vehicleInspected ? 'yes' : 'no')}
                                        onChange={this.onInputStateChange}
                                        label={t('preInspections.form.labels.vehicleInspection')}
                                    />
                                )}
                            </div>
                        </div>
                        <div className="form-element">
                            <div className="item m-r">
                                <FormTextField
                                    containerStyles="field-new-note"
                                    label={t('casualties.budget.km')}
                                    name="vehicleMileage"
                                    onChange={this.onInputStateChange}
                                    value={vehicleMileage}
                                    disabled={readOnly}
                                />
                            </div>
                            <div className="item m-l">
                                {readOnly ? (
                                    <FormTextField
                                        containerStyles="mileageReadout"
                                        label={t('casualties.budget.read')}
                                        name="mileageReadout"
                                        onChange={this.onInputStateChange}
                                        value={t(`keywords.${mileageReadout}`)}
                                        disabled
                                    />
                                ) : (
                                    <FormSelectField
                                        options={readOptions}
                                        name="mileageReadout"
                                        value={mileageReadout || t(this.noneKeyword)}
                                        onChange={this.onInputStateChange}
                                        label={t('casualties.budget.read')}
                                    />
                                )}
                            </div>
                        </div>
                        <div className="form-element">
                            <div className="item m-r">
                                <FormTextField
                                    containerStyles="field-new-note"
                                    label={t('casualties.budget.color')}
                                    name="vehicleColor"
                                    onChange={this.onInputStateChange}
                                    value={vehicleColor}
                                    disabled={readOnly}
                                />
                            </div>
                            <div className="item m-l">
                                {readOnly ? (
                                    <FormTextField
                                        containerStyles="vehicleImported"
                                        label={t('preInspections.form.labels.vehicleImported')}
                                        name="vehicleImported"
                                        onChange={this.onInputStateChange}
                                        value={vehicleImported === null ? t(this.noneKeyword) : (vehicleImported ? t(this.yesKeyword) : t(this.noKeyword))}
                                        disabled
                                    />
                                ) : (
                                    <FormSelectField
                                        options={importedOptions}
                                        name="vehicleImported"
                                        value={vehicleImported === null ? 'none' : (vehicleImported ? 'yes' : 'no')}
                                        onChange={this.onInputStateChange}
                                        label={t('preInspections.form.labels.vehicleImported')}
                                    />
                                )}
                            </div>
                        </div>
                        <div className="form-element">
                            <div className="item m-r">
                                <FormDatePicker
                                    name="vehicleLicenseDate"
                                    label={t('casualties.budget.registrationDate')}
                                    value={vehicleLicenseDate ? new Date(vehicleLicenseDate) : null}
                                    onChange={(name: string, date: Date) => this.onDateChange(name, date, vehicleLicenseDate)}
                                    disabled={readOnly}
                                />
                            </div>
                            <div className="item m-l">
                                <FormDatePicker
                                    name="vehicleManufactureDate"
                                    label={t('casualties.budget.manufactureDate')}
                                    value={vehicleManufactureDate ? new Date(vehicleManufactureDate) : null}
                                    onChange={(name: string, date: Date) => this.onDateChange(name, date, vehicleManufactureDate)}
                                    disabled={readOnly}
                                />
                            </div>
                        </div>
                        <div className="form-element">
                            <div className="item m-r">
                                <FormTextField
                                    containerStyles="field-new-note"
                                    label={t('casualties.budget.repairDays')}
                                    name="mechanicRepairDays"
                                    onChange={this.onInputStateChange}
                                    value={String(mechanicRepairDays)}
                                    disabled={readOnly}
                                />
                            </div>
                            <div className="item m-l">
                                {readOnly ? (
                                    <FormTextField
                                        containerStyles="vehicleOperative"
                                        label={t('casualties.budget.vehicleCirculates')}
                                        name="vehicleOperative"
                                        onChange={this.onInputStateChange}
                                        value={vehicleOperative ? t(this.yesKeyword) : t(this.noKeyword)}
                                        disabled
                                    />
                                ) : (
                                    <FormSelectField
                                        options={simpleImportedOptions}
                                        name="vehicleOperative"
                                        value={vehicleOperative ? 'yes' : 'no'}
                                        onChange={this.onInputStateChange}
                                        label={t('casualties.budget.vehicleCirculates')}
                                    />
                                )}
                            </div>
                        </div>
                        <div className="form-element">
                            <div className="item m-r">
                                <FormDatePicker
                                    name="mechanicEntryDate"
                                    label={t('casualties.budget.mechanicDate')}
                                    value={mechanicEntryDate ? new Date(mechanicEntryDate) : null}
                                    onChange={(name: string, date: Date) => this.onDateChange(name, date, mechanicEntryDate)}
                                    disabled={readOnly}
                                />
                            </div>
                            <div className="item m-l">
                                <FormTextField
                                    containerStyles="field-new-note"
                                    label={t('casualties.budget.generalStatus')}
                                    name="generalState"
                                    onChange={this.onInputStateChange}
                                    value={generalState}
                                    disabled={readOnly}
                                />
                            </div>
                        </div>
                        {!readOnly && (
                        <Button
                            styles="btn--purple"
                            text={t('casualties.budget.saveButton')}
                            callback={this.updateBudget}
                        />
                        )}
                    </div>
                </div>
            </div>
        );
    };

    render() {
        const { t, readOnly, role } = this.props;

        const { details } = this.state;

        return (
            <div>
                <div>
                    {details && details.length > 0
                        ? this.renderResume()
                        : (
                            <div className="app-screen__form__form-container__no-content">
                                <div className="content">
                                    <SvgIcon icon={ICON.CAR_CRASH} />
                                    <h1>{t('noContent.ops')}</h1>
                                    <p>{t('noContent.noBudgets')}</p>
                                </div>
                                {!readOnly && role !== UserRoles.MECHANIC && (
                                <Button
                                    text={t('global.buttons.createNew')}
                                    icon={ICON.PLUS}
                                    iconPosition="right"
                                    styles="btn--green"
                                    callback={this.onCreateNewClick}
                                />
                                )}
                            </div>
                        )}
                </div>
            </div>
        );
    }
}

export default withTranslationContext(CasualtyBudgetTab);
