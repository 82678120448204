/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import axios from 'axios';

import {
    AuthenticationActionTypes,
    AuthenticationAction,
} from './authentication_types';
import { LoginFormFields, AuthenticationUser } from '../constants/authentication';
import { authenticationLoginURL } from '../services/authentication';
import { permissionsResetActionCreator, permissionsSetActionCreator } from './authorization';
import {
    adminPermissions,
    collaboratorPermissions,
    insuranceUserPermissions,
    mechanicPermissions,
    externalAdminPermissions,
    UserRoles,
    glassProviderPermissions,
} from '../constants/authorization';
import { resetVideoState } from './video';

/**
 * signals the login request is being made
 * @returns {AuthenticationActionTypes}
 */
export const loginRequestActionCreator = (): AuthenticationActionTypes => {
    return {
        type: AuthenticationAction.LOGIN_REQUEST,
    };
};

/**
 * received login success
 * @param {object} auth
 * @returns {AuthenticationActionTypes}
 */
export const loginSuccessActionCreator = (auth: object): AuthenticationActionTypes => {
    return {
        type: AuthenticationAction.LOGIN_SUCCESS,
        payload: auth,
    };
};

/**
 * received login failure
 * @param {object} formErrors
 * @returns {AuthenticationActionTypes}
 */
export const loginFailureActionCreator = (formErrors: object): AuthenticationActionTypes => {
    return {
        type: AuthenticationAction.LOGIN_FAILURE,
        payload: formErrors,
    };
};

/**
 * signals the logout request is being made
 * @returns {AuthenticationActionTypes}
 */
export const logoutRequestActionCreator = (): AuthenticationActionTypes => {
    return {
        type: AuthenticationAction.LOGOUT_REQUEST,
    };
};

/**
 * sets authentication properties
 * @param {object} auth
 * @returns {AuthenticationActionTypes}
 */
export const setAuthenticationActionCreator = (auth: object): AuthenticationActionTypes => {
    return {
        type: AuthenticationAction.SET_AUTHENTICATION,
        payload: auth,
    };
};

/**
 * resets the authentication properties
 * @returns {AuthenticationActionTypes}
 */
export const resetAuthenticationActionCreator = (): AuthenticationActionTypes => {
    return {
        type: AuthenticationAction.RESET_AUTHENTICATION,
    };
};

/**
 * set or update the credentials
 * @param {object} credentials
 * @returns {AuthenticationActionTypes}
 */
export const turnCredentialsSetActionCreator = (credentials: object): AuthenticationActionTypes => {
    return {
        type: AuthenticationAction.SET_TURN_CREDENTIALS,
        payload: credentials,
    };
};

/**
 * update the token value
 * @param {object} token
 * @returns {AuthenticationActionTypes}
 */
export const tokenSetActionCreator = (token: object): AuthenticationActionTypes => {
    return {
        type: AuthenticationAction.SET_TOKEN,
        payload: token,
    };
};

/**
 * update the user info
 * @param {object} user
 * @returns {AuthenticationActionTypes}
 */
export const userSetActionCreator = (user: object): AuthenticationActionTypes => {
    return {
        type: AuthenticationAction.SET_USER,
        payload: user,
    };
};

/**
 * makes the login request and sets the necessary data in the store
 * @param {LoginFormFields} formData
 * @param {Function} onSuccess
 */
export const requestLogin = (formData: LoginFormFields, onSuccess: Function) => {
    return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
        dispatch(loginRequestActionCreator());
        try {
            const { status, data, headers } = await axios.post(authenticationLoginURL(), formData);
            if (status === 200) {
                dispatch(loginSuccessActionCreator({
                    token: headers.authorization,
                    user: data,
                }));

                switch (data.role) {
                    case UserRoles.ADMIN:
                        dispatch(permissionsSetActionCreator(adminPermissions));
                        break;
                    case UserRoles.MECHANIC:
                        dispatch(permissionsSetActionCreator(mechanicPermissions));
                        break;
                    case UserRoles.INSURANCE_USER:
                        dispatch(permissionsSetActionCreator(insuranceUserPermissions));
                        break;
                    case UserRoles.COLLABORATOR:
                        dispatch(permissionsSetActionCreator(collaboratorPermissions));
                        break;
                    case UserRoles.EXTERNAL_ADMIN:
                        dispatch(permissionsSetActionCreator(externalAdminPermissions));
                        break;
                    case UserRoles.GLASS_PROVIDER:
                        dispatch(permissionsSetActionCreator(glassProviderPermissions));
                        break;
                    default:
                }

                onSuccess();
            }
        } catch (error) {
            if (error && error.response) {
                dispatch(loginFailureActionCreator(error.response.data));
            } else {
                dispatch(loginFailureActionCreator({}));
            }
        }
    };
};

/**
 * makes the logout request and sets the necessary data in the store
 */
export const requestLogout = () => {
    return (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
        dispatch(resetAuthenticationActionCreator());
        dispatch(permissionsResetActionCreator());
        dispatch(resetVideoState());
    };
};

/**
 * set or update the credentials for turn server connection
 * @param {string} turnUsername
 * @param {string} turnCredential
 */
export const requestUpdateTurnCredentials = (turnUsername: string, turnCredential: string) => {
    return (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
        dispatch(turnCredentialsSetActionCreator({ turnUsername, turnCredential }));
    };
};

/**
 * update token on the store
 * @param {string} token
 */
export const requestUpdateToken = (token: string) => {
    return (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
        dispatch(tokenSetActionCreator({ token }));
    };
};

/**
 * update user on the store
 * @param {AuthenticationUser} user
 */
export const requestUpdateUser = (user: AuthenticationUser) => {
    return (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
        dispatch(userSetActionCreator({ user }));
    };
};
