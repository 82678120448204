/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { Component } from 'react';
import { get } from 'lodash';
import axios from 'axios';
import { RouteComponentProps } from 'react-router-dom';
import logo from '../../assets/images/entry_logo.svg';
import EntryPageFormTextfield from '../elements/EntryPageFormTextfield';
import { AuthenticationContextInterface, withAuthenticationContext } from '../controllers/authentication/AuthenticationContext';
import { TranslationContextInterface, withTranslationContext } from '../controllers/translation/TranslationContext';
import { MatchParams } from '../../constants/misc';
import Button from '../elements/Button';
import { getFormErrors, IFormError, VALIDATIONS } from '../../utils/validation';
import { recoverPasswordURL } from '../../services/authentication';
import { INDEX_ROUTE } from '../../constants/routes';

interface OwnProps extends RouteComponentProps<MatchParams>, AuthenticationContextInterface, TranslationContextInterface {}

interface OwnState {
    email: string;
    formErrors: any;
}

const initialState = {
    email: '',
    formErrors: {},
};

class RecoverPasswordScreen extends Component<OwnProps, OwnState> {
    state = initialState;

    onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (this.validateFields()) {
            this.sendRecoverPasswordRequest();
        }
    };

    onInputChange = (e: React.FormEvent<HTMLInputElement>) => {
        this.setState({
            email: e.currentTarget.value,
        });
    };

    validateFields = () => {
        let errors: IFormError | null = getFormErrors(this.state, VALIDATIONS.RECOVER_PASSWORD_FORM);

        if (errors && Object.keys(errors).length === 0) errors = null;

        this.setState({ formErrors: errors ? { fields: errors } : errors });
        return errors === null;
    };

    sendRecoverPasswordRequest = async () => {
        const { history } = this.props;

        await axios.post(recoverPasswordURL(), { email: this.state.email.trim() })
            .then(() => {
                history.push(INDEX_ROUTE);
            });
    };

    render() {
        const { t } = this.props;
        const { email, formErrors } = this.state;

        return (
            <div className="entry-page">
                <div className="entry-page__centered recover-password">
                    <img src={logo} alt="logo" />
                    <form onSubmit={this.onSubmit}>
                        <div className="entry-page__centered__fields">
                            <h2>{t('recoverPassword.helpText')}</h2>
                            <EntryPageFormTextfield
                                name="email"
                                value={email}
                                placeholder={t('login.labels.user')}
                                onChange={this.onInputChange}
                                errors={get(formErrors, 'fields.email', null)}
                            />
                        </div>
                        <Button
                            type="submit"
                            text={t('recoverPassword.sendBtn')}
                            styles="btn--purple"
                        />
                    </form>
                </div>
            </div>
        );
    }
}

export default withAuthenticationContext(withTranslationContext(RecoverPasswordScreen));
