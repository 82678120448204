/**
 *
 * @Copyright 2022 VOID SOFTWARE, S.A.
 *
 */

import React, { Component } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { isEqual } from 'lodash';
import moment from 'moment';

import { PermissionKey } from '../../../constants/authorization';
import {
    MatchParams, KeyedObject, BrokenGlassControlOrigin, ListOrder, RESULTS_PER_PAGE,
} from '../../../constants/misc';
import { BROKEN_GLASS_CONTROL_DETAILS_ROUTE, BROKEN_GLASS_CONTROL_EDIT_ROUTE } from '../../../constants/routes';
import { BrokenGlassControlTabsEnum } from '../../../constants/tabs';
import { AuthenticationContextInterface, withAuthenticationContext } from '../../controllers/authentication/AuthenticationContext';
import { BrokenGlassControlSearchResponse, BrokenGlassControlStatus } from '../../../constants/types';
import { BrokenGlassControlContext, withBrokenGlassControlContext } from '../../controllers/brokenGlass/BrokenGlassControlContext';
import { withTranslationContext, TranslationContextInterface } from '../../controllers/translation/TranslationContext';
import Button from '../../elements/Button';
import Loader from '../../elements/Loader';
import TableCell, { TableCellType } from '../../elements/TableCell';
import TableHeader from '../../elements/TableHeader';
import TablePaging from '../../elements/TablePaging';
import { checkPermission } from '../../../utils/authorization';
import { AuthorizationContextInterface, withAuthorizationContext } from '../../controllers/authorization/AuthorizationContext';
import Can from '../../containers/Can';
import { getBrokenGlassControlMobileUrl } from '../../../utils/misc';
import withQueryParams, { WithQueryParamsProps } from '../../hocs/withQueryParams';
import FilterModal from '../../elements/FilterModal';

interface OwnProps extends RouteComponentProps<MatchParams>, BrokenGlassControlContext, TranslationContextInterface, AuthenticationContextInterface, AuthorizationContextInterface, WithQueryParamsProps {
    tabItem: string | undefined;
}

interface OwnState {
    isFetching: boolean;
    brokenGlassControls: Array<BrokenGlassControlSearchResponse>;
    totalResults: number;
    showFilterModal: boolean;
    startDate: string;
    endDate: string;
}

const initialState: OwnState = {
    isFetching: false,
    brokenGlassControls: [],
    totalResults: 0,
    showFilterModal: false,
    startDate: '',
    endDate: '',
};

class BrokenGlassControlListScreen extends Component<OwnProps, OwnState> {
    state = initialState;

    componentDidMount() {
        const { location, onQueryParamsChange } = this.props;

        onQueryParamsChange(location.search);
    }

    componentDidUpdate(prevProps: Readonly<OwnProps>): void {
        const {
            listParams, tabItem: oldTab, location, onQueryParamsChange,
        } = this.props;
        const {
            listParams: oldListParams, tabItem, location: oldLocation,
        } = prevProps;

        const hasTabChanged: boolean = tabItem !== oldTab;

        if (location.search !== oldLocation.search) {
            onQueryParamsChange(location.search);
        }

        if (hasTabChanged || !isEqual(listParams, oldListParams)) {
            this.loadList();
        }
    }

    onControlClick = (id: string | number): void => {
        const { history, user, permissions } = this.props;

        if (!user) return;

        if (checkPermission(permissions, [PermissionKey.ALL, PermissionKey.EDIT_BROKEN_GLASS_CONTROLS])) {
            history.push(`${BROKEN_GLASS_CONTROL_EDIT_ROUTE}/${id}`);
        } else if (checkPermission(permissions, [PermissionKey.ALL, PermissionKey.VIEW_BROKEN_GLASS_CONTROLS_DETAILS])) {
            history.push(`${BROKEN_GLASS_CONTROL_DETAILS_ROUTE}/${id}`);
        }
    }

    onFilterSubmit = (startDate: number, endDate: number) => {
        this.setState({
            startDate: startDate ? moment(startDate).format('YYYY-MM-DD') : '',
            endDate: endDate ? moment(endDate).format('YYYY-MM-DD') : '',
        }, this.loadList);
    }

    loadList = async (): Promise<void> => {
        const {
            getBrokenGlassControls,
            tabItem,
            listParams,
        } = this.props;
        const { startDate, endDate } = this.state;

        const requestBody: KeyedObject = {
            query: listParams?.search || '',
            page: listParams ? listParams.page - 1 : 0,
            limit: RESULTS_PER_PAGE,
            sort: listParams?.sort || 'controlDate',
            order: listParams?.order || ListOrder.Desc,
            startDate: startDate || undefined,
            endDate: endDate || undefined,
        };
        
        if (tabItem !== BrokenGlassControlTabsEnum.ALL) {
            requestBody.status = tabItem;
        }

        this.setState({
            isFetching: true,
        });
        
        const { results, total } = await getBrokenGlassControls(requestBody);

        this.setState({
            brokenGlassControls: results,
            totalResults: total,
            isFetching: false,
        });
    }

    renderBrokenGlassControlList(): React.ReactNode {
        const { t, token } = this.props;
        const { brokenGlassControls } = this.state;
        const hasData = brokenGlassControls.length > 0;

        if (!hasData) {
            return (
                <tbody className="no-data">
                    <tr>
                        <td colSpan={10}>{t('global.noData')}</td>
                    </tr>
                </tbody>
            );
        }

        return (
            <tbody>
                {
                    brokenGlassControls.map(bgControl => {
                        const {
                            id,
                            status,
                            uid,
                            insuranceCompanyName,
                            insurancePolicyNumber,
                            controlDate,
                            doneDate,
                            vehicleLicensePlate,
                            vehicleBrand,
                            vehicleModel,
                            glassProviderDistrict,
                            glassProviderEmail,
                        } = bgControl;

                        let buttonText: string | null = null;
                        let buttonStyles: string | null = null;
                        let url = '';

                        if (status === BrokenGlassControlStatus.PENDING) {
                            buttonText = t('brokenGlassControl.checkIn');
                            buttonStyles = 'btn--purple__check-in';
                            url = '/check-in';
                        } else if (status === BrokenGlassControlStatus.ONGOING) {
                            buttonText = t('brokenGlassControl.checkOut');
                            buttonStyles = 'btn--purple__check-out';
                            url = '/check-out';
                        }

                        return (
                            <tr key={id} onClick={(): void => this.onControlClick(id)}>
                                <TableCell
                                    type={TableCellType.PRE_INSPECTION_STATUS}
                                    value={status}
                                    title={t(`brokenGlassControl.list.tabItems.${status}`)}
                                />
                                <TableCell
                                    value={uid}
                                />
                                <TableCell
                                    value={insurancePolicyNumber || '--'}
                                />
                                <TableCell
                                    value={insuranceCompanyName || '--'}
                                />
                                <Can
                                    actions={[PermissionKey.ALL, PermissionKey.VIEW_PROVIDER_EMAIL_IN_BROKEN_GLASS_CONTROL_LIST]}
                                    yes={() => (
                                        <TableCell
                                            value={glassProviderEmail}
                                        />
                                    )}
                                />
                                <TableCell
                                    type={TableCellType.DATE}
                                    value={Date.parse(controlDate)}
                                />
                                <TableCell
                                    type={doneDate ? TableCellType.DATE : TableCellType.TEXT}
                                    value={doneDate || '--'}
                                />
                                <TableCell
                                    value={vehicleLicensePlate || '--'}
                                />
                                <TableCell
                                    value={vehicleBrand || '--'}
                                />
                                <TableCell
                                    value={vehicleModel || '--'}
                                />
                                <TableCell
                                    value={glassProviderDistrict || '--'}
                                />
                                <Can
                                    actions={[PermissionKey.ALL, PermissionKey.BROKEN_GLASS_CONTROL_CHECK_IN, PermissionKey.BROKEN_GLASS_CONTROL_CHECK_OUT]}
                                    yes={() => {
                                        if (!buttonText) {
                                            return (
                                                <TableCell
                                                    value=""
                                                />
                                            );
                                        }

                                        return (
                                            <td onClick={(e): void => e.stopPropagation()}>
                                                <form
                                                    id="thisForm"
                                                    method="POST"
                                                    action={getBrokenGlassControlMobileUrl(status)}
                                                >
                                                    <input type="hidden" name="brokenGlassControlId" value={id || ''} />
                                                    <input type="hidden" name="token" value={token || ''} />
                                                    <input type="hidden" name="origin" value={BrokenGlassControlOrigin.ListPage} />
                                                    <input type="hidden" name="url" value={url} />
                                                    <Button
                                                        type="submit"
                                                        text={buttonText}
                                                        styles={`btn--purple ${buttonStyles || ''}`}
                                                    />
                                                </form>
                                            </td>
                                        );
                                    }}
                                />
                            </tr>
                        );
                    })
                }
            </tbody>
        );
    }

    render(): React.ReactNode {
        const {
            t, listParams,
        } = this.props;
        const {
            isFetching, brokenGlassControls, totalResults, showFilterModal,
            startDate, endDate,
        } = this.state;

        const hasData: boolean = brokenGlassControls && brokenGlassControls.length > 0;

        return (
            <div data-testid="broken-glass-control-list">
                {isFetching && (
                    <div className="loader-wrapper" data-testid="loader-wrapper">
                        <Loader />
                    </div>
                )}
                {showFilterModal && (
                    <FilterModal
                        onModalClose={() => this.setState({ showFilterModal: false })}
                        onSubmit={this.onFilterSubmit}
                        startDate={startDate}
                        endDate={endDate}
                    />
                )}
                <div className="app-screen__table">
                    <div className="app-screen__table__content bigger">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>
                                        <TableHeader
                                            text={t('brokenGlassControl.list.headers.status')}
                                            sort={listParams?.sort}
                                            order={listParams?.order}
                                            field="status"
                                            sortable
                                        />
                                    </th>
                                    <th>
                                        <TableHeader
                                            text={t('brokenGlassControl.list.headers.process')}
                                            sort={listParams?.sort}
                                            order={listParams?.order}
                                            field="uid"
                                            sortable
                                        />
                                    </th>
                                    <th>
                                        <TableHeader
                                            text={t('brokenGlassControl.list.headers.policy')}
                                            sort={listParams?.sort}
                                            order={listParams?.order}
                                            field="insurancePolicyNumber"
                                            sortable
                                        />
                                    </th>
                                    <th>
                                        <TableHeader
                                            text={t('brokenGlassControl.list.headers.insurance')}
                                            sort={listParams?.sort}
                                            order={listParams?.order}
                                            field="insuranceCompanyName"
                                            sortable
                                        />
                                    </th>
                                    <Can
                                        actions={[PermissionKey.ALL, PermissionKey.VIEW_PROVIDER_EMAIL_IN_BROKEN_GLASS_CONTROL_LIST]}
                                        yes={() => (
                                            <th>
                                                <TableHeader
                                                    text={t('brokenGlassControl.list.headers.glassProviderEmail')}
                                                    sort={listParams?.sort}
                                                    order={listParams?.order}
                                                    field="glassProviderEmail"
                                                    sortable
                                                />
                                            </th>
                                        )}
                                    />
                                    <th>
                                        <TableHeader
                                            text={t('brokenGlassControl.list.headers.startDate')}
                                            sort={listParams?.sort}
                                            order={listParams?.order}
                                            field="controlDate"
                                            sortable
                                            filterable
                                            onFilterClick={() => this.setState({ showFilterModal: true })}
                                        />
                                    </th>
                                    <th>
                                        <TableHeader
                                            text={t('brokenGlassControl.list.headers.conclusionDate')}
                                            sort={listParams?.sort}
                                            order={listParams?.order}
                                            field="doneDate"
                                            sortable
                                        />
                                    </th>
                                    <th>
                                        <TableHeader
                                            text={t('brokenGlassControl.list.headers.vehicleLicensePlate')}
                                            sort={listParams?.sort}
                                            order={listParams?.order}
                                            field="vehicleLicensePlate"
                                            sortable
                                        />
                                    </th>
                                    <th>
                                        <TableHeader
                                            text={t('brokenGlassControl.list.headers.vehicleBrand')}
                                            sort={listParams?.sort}
                                            order={listParams?.order}
                                            field="vehicleBrand"
                                            sortable
                                        />
                                    </th>
                                    <th>
                                        <TableHeader
                                            text={t('brokenGlassControl.list.headers.vehicleModel')}
                                            sort={listParams?.sort}
                                            order={listParams?.order}
                                            field="vehicleModel"
                                            sortable
                                        />
                                    </th>
                                    <th>
                                        <TableHeader
                                            text={t('brokenGlassControl.list.headers.district')}
                                            sort={listParams?.sort}
                                            order={listParams?.order}
                                            field="glassProviderDistrict"
                                            sortable
                                        />
                                    </th>
                                    <th>
                                        <Can
                                            actions={[PermissionKey.ALL, PermissionKey.BROKEN_GLASS_CONTROL_CHECK_IN, PermissionKey.BROKEN_GLASS_CONTROL_CHECK_OUT]}
                                            yes={() => (
                                                <TableHeader
                                                    text=""
                                                    sortable={false}
                                                />
                                            )}
                                        />
                                    </th>
                                </tr>
                            </thead>
                            {this.renderBrokenGlassControlList()}
                        </table>
                        {hasData && (
                            <TablePaging
                                currentPage={listParams?.page || 1}
                                limit={RESULTS_PER_PAGE}
                                totalResults={totalResults}
                            />
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

export default withAuthorizationContext(withAuthenticationContext(withBrokenGlassControlContext(withRouter(withTranslationContext(withQueryParams(BrokenGlassControlListScreen))))));
