/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import { REHYDRATE } from 'redux-persist';

import { MessageState } from './types';
import {
    MessagesAction,
} from '../actions/messages_types';

/**
 * @typedef {MessageState messageInitialState
 */
export const messageInitialState: MessageState = {
    unseenMessages: 0,
    unseenDisputes: 0,
    isFetching: false,
};

/**
 * authentication reducer
 * @param {MessageState} state
 * @param {any} action
 * @returns {MessageState}
 */
export default function (state = messageInitialState, action: any): MessageState {
    switch (action.type) {
        case REHYDRATE:
            if (action.payload) {
                return {
                    ...action.payload,
                    isFetching: false,
                };
            }
            return state;
        case MessagesAction.MESSAGES_REQUEST:
            return {
                ...state,
                isFetching: true,
            };
        case MessagesAction.MESSAGES_SUCCESS:
            return {
                ...state,
                isFetching: false,
            };
        case MessagesAction.MESSAGES_UNREAD_SUCCESS:
            return {
                ...state,
                ...action.payload,
                isFetching: false,
            };
        case MessagesAction.MESSAGES_FAILURE:
            return {
                ...state,
                isFetching: false,
            };
        default:
            return state;
    }
}
