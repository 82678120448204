/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { Component, RefObject } from 'react';
import Chart from 'chart.js';
import { ChartObjectInfo } from '../../../constants/types';
import { TranslationContextInterface, withTranslationContext } from '../../controllers/translation/TranslationContext';

/**
 * @typedef {Object} OwnProps
 * @property {ChartObjectInfo} data
 * @property {boolean} hasPriceDataset
 */
interface OwnProps extends TranslationContextInterface{
    data: ChartObjectInfo;
    hasPriceDataset: boolean;
}

/**
 * @typedef {Object} OwnState
 * @property {RefObject<any>} chartRef
 */
interface OwnState {
    chartRef: RefObject<any>;
}

/**
 * shows the bar chart with two or three datasets
 * @extends {Component<OwnProps, OwnState>}
 */
class BarChart extends Component<OwnProps, OwnState> {
    myChart: Chart|null = null;

    constructor(props: OwnProps) {
        super(props);

        this.state = {
            chartRef: React.createRef(),
        };
    }

    componentDidMount() {
        const {
            chartRef,
        } = this.state;

        const { t, hasPriceDataset } = this.props;

        const datasets = [];

        datasets.push({
            label: t('statistics.quantity'),
            data: this.props.data.dataset1.map(d => d.value),
            backgroundColor: '#AC39A5',
        });

        if (hasPriceDataset) {
            datasets.push({
                label: t('statistics.price'),
                data: this.props.data.dataset2!!.map(d => d.value),
                backgroundColor: '#40418A',
            });
        }

        datasets.push({
            label: t('statistics.duration'),
            data: this.props.data.dataset3!!.map(d => d.value),
            backgroundColor: '#727272',
        });

        const that = this;

        this.myChart = new Chart(chartRef.current, {
            type: 'bar',
            data: {
                labels: this.props.data.labels,
                datasets,
            },
            options: {
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                    xAxes: [{
                        gridLines: {
                            drawOnChartArea: false,
                        },
                    }],
                    yAxes: [{
                        gridLines: {
                            display: false,
                        },
                        ticks: {
                            display: false,
                            beginAtZero: true,
                        },
                    }],
                },
                legend: {
                    display: false,
                },
                tooltips: {
                    backgroundColor: '#FFF',
                    titleFontColor: '#000',
                    bodyFontColor: '#000',
                    mode: 'index',
                    callbacks: {
                        title: (tooltipItem, data) => {
                            return '';
                        },
                        label(tooltipItems, data) {
                            const i = tooltipItems.index;

                            if (i !== 0 && !i) return '';

                            if (tooltipItems.datasetIndex === 0) {
                                return that.props.data.labelsDataset1[i];
                            }

                            if (tooltipItems.datasetIndex === 2 && that.props.data.labelsDataset3) {
                                return that.props.data.labelsDataset3[i];
                            }

                            if (data.datasets?.length === 3) {
                                if (tooltipItems.datasetIndex === 1 && that.props.data.labelsDataset2) {
                                    return that.props.data.labelsDataset2[i];
                                }
                            } else {
                                if (tooltipItems.datasetIndex === 1 && that.props.data.labelsDataset3) {
                                    return that.props.data.labelsDataset3[i];
                                }
                            }

                            return '';
                        },
                    },
                },
            },
        });
    }

    componentDidUpdate() {
        const size: number = this.myChart?.data.datasets?.length || 0;

        if (this.myChart && this.myChart.data.datasets) {
            this.myChart.data.labels = this.props.data.labels;
            this.myChart.data.datasets[0].data = this.props.data.dataset1.map(d => d.value);
            if (size === 2) {
                this.myChart.data.datasets[1].data = this.props.data.dataset3!!.map(d => d.value);
            }

            if (size === 3) {
                this.myChart.data.datasets[1].data = this.props.data.dataset2!!.map(d => d.value);
                this.myChart.data.datasets[2].data = this.props.data.dataset3!!.map(d => d.value);
            }
            
            this.myChart.update();
        }
    }

    render() {
        const {
            chartRef,
        } = this.state;

        return (
            <canvas ref={chartRef} />
        );
    }
}

export default withTranslationContext(BarChart);
