/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

export interface IError {
    typeOfViolation: string;
    errorCode?: number;
    min?: number;
    max?: number;
    size?: number;
}

export interface IFormError {
    [key: string]: IError[] | null;
}

const NOT_BLANK = 'NotBlank';
const NOT_EMPTY = 'NotEmpty';
const MIN = 'Min';
const MAX = 'Max';
const REGEX = 'Regex';
const MIN_LENGTH = 'MinLength';
const MAX_LENGTH = 'MaxLength';
const MAX_LENGTH_DECIMAL = 'MaxLengthDecimal';
const PHONE = 'Phone';

const emailRegex = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
const numberRegex = /^[0-9]+$/;
const phoneNumberPtRegex = /^\+?[1-9][0-9\s.-]{6,14}$/;
const phoneNumberUkRegex = /((\+44(\s\(0\)\s|\s0\s|\s)?)|0)7\d{3}(\s)?\d{6}$/;
const phoneNumberUsRegex = /^(\(\d{3}\)|\d{3}-)\d{3}-\d{4}$/;
const decimalNumberRegex = /^\d+[.]?\d{0,2}$/;

export const VALIDATIONS = {
    LOGIN_FORM: {
        email: {
            validations: [NOT_BLANK],
        },
        password: {
            validations: [NOT_BLANK, MIN_LENGTH],
            min: 4,
        },
    },
    RECOVER_PASSWORD_FORM: {
        email: {
            validations: [NOT_BLANK],
        },
    },
    RESET_PASSWORD_FORM: {
        password: {
            validations: [NOT_BLANK, MIN_LENGTH],
            min: 4,
        },
        passwordConfirmation: {
            validations: [NOT_BLANK, MIN_LENGTH],
            min: 4,
        },
    },
    CREATE_USER_FORM: {
        name: {
            validations: [NOT_BLANK],
        },
        email: {
            validations: [NOT_BLANK, REGEX],
            regex: emailRegex,
        },
        permissions: {
            validations: [NOT_BLANK],
        },
    },
    USER_EDIT_FORM: {
        name: {
            validations: [NOT_BLANK],
        },
        contact: {
            validations: [PHONE],
        },
        nif: {
            validations: [REGEX],
            regex: numberRegex,
        },
        labourRateMechanic: {
            validations: [REGEX, MAX_LENGTH_DECIMAL],
            regex: decimalNumberRegex,
            max: 17,
        },
        labourRatePaint: {
            validations: [REGEX, MAX_LENGTH_DECIMAL],
            regex: decimalNumberRegex,
            max: 17,
        },
    },
    CASUALTY_FORM: {
        insuredValue: {
            validations: [REGEX],
            regex: numberRegex,
        },
        deductibleValue: {
            validations: [REGEX],
            regex: numberRegex,
        },
        insuranceCompanyId: {
            validations: [NOT_BLANK],
        },
    },
    FILTER_FORM: {
        startDate: {
            validations: [NOT_BLANK],
        },
    },
    CREATE_MESSAGE: {
        subject: {
            validations: [NOT_BLANK],
        },
        text: {
            validations: [NOT_BLANK],
        },
    },
    IMPORT_FILE_FORM: {
        insuranceCompanyId: {
            validations: [NOT_BLANK],
        },
        fileName: {
            validations: [NOT_BLANK],
        },
    },
    INFORMATIVE_NOTE_FORM: {
        message: {
            validations: [NOT_BLANK, MAX_LENGTH],
            max: 5000,
        },
        from: {
            validations: [NOT_BLANK],
        },
        mileage: {
            validations: [REGEX],
            regex: decimalNumberRegex,
        },
        situation: {
            validations: [NOT_BLANK],
        },
    },
    DOCUMENT_EDIT: {
        fileName: {
            validations: [NOT_BLANK],
        },
    },
    PRE_INSPECTION_FORM: {
        marketValue: {
            validations: [REGEX],
            regex: decimalNumberRegex,
        },
        designatedValue: {
            validations: [REGEX],
            regex: decimalNumberRegex,
        },
        glassDamageEstimate: {
            validations: [REGEX],
            regex: decimalNumberRegex,
        },
        vehicleMileage: {
            validations: [REGEX],
            regex: numberRegex,
        },
        observations: {
            validations: [MAX_LENGTH],
            max: 1000,
        },
    },
    PRE_INSPECTION_GLASS_FORM: {
        repairCost: {
            validations: [REGEX],
            regex: decimalNumberRegex,
        },
    },
    TOKEN_MODAL: {
        emailToShare: {
            validations: [REGEX],
            regex: emailRegex,
        },
        phoneToShare: {
            validations: [NOT_BLANK, PHONE],
        },
    },
    PRE_INSPECTION_CREATE_FORM: {
        insuranceCompanyId: {
            validations: [NOT_BLANK],
        },
        simulationDate: {
            validations: [NOT_BLANK],
        },
        insured: {
            validations: [NOT_BLANK],
        },
        insuredContact: {
            validations: [NOT_BLANK, PHONE],
        },
        insuredEmail: {
            validations: [NOT_BLANK, REGEX],
            regex: emailRegex,
        },
        insuranceEurotaxValue: {
            validations: [REGEX, MAX_LENGTH_DECIMAL],
            regex: decimalNumberRegex,
            max: 17,
        },
        vehicleLicensePlate: {
            validations: [NOT_BLANK],
        },
        vehicleBrand: {
            validations: [NOT_BLANK],
        },
        vehicleModel: {
            validations: [NOT_BLANK],
        },
        vehicleMileage: {
            validations: [REGEX, MAX_LENGTH],
            regex: numberRegex,
            max: 17,
        },
        insuranceYearlyAmount: {
            validations: [REGEX, MAX_LENGTH_DECIMAL],
            regex: decimalNumberRegex,
            max: 17,
        },
        insuranceExtrasAmount: {
            validations: [REGEX, MAX_LENGTH_DECIMAL],
            regex: decimalNumberRegex,
            max: 17,
        },
        agentContact: {
            validations: [PHONE],
        },
        agentEmail: {
            validations: [REGEX],
            regex: emailRegex,
        },
        marketValue: {
            validations: [REGEX, MAX_LENGTH_DECIMAL],
            regex: decimalNumberRegex,
            max: 17,
        },
        designatedValue: {
            validations: [REGEX],
            regex: decimalNumberRegex,
        },
        eurotaxPurchaseValue: {
            validations: [REGEX, MAX_LENGTH_DECIMAL],
            regex: decimalNumberRegex,
            max: 17,
        },
        eurotaxSaleValue: {
            validations: [REGEX, MAX_LENGTH_DECIMAL],
            regex: decimalNumberRegex,
            max: 17,
        },
        glassDamageEstimate: {
            validations: [REGEX, MAX_LENGTH_DECIMAL],
            regex: decimalNumberRegex,
            max: 17,
        },
    },
    PRE_INSPECTION_GLASS_CREATE_FORM: {
        insuranceCompanyId: {
            validations: [NOT_BLANK],
        },
        simulationDate: {
            validations: [NOT_BLANK],
        },
        insured: {
            validations: [NOT_BLANK],
        },
        insuredContact: {
            validations: [NOT_BLANK, PHONE],
        },
        insuredEmail: {
            validations: [NOT_BLANK, REGEX],
            regex: emailRegex,
        },
        vehicleLicensePlate: {
            validations: [NOT_BLANK],
        },
        vehicleBrand: {
            validations: [NOT_BLANK],
        },
        vehicleModel: {
            validations: [NOT_BLANK],
        },
    },
    SEND_MAIL_MODAL: {
        email: {
            validations: [NOT_BLANK, REGEX],
            regex: emailRegex,
        },
    },
    BROKEN_GLASS_CONTROL_CREATE_FORM: {
        insuranceCompanyId: {
            validations: [NOT_BLANK],
        },
        insuredContact: {
            validations: [PHONE],
        },
        insuredEmail: {
            validations: [REGEX],
            regex: emailRegex,
        },
        glassProviderId: {
            validations: [NOT_BLANK],
        },
        controlDate: {
            validations: [NOT_BLANK],
        },
        costAmount: {
            validations: [REGEX],
            regex: numberRegex,
        },
        insurancePolicyNumber: {
            validations: [NOT_BLANK, REGEX],
            regex: numberRegex,
        },
        vehicleLicensePlate: {
            validations: [NOT_BLANK],
        },
    },
};

export const validateField = (fieldName: string, fieldValue: any, formValidations: { [key: string]: any }) => {
    let errors: IError[] | null = null;
    let isFilled = true;

    if (!formValidations[fieldName]) return errors;

    const {
        validations, regex, max, min,
    } = formValidations[fieldName];

    if (validations.includes(NOT_BLANK)) {
        if (fieldValue === null || fieldValue === undefined || fieldValue.toString().trim() === '') {
            errors = [{ typeOfViolation: 'NotBlank' }];
            isFilled = false;
        }
    } else if (validations.includes(NOT_EMPTY)) {
        if (Array.isArray(fieldValue) && fieldValue.length === 0) {
            errors = [{ typeOfViolation: 'NotNull' }];
            isFilled = false;
        }
    }

    if (isFilled) {
        if (validations.includes(MAX)) {
            if (fieldValue && fieldValue > max) {
                errors = [
                    {
                        typeOfViolation: 'Max',
                        max,
                    },
                ];
            }
        }

        if (validations.includes(REGEX)) {
            if (fieldValue && String(fieldValue) !== '' && !regex.test(fieldValue)) {
                errors = [{ typeOfViolation: 'Pattern' }];
            }
        }

        if (validations.includes(MIN)) {
            if (fieldValue !== undefined && Number(fieldValue) < min) {
                errors = [
                    {
                        typeOfViolation: 'Min',
                        min,
                    },
                ];
            }
        }

        if (validations.includes(MIN_LENGTH)) {
            if (fieldValue !== undefined && String(fieldValue).length < min) {
                errors = [
                    {
                        typeOfViolation: 'MinLength',
                        min,
                    },
                ];
            }
        }

        if (validations.includes(MAX_LENGTH)) {
            if (fieldValue !== undefined && String(fieldValue).length > max) {
                errors = [
                    {
                        typeOfViolation: 'MaxLength',
                        max,
                    },
                ];
            }
        }

        if (validations.includes(MAX_LENGTH_DECIMAL)) {
            if (fieldValue !== undefined && String(fieldValue).split('.')[0].length > max) {
                errors = [
                    {
                        typeOfViolation: 'MaxLengthDecimal',
                        max,
                    },
                ];
            }
        }

        if (validations.includes(PHONE)) {
            if (fieldValue && String(fieldValue) !== ''
                && !phoneNumberPtRegex.test(fieldValue)
                && !phoneNumberUkRegex.test(fieldValue)
                && !phoneNumberUsRegex.test(fieldValue)) {
                errors = [{ typeOfViolation: 'Pattern' }];
            }
        }
    }

    return errors;
};

export const getFormErrors: Function = (data: {[key: string]: any}, formValidations: { [key: string]: any }) => {
    let errors: IFormError | null = {};

    Object.keys(data).forEach(field => {
        const { [field]: fieldValue } = data;

        if (field in formValidations) {
            const { manual } = formValidations[field];

            if (!manual && errors) {
                errors[field] = validateField(
                    field,
                    fieldValue,
                    formValidations,
                );

                if (!errors[field]) delete errors[field];
            }
        }
    });

    if (Object.keys(errors).length === 0) errors = null;
    return errors;
};
