/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { Component, Fragment } from 'react';
import axios from 'axios';
import { RouteComponentProps, withRouter, NavLink } from 'react-router-dom';
import logo from '../../assets/images/logo_horizontal.svg';
import { ICON, SvgIcon } from './SvgIcon';
import { MatchParams } from '../../constants/misc';
import {
    AuthenticationContextInterface,
    withAuthenticationContext,
} from '../controllers/authentication/AuthenticationContext';
import {
    MessageContextInterface,
    withMessageContext,
} from '../controllers/messages/MessageContext';
import {
    TranslationContextInterface,
    withTranslationContext,
} from '../controllers/translation/TranslationContext';
import Button from './Button';
import { authenticationLogoutURL } from '../../services/authentication';
import {
    MESSAGES_RECEIVED_LIST_ROUTE,
    CASUALTIES_LIST_ROUTE,
    USERS_LIST_ROUTE,
    STATISTICS_ROUTE,
    PRE_INSPECTIONS_LIST_ROUTE, INDEX_ROUTE,
    USER_MECHANIC_EDIT_ROUTE,
    USERS_EDIT_ROUTE,
    USER_INSURANCE_EDIT_ROUTE,
    DISPUTES_LIST_ROUTE,
    USER_GLASS_PROVIDER_EDIT_ROUTE,
    BROKEN_GLASS_CONTROL_LIST_ROUTE,
} from '../../constants/routes';
import Can from '../containers/Can';
import { PermissionKey, UserRoles } from '../../constants/authorization';

interface OwnProps extends RouteComponentProps<MatchParams>, AuthenticationContextInterface, TranslationContextInterface, MessageContextInterface {}

interface OwnState {}

const initialState: OwnState = {};

class TopBar extends Component<OwnProps, OwnState> {
    state = initialState;

    componentDidMount() {
        const { user } = this.props;

        if (user && user.role !== UserRoles.INSURANCE_USER) {
            const { unreadMessageAndDisputes } = this.props;
            unreadMessageAndDisputes();
        }
    }

    onLogoClick = () => {
        const { history } = this.props;
        history.push(INDEX_ROUTE);
    };

    onLogoutClick = () => {
        this.logoutRequest();
    };

    onProfileClick = () => {
        const { user, history } = this.props;
        if (user) {
            const { id, role } = user;

            switch (role) {
                case UserRoles.MECHANIC:
                    history.push(`${USER_MECHANIC_EDIT_ROUTE}/${id}`);
                    break;
                case UserRoles.INSURANCE_USER:
                    history.push(`${USER_INSURANCE_EDIT_ROUTE}/${id}`);
                    break;
                case UserRoles.GLASS_PROVIDER:
                    history.push(`${USER_GLASS_PROVIDER_EDIT_ROUTE}/${id}`);
                    break;
                case UserRoles.ADMIN:
                case UserRoles.EXTERNAL_ADMIN:
                case UserRoles.COLLABORATOR:
                    history.push(`${USERS_EDIT_ROUTE}/${role}/${id}`);
                    break;
                default:
            }
        }
    }

    logoutRequest = async () => {
        await axios.get(authenticationLogoutURL())
            .then(() => {
                this.props.logout();
            });
    };

    belongsToRoute = (match: any, location: any, route: string) => {
        const {
            pathname,
        } = location;

        const parts = route.split('/');

        if (parts.length > 1) {
            return pathname.includes(parts[1]);
        }

        return false;
    };

    render() {
        const {
            t, user, unseenDisputes, unseenMessages,
        } = this.props;
        let role = '';

        if (user) role = user.role;

        const width = window.innerWidth;

        return (
            <div className="app-screen__topbar" data-testid="app-topbar">
                <div className="app-screen__topbar__container">
                    <img src={logo} alt="" onClick={this.onLogoClick} />
                    <div className="app-screen__topbar__container__menu">
                        <div className="app-screen__topbar__container__menu__menu-item">
                            <Can
                                actions={[PermissionKey.ALL, PermissionKey.VIEW_PRE_INSPECTIONS]}
                                yes={() => (
                                    <NavLink
                                        exact
                                        to={PRE_INSPECTIONS_LIST_ROUTE}
                                        isActive={(m, l) => this.belongsToRoute(m, l, PRE_INSPECTIONS_LIST_ROUTE)}
                                    >
                                        <SvgIcon icon={ICON.CAR} />
                                        {width >= 768 && (<p>{t('topbar.menu.preSurveys')}</p>)}
                                    </NavLink>
                                )}
                            />
                        </div>
                        <div className="app-screen__topbar__container__menu__menu-item">
                            <Can
                                actions={[PermissionKey.ALL, PermissionKey.VIEW_CASUALTIES]}
                                yes={() => (
                                    <NavLink
                                        exact
                                        to={CASUALTIES_LIST_ROUTE}
                                        isActive={(m, l) => this.belongsToRoute(m, l, CASUALTIES_LIST_ROUTE)}
                                    >
                                        <SvgIcon icon={ICON.PROCESSES} />
                                        {width >= 768 && (<p>{t('topbar.menu.casulties')}</p>)}
                                    </NavLink>
                                )}
                            />
                        </div>
                        <div className="app-screen__topbar__container__menu__menu-item">
                            <Can
                                actions={[PermissionKey.ALL, PermissionKey.VIEW_BROKEN_GLASS_CONTROLS]}
                                yes={() => (
                                    <NavLink
                                        exact
                                        to={BROKEN_GLASS_CONTROL_LIST_ROUTE}
                                        isActive={(m, l) => this.belongsToRoute(m, l, BROKEN_GLASS_CONTROL_LIST_ROUTE)}
                                        data-testid="broken-glass-menu-item"
                                    >
                                        <SvgIcon icon={ICON.BROKEN_GLASS_CONTROL} />
                                        {width >= 768 && (<p>{t('topbar.menu.brokenGlassControl')}</p>)}
                                    </NavLink>
                                )}
                            />
                        </div>
                        <div className="app-screen__topbar__container__menu__menu-item message">
                            {role !== UserRoles.INSURANCE_USER && (
                            <Can
                                actions={[PermissionKey.ALL, PermissionKey.VIEW_MESSAGES]}
                                yes={() => (
                                    <Fragment>
                                        <div className="menu-div">
                                            <NavLink
                                                exact
                                                to={MESSAGES_RECEIVED_LIST_ROUTE}
                                                isActive={(m, l) => this.belongsToRoute(m, l, MESSAGES_RECEIVED_LIST_ROUTE)}
                                            >
                                                <SvgIcon icon={ICON.MESSAGES} />
                                                {width >= 768 && (<p>{t('topbar.menu.messages')}</p>)}
                                            </NavLink>
                                        </div>
                                        {unseenMessages > 0 && (
                                        <div className="number-div">
                                            {unseenMessages}
                                        </div>
                                        )}
                                    </Fragment>
                                )}
                            />
                            )}
                        </div>
                        <div className="app-screen__topbar__container__menu__menu-item message">
                            <Can
                                actions={[PermissionKey.ALL, PermissionKey.VIEW_DISPUTES]}
                                yes={() => (
                                    <Fragment>
                                        <div className="menu-div">
                                            <NavLink
                                                exact
                                                to={DISPUTES_LIST_ROUTE}
                                                isActive={(m, l) => this.belongsToRoute(m, l, DISPUTES_LIST_ROUTE)}
                                            >
                                                <SvgIcon icon={ICON.DISPUTE} />
                                                {width >= 768 && (<p>{t('topbar.menu.disputes')}</p>)}
                                            </NavLink>
                                        </div>
                                        {unseenDisputes > 0 && (
                                            <div className="number-div">
                                                {unseenDisputes}
                                            </div>
                                        )}
                                    </Fragment>
                                )}
                            />
                        </div>
                        <div className="app-screen__topbar__container__menu__menu-item">
                            <Can
                                actions={[PermissionKey.ALL, PermissionKey.VIEW_USERS]}
                                yes={() => (
                                    <NavLink
                                        exact
                                        to={USERS_LIST_ROUTE}
                                        isActive={(m, l) => this.belongsToRoute(m, l, USERS_LIST_ROUTE)}
                                    >
                                        <SvgIcon icon={ICON.USERS} />
                                        {width >= 768 && (<p>{t('topbar.menu.users')}</p>)}
                                    </NavLink>
                                )}
                            />
                        </div>
                        <div className="app-screen__topbar__container__menu__menu-item">
                            <Can
                                actions={[PermissionKey.ALL, PermissionKey.VIEW_STATISTICS]}
                                yes={() => (
                                    <NavLink
                                        exact
                                        to={STATISTICS_ROUTE}
                                        isActive={(m, l) => this.belongsToRoute(m, l, STATISTICS_ROUTE)}
                                    >
                                        <SvgIcon icon={ICON.TRENDING_UP} />
                                        {width >= 768 && (<p>{t('topbar.menu.statistics')}</p>)}
                                    </NavLink>
                                )}
                            />
                        </div>
                    </div>
                    <div className="app-screen__topbar__container__right-container">
                        <div className="app-screen__topbar__container__right-container__logout">
                            <p
                                data-testid="user-profile"
                                className="user-name"
                                onClick={this.onProfileClick}
                            >{user && user.name}
                            </p>
                            <Button
                                text={t('topbar.logout')}
                                callback={this.onLogoutClick}
                                isLink
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withMessageContext(withRouter(withAuthenticationContext(withTranslationContext(TopBar))));
