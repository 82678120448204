/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { createContext, ComponentType, FC } from 'react';

import { ConnectedAuthenticationController } from './AuthenticationController';
import { AuthenticationUser, LoginFormFields } from '../../../constants/authentication';

/**
 * @typedef {Object} AuthenticationContext
 * @property {boolean} isAuthenticated
 * @property {boolean} isFetching
 * @property {AuthenticationUser|null} user
 * @property {Function} login
 * @property {Function} logout
 * @property {string | null} token
 * @property {string} turnCredential
 * @property {string} turnUsername
 * @property {Function} updateTurnCredentials
 * @property {Function} updateToken
 * @property {Function} updateUser
 * @property {object|null} formErrors
 */
export interface AuthenticationContextInterface {
    isAuthenticated: boolean;
    isFetching: boolean;
    user: AuthenticationUser | null;
    turnCredential: string;
    turnUsername: string;
    login(fields: LoginFormFields, callback: Function): void;
    logout(): void;
    updateTurnCredentials(username: string, credential: string): void;
    updateToken(token: string): void;
    updateUser(user: AuthenticationUser): void;
    token: string | null;
    formErrors: object | null;
}

// Create the context

export const authenticationContextDefaultValue = {
    isAuthenticated: false,
    isFetching: false,
    turnCredential: '',
    turnUsername: '',
    login: () => {},
    logout: () => {},
    updateTurnCredentials: () => {},
    updateToken: () => {},
    updateUser: () => {},
    user: null,
    token: null,
    formErrors: null,
};

const AuthenticationContext = createContext<AuthenticationContextInterface | null>(authenticationContextDefaultValue);

// Export Provider and Consumer

export const AuthenticationContextProvider = AuthenticationContext.Provider;
export const AuthenticationContextConsumer = AuthenticationContext.Consumer;

/**
 * provides a context HOC that components can use to subscribe as consumers
 * @param Component
 * @returns {React.FC}
 */
export const withAuthenticationContext = <P extends object>(
    Component: ComponentType<P>,
): FC<Omit<P, keyof AuthenticationContextInterface>> => props => (
    <ConnectedAuthenticationController>
        <AuthenticationContextConsumer>
            {ctx => <Component {...props as P} {...ctx} />}
        </AuthenticationContextConsumer>
    </ConnectedAuthenticationController>
    );
