/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import { SocketReceivedMessage } from '../constants/socket_types';

export enum VideoAction {
    VIDEO_RESET = 'VIDEO_RESET',
    SET_ONLINE_FLUXE_USERS = 'SET_ONLINE_FLUXE_USERS',
    SET_ONLINE_MECHANIC_USERS = 'SET_ONLINE_MECHANIC_USERS',
    SET_ONLINE_INSURANCE_USERS = 'SET_ONLINE_INSURANCE_USERS',
    SET_ONLINE_GLASS_PROVIDER_USERS = 'SET_ONLINE_GLASS_PROVIDER_USERS',
    INCOMING_CALL = 'INCOMING_CALL',
    ICE_CANDIDATE_FOUND = 'ICE_CANDIDATE_FOUND',
    CALL_ANSWERED = 'CALL_ANSWERED',
    ANSWER_CALL = 'ANSWER_CALL',
    SEND_MESSAGE = 'SEND_MESSAGE',
    DECLINE_CALL = 'DECLINE_CALL',
    CALL_DECLINED = 'CALL_DECLINED',
    CALL_TERMINATED = 'CALL_TERMINATED',
    RESET_FLAGS = 'RESET_FLAGS',
    RESET_PENDING_CANDIDATES = 'RESET_PENDING_CANDIDATES',
    RESET_HAS_CANDIDATES = 'RESET_HAS_CANDIDATES',
}

export interface ResetUsers {
    type: typeof VideoAction.VIDEO_RESET;
}

export interface ResetFlags {
    type: typeof VideoAction.RESET_FLAGS;
}

export interface SetOnlineFluxeUsers {
    type: typeof VideoAction.SET_ONLINE_FLUXE_USERS;
    payload: Array<number>;
}

export interface SetOnlineMechanicUsers {
    type: typeof VideoAction.SET_ONLINE_MECHANIC_USERS;
    payload: Array<number>;
}

export interface SetOnlineInsuranceUsers {
    type: typeof VideoAction.SET_ONLINE_INSURANCE_USERS;
    payload: Array<number>;
}
export interface SetOnlineGlassProviderUsers {
    type: typeof VideoAction.SET_ONLINE_GLASS_PROVIDER_USERS;
    payload: Array<number>;
}

export interface HasIncomingCall {
    type: typeof VideoAction.INCOMING_CALL;
    payload: SocketReceivedMessage;
}

export interface IceCandidateFound {
    type: typeof VideoAction.ICE_CANDIDATE_FOUND;
    payload: SocketReceivedMessage;
}

export interface CallAnswered {
    type: typeof VideoAction.CALL_ANSWERED;
    payload: SocketReceivedMessage;
}

export interface AnswerCall {
    type: typeof VideoAction.ANSWER_CALL;
}

export interface SendMessage {
    type: typeof VideoAction.SEND_MESSAGE;
    payload: string | null;
}

export interface DeclineCall {
    type: typeof VideoAction.DECLINE_CALL;
}

export interface CallDeclined {
    type: typeof VideoAction.CALL_DECLINED;
}

export interface CallTerminated {
    type: typeof VideoAction.CALL_TERMINATED;
}

export type VideoActionType = SetOnlineFluxeUsers | SetOnlineMechanicUsers | SetOnlineInsuranceUsers| SetOnlineGlassProviderUsers |
    HasIncomingCall | IceCandidateFound | ResetUsers | CallAnswered | AnswerCall | SendMessage |
    DeclineCall | CallDeclined | CallTerminated | ResetFlags;
