/**
 *
 * @Copyright 2022 VOID SOFTWARE, S.A.
 *
 */

import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { MatchParams, TabItem } from '../../../constants/misc';
import {
    TranslationContextInterface,
    withTranslationContext,
} from '../../controllers/translation/TranslationContext';
import Tabs from '../../elements/Tabs';
import { UsersTabsEnum, USERS_TABS_ITEM } from '../../../constants/tabs';
import UsersList from './UsersListScreen';
import NewUserModal from '../../elements/NewUserModal';
import {
    AuthorizationContextInterface,
    withAuthorizationContext,
} from '../../controllers/authorization/AuthorizationContext';
import { displayNotification, NOTIFICATION_TYPE } from '../../../utils/notifs';
import {
    USERS_ADMIN_LIST_ROUTE,
    USERS_COLLABORATOR_LIST_ROUTE,
    USERS_INSURANCE_LIST_ROUTE,
    USERS_LIST_ROUTE,
    USERS_MECHANIC_LIST_ROUTE,
    USERS_GLASS_PROVIDER_LIST_ROUTE,
} from '../../../constants/routes';
import { UsersContext, withUsersContext } from '../../controllers/users/UsersContext';

interface OwnProps extends RouteComponentProps<MatchParams>, TranslationContextInterface, AuthorizationContextInterface, UsersContext {}

interface OwnState {
    tabItemSelected?: string;
    tabItems: TabItem[];
    addUserModalOpen: boolean;
    hasNewUsers: boolean;
}

const initialState: OwnState = {
    tabItemSelected: UsersTabsEnum.ALL,
    tabItems: [],
    addUserModalOpen: false,
    hasNewUsers: false,
};

class UsersScreen extends Component<OwnProps, OwnState> {
    state = initialState;

    componentDidMount() {
        this.prepare();
    }

    componentDidUpdate(prevProps: Readonly<OwnProps>, prevState: Readonly<OwnState>, snapshot?: any): void {
        const { match: oldMatch } = prevProps;
        const { match } = this.props;
        const { path: oldPath } = oldMatch;
        const { path } = match;

        if (path !== oldPath) {
            let tabItemSelected = UsersTabsEnum.ALL;
            switch (path) {
                case USERS_MECHANIC_LIST_ROUTE:
                    tabItemSelected = UsersTabsEnum.MECHANIC;
                    break;
                case USERS_INSURANCE_LIST_ROUTE:
                    tabItemSelected = UsersTabsEnum.INSURANCE_USER;
                    break;
                case USERS_ADMIN_LIST_ROUTE:
                    tabItemSelected = UsersTabsEnum.ADMIN;
                    break;
                case USERS_COLLABORATOR_LIST_ROUTE:
                    tabItemSelected = UsersTabsEnum.COLLABORATOR;
                    break;
                case USERS_GLASS_PROVIDER_LIST_ROUTE:
                    tabItemSelected = UsersTabsEnum.GLASS_PROVIDER;
                    break;
                default:
            }

            this.setState({ tabItemSelected });
        }
    }

    onSuccessResendInvites = () => {
        const { t } = this.props;
        displayNotification(NOTIFICATION_TYPE.SUCCESS, t('users.successResendInvitesNotif'));
    }

    onFailureResendInvites = (errorCode: number) => {
        const { t } = this.props;
        if (errorCode === 55) return displayNotification(NOTIFICATION_TYPE.ERROR, t('users.error55ResendInvitesNotif'));
        return displayNotification(NOTIFICATION_TYPE.ERROR, t('users.errorResendInvitesNotif'));
    }

    onTabChange = (newTab: string) => {
        const { history } = this.props;
        switch (newTab) {
            case UsersTabsEnum.ALL:
                history.push(USERS_LIST_ROUTE);
                break;
            case UsersTabsEnum.MECHANIC:
                history.push(USERS_MECHANIC_LIST_ROUTE);
                break;
            case UsersTabsEnum.INSURANCE_USER:
                history.push(USERS_INSURANCE_LIST_ROUTE);
                break;
            case UsersTabsEnum.ADMIN:
                history.push(USERS_ADMIN_LIST_ROUTE);
                break;
            case UsersTabsEnum.COLLABORATOR:
                history.push(USERS_COLLABORATOR_LIST_ROUTE);
                break;
            case UsersTabsEnum.GLASS_PROVIDER:
                history.push(USERS_GLASS_PROVIDER_LIST_ROUTE);
                break;
            default:
        }
        this.setState({
            tabItemSelected: newTab,
        });
    };

    onCreateUserSuccess = () => {
        const { t } = this.props;
        const { hasNewUsers } = this.state;
        this.setState({
            addUserModalOpen: false,
            hasNewUsers: !hasNewUsers,
        }, () => {
            displayNotification(NOTIFICATION_TYPE.SUCCESS, t('createUser.successNotif'));
        });
    };

    onModalClose = () => {
        this.setState({ addUserModalOpen: false });
    };

    prepare = () => {
        const { t, match } = this.props;
        const { path } = match;
        const tabItems: TabItem[] = [];

        let tabItemSelected = UsersTabsEnum.ALL;
        switch (path) {
            case USERS_MECHANIC_LIST_ROUTE:
                tabItemSelected = UsersTabsEnum.MECHANIC;
                break;
            case USERS_INSURANCE_LIST_ROUTE:
                tabItemSelected = UsersTabsEnum.INSURANCE_USER;
                break;
            case USERS_ADMIN_LIST_ROUTE:
                tabItemSelected = UsersTabsEnum.ADMIN;
                break;
            case USERS_COLLABORATOR_LIST_ROUTE:
                tabItemSelected = UsersTabsEnum.COLLABORATOR;
                break;
            case USERS_GLASS_PROVIDER_LIST_ROUTE:
                tabItemSelected = UsersTabsEnum.GLASS_PROVIDER;
                break;
            default:
        }

        Object.keys(USERS_TABS_ITEM).forEach(k => {
            const i = USERS_TABS_ITEM[Number(k)];

            const item: TabItem = {
                value: i,
                text: t(`users.list.tabItems.${i}`),
            };

            tabItems.push(item);
        });

        this.setState({
            tabItems,
            tabItemSelected,
        });
    };

    addUserClick = () => {
        this.setState({ addUserModalOpen: true });
    };

    resendInvitesButtonClick = () => {
        const { resendInvitesEmails } = this.props;
        resendInvitesEmails(this.onSuccessResendInvites, this.onFailureResendInvites);
    }

    render() {
        const { t } = this.props;
        const {
            tabItems, tabItemSelected, addUserModalOpen, hasNewUsers,
        } = this.state;

        return (
            <div>
                <Tabs
                    tabsItems={tabItems}
                    onTabChange={this.onTabChange}
                    withSearch
                    addButtonText={t('users.addUserBtn')}
                    addButtonClickHandler={this.addUserClick}
                    tabSelected={tabItemSelected}
                    btnResendInvitesClickHandler={this.resendInvitesButtonClick}
                />
                <UsersList
                    tabItem={tabItemSelected}
                    hasNewUsers={hasNewUsers}
                />
                { addUserModalOpen && (<NewUserModal onModalClose={this.onModalClose} onCreateSuccess={this.onCreateUserSuccess} />)}
            </div>
        );
    }
}

export default withAuthorizationContext(withTranslationContext(withUsersContext(UsersScreen)));
