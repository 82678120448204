/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React from 'react';

export interface WithSortProps {
    sortValue: string;
    sortOrder: string;
    onSortChange: Function;
}

interface State {
    sort: any | null;
    order: string | null;
}

const withSort = <P extends object>(
    Component: React.ComponentType<P>
) =>
    class WithSort extends React.Component<Omit<P, keyof WithSortProps>, State> {
        state: State = {
            sort: null,
            order: null,
        };

        onSortChange = (column: string) => {
            const {
                sort,
                order,
            } = this.state;

            let newOrder = null;
            if (sort === column) {
                if (order === 'asc') newOrder = 'desc';
            } else {
                newOrder = 'asc';
            }

            this.setState({
                sort: (newOrder) ? column : null,
                order: newOrder,
            });
        };

        render() {
            const { ...props } = this.props;
            const { sort, order } = this.state;

            return (
                <Component
                    {...props as P}
                    sortValue={sort}
                    sortOrder={order}
                    onSortChange={this.onSortChange}
                />
            );
        }
    };

export default withSort;
