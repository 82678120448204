/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { Component, RefObject } from 'react';
import Button from './Button';

interface OwnProps {
    onSelected: Function;
    buttonText?: string;
    buttonIcon?: string;
    styles?: string;
    helpText?: string;
    toolTipText?: string;
    multiple?: boolean;
}

interface OwnState {
    fileInputRef: RefObject<HTMLInputElement>;
}

class DocumentUpload extends Component<OwnProps, OwnState> {
    constructor(props: OwnProps) {
        super(props);

        this.state = {
            fileInputRef: React.createRef(),
        };
    }

    onOpenFileDialog = () => {
        if (this.state.fileInputRef.current) {
            this.state.fileInputRef.current.click();
        }
    };

    onFileSelected = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { onSelected } = this.props;

        const {
            currentTarget: {
                files,
            },
        } = event;

        if (files) {
            onSelected(files);
        }
    };

    render() {
        const {
            buttonText, styles, buttonIcon, helpText, toolTipText, multiple,
        } = this.props;

        return (
            <div className="document-upload">
                <input
                    type="file"
                    ref={this.state.fileInputRef}
                    onChange={this.onFileSelected}
                    multiple={multiple}
                />
                <div>
                    <Button
                        type="button"
                        text={buttonText || ''}
                        styles={`btn--green ${styles}`}
                        icon={buttonIcon || undefined}
                        iconPosition="right"
                        callback={this.onOpenFileDialog}
                        toolTipText={toolTipText || undefined}
                    />
                    <p>
                        {helpText}
                    </p>
                </div>
            </div>
        );
    }
}

export default DocumentUpload;
