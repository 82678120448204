/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { Component } from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { BrowserRouter } from 'react-router-dom';
import { configureStore } from './store';
import { setupAPI } from './api';
import Router from './components/Router';
import moment from 'moment';
import 'moment/locale/pt';

moment.locale('pt');

const { persistor, store } = configureStore();


class App extends Component {
    state = { isReady: false };

    async componentDidMount() {
        await setupAPI(store);
        this.setState({ isReady: true });
    }

    render() {
        return (
            <div className="app-screen">
                <PersistGate persistor={persistor}>
                    <Provider store={store}>
                        <BrowserRouter>
                            <Router />
                        </BrowserRouter>
                    </Provider>
                </PersistGate>
            </div>
        );
    }
}

export default App;
