/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { FunctionComponent } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { ICON, SvgIcon } from './SvgIcon';
import Button from './Button';
import { ListOrder, ListQueryParamKey } from '../../constants/misc';

interface OwnProps {
    text: string;
    sort?: string;
    order?: string;
    field?: string;
    sortable: boolean;
    headerClass?: string;
    onSortChange?: Function;
    onFilterClick?: Function;
    filterable?: boolean;
}

const TableHeader: FunctionComponent<OwnProps> = (props: OwnProps) => {
    const {
        headerClass,
        sort,
        field,
        order,
        filterable,
        onFilterClick,
        sortable,
        onSortChange,
        text,
    } = props;

    const classHeader = headerClass || '';
    let sortIcon;
    let filterIcon;

    const location = useLocation();
    const history = useHistory();

    if (sort && field && sort === field) {
        if (order === 'desc') {
            sortIcon = <SvgIcon icon={ICON.CHEVRON_DOWN} />;
        } else {
            sortIcon = <SvgIcon icon={ICON.CHEVRON_UP} />;
        }
    }

    if (filterable && onFilterClick) {
        filterIcon = (
            <Button
                icon={ICON.FILTER}
                iconPosition="right"
                text=""
                callback={(): void => onFilterClick(props.field)}
            />
        );
    }

    const onClick = (): void => {
        if (sortable) {
            if (onSortChange) {
                onSortChange(props.field);
            } else {
                const query = new URLSearchParams(location.search);

                query.set(ListQueryParamKey.Sort, props.field || '');
                query.set(ListQueryParamKey.Order, ListOrder.Asc);

                if (sort === field && order === ListOrder.Asc) {
                    query.set(ListQueryParamKey.Order, ListOrder.Desc);
                } else if (sort === field && order === ListOrder.Desc) {
                    query.delete(ListQueryParamKey.Order);
                    query.delete(ListQueryParamKey.Sort);
                }

                history.push(`${location.pathname}?${query.toString()}`);
            }
        }
    };

    return (
        <div data-testid={`table-header-${field}`}>
            {filterIcon}
            <div
                className={sortable ? 'sortable' : classHeader}
                onClick={onClick}
            >
                {text}&nbsp;{sortIcon}
            </div>
        </div>

    );
};

export default TableHeader;