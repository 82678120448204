/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import { API_URL } from '../settings';

export const authenticationLoginURL = (): string => {
    return `${API_URL}/authentication/login`;
};

export const authenticationLogoutURL = (): string => {
    return `${API_URL}/authentication/logout`;
};

export const recoverPasswordURL = (): string => {
    return `${API_URL}/authentication/recover-password`;
};

export const resetPasswordURL = (): string => {
    return `${API_URL}/authentication/reset-password`;
};
