/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { Component } from 'react';
import moment from 'moment';
import { uniqueId } from 'lodash';
import { ICON, SvgIcon } from './SvgIcon';
import Button from './Button';
import { TranslationContextInterface, withTranslationContext } from '../controllers/translation/TranslationContext';
import { PreInspectionsStatus } from '../../constants/types';

interface OwnProps extends TranslationContextInterface {
    type?: string;
    value?: string | number | boolean;
    cellClass?: string;
    actions?: TableCellActions[];
    onEdit?: ((event: React.MouseEvent<HTMLButtonElement>) => void);
    onDelete?: ((event: React.MouseEvent<HTMLButtonElement>) => void);
    onMessage?: ((event: React.MouseEvent<HTMLButtonElement>) => void);
    onDownload?: ((event: React.MouseEvent<HTMLButtonElement>) => void);
    icon?: string;
    contentEditable?: boolean;
    onContentEdit?: Function;
    title?: string;
    name?: string;
}

interface OwnState {
    isActionsOpen: boolean;
}

export enum TableCellActions {
    EDIT = 'EDIT',
    ATTACHMENT = 'ATTACHMENT',
    DELETE = 'DELETE',
    MESSAGE = 'MESSAGE',
    DOWNLOAD = 'DOWNLOAD',
}

export enum TableCellType {
    TEXT = 'TEXT',
    STATUS = 'STATUS',
    ACTIONS = 'ACTIONS',
    DATE = 'DATE',
    DATETIME = 'DATETIME',
    ICON = 'ICON',
    PRE_INSPECTION_STATUS = 'PRE_INSPECTION_STATUS',
}

const initialState = {
    isActionsOpen: false,
};

class TableCell extends Component<OwnProps, OwnState> {
    state = initialState;

    private readonly greenCircleCSS: string = 'green-circle';

    componentDidMount(): void {
        const { type } = this.props;
        if (type === TableCellType.ACTIONS) {
            document.addEventListener('mousedown', this.handleClickOutside);
        }
    }

    componentWillUnmount(): void {
        const { type } = this.props;
        if (type === TableCellType.ACTIONS) {
            document.removeEventListener('mousedown', this.handleClickOutside);
        }
    }

    handleClickOutside = (e: any) => {
        const { target } = e;
        const { name } = target;

        if (name !== 'button-actions-cell') {
            this.setState({ isActionsOpen: false });
        }
    };

    renderStatus = () => {
        const { value, name } = this.props;
        let className = 'yellow-circle';

        if (value === true) {
            className = 'red-circle';
        }

        if (name && name === 'online') {
            if (value === true) {
                className = this.greenCircleCSS;
            }
        }

        if (name && name === 'pending') {
            if (value === false) {
                className = 'orange-circle';
            }
        }

        return (
            <div className="status-cell">
                <span className={className} />
            </div>
        );
    };

    renderPreInspectionStatus = () => {
        const { value } = this.props;

        let dotClassName = '';
        switch (value) {
            case PreInspectionsStatus.DONE:
                dotClassName = 'red-circle';
                break;
            case PreInspectionsStatus.CANCELED:
                dotClassName = 'black-circle';
                break;
            case PreInspectionsStatus.ONGOING:
                dotClassName = this.greenCircleCSS;
                break;
            case PreInspectionsStatus.PENDING:
                dotClassName = 'yellow-circle';
                break;
            case PreInspectionsStatus.REOPENED:
                dotClassName = this.greenCircleCSS;
                break;
            default:
        }

        return (
            <div className="status-cell">
                <span className={dotClassName} />
            </div>
        );
    };

    onActionClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        const { isActionsOpen } = this.state;

        this.setState({
            ...this.state,
            isActionsOpen: !isActionsOpen,
        });

        e.stopPropagation();
    };

    renderActions = () => {
        const { t } = this.props;
        if (!this.props.actions) return <React.Fragment />;

        return (
            <div className="actions-drop-down">
                { this.props.actions.map(action => {
                    const key = uniqueId();
                    switch (action) {
                        case TableCellActions.EDIT:
                            return (
                                <Button
                                    name="button-actions-cell"
                                    text={t('table.actions.edit')}
                                    key={key}
                                    withoutSpan
                                    callback={this.props.onEdit}
                                />
                            );
                        case TableCellActions.ATTACHMENT:
                            return (
                                <Button
                                    name="button-actions-cell"
                                    text={t('table.actions.attachment')}
                                    key={key}
                                    withoutSpan
                                    callback={this.props.onEdit}
                                />
                            );
                        case TableCellActions.DELETE:
                            return (
                                <Button
                                    name="button-actions-cell"
                                    text={t('table.actions.delete')}
                                    key={key}
                                    withoutSpan
                                    callback={this.props.onDelete}
                                />
                            );
                        case TableCellActions.MESSAGE:
                            return (
                                <Button
                                    name="button-actions-cell"
                                    text={t('table.actions.message')}
                                    key={key}
                                    withoutSpan
                                    callback={this.props.onMessage}
                                />
                            );
                        case TableCellActions.DOWNLOAD:
                            return (
                                <Button
                                    callback={this.props.onDownload}
                                    name="button-actions-cell"
                                    text={t('table.actions.download')}
                                    key={key}
                                    withoutSpan
                                />
                            );
                        default:
                            return null;
                    }
                })}
            </div>
        );
    };

    renderActionCell = () => {
        const { isActionsOpen } = this.state;
        if (!this.props.actions) return <React.Fragment />;

        return (
            <div className="actions-cell">
                <Button
                    icon={ICON.LIST}
                    callback={!isActionsOpen ? this.onActionClick : undefined}
                />
                { isActionsOpen && this.renderActions() }
            </div>
        );
    };

    renderValue = () => {
        const { type, value, icon } = this.props;
        if (!type) {
            return value;
        }
        switch (type) {
            case TableCellType.TEXT:
                return value;
            case TableCellType.STATUS:
                return this.renderStatus();
            case TableCellType.PRE_INSPECTION_STATUS:
                return this.renderPreInspectionStatus();
            case TableCellType.ACTIONS:
                return this.renderActionCell();
            case TableCellType.DATE:
                return moment(Number(value)).format('DD/MM/YYYY');
            case TableCellType.DATETIME:
                return moment(Number(value)).format('DD/MM/YYYY @ HH:mm');
            case TableCellType.ICON:
                if (!icon) return null;
                return <SvgIcon icon={icon} />;
            default:
                return null;
        }
    };

    render() {
        const { cellClass, contentEditable, title } = this.props;
        return (
            <td className={cellClass || ''} contentEditable={contentEditable} title={title}>
                {this.renderValue()}
            </td>
        );
    }
}

export default withTranslationContext(TableCell);
