/**
 *
 * @Copyright 2022 VOID SOFTWARE, S.A.
 *
 */

import { API_URL, MOBILE_URL } from '../settings';

export const brokenGlassControlURL = (id?: string | number): string => {
    return `${API_URL}/broken-glass-control/${id || ''}`;
};

export const brokenGlassControlSearchURL = (): string => {
    return `${API_URL}/broken-glass-control/search`;
};

export const brokenGlassControlCheckInURL = (): string => {
    return `${MOBILE_URL}/check-in`;
};

export const brokenGlassControlCheckOutURL = (): string => {
    return `${MOBILE_URL}/check-out`;
};

export const brokenGlassControlResumeURL = (id: number): string => {
    return `${API_URL}/broken-glass-control/${id}/documents/resume`;
};

export const brokenGlassControlDocumentURL = (id: number, fileId: number): string => {
    return `${API_URL}/broken-glass-control/${id}/documents/${fileId}`;
};

export const brokenGlassControlDocumentsURL = (id: number): string => {
    return `${API_URL}/broken-glass-control/${id}/documents`;
};

export const brokenGlassControlImportFiles = (): string => {
    return `${API_URL}/import-files`;
};
