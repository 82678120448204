/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React from 'react';
import { toast } from 'react-toastify';

const toastList = new Set();
const MAX_TOAST = 2;
const toastPosition = toast.POSITION.BOTTOM_CENTER;

export enum NOTIFICATION_TYPE {
    SUCCESS = 'success',
    ERROR = 'error',
}

export const displayNotification = (type: NOTIFICATION_TYPE, message: string, autoClose = true) => {
    if (toastList.size < MAX_TOAST) {
        let id: number | string;

        switch (type) {
            case NOTIFICATION_TYPE.ERROR:
                id = toast.error((
                    <p>{message}</p>
                ), {
                    onClose: () => toastList.delete(id),
                    position: toastPosition,
                    autoClose: autoClose ? 5000 : autoClose,
                });
                break;
            case NOTIFICATION_TYPE.SUCCESS:
                id = toast.success((
                    <p>{message}</p>
                ), {
                    onClose: () => toastList.delete(id),
                    position: toastPosition,
                    autoClose: autoClose ? 5000 : autoClose,
                });
                break;
            default:
        }
    }
};
