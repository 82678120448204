/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { Component } from 'react';
import axios from 'axios';
import { get } from 'lodash';
import { TranslationContextInterface, withTranslationContext } from '../controllers/translation/TranslationContext';
import logo from '../../assets/images/logo_horizontal.svg';
import FormTextField from './FormTextField';
import Button from './Button';
import { CasualtyRequest, Message, MessageRequest } from '../../constants/types';
import { AuthenticationContextInterface, withAuthenticationContext } from '../controllers/authentication/AuthenticationContext';
import { UserRoles } from '../../constants/authorization';
import { FLUXE_MESSAGE_NAME } from '../../constants/misc';
import FormTextAreaField from './FormTextAreaField';
import { messagesURL } from '../../services/messages';
import { getFormErrors, IFormError, VALIDATIONS } from '../../utils/validation';
import { displayNotification, NOTIFICATION_TYPE } from '../../utils/notifs';

interface OwnProps extends TranslationContextInterface, AuthenticationContextInterface {
    onModalClose: Function;
    casualty?: CasualtyRequest;
    onNewMessageCreated?: Function;
    messageToView?: Message;
}

interface OwnState {
    message: MessageRequest;
    to: string;
    from: string;
    formErrors: any;
    isFetching: boolean;
}

const initialState: OwnState = {
    message: {
        casualtyId: null,
        subject: '',
        text: '',
    },
    to: '',
    from: '',
    formErrors: {},
    isFetching: false,
};

const modalOutsideId = 'modal-outside';

class MessageModal extends Component<OwnProps, OwnState> {
    state = initialState;

    componentDidMount(): void {
        const { user, casualty, messageToView } = this.props;
        const fromFluxe = user?.role === UserRoles.ADMIN || user?.role === UserRoles.COLLABORATOR || user?.role === UserRoles.EXTERNAL_ADMIN;
        
        if (!messageToView && user && casualty && fromFluxe) {
            this.setState({
                message: {
                    ...this.state.message,
                    casualtyId: casualty.id,
                },
                to: casualty.mechanicName ? casualty.mechanicName : '',
                from: FLUXE_MESSAGE_NAME,
            });
        } else if (!messageToView && user && casualty && user.role === UserRoles.MECHANIC) {
            this.setState({
                message: {
                    ...this.state.message,
                    casualtyId: casualty.id,
                },
                to: FLUXE_MESSAGE_NAME,
                from: user.name,
            });
        } else if (messageToView) {
            this.setState({
                message: {
                    casualtyId: null,
                    subject: messageToView.subject,
                    text: messageToView.text,
                },
                to: messageToView.to.name,
                from: messageToView.from.name,
            });
        }

        document.addEventListener('mousedown', this.handleOutsideClick);
    }

    componentWillUnmount(): void {
        document.removeEventListener('mousedown', this.handleOutsideClick);
    }

    onInputChange = (e: React.FormEvent<HTMLInputElement>) => {
        this.setState({
            ...this.state,
            message: {
                ...this.state.message,
                [e.currentTarget.name]: e.currentTarget.value,
            },
        });
    };

    onSubmit = () => {
        const { message } = this.state;

        if (this.validateFields()) {
            const fields = {
                subject: String(message.subject).trim(),
                text: String(message.text).trim(),
                casualtyId: message.casualtyId,
            };
            this.sendMessageRequest(fields);
        }
    };

    validateFields = () => {
        let errors: IFormError | null = getFormErrors(this.state.message, VALIDATIONS.CREATE_MESSAGE);

        if (errors && Object.keys(errors).length === 0) errors = null;

        this.setState({ formErrors: errors ? { fields: errors } : errors });
        return errors === null;
    };

    sendMessageRequest = async (fields: object) => {
        const { t, onNewMessageCreated } = this.props;

        this.setState({ isFetching: true });

        await axios.post(messagesURL(), fields)
            .then(() => {
                this.setState({ isFetching: false });
                displayNotification(NOTIFICATION_TYPE.SUCCESS, t('messages.create.successNotif'));
                if (onNewMessageCreated) onNewMessageCreated();
                this.onClose();
            })
            .catch(error => {
                displayNotification(NOTIFICATION_TYPE.ERROR, t('messages.create.errorNotif'));
                if (error.response) {
                    this.handleResponse(error.response.data);
                }
            });
    };

    handleResponse = (formErrors: any = null) => {
        if (formErrors && Object.keys(formErrors).length > 0) {
            this.setState({
                formErrors,
                isFetching: false,
            });
        } else {
            this.setState({ isFetching: false });
        }
    };

    handleOutsideClick = (e: any) => {
        const { target } = e;
        const { id } = target;

        if (id === modalOutsideId) {
            this.onClose();
        }
    };

    onClose = () => {
        const { onModalClose, messageToView } = this.props;
        this.setState({ ...initialState });
        onModalClose(messageToView?.id);
    };

    render() {
        const { t, casualty, messageToView } = this.props;
        const {
            to, from, message, formErrors,
        } = this.state;

        let disabled = false;

        let casualtyId = '';
        if (casualty) {
            casualtyId = casualty.uid;
        } else if (messageToView) {
            disabled = true;
            casualtyId = messageToView.casualtyUID;
        }

        return (
            <div>
                <div id={modalOutsideId} className="app-screen__modal">
                    <div className="app-screen__modal__container new-message-modal">
                        <div className="border-header">
                            <img src={logo} alt="logo" />
                        </div>
                        <div className="app-screen__modal__container__box new-message-box">
                            <div className="app-screen__modal__container__box__content new-message-content">
                                <div className="app-screen__modal__container__box__content__header">
                                    <div className="status-dot green-circle" />
                                    <p>Process</p>
                                    <h4>{casualtyId}</h4>
                                </div>
                                <div className="linear">
                                    <FormTextField
                                        containerStyles="field-new-message"
                                        label={t('messages.create.from')}
                                        name="from"
                                        value={from}
                                        disabled
                                    />
                                    <FormTextField
                                        containerStyles="field-new-message"
                                        label={t('messages.create.to')}
                                        name="to"
                                        value={to}
                                        disabled
                                    />
                                </div>
                                <FormTextField
                                    containerStyles="field-new-message"
                                    label={t('messages.create.subject')}
                                    name="subject"
                                    value={message.subject}
                                    onChange={this.onInputChange}
                                    errors={get(formErrors, 'fields.subject', null)}
                                    disabled={disabled}
                                />
                                <FormTextAreaField
                                    containerStyles="field-message-text"
                                    name="text"
                                    value={message.text}
                                    rows={8}
                                    onChange={this.onInputChange}
                                    label={t('messages.create.message')}
                                    errors={get(formErrors, 'fields.text', null)}
                                    disabled={disabled}
                                    smallArea
                                />
                                {messageToView ? (
                                    <Button
                                        styles="btn--purple"
                                        text={t('global.buttons.ok')}
                                        callback={this.onClose}
                                    />
                                ) : (
                                    <Button
                                        styles="btn--green"
                                        text={t('global.buttons.send')}
                                        callback={this.onSubmit}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withAuthenticationContext(withTranslationContext(MessageModal));
