/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { createContext, ComponentType, FC } from 'react';

import { ConnectedMessageController } from './MessageController';

/**
 * @typedef {Object} MessageContext
 * @property {number} unseenDisputes
 * @property {number} unseenMessages
 * @property {boolean} isFetching
 * @property {Function} unreadMessageAndDisputes
 */
export interface MessageContextInterface {
    unseenDisputes: number;
    unseenMessages: number;
    isFetching: boolean;
    unreadMessageAndDisputes(): void;
}

export const messageContextDefaultValue = {
    unseenDisputes: 0,
    unseenMessages: 0,
    isFetching: false,
    unreadMessageAndDisputes: () => {},
};

const MessageContext = createContext<MessageContextInterface | null>(messageContextDefaultValue);

// Export Provider and Consumer

export const MessageContextProvider = MessageContext.Provider;
export const MessageContextConsumer = MessageContext.Consumer;

/**
 * provides a context HOC that components can use to subscribe as consumers
 * @param Component
 * @returns {React.FC}
 */
export const withMessageContext = <P extends object>(
    Component: ComponentType<P>,
): FC<Omit<P, keyof MessageContextInterface>> => props => (
    <ConnectedMessageController>
        <MessageContextConsumer>
            {ctx => <Component {...props as P} {...ctx} />}
        </MessageContextConsumer>
    </ConnectedMessageController>
    );
