/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

export const EXPERTISE_COMPENSATION_TYPE: string[] = [
    'CIVIL_LIABILITY',
    'FOREIGN_CASUALTY',
    'IDS_CREDITOR',
    'IDS_DEBTOR',
    'IOD_MULTI_RISK',
    'NOT_APPLICABLE',
    'OTHER_SECTORS',
    'OWN_DAMAGE',
    'PATRIMONIAL',
    'QIV',
];

export const EXPERTISE_SITUATION_TYPE: string[] = [
    'CONDITIONAL',
    'DEFINITIVE',
];
