/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React from 'react';
import { toArray, forEach, debounce } from 'lodash';

const urlEncodeRegex = /^[/?:&=+$#{}_\\%|^`äÄöÖüÜß]$/;

export interface WithSearchProps {
    searchValue: string;
    searchedValue: string;
    onSearchChange: (event: React.FormEvent<HTMLInputElement>) => void;
    onSearchSubmit: Function;
    onSearchClear: Function;
}

interface State {
    searchValue: string;
    searchedValue: string;
}

const withSearch = <P extends object>(
    Component: React.ComponentType<P>
) =>
    class WithSearch extends React.Component<Omit<P, keyof WithSearchProps>, State> {
        state: State = {
            searchValue: '',
            searchedValue: '',
        };

        onSearchSubmit = (overrideValue?: string) => {
            const { searchValue } = this.state;

            this.setState({
                searchedValue: overrideValue || searchValue,
                searchValue: overrideValue || searchValue,
            });
        };

        triggerSubmit = debounce(this.onSearchSubmit, 500);

        onSearchChange = (event: React.FormEvent<HTMLInputElement>) => {
            const { currentTarget } = event;

            let value: string[] = [];

            if (currentTarget && currentTarget.value) {
                const { value: inputValue } = currentTarget;
                value = toArray(inputValue);
            }

            let searchStr = '';

            forEach(value, (char: string) => {
                if (urlEncodeRegex.test(char) === false) {
                    searchStr += char;
                }
            });

            this.setState({ searchValue: searchStr });

            this.triggerSubmit();
        };

        onSearchClear = () => {
            this.setState({
                searchValue: '',
                searchedValue: '',
            });
        };

        render() {
            const { ...props } = this.props;
            const { searchValue, searchedValue } = this.state;

            return (
                <Component
                    {...props as P}
                    searchValue={searchValue}
                    searchedValue={searchedValue}
                    onSearchChange={this.onSearchChange}
                    onSearchSubmit={this.onSearchSubmit}
                    onSearchClear={this.onSearchClear}
                />
            );
        }
    };

export default withSearch;
