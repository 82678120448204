/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { Component, RefObject } from 'react';
import ErrorMessage from './ErrorMessage';
import pt from 'date-fns/locale/pt';
import 'react-datepicker/dist/react-datepicker.css';
import ReactDatePicker, { registerLocale } from 'react-datepicker';
import { ICON, SvgIcon } from './SvgIcon';

registerLocale('pt', pt);

interface OwnProps {
    name: string;
    value: Date | null;
    onChange: Function;
    label: string;
    errors?: any;
    containerStyles?: string;
    fieldStyles?: string;
    disabled?: boolean;
    maxDate?: Date;
}

interface OwnState {
    calendarRef?: RefObject<ReactDatePicker>;
}

class FormDatePicker extends Component<OwnProps, OwnState> {
    state = { calendarRef: React.createRef<ReactDatePicker>() };

    onCalendarClick = () => {
        if (this.state.calendarRef.current) {
            this.state.calendarRef.current.setOpen(true);
        }
    };

    renderErrors = () => {
        const { name, errors } = this.props;

        return (
            <ErrorMessage errors={errors} field={name} />
        );
    };

    render() {
        const {
            name,
            value,
            label,
            onChange,
            containerStyles,
            fieldStyles,
            disabled,
            errors,
            maxDate,
        } = this.props;

        const containerClass = containerStyles || '';
        let fieldClass = fieldStyles || '';

        if (disabled) {
            fieldClass += ' disabled';
        }

        return (
            <div className={`field-container ${containerClass}`}>
                <div className="field-container__label">
                    {label}
                </div>
                <div className={`field-container__text-field ${fieldClass}`}>
                    <ReactDatePicker
                        ref={this.state.calendarRef}
                        dateFormat="dd/MM/yyyy"
                        selected={value}
                        onChange={date => onChange(name, date)}
                        calendarClassName="calendar-container"
                        locale="pt"
                        disabled={disabled || false}
                        maxDate={maxDate || null}
                        name={name}
                    />
                    <SvgIcon icon={ICON.CALENDAR} callback={this.onCalendarClick} />
                </div>
                <p>{errors && this.renderErrors()}</p>
            </div>
        );
    }
}

export default FormDatePicker;
