import React, { Component } from 'react';
import axios from 'axios';
import { get } from 'lodash';
import { RouteComponentProps } from 'react-router-dom';
import Loader from '../../elements/Loader';
import { TranslationContextInterface, withTranslationContext } from '../../controllers/translation/TranslationContext';
import { AuthenticationContextInterface, withAuthenticationContext } from '../../controllers/authentication/AuthenticationContext';
import { MatchParams } from '../../../constants/misc';
import { UserRoles } from '../../../constants/authorization';
import {
    adminUserURL, collaboratorsUserURL, externalAdminUserURL, usersToggleURL,
} from '../../../services/users';
import { ICON, SvgIcon } from '../../elements/SvgIcon';
import { NOT_FOUND_ROUTE } from '../../../constants/routes';
import FormTextField from '../../elements/FormTextField';
import Button from '../../elements/Button';
import { getFormErrors, IFormError, VALIDATIONS } from '../../../utils/validation';
import { displayNotification, NOTIFICATION_TYPE } from '../../../utils/notifs';
import displayConfirm from '../../elements/displayConfirm';
import { VideoContextInterface, withVideoContext } from '../../controllers/video/VideoContext';

interface OwnProps extends RouteComponentProps<MatchParams>, TranslationContextInterface, VideoContextInterface, AuthenticationContextInterface {}

interface OwnState {
    id: string;
    name: string;
    email: string;
    preparing: boolean;
    role: string | null;
    user: object;
    url: string;
    formErrors: any;
    contact: string;
    isFetching: boolean;
    statusDotClassName: string;
    inactive: boolean;
    hasModifications: boolean;
    registered: boolean;
    isEditable: boolean;
}

const initialState: OwnState = {
    id: '',
    name: '',
    email: '',
    preparing: false,
    isFetching: false,
    role: null,
    user: {},
    url: '',
    formErrors: {},
    contact: '',
    statusDotClassName: '',
    inactive: false,
    hasModifications: false,
    registered: false,
    isEditable: false,
};

class AdminCollabUserEditScreen extends Component<OwnProps, OwnState> {
    state = initialState;

    componentDidMount(): void {
        const { match, user } = this.props;
        const { params } = match;
        const { id, role } = params;

        if (id && role) {
            let url = '';
            let isEditable = true;
            switch (role) {
                case UserRoles.ADMIN:
                    url = adminUserURL(id);
                    break;
                case UserRoles.EXTERNAL_ADMIN:
                    url = externalAdminUserURL(id);
                    isEditable = String(user?.id) !== id; // check if user is editing itsef (external admin cant edit himselfs)
                    break;
                default:
                    url = collaboratorsUserURL(id);
            }

            this.setState({
                id, role, url, isEditable,
            }, () => this.prepare());
        }
    }

    onBackClick = () => {
        const { history } = this.props;
        history.goBack();
    };

    onInputChange = (e: React.FormEvent<HTMLInputElement>) => {
        this.setState({
            ...this.state,
            [e.currentTarget.name]: e.currentTarget.value,
            hasModifications: true,
        });
    };

    onToggleClick = () => {
        const { t } = this.props;
        const { id, inactive, name } = this.state;

        let msg: string = t('editUser.deactivateMessage', { name });
        if (inactive) msg = t('editUser.activateMessage', { name });

        displayConfirm({
            acceptButtonText: t('global.buttons.accept'),
            onAccept: () => this.toggleRequest(id),
            onReject: () => {},
            rejectButtonText: t('global.buttons.reject'),
            containerStyle: 'remove-document',
            title: msg,
        });
    };

    onSave = () => {
        const { role } = this.state;
        if (this.validateForm()) {
            if (role !== UserRoles.EXTERNAL_ADMIN) {
                const fields = {
                    name: this.state.name,
                    email: this.state.email,
                    contact: this.state.contact,
                };
            
                const formData = new FormData();
                formData.append('userRequest', new Blob([JSON.stringify(fields)], {
                    type: 'application/json',
                }));
                this.editUserRequest(formData);
            } else {
                const fields = {
                    name: this.state.name,
                    contact: this.state.contact,
                };
                this.editUserRequest(fields);
            }
        }
    };

    toggleRequest = async (id: number | string) => {
        const { t } = this.props;
        const { inactive: oldInactive } = this.state;

        this.setState({ isFetching: true });

        await axios.post(usersToggleURL(id))
            .then(response => {
                const { inactive } = response.data;

                if (inactive) displayNotification(NOTIFICATION_TYPE.SUCCESS, t('editUser.deactivateSuccess'));
                else displayNotification(NOTIFICATION_TYPE.SUCCESS, t('editUser.activateSuccess'));

                this.setState({
                    isFetching: false,
                    inactive,
                });
            })
            .catch(() => {
                if (oldInactive) displayNotification(NOTIFICATION_TYPE.ERROR, t('editUser.deactivateError'));
                else displayNotification(NOTIFICATION_TYPE.ERROR, t('editUser.activateError'));

                this.setState({ isFetching: false });
            });
    };

    editUserRequest = async (fields: {}) => {
        const {
            t, user, updateToken, updateUser,
        } = this.props;
        let authId = '';
        const { url, id, role } = this.state;

        if (user) authId = user.id;
        const isAuth = String(id) === String(authId);

        this.setState({ isFetching: true });
        
        let config = {};
        if (role !== UserRoles.EXTERNAL_ADMIN) {
            config = {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            };
        }

        await axios.put(url, fields, config)
            .then(response => {
                if (isAuth) {
                    const { headers, data } = response;
                    updateToken(headers.authorization);
                    updateUser(data);
                }
                displayNotification(NOTIFICATION_TYPE.SUCCESS, t('editUser.successNotif'));
                this.onBackClick();
            })
            .catch(error => {
                displayNotification(NOTIFICATION_TYPE.ERROR, t('editUser.errorNotif'));
                if (error.response) {
                    this.handleResponse(error.response.data);
                }
            });
    };

    prepare = async () => {
        const { history } = this.props;
        const { url, preparing } = this.state;

        if (preparing) return;

        this.setState({ preparing: true });

        await axios.get(url)
            .then(response => {
                const { data } = response;

                this.setState({
                    preparing: false,
                    name: data.name,
                    email: data.email,
                    contact: data.contact || '',
                    inactive: data.inactive,
                    registered: data.registered,
                });
            })
            .catch(error => {
                if (error.response) {
                    const { status } = error.response;
                    if (status === 403) {
                        history.push(NOT_FOUND_ROUTE);
                    }
                }
                this.setState({ preparing: false });
            });
    };

    handleResponse = (formErrors: any = null) => {
        if (formErrors && Object.keys(formErrors).length > 0) {
            this.setState({
                formErrors,
                isFetching: false,
            });
        } else {
            this.setState({ isFetching: false });
        }
    };

    validateForm = () => {
        let errors: IFormError | null = getFormErrors(this.state, VALIDATIONS.USER_EDIT_FORM);

        if (errors && Object.keys(errors).length === 0) errors = null;

        this.setState({ formErrors: errors ? { fields: errors } : errors });
        return errors === null;
    };

    render() {
        const { t, onlineFluxeUsers, user } = this.props;
        let authId = '';
        const {
            id,
            preparing,
            name,
            role,
            email,
            formErrors,
            contact,
            isFetching,
            inactive,
            hasModifications,
            registered,
            isEditable,
        } = this.state;

        const showLoader: boolean = isFetching || preparing;
        const isOnline = onlineFluxeUsers.includes(Number(id));

        if (user) authId = user.id;
        const isAdmin = role === UserRoles.ADMIN;
        const isAuth = String(id) === String(authId);
        const isDisabled = (isAdmin && !isAuth) || !isEditable;
        
        return (
            <div>
                {showLoader && (
                    <div className="loader-wrapper">
                        <Loader />
                    </div>
                )}
                <div className="app-screen__form-edit-user">
                    <div className="app-screen__form-edit-user__top-container">
                        <div className="info-container">
                            <SvgIcon icon={ICON.LEFT_ARROW} callback={this.onBackClick} />
                            <div className="info">
                                <p className="title">{t('editUser.smallTitle')}</p>
                                <p className="user-name">{name}</p>
                                {registered === false ? (
                                    <div className="status-dot orange-circle" />
                                ) : (
                                    <div className={inactive ? 'status-dot red-circle' : `${isOnline ? ' status-dot green-circle' : 'status-dot yellow-circle'}`} />
                                )}
                            </div>
                        </div>
                        <div className="buttons-container">
                            {!(isDisabled) && (
                                <Button
                                    text={t('global.buttons.saveModifications')}
                                    type="submit"
                                    styles="btn--purple"
                                    callback={this.onSave}
                                    disabled={!hasModifications}
                                />
                            )}
                            {!isAuth && (
                                <Button
                                    text={inactive ? t('global.buttons.activate') : t('global.buttons.deactivate')}
                                    callback={this.onToggleClick}
                                    styles="btn--green"
                                    iconPosition="right"
                                />
                            )}
                        </div>
                    </div>
                    <div className="app-screen__form-edit-user__container">
                        <div className="app-screen__form-edit-user__container__content">
                            <h3>{t('editUser.subtitles.personalData')}</h3>
                            <div className="row">
                                <div className="col-sm-8">
                                    <FormTextField
                                        label={t('editUser.labels.name')}
                                        name="name"
                                        value={name}
                                        onChange={this.onInputChange}
                                        errors={get(formErrors, 'fields.name', null)}
                                        disabled={isDisabled}
                                    />
                                </div>
                                <div className="col-sm-4">
                                    <FormTextField
                                        label={t('editUser.labels.type')}
                                        name="role"
                                        value={t(`enums.userRoles.${role}`)}
                                        disabled
                                    />
                                </div>
                            </div>
                            <h3 className="subtitle-with-margin">{t('editUser.subtitles.contactData')}</h3>
                            <div className="row">
                                <div className="col-sm-4">
                                    <FormTextField
                                        label={t('editUser.labels.contact')}
                                        name="contact"
                                        value={contact}
                                        onChange={this.onInputChange}
                                        errors={get(formErrors, 'fields.contact', null)}
                                        disabled={isDisabled}
                                    />
                                </div>
                                <div className="col-sm-4">
                                    <FormTextField
                                        label={t('editUser.labels.email')}
                                        name="email"
                                        value={email}
                                        onChange={this.onInputChange}
                                        disabled={!isAuth || !isEditable}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withAuthenticationContext(withVideoContext(withTranslationContext(AdminCollabUserEditScreen)));
