/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import LoginScreen from './screens/LoginScreen';
import AuthenticatedRoute from './containers/AuthenticatedRoute';
import {
    INDEX_ROUTE,
    LOGIN_ROUTE,
    NOT_FOUND_ROUTE,
    RECOVER_PASSWORD_ROUTE, REGISTER_ROUTE, RESET_PASSWORD_ROUTE,
    BUDGET_DETAIL_ROUTE,
} from '../constants/routes';
import IndexScreen from './screens/IndexScreen';
import RecoverPasswordScreen from './screens/RecoverPasswordScreen';
import ResetPasswordScreen from './screens/ResetPasswordScreen';

export default () => (
    <div className="app-router">
        <Switch>
            <Route exact path={NOT_FOUND_ROUTE}>
                <div>
                    not found
                </div>
            </Route>
            <Route exact path={LOGIN_ROUTE} component={LoginScreen} />
            <Route exact path={RECOVER_PASSWORD_ROUTE} component={RecoverPasswordScreen} />
            <Route exact path={`${RESET_PASSWORD_ROUTE}/:token`} component={ResetPasswordScreen} />
            <Route exact path={`${REGISTER_ROUTE}/:token`} component={ResetPasswordScreen} />
            <Route exact path={BUDGET_DETAIL_ROUTE} component={LoginScreen} />
            <Switch>
                <AuthenticatedRoute path={INDEX_ROUTE} component={IndexScreen} />
            </Switch>
        </Switch>
        <ToastContainer hideProgressBar className="toast-container" />
    </div>
)
