/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import { API_URL } from '../settings';
import { objectToParams } from '../utils/misc';

export const casualtiesURL = (params: object = {}) => {
    const queryString = objectToParams(params);
    return `${API_URL}/casualties${queryString}`;
};

export const casualtyURL = (id: string = '') => {
    return `${API_URL}/casualties/${id}`;
};

export const casualtyDocumentsURL = (id: string = '', params: object = {}) => {
    const queryString = objectToParams(params);
    return `${API_URL}/casualties/${id}/documents${queryString}`;
};

export const casualtyDocumentURL = (id: string = '', fileId: string = '') => {
    return `${API_URL}/casualties/${id}/documents/${fileId}`;
};

export const casualtyInformativeNoteURL = (id: string | number) => {
    return `${API_URL}/casualties/${id}/documents/informative-note`;
};

export const casualtyDocumentBudgetURL = (id: string | number ) => {
    return `${API_URL}/casualties/${id}/documents/budget`;
};

export const casualtyDocumentResumeURL = (id: string | number ) => {
    return `${API_URL}/casualties/${id}/documents/resume`;
};

export const casualtyDocumentReportURL = (id: string | number ) => {
    return `${API_URL}/casualties/${id}/documents/report`;
};

export const casualtyBudgetURL = (id: string | number) => {
    return `${API_URL}/casualties/${id}/budget`;
};

export const casualtyBudgetDetailsURL = (id: string | number, budgetId: string | number, ) => {
    return `${API_URL}/casualties/${id}/budget/${budgetId}`;
};

export const casualtyBudgetUserURL = (id: string | number, budgetId: string | number, ) => {
    return `${API_URL}/casualties/${id}/budget/${budgetId}/user`;
};

export const casualtyFinishURL = (id: string | number) => {
    return `${API_URL}/casualties/${id}/finish`;
};

export const casualtyReopenURL = (id: string | number) => {
    return `${API_URL}/casualties/${id}/reopen`;
};

export const casualtyCancelURL = (id: string | number) => {
    return `${API_URL}/casualties/${id}/cancel`;
};

export const casualtyRestartURL = (id: string | number) => {
    return `${API_URL}/casualties/${id}/restart`;
};
