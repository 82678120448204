/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { Component } from 'react';
import axios from 'axios';
import { get } from 'lodash';
import { TranslationContextInterface, withTranslationContext } from '../controllers/translation/TranslationContext';
import logo from '../../assets/images/logo_horizontal.svg';
import FormTextField from './FormTextField';
import Button from './Button';
import FormSelectField from './FormSelectField';
import { CasualtyRequest, InformativeNoteRequest } from '../../constants/types';
import { AuthenticationContextInterface, withAuthenticationContext } from '../controllers/authentication/AuthenticationContext';
import { SelectOption } from '../../constants/misc';
import FormTextAreaField from './FormTextAreaField';
import { getFormErrors, IFormError, VALIDATIONS } from '../../utils/validation';
import { displayNotification, NOTIFICATION_TYPE } from '../../utils/notifs';
import { casualtyInformativeNoteURL } from '../../services/casualties';
import { EXPERTISE_SITUATION_TYPE } from '../../constants/expertise';

interface OwnProps extends AuthenticationContextInterface, TranslationContextInterface {
    onModalClose: Function;
    casualty: CasualtyRequest;
    onNewInformativeNoteCreated?: Function;
}

interface OwnState {
    note: InformativeNoteRequest;
    from: string;
    formErrors: any;
    isFetching: boolean;
    expertiseSituationOptions: Array<SelectOption>;
    situation: string | null;
}

const initialState: OwnState = {
    note: {
        mileage: null,
        message: '',
    },
    from: '',
    formErrors: {},
    isFetching: false,
    expertiseSituationOptions: [],
    situation: null,
};

const modalOutsideId = 'modal-outside';

class InformativeNoteModal extends Component<OwnProps, OwnState> {
    state = { ...initialState };

    componentDidMount(): void {
        const { user, t } = this.props;

        const expertiseSituationOptions: Array<SelectOption> = [];

        expertiseSituationOptions.push({
            value: '',
            label: t('informativeNote.labels.defaultSelectOption'),
        });

        Object.keys(EXPERTISE_SITUATION_TYPE).forEach(k => {
            const i = EXPERTISE_SITUATION_TYPE[Number(k)];

            const item: SelectOption = {
                value: i,
                label: t(`enums.expertiseSituationType.${i}`),
            };
            expertiseSituationOptions.push(item);
        });

        if (user) {
            this.setState({
                from: user.name,
            });
        }

        const { casualty } = this.props;

        let expertiseSituation: string | null = '';
        if (casualty) {
            expertiseSituation = casualty.expertiseSituation;
        }

        this.setState({
            expertiseSituationOptions,
            situation: expertiseSituation,
        });

        document.addEventListener('mousedown', this.handleOutsideClick);
    }

    componentWillUnmount(): void {
        document.removeEventListener('mousedown', this.handleOutsideClick);
    }

    onInputChange = (e: React.FormEvent<HTMLInputElement>) => {
        this.setState({
            ...this.state,
            note: {
                ...this.state.note,
                [e.currentTarget.name]: e.currentTarget.value,
            },
        });
    };

    onInputStateChange = (e: React.FormEvent<HTMLInputElement>) => {
        this.setState({
            ...this.state,
            [e.currentTarget.name]: e.currentTarget.value,
        });
    };

    onSubmit = () => {
        const { note, from, situation } = this.state;

        if (this.validateFields()) {
            const fields = {
                from,
                mileage: String(note.mileage).trim() === '' ? null : Number(note.mileage),
                message: String(note.message).trim(),
                situation,
            };
            this.sendInformativeNoteRequest(fields);
        }
    };

    validateFields = () => {
        let errors: IFormError | null = getFormErrors(this.state.note, VALIDATIONS.INFORMATIVE_NOTE_FORM);

        if (errors && Object.keys(errors).length === 0) errors = null;

        this.setState({ formErrors: errors ? { fields: errors } : errors });
        return errors === null;
    };

    sendInformativeNoteRequest = async (fields: object) => {
        const { t, casualty, onNewInformativeNoteCreated } = this.props;

        if (!casualty.id) return;

        this.setState({ isFetching: true });

        await axios.post(casualtyInformativeNoteURL(casualty.id), fields)
            .then(() => {
                this.setState({ isFetching: false });
                displayNotification(NOTIFICATION_TYPE.SUCCESS, t('informativeNote.successNotif'));
                if (onNewInformativeNoteCreated) onNewInformativeNoteCreated();
                this.onClose();
            })
            .catch(error => {
                displayNotification(NOTIFICATION_TYPE.ERROR, t('informativeNote.errorNotif'));
                if (error.response) {
                    this.handleResponse(error.response.data);
                }
            });
    };

    handleResponse = (formErrors: any = null) => {
        if (formErrors && Object.keys(formErrors).length > 0) {
            this.setState({
                formErrors,
                isFetching: false,
            });
        } else {
            this.setState({ isFetching: false });
        }
    };

    handleOutsideClick = (e: any) => {
        const { target } = e;
        const { id } = target;

        if (id === modalOutsideId) {
            this.onClose();
        }
    };

    onClose = () => {
        const { onModalClose } = this.props;
        this.setState({ ...initialState });
        onModalClose();
    };

    render() {
        const { t, casualty } = this.props;
        const {
            from, note, formErrors, expertiseSituationOptions, situation,
        } = this.state;

        let casualtyId = '';
        if (casualty) {
            casualtyId = casualty.uid;
        }

        return (
            <div>
                <div id={modalOutsideId} className="app-screen__modal" data-testid="modal-outside-test">
                    <div className="app-screen__modal__container new-message-modal">
                        <div className="border-header">
                            <img src={logo} alt="logo" />
                            <p>{t('informativeNote.createTitle')}</p>
                        </div>
                        <div className="app-screen__modal__container__box new-message-box">
                            <div className="app-screen__modal__container__box__content new-message-content">
                                <div className="app-screen__modal__container__box__content__header">
                                    <div className="status-dot green-circle" />
                                    <p>Process</p>
                                    <h4>{casualtyId}</h4>
                                </div>
                                <div className="mt-10">
                                    <FormTextField
                                        containerStyles="field-new-note"
                                        label={t('informativeNote.labels.from')}
                                        name="from"
                                        value={from}
                                        onChange={this.onInputStateChange}
                                        errors={get(formErrors, 'fields.from', null)}
                                    />
                                </div>
                                <div className="inline">
                                    <FormTextField
                                        containerStyles="field-new-note"
                                        label={t('informativeNote.labels.mileage')}
                                        name="mileage"
                                        value={note.mileage}
                                        onChange={this.onInputChange}
                                        errors={get(formErrors, 'fields.mileage', null)}
                                    />
                                    <FormSelectField
                                        containerStyles="field-new-note"
                                        options={expertiseSituationOptions}
                                        name="situation"
                                        value={situation}
                                        onChange={this.onInputStateChange}
                                        label={t('informativeNote.labels.expertiseSituation')}
                                        errors={get(formErrors, 'fields.situation', null)}
                                    />
                                </div>
                                <FormTextAreaField
                                    containerStyles="field-message-text"
                                    name="message"
                                    value={note.message}
                                    rows={8}
                                    onChange={this.onInputChange}
                                    label={t('informativeNote.labels.message')}
                                    errors={get(formErrors, 'fields.message', null)}
                                    smallArea
                                />
                                <Button
                                    styles="btn--green"
                                    text={t('informativeNote.labels.sendNote')}
                                    callback={this.onSubmit}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withAuthenticationContext(withTranslationContext(InformativeNoteModal));
