/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import moment from 'moment';
import { BrokenGlassControlStatus } from '../constants/types';
import { brokenGlassControlCheckInURL, brokenGlassControlCheckOutURL } from '../services/brokenGlassControl';

export const objectToParams = (obj: any, useQ = true) => {
    let params = '';

    if (obj !== undefined && obj !== null) {
        Object.keys(obj).forEach(key => {
            if (obj[key] !== null) {
                if (typeof obj[key] === 'string' && obj[key].length < 1) return;
                if (params.length > 0) {
                    params = `${params}&${key}=${obj[key]}`;
                } else {
                    params = `${key}=${obj[key]}`;
                }
            }
        });
    }

    if (useQ) {
        if (params && params.length > 0) {
            params = `?${params}`;
        }
    }

    return params;
};

export const generateInt = (min: number, max: number): number => {
    const minVal = Math.ceil(min);
    const maxVal = Math.floor(max);
    return Math.floor(Math.random() * (maxVal - minVal + 1)) + minVal;
};

export const numberToCurrency = (number: number) => {
    const formatter = new Intl.NumberFormat('pt-PT', {
        style: 'currency',
        currency: 'EUR',
        minimumFractionDigits: 2,
    });
    return formatter.format(number);
};

export const asyncForEach = async (array: Array<any>, callback: Function) => {
    for (let index = 0; index < array.length; index++) {
        await callback(array[index], index, array);
    }
};

/**
 * Get the filename from header content disposition
 * @property {string} contentDisposition
 * Example header format: "attachment;filename="PP_FLXPER-20-00020.pdf""
 */
export const getFilemaneFromContentDisposition = (contentDisposition: string) => {
    const contentParts = contentDisposition.split(';');
    const filenameParts = contentParts[1].split('=');
    return filenameParts[1].split('"').join('');
};

/**
* validates a value if null or empty
* @param {any} value
* @returns {string|null}
*/
export const validateString = (value: any) => {
    if (value && String(value).trim().length > 0) return value;
    return null;
};

/**
* set a date to miliseconds
* @param {string} date
* @returns {mumber | null}
*/
export const setDateTime = (date: string | null): number | null => {
    if (!date) return null;
    return moment(date).add(12, 'hours').unix() * 1000;
};

export const getBrokenGlassControlMobileUrl = (status: BrokenGlassControlStatus) => {
    return status === BrokenGlassControlStatus.ONGOING ? brokenGlassControlCheckOutURL() : brokenGlassControlCheckInURL();
};
