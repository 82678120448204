/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React from 'react';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { SvgIcon } from './SvgIcon';
import Button from './Button';

interface OwnProps {
    icon?: string;
    title?: string;
    message?: string;
    secondMessage?: string;
    acceptButtonText?: string;
    onAccept?: Function;
    rejectButtonText?: string;
    onReject?: Function;
    containerStyle?: string;
}

const displayConfirm = (props: OwnProps) => {
    const {
        icon,
        title,
        message,
        acceptButtonText,
        onAccept,
        rejectButtonText,
        onReject,
        containerStyle,
        secondMessage,
    } = props;

    const containerClass: string = containerStyle || '';

    confirmAlert({
        customUI: ({ onClose }) => {
            return (
                <div className="confirm">
                    <div className={`confirm__inner confirmation ${containerClass}`}>
                        {icon && (
                            <SvgIcon icon={icon} />
                        )}
                        {title && (
                            <h6>{title}</h6>
                        )}
                        {message && (
                            <p>{message}</p>
                        )}
                        {secondMessage && (
                            <p>{secondMessage}</p>
                        )}

                        <div className="confirm__inner__buttons">
                            {rejectButtonText && (
                                <Button
                                    type="button"
                                    text={rejectButtonText}
                                    styles="btn--purple"
                                    callback={() => {
                                        onClose();
                                        if (onReject) onReject();
                                    }}
                                />
                            )}
                            {acceptButtonText && (
                                <Button
                                    type="button"
                                    text={acceptButtonText}
                                    styles="btn--dark-purple"
                                    callback={() => {
                                        onClose();
                                        if (onAccept) onAccept();
                                    }}
                                />
                            )}
                        </div>
                    </div>
                </div>
            )
        }
    })
};

export default displayConfirm;
