/**
 *
 * @Copyright 2022 VOID SOFTWARE, S.A.
 *
 */

import React, { Component, ReactNode, RefObject } from 'react';
import axios from 'axios';
import { cloneDeep } from 'lodash';
import { ICON, SvgIcon } from './SvgIcon';
import {
    TranslationContextInterface,
    withTranslationContext,
} from '../controllers/translation/TranslationContext';
import { displayNotification, NOTIFICATION_TYPE } from '../../utils/notifs';
import { ApiFile, Box } from '../../constants/types';
import { withAnnotationContext, AnnotationContext } from '../controllers/annotation/AnnotationContext';
import IconClose from '../assets/IconClose';

interface OwnProps extends TranslationContextInterface, AnnotationContext {
    onModalClose: Function;
    img?: string | null;
    imgId: number;
    imgLabel?: string | null;
    isEditable: boolean;
    imageFile?: ApiFile;
}

interface OwnState {
    isZoomed: boolean;
    imgSrc: string;
    isFetching: boolean;
    isDrawing: boolean;
    boxes: Box[];
    newBox: Box | null;
    unzoomedHeight: number;
    unzoomedWidth: number;
 }

const initialState: OwnState = {
    isZoomed: false,
    imgSrc: '',
    isFetching: false,
    isDrawing: false,
    boxes: [],
    newBox: null,
    unzoomedHeight: 0,
    unzoomedWidth: 0,
};
 
class DrawImageModal extends Component<OwnProps, OwnState> {
    state = initialState;

    imageRef: RefObject<HTMLImageElement> = React.createRef()

    canvasRef: RefObject<HTMLCanvasElement>= React.createRef();

    componentDidMount(): void {
        const {
            img,
            imageFile,
        } = this.props;

        document.addEventListener('keydown', this.onEscPress, false);

        if (img) {
            const { isFetching } = this.state;

            if (isFetching) return;

            this.setState({
                isFetching: true,
            });

            axios.get(img, {
                responseType: 'arraybuffer',
            }).then(res => {
                const blob = new Blob([res.data], {
                    type: 'image/jpeg',
                });
                const objectURL = URL.createObjectURL(blob);
                this.setState({
                    imgSrc: objectURL,
                    isFetching: false,
                }, this.getBoxes);
            }).catch(() => {
                this.setState({
                    isFetching: false,
                });
            });
        } else if (imageFile) {
            this.setState({
                imgSrc: imageFile.fileString,
                isFetching: false,
            }, this.getBoxes);
        }
    }

    componentWillUnmount(): void {
        document.removeEventListener('keydown', this.onEscPress, false);
    }

    onEscPress = (event: KeyboardEvent): void => {
        const { isZoomed } = this.state;

        if (isZoomed && event.key === 'Escape') {
            this.onClose();
        }
    }

    onClose = (): void => {
        const { onModalClose } = this.props;
        onModalClose();
    }

    handleMouseDown = (e: React.MouseEvent): void => {
        const canvas = this.canvasRef.current;

        if (canvas) {
            const { left, top } = canvas.getBoundingClientRect();
            const horizontalPositionTopLeft = e.clientX - left;
            const verticalPositionTopLeft = e.clientY - top;

            this.setState({
                newBox: {
                    id: -1,
                    horizontalPositionTopLeft,
                    verticalPositionTopLeft,
                    horizontalPositionBottomRight: horizontalPositionTopLeft,
                    verticalPositionBottomRight: verticalPositionTopLeft,
                    comment: '',
                    isEditing: false,
                    isSelected: false,
                },
                isDrawing: true,
            });
        }
    }

    getBoxes = async (): Promise<void> => {
        const {
            getAnnotations, getGlassDamages, imgId, isEditable, t,
        } = this.props;
            
        const [annotation, glassDamages] = await Promise.all([getAnnotations(imgId, isEditable), getGlassDamages(imgId)]);

        if (annotation.failed) {
            displayNotification(NOTIFICATION_TYPE.ERROR, t('modalImage.commentsFailed'));
        }

        if (glassDamages.failed) {
            displayNotification(NOTIFICATION_TYPE.ERROR, t('modalImage.glassDamagesFailed'));
        }
         
        this.setState({ boxes: [...annotation.list, ...glassDamages.list.map(glass => ({ ...glass, isGlassDamage: true }))] });
    }

    setImgDimensions = () => {
        if (this.imageRef.current) {
            this.setState({
                unzoomedHeight: this.imageRef.current.height,
                unzoomedWidth: this.imageRef.current.width,
            });
        }
    }

    handleMouseUp = (): void => {
        const { newBox, isDrawing } = this.state;

        if (newBox) {
            const isBoxValid = Math.abs(newBox.horizontalPositionTopLeft - newBox.horizontalPositionBottomRight) * Math.abs(newBox.verticalPositionTopLeft - newBox.verticalPositionBottomRight) > 10;

            const xStart = Math.min(newBox.horizontalPositionTopLeft, newBox.horizontalPositionBottomRight);
            const xEnd = Math.max(newBox.horizontalPositionTopLeft, newBox.horizontalPositionBottomRight);
            const yStart = Math.min(newBox.verticalPositionTopLeft, newBox.verticalPositionBottomRight);
            const yEnd = Math.max(newBox.verticalPositionTopLeft, newBox.verticalPositionBottomRight);

            if (isDrawing && isBoxValid) {
                this.createNote({
                    ...newBox,
                    horizontalPositionTopLeft: xStart,
                    horizontalPositionBottomRight: xEnd,
                    verticalPositionTopLeft: yStart,
                    verticalPositionBottomRight: yEnd,
                });
            }
        }
        this.setState({
            isDrawing: false,
        });
    }

    editNote = (box: Box): void => {
        const { boxes, isZoomed } = this.state;

        if (!isZoomed) return;

        this.setState({
            boxes: boxes.map((b => {
                if (b.id === box.id || this.isPositionEqual(box, b)) {
                    return { ...b, isEditing: true, isSelected: true };
                }
                return { ...b, isEditing: false, isSelected: false };
            })),
        });
    }

    selectNote = (box: Box): void => {
        const { boxes, isZoomed } = this.state;

        if (!isZoomed) return;
        const newBoxes = cloneDeep(boxes).map(b => ({ ...b, isSelected: false }));
        const index = boxes.findIndex(b => b.id === box.id || this.isPositionEqual(box, b));
        
        if (index < 0) return;
        newBoxes[index].isSelected = !boxes[index].isSelected;

        this.setState({ boxes: newBoxes });
    }

    unselectNotes = (): void => {
        const { boxes, isZoomed } = this.state;

        if (!isZoomed) return;

        this.setState({
            boxes: boxes.map(b => ({ ...b, isSelected: false })),
        });
    }
    
    createNote = async (box: Box) => {
        const { boxes } = this.state;
        const { addAnnotation, imgId } = this.props;

        const addedBox = await addAnnotation(box, imgId);

        if (!addedBox) return;

        const canvas = this.canvasRef.current;
        const ctx = canvas?.getContext('2d');
        if (canvas && ctx) {
            ctx.clearRect(0, 0, canvas.width, canvas.height);
        }

        this.setState({
            isDrawing: false,
            boxes: [...boxes, { ...addedBox, isEditing: true, isSelected: true }],
        });
    }

    handleMouseDrag = (e: React.MouseEvent): void => {
        const { isDrawing, newBox } = this.state;
        const { isEditable } = this.props;
        e.preventDefault();
        e.stopPropagation();
        
        if (!isDrawing) {
            return;
        }

        const canvas = this.canvasRef.current;
        const ctx = canvas?.getContext('2d');
        if (canvas && ctx && newBox) {
            ctx.clearRect(0, 0, canvas.width, canvas.height);
            ctx.strokeStyle = isEditable ? 'green' : 'red';
            ctx.lineWidth = 2;

            const { left, top } = canvas.getBoundingClientRect();
            const x = e.clientX - left;
            const y = e.clientY - top;
            this.setState({
                newBox: {
                    ...newBox,
                    id: -1,
                    horizontalPositionBottomRight: x,
                    verticalPositionBottomRight: y,
                    comment: '',
                    isEditing: true,
                    isSelected: true,
                },
            });

            ctx.strokeRect(newBox.horizontalPositionTopLeft, newBox.verticalPositionTopLeft, x - newBox.horizontalPositionTopLeft, y - newBox.verticalPositionTopLeft);
        }
    }

    isPositionEqual =(b1: Box, b2: Box): boolean => b1.horizontalPositionTopLeft === b2.horizontalPositionTopLeft && b1.horizontalPositionBottomRight === b2.horizontalPositionBottomRight && b1.verticalPositionTopLeft === b2.verticalPositionTopLeft && b1.verticalPositionBottomRight === b2.verticalPositionBottomRight

    removeNote = async (box: Box): Promise<void> => {
        const {
            deleteAnnotation, deleteGlassAIAnnotation, imgId, t,
        } = this.props;
        const { boxes, isZoomed } = this.state;

        if (!isZoomed) {
            return;
        }

        if (box.isGlassDamage) {
            if (await deleteGlassAIAnnotation(imgId, box.id)) {
                displayNotification(NOTIFICATION_TYPE.ERROR, t('modalImage.removeAnotationError'));
                return;
            }
        } else {
            deleteAnnotation(box, imgId);
        }

        this.setState({
            boxes: boxes.filter(b => !this.isPositionEqual(box, b)),
        });
    }

    removeBoxFocus = (event: React.FocusEvent<HTMLInputElement>, box: Box): void => {
        const { t } = this.props;
        const comment = event.currentTarget.value;
        if (comment.length > 20) {
            displayNotification(
                NOTIFICATION_TYPE.ERROR,
                t('modalImage.messageTooLong'),
            );
            return;
        }

        this.handleEditNote(comment, box);
    }

    handleEditNote = async (comment: string, box: Box) => {
        const { boxes } = this.state;
        const { editAnnotation, imgId } = this.props;

        const idx = boxes.findIndex(b => b.id === box.id);
        const newBoxes = [...boxes];
        newBoxes[idx] = {
            ...newBoxes[idx],
            comment,
            isEditing: false,
        };
        this.setState({
            boxes: [...newBoxes],
        });

        await editAnnotation({ ...box, comment }, imgId);
    }

    changeZoomStatus = (isZoomed: boolean): void => {
        this.setState({
            isZoomed,
        });
    }

    renderBoxes = (boxes: Array<Box>): ReactNode => {
        const { isEditable } = this.props;
        const {
            isZoomed, unzoomedWidth, unzoomedHeight,
        } = this.state;

        if (!this.imageRef.current) return null;

        if (boxes.length === 0) return null;

        const imgRealWidth = this.imageRef.current.naturalWidth;
        const imgRealHeight = this.imageRef.current.naturalHeight;

        return (
            <div
                className="comments-boxes"
                style={{
                    width: isZoomed ? imgRealWidth : unzoomedWidth,
                    height: isZoomed ? imgRealHeight : unzoomedHeight,
                }}
                data-testid="boxes-wrapper"
            >
                {boxes.map(box => {
                    const xStart = Math.min(box.horizontalPositionTopLeft, box.horizontalPositionBottomRight); // did this so things in production dont get broken
                    const xEnd = Math.max(box.horizontalPositionTopLeft, box.horizontalPositionBottomRight);
                    const yStart = Math.min(box.verticalPositionTopLeft, box.verticalPositionBottomRight);
                    const yEnd = Math.max(box.verticalPositionTopLeft, box.verticalPositionBottomRight);

                    let top = (yStart * 100) / imgRealHeight;
                    let left = (xStart * 100) / imgRealWidth;
                    let right = (Math.abs(imgRealWidth - xEnd) * 100) / imgRealWidth;
                    let bottom = (Math.abs(imgRealHeight - yEnd) * 100) / imgRealHeight;

                    const increasedValue = isZoomed ? 1 : 2;
                    if (100 - (left + right) < 5) { // 100 - 100%
                        left -= increasedValue;
                        right -= increasedValue;
                    }

                    if (100 - (top + bottom) < 5) {
                        bottom -= increasedValue;
                        top -= increasedValue;
                    }

                    return (
                        <div
                            key={box.id}
                            className="box-wrapper"
                            style={{
                                top: `${top}%`,
                                bottom: `${bottom}%`,
                                left: `${left}%`,
                                right: `${right}%`,
                            }}
                            onMouseDown={isEditable ? this.handleMouseDown : () => {}}
                            onMouseMove={isEditable ? this.handleMouseDrag : () => {}}
                            onMouseUp={isEditable ? this.handleMouseUp : () => {}}
                            onMouseLeave={isEditable ? this.handleMouseUp : () => {}}
                        >
                            {isZoomed && box.isSelected && (
                                <button className="box-wrapper__close-btn zoomed" type="button" data-testid="close-button" onClick={() => this.removeNote(box)}>
                                    <IconClose />
                                </button>
                            )}
                            <div
                                className="box-wrapper__box"
                                style={{
                                    borderWidth: isZoomed ? '14px' : '3px',
                                    borderColor: !box.isGlassDamage ? 'green' : 'red',
                                }}
                                onClick={isEditable || box.isGlassDamage ? () => this.selectNote(box) : () => {}}
                            />
                            {isEditable && !box.isGlassDamage && (
                                <div className="box-wrapper__comment">
                                    <div>
                                        {box.isEditing ? (
                                            // eslint-disable-next-line jsx-a11y/no-autofocus
                                            <input autoFocus type="text" onBlur={(e): void => this.removeBoxFocus(e, box)} placeholder={box.comment} />
                                        ) : (
                                            <p data-testid="commentLabel" onClick={(): void => this.editNote(box)}>{box.comment}</p>
                                        )}
                                    </div>
                                </div>
                            )}
                        </div>
                    );
                })}
            </div>
        );
    }

    renderCanvas(): ReactNode {
        return (
            <canvas
                ref={this.canvasRef}
                id="draw-canvas--editable"
                onMouseDown={this.handleMouseDown}
                onMouseMove={this.handleMouseDrag}
                onMouseUp={this.handleMouseUp}
                onMouseLeave={this.handleMouseUp}
                width={this.imageRef.current?.naturalWidth}
                height={this.imageRef.current?.naturalHeight}
                onClick={this.unselectNotes}
            />
        );
    }

    render(): ReactNode {
        const {
            img, imgLabel, t, isEditable,
        } = this.props;
        const {
            isFetching, imgSrc, isZoomed, boxes,
        } = this.state;

        return isZoomed ? (
            <div className="app-screen__modal-zoom">
                <div
                    className="draw-image-container draw-image-container__zoomed"
                >
                    {isEditable && (
                    <p className="draw-message">{t('modalImage.dragToDraw')}</p>
                    )}
                    <div data-testid="zoomed-button-group" className="draw-image-container__zoomed--button-group">
                        <SvgIcon callback={(): void => this.changeZoomStatus(false)} icon={ICON.ZOOM_OUT} />
                        <SvgIcon callback={this.onClose} icon={ICON.CROSS} />
                    </div>
                    <img
                        src={imgSrc}
                        alt={imgLabel || ''}
                        ref={this.imageRef}
                    />
                    {this.renderBoxes(boxes)}
                    {isEditable && this.renderCanvas()}
                </div>
            </div>
        ) : (
            <div className="app-screen__modal" data-testid="image-draw-modal">
                <div className="app-screen__modal__container">
                    <div className="app-screen__modal__container__box">
                        <SvgIcon callback={this.onClose} icon={ICON.CROSS} />
                        <div className="app-screen__modal__container__box__content image-show">
                            <div
                                className="draw-image-container"
                                onClick={(): void => this.changeZoomStatus(true)}
                            >
                                {img !== null && isFetching ? (
                                    <p>{t('modalImage.loading')}</p>
                                ) : (
                                    <img
                                        className="unzoomed"
                                        src={imgSrc}
                                        alt={imgLabel || ''}
                                        ref={this.imageRef}
                                        onLoad={this.setImgDimensions}
                                    />
                                )}
                                {!isFetching && img !== null && this.renderBoxes(boxes)}
                            </div>
                            {imgLabel && (
                                <p>{imgLabel}</p>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
 
export default withAnnotationContext(withTranslationContext(DrawImageModal));
