/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';

import { AuthenticationContextProvider } from './AuthenticationContext';

import { AppState } from '../../../reducers/types';
import { AuthenticationUser, LoginFormFields } from '../../../constants/authentication';
import { requestLogin, requestLogout, requestUpdateTurnCredentials, requestUpdateToken, requestUpdateUser } from '../../../actions/authentication';

/**
 * @typedef {Object} StateProps
 * @property {boolean} isAuthenticated
 * @property {boolean} isFetching
 * @property {string | null} token
 * @property {AuthenticationUser | null} user
 * @property {object | null} formErrors
 * @property {string} turnCredential
 * @property {string} turnUsername
 */
interface StateProps {
    isAuthenticated: boolean;
    isFetching: boolean;
    token: string | null;
    user: AuthenticationUser | null;
    formErrors: object | null;
    turnCredential: string;
    turnUsername: string;
}

/**
 * @typedef {Object} DispatchProps
 * @property {Function} requestLogin
 * @property {Function} requestLogout
 * @property {Function} requestUpdateTurnCredentials
 * @property {Function} requestUpdateToken
 * @property {Function} requestUpdateUser
 */
interface DispatchProps {
    requestLogin: Function;
    requestLogout: Function;
    requestUpdateTurnCredentials: Function;
    requestUpdateToken: Function;
    requestUpdateUser: Function;
}

/**
 * @typedef {Object} OwnProps
 * @property {any} children
 */
interface OwnProps {
    children: any;
}

/**
 * @typedef {Object} Props
 */
type Props = StateProps & DispatchProps & OwnProps;

/**
 * @typedef {Object} OwnState
 */
interface OwnState {}

/**
 * @typedef {Object} State
 */
type State = OwnState;

/**
 * provides store properties and actions access to its consumers
 * @extends {Component<Props, State>}
 */
export class AuthenticationController extends Component<Props, State> {

    /**
     * calls the login action creator
     * @param {LoginFormFields} fields
     * @param {Function} callback
     */
    login = (fields: LoginFormFields, callback: Function) => {
        const {
            requestLogin,
        } = this.props;
        requestLogin(fields, callback);
    };

    /**
     * calls the logout action creator
     */
    logout = () => {
        const { requestLogout } = this.props;
        requestLogout();
    };

    /**
     * update or set the turn server on store credentials
     * @param {string} username
     * @param {string} credential
     */
    updateTurnCredentials = (username: string, credential: string) => {
        const { requestUpdateTurnCredentials } = this.props;
        requestUpdateTurnCredentials(username, credential);
    }

    /**
     * update the user token
     * @param {string} token
     */
    updateToken = (token: string) => {
        const { requestUpdateToken } = this.props;
        requestUpdateToken(token);
    }

    /**
     * update the user
     * @param {AuthenticationUser} user
     */
    updateUser = (user: AuthenticationUser) => {
        const { requestUpdateUser } = this.props;
        requestUpdateUser(user);
    }

    render() {
        const {
            children,
            isAuthenticated,
            isFetching,
            token,
            user,
            turnCredential,
            turnUsername,
            formErrors,
        } = this.props;

        return (
            <AuthenticationContextProvider
                value={{
                    isAuthenticated: isAuthenticated,
                    isFetching: isFetching,
                    login: this.login,
                    logout: this.logout,
                    token: token,
                    updateToken: this.updateToken,
                    updateUser: this.updateUser,
                    user: user,
                    formErrors: formErrors,
                    turnCredential,
                    turnUsername,
                    updateTurnCredentials: this.updateTurnCredentials,
                }}
            >
                {children}
            </AuthenticationContextProvider>
        );
    }

}

/**
 * mapStateToProps
 * @param {AppState} state
 * @returns {StateProps}
 */
const mapStateToProps = (state: AppState): StateProps => {
    return {
        isAuthenticated: state.authentication.isAuthenticated,
        isFetching: state.authentication.isFetching,
        token: state.authentication.token,
        user: state.authentication.user,
        formErrors: state.authentication.formErrors,
        turnCredential: state.authentication.turnCredential,
        turnUsername: state.authentication.turnUsername,
    };
};

/**
 * mapDispatchToProps
 * @param {Dispatch} dispatch
 * @returns {DispatchProps}
 */
export const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>): DispatchProps => ({
    requestLogin: (formData: LoginFormFields, onSuccess: Function) => dispatch(requestLogin(formData, onSuccess)),
    requestLogout: () => dispatch(requestLogout()),
    requestUpdateTurnCredentials: (username: string, credential: string) => dispatch(requestUpdateTurnCredentials(username, credential)),
    requestUpdateToken: (token: string) => dispatch(requestUpdateToken(token)),
    requestUpdateUser: (user: AuthenticationUser) => dispatch(requestUpdateUser(user))
});

export const ConnectedAuthenticationController = connect(mapStateToProps, mapDispatchToProps)(AuthenticationController);
