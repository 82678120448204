/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { Component } from 'react';
import axios from 'axios';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import moment from 'moment';
import { TranslationContextInterface, withTranslationContext } from '../../controllers/translation/TranslationContext';
import Loader from '../../elements/Loader';
import { MatchParams, TabItem } from '../../../constants/misc';
import { CasualtyFormTabsEnum, CASUALTY_FORM_TABS_ITEM, CASUALTY_FORM_TABS_ITEM_INSURANCE } from '../../../constants/tabs';
import Tabs from '../../elements/Tabs';
import CasualtyRequestTab from '../../elements/CasualtyRequestTab';
import {
    casualtiesURL,
    casualtyDocumentsURL,
    casualtyDocumentURL,
    casualtyURL,
    casualtyFinishURL,
    casualtyReopenURL,
    casualtyCancelURL,
    casualtyDocumentReportURL,
    casualtyRestartURL,
} from '../../../services/casualties';
import { ICON, SvgIcon } from '../../elements/SvgIcon';
import {
    CASUALTIES_DETAILS_ROUTE,
    CASUALTIES_EDIT_ROUTE,
    USER_INSURANCE_DETAILS_ROUTE,
    USER_INSURANCE_EDIT_ROUTE,
    USER_MECHANIC_DETAILS_ROUTE,
    USER_MECHANIC_EDIT_ROUTE,
} from '../../../constants/routes';
import Button from '../../elements/Button';
import { CasualtyRequest, CasualtyResponse, CasualtyStatus } from '../../../constants/types';
import { displayNotification, NOTIFICATION_TYPE } from '../../../utils/notifs';
import { getFormErrors, IFormError, VALIDATIONS } from '../../../utils/validation';
import CasualtyBudgetTab from '../../elements/CasualtyBudgetTab';
import CasualtyDocumentsTab from '../../elements/CasualtyDocumentsTab';
import CasualtyMessagesTab from '../../elements/CasualtyMessagesTab';
import { AuthenticationContextInterface, withAuthenticationContext } from '../../controllers/authentication/AuthenticationContext';
import { PermissionKey, UserRoles } from '../../../constants/authorization';
import MessageModal from '../../elements/MessageModal';
import { AuthorizationContextInterface, withAuthorizationContext } from '../../controllers/authorization/AuthorizationContext';
import { checkPermission } from '../../../utils/authorization';
import { VideoContextInterface, withVideoContext } from '../../controllers/video/VideoContext';
import VideoComponent from '../../elements/VideoComponent';
import { SocketReceivedMessage, SocketReceivedMessageType } from '../../../constants/socket_types';
import InformativeNoteModal from '../../elements/InformativeNoteModal';
import displayConfirm from '../../elements/displayConfirm';
import { getFilemaneFromContentDisposition } from '../../../utils/misc';

interface OwnProps extends RouteComponentProps<MatchParams>, TranslationContextInterface, AuthenticationContextInterface, AuthorizationContextInterface, VideoContextInterface {
    onVideoCall: Function;
}

interface OwnState {
    preparing: boolean;
    tabItemSelected?: string;
    tabItems: TabItem[];
    formErrors: any;
    id: string;
    isEdit: boolean;
    casualtyRequest: CasualtyRequest;
    isFetching: boolean;
    documentCount: number;
    creatingNewMessage: boolean;
    messagesCount: number;
    viewingDetails: boolean;
    isOnVideoCall: boolean;
    isMakingCall: boolean;
    isUserOnline: boolean;
    hasModifications: boolean;
    pendingCandidates: Array<SocketReceivedMessage>;
    isVideoBig: boolean;
    creatingInformativeNote: boolean;
    hasCreateResume: boolean;
    hasNewInformativeNote: boolean;
}

let AUX_COUNT = 0;

class CasualtyFormScreen extends Component<OwnProps, OwnState> {
    private readonly acceptButton: string = 'global.buttons.accept';

    private readonly hasChangesError: string = 'errors.modificationsWasNotSaved';

    private readonly rejectButton: string = 'global.buttons.reject';
    
    constructor(props: OwnProps) {
        super(props);

        const casualtyRequest: CasualtyRequest = {
            id: null,
            status: '',
            uid: '',
            casualtyDate: '',
            compensationType: '',
            deductibleValue: null,
            expertiseDate: '',
            expertiseNumber: '',
            expertiseObservations: '',
            expertiseSituation: '',
            insuranceCompanyId: null,
            insurancePolicyNumber: '',
            insuredValue: null,
            mechanicName: '',
            vehicleBrand: '',
            vehicleLicensePlate: '',
            vehicleModel: '',
            vehicleVersion: '',
            vehicleOwner: '',
            vehicleOwnerContact: '',
            insuranceCompanyName: '',
            insuranceProcessNumber: '',
            mechanicId: null,
            creatorId: null,
            vin: '',
            vehicleInfo: null,
            apiKey: '',
        };

        this.state = {
            id: '',
            preparing: false,
            formErrors: {},
            tabItemSelected: CasualtyFormTabsEnum.REQUEST,
            tabItems: [],
            isEdit: false,
            isFetching: false,
            documentCount: 0,
            messagesCount: 0,
            creatingNewMessage: false,
            casualtyRequest,
            viewingDetails: false,
            isOnVideoCall: false,
            isMakingCall: false,
            isUserOnline: false,
            hasModifications: false,
            isVideoBig: false,
            pendingCandidates: [],
            creatingInformativeNote: false,
            hasCreateResume: false,
            hasNewInformativeNote: false,
        };
    }

    componentDidMount() {
        const {
            match, user, hasCandidates, candidates, updatePendingCandidates,
        } = this.props;
        const { params, path } = match;
        const { id } = params;
        let viewingDetails = false;
        let role = '';

        if (user) role = user.role;

        if (role === UserRoles.INSURANCE_USER || path.startsWith(CASUALTIES_DETAILS_ROUTE)) {
            viewingDetails = true;
        }

        let casualtyId = '';
        let isEdit = false;

        if (id) {
            isEdit = true;
            casualtyId = id;
        }

        const tabItems: TabItem[] = [];
        let TAB_TYPE = CASUALTY_FORM_TABS_ITEM;

        if (role === UserRoles.INSURANCE_USER) TAB_TYPE = CASUALTY_FORM_TABS_ITEM_INSURANCE;

        Object.keys(TAB_TYPE).forEach(k => {
            const i = TAB_TYPE[Number(k)];
            this.pushTabItems(i, tabItems);
        });

        let isOnVideoCall = false;
        let pendingCandidates: Array<SocketReceivedMessage> = [];
        if (hasCandidates) {
            isOnVideoCall = true;

            if (candidates) {
                pendingCandidates = candidates;
            }
        }

        updatePendingCandidates(false, []);

        this.setState({
            tabItems,
            id: casualtyId,
            isEdit,
            viewingDetails,
            isOnVideoCall,
            pendingCandidates,
        }, () => this.prepare());
    }

    pushTabItems = (i: string, tabItems: TabItem[]) => {
        const { t } = this.props;
        const item: TabItem = {
            value: i,
            text: t(`casualties.form.tabItems.${i}`),
        };

        tabItems.push(item);
    }

    componentDidUpdate(prevProps: Readonly<OwnProps>, prevState: Readonly<{}>, snapshot?: any): void {
        const {
            answerCall: oldAnswerCall,
            callDeclined: oldCallDeclined,
            onlineMechanicUsers: oldMechanicUsers,
            onlineFluxeUsers: oldFluxeUsers,
            match: oldMatch,
            signalMessage: oldSignalMessage,
        } = prevProps;
        const {
            answerCall,
            callDeclined,
            onlineMechanicUsers,
            onlineFluxeUsers,
            user,
            wasIceCandidateFound,
            signalMessage,
            match,
        } = this.props;
        const {
            casualtyRequest, isUserOnline, isOnVideoCall, pendingCandidates,
        } = this.state;
        const { params: oldParams } = oldMatch;
        const { params } = match;
        const { id: oldId } = oldParams;
        const { id } = params;

        const hasMechanicUsersChanged = oldMechanicUsers !== onlineMechanicUsers;
        const hasFluxeUsersChanged = oldFluxeUsers !== onlineFluxeUsers;

        if (wasIceCandidateFound && !isOnVideoCall && signalMessage && signalMessage !== oldSignalMessage && signalMessage.messageType === SocketReceivedMessageType.ICE_CANDIDATE_FOUND) {
            pendingCandidates.push(signalMessage);
            this.setState({ pendingCandidates: [...pendingCandidates] });
        }

        if (id !== oldId) {
            this.setState({
                id: id || '',
            }, () => { this.prepare(); });
        }

        if (hasMechanicUsersChanged || hasFluxeUsersChanged) {
            let newIsUserOnline = isUserOnline;

            if (user && casualtyRequest && (user.role === UserRoles.ADMIN || user.role === UserRoles.COLLABORATOR)) {
                newIsUserOnline = Boolean(casualtyRequest.mechanicId && onlineMechanicUsers.includes(casualtyRequest.mechanicId));
            } else if (user && casualtyRequest && user.role === UserRoles.MECHANIC) {
                newIsUserOnline = Boolean(casualtyRequest.mechanicId && onlineFluxeUsers.length > 0);
            }

            this.setState({
                isUserOnline: newIsUserOnline,
            });
        }

        if (answerCall !== oldAnswerCall && answerCall) {
            this.setState({
                isOnVideoCall: true,
                isMakingCall: false,
            });
            return;
        }

        if (callDeclined && (callDeclined !== oldCallDeclined)) {
            this.setState({
                isOnVideoCall: false,
                isMakingCall: false,
            });
        }
    }

    onTabChange = (newTab: string) => {
        this.setState({
            tabItemSelected: newTab,
        });
    };

    onVideoConfigError = () => {
        const { t } = this.props;
        displayNotification(NOTIFICATION_TYPE.ERROR, t('errors.errorVideo'));
        this.setState({
            isOnVideoCall: false,
            isMakingCall: false,
        });
    };

    /**
     * generates the resume PDF for pre inspection and makes the download
     */
    onGeneratePdfClick = () => {
        const { casualtyRequest, isFetching, preparing } = this.state;
        if (preparing || isFetching) return;

        this.setState({ isFetching: true });

        axios({
            method: 'POST',
            url: casualtyDocumentReportURL(String(casualtyRequest.id)),
            responseType: 'blob',
        }).then(response => {
            this.setState({
                isFetching: false,
            });

            const { headers, data } = response;
            const mimetype = headers['content-type'];
            const filename = getFilemaneFromContentDisposition(headers['content-disposition']);
            const blob = new Blob([data], { type: mimetype });
            const link = document.createElement('a');
            const url = window.URL.createObjectURL(blob);
            link.href = url;
            link.download = filename;
            document.body.appendChild(link);
            link.click();
            window.URL.revokeObjectURL(url);
        }).catch(() => {
            this.setState({
                isFetching: false,
            });
        });
    }

    onSaveClick = () => {
        const { isEdit, casualtyRequest } = this.state;

        const {
            expertiseObservations,
            insurancePolicyNumber,
            vehicleBrand,
            vehicleLicensePlate,
            vehicleModel,
            vehicleOwner,
            insuranceProcessNumber,
            vehicleOwnerContact,
            expertiseDate,
            casualtyDate,
            expertiseSituation,
            compensationType,
        } = casualtyRequest;

        if (this.validateFields()) {
            const fields: CasualtyRequest = {
                ...casualtyRequest,
                expertiseObservations: expertiseObservations.trim(),
                insurancePolicyNumber: insurancePolicyNumber.trim(),
                vehicleBrand: vehicleBrand.trim(),
                vehicleLicensePlate: vehicleLicensePlate.trim(),
                vehicleModel: vehicleModel.trim(),
                vehicleOwner: vehicleOwner.trim(),
                insuranceProcessNumber,
                vehicleOwnerContact: vehicleOwnerContact ? String(vehicleOwnerContact).trim() : null,
                expertiseDate: moment(expertiseDate).add(2, 'hours').unix() * 1000,
                casualtyDate: moment(casualtyDate).add(2, 'hours').unix() * 1000,
                expertiseSituation: expertiseSituation === '' ? null : expertiseSituation,
                compensationType: compensationType === '' ? null : compensationType,
            };

            if (isEdit) this.editCasualtyRequest(fields);
            else this.createCasualtyRequest(fields);
        }
    };

    onConcludeReopenClick = (isConclude: boolean) => {
        const { t } = this.props;
        const { hasModifications, id } = this.state;
        let title = t('casualties.form.closeConfirmationMsg');
        let onAccept = () => this.makeRequest(casualtyFinishURL(String(id)));

        if (hasModifications) {
            displayNotification(NOTIFICATION_TYPE.ERROR, t(this.hasChangesError));
            return;
        }

        if (!isConclude) {
            title = t('casualties.form.reopenConfirmationMsg');
            onAccept = () => this.makeRequest(casualtyReopenURL(String(id)));
        }

        displayConfirm({
            acceptButtonText: t(this.acceptButton),
            onAccept,
            onReject: () => {},
            rejectButtonText: t(this.rejectButton),
            title,
        });
    };

    makeRequest = (url: string) => {
        const { isFetching, casualtyRequest } = this.state;

        if (isFetching) return;

        this.setState({
            isFetching: true,
        });

        axios.post(url).then(response => {
            const { data } = response;

            this.setState({
                casualtyRequest: {
                    ...casualtyRequest,
                    ...data,
                },
                isFetching: false,
            });
        }).catch(() => this.setState({
            isFetching: false,
        }));
    }

    onVideoSizeChanged = () => {
        const { isVideoBig } = this.state;
        this.setState({ isVideoBig: !isVideoBig });
    };

    validateFields = (): boolean => {
        let errors: IFormError | null = getFormErrors(this.state.casualtyRequest, VALIDATIONS.CASUALTY_FORM);

        if (errors && Object.keys(errors).length === 0) errors = null;

        this.setState({ formErrors: errors ? { fields: errors } : errors });
        return errors === null;
    };

    editCasualtyRequest = async (fields: any) => {
        const { id } = this.state;
        const { t } = this.props;

        this.setState({ isFetching: true });

        await axios.put(casualtyURL(id), fields)
            .then(() => {
                displayNotification(NOTIFICATION_TYPE.SUCCESS, t('casualties.form.editSuccessNotif'));
                this.setState({
                    isFetching: false,
                    hasModifications: false,
                });
            })
            .catch(error => {
                displayNotification(NOTIFICATION_TYPE.ERROR, t('casualties.form.editErrorNotif'));
                if (error.response) {
                    this.handleResponse(error.response.data);
                }
            });
    };

    createCasualtyRequest = async (fields: any) => {
        const { t, permissions, history } = this.props;

        this.setState({ isFetching: true });

        await axios.post(casualtiesURL(), fields)
            .then(response => {
                displayNotification(NOTIFICATION_TYPE.SUCCESS, t('casualties.form.createSuccessNotif'));

                const tabItems: TabItem[] = [];

                Object.keys(CASUALTY_FORM_TABS_ITEM).forEach(k => {
                    const i = CASUALTY_FORM_TABS_ITEM[Number(k)];

                    const item: TabItem = {
                        value: i,
                        text: t(`casualties.form.tabItems.${i}`),
                    };
                    tabItems.push(item);
                });

                this.setState({
                    tabItems,
                    id: response.data.id,
                    isFetching: false,
                    hasModifications: false,
                }, () => {
                    if (checkPermission(permissions, [PermissionKey.ALL, PermissionKey.EDIT_CASUALTIES])) {
                        history.push(`${CASUALTIES_EDIT_ROUTE}/${response.data.id}`);
                    } else {
                        history.push(`${CASUALTIES_DETAILS_ROUTE}/${response.data.id}`);
                    }
                });
            })
            .catch(error => {
                displayNotification(NOTIFICATION_TYPE.ERROR, t('casualties.form.createErrorNotif'));
                if (error.response) {
                    this.handleResponse(error.response.data);
                }
            });
    };

    handleResponse = (formErrors: any = null) => {
        if (formErrors && Object.keys(formErrors).length > 0) {
            this.setState({
                formErrors,
                isFetching: false,
            });
        } else {
            this.setState({ isFetching: false });
        }
    };

    onAddClickHandler = () => {
        const { tabItemSelected } = this.state;

        if (tabItemSelected) {
            switch (tabItemSelected) {
                case CasualtyFormTabsEnum.BUDGET:
                    this.setState({
                        hasCreateResume: true,
                    });
                    break;
                case CasualtyFormTabsEnum.DOCUMENTS:
                    this.setState({ creatingInformativeNote: true });
                    break;
                case CasualtyFormTabsEnum.MESSAGES:
                    this.setState({ creatingNewMessage: true });
                    break;
                default:
            }
        }
    };

    onCancelClick = () => {
        const { t } = this.props;
        const { hasModifications, id } = this.state;

        if (hasModifications) {
            displayNotification(NOTIFICATION_TYPE.ERROR, t(this.hasChangesError));
            return;
        }

        displayConfirm({
            acceptButtonText: t(this.acceptButton),
            onAccept: () => this.makeRequest(casualtyCancelURL(String(id))),
            onReject: () => {},
            rejectButtonText: t(this.rejectButton),
            title: t('casualties.form.cancelConfirmationMsg'),
        });
    };

    onRestartClick = () => {
        const { t } = this.props;
        const { hasModifications, id } = this.state;

        if (hasModifications) {
            displayNotification(NOTIFICATION_TYPE.ERROR, t(this.hasChangesError));
            return;
        }

        displayConfirm({
            acceptButtonText: t(this.acceptButton),
            onAccept: () => this.makeRequest(casualtyRestartURL(String(id))),
            onReject: () => {},
            rejectButtonText: t(this.rejectButton),
            title: t('casualties.form.reopenConfirmationMsg'),
        });
    };

    onBackClick = () => {
        const { history } = this.props;
        history.goBack();
    };

    onCasualtyChange: Function = (casualty: any) => {
        const { casualtyRequest } = this.state;
        this.setState({
            hasModifications: true,
            casualtyRequest: {
                ...casualty,
                status: casualtyRequest.status,
            },
        });
    };

    onNoteModalClose = () => {
        this.setState({
            creatingInformativeNote: false,
        });
    };

    onNewInformativeNoteCreated = () => {
        const { hasNewInformativeNote } = this.state;

        this.setState({
            creatingInformativeNote: false,
            hasNewInformativeNote: !hasNewInformativeNote,
        });
    };

    onFileSelected = (files: File []) => {
        const { tabItemSelected } = this.state;
        const totalDocs = files.length;
        AUX_COUNT = 0;

        if (tabItemSelected === CasualtyFormTabsEnum.DOCUMENTS) {
            Object.keys(files).forEach(key => {
                const file = files[Number(key)];
                const formData = new FormData();
                formData.append('file', file, file.name);
                formData.append('fileName', file.name);
                this.uploadDocument(formData, totalDocs);
            });
        }
    };

    onMessageModalClose = () => {
        this.setState({ creatingNewMessage: false });
    };

    onMessageCreated = () => {
        const { messagesCount } = this.state;

        this.setState({ messagesCount: messagesCount + 1 });
    };

    onVideoCallClick = async () => {
        this.setState({ isOnVideoCall: true, isMakingCall: true });
    };

    onRejectCallClick = () => {
        const { notifyDeclineCall } = this.props;

        notifyDeclineCall();
    };

    onCallTerminate = () => {
        this.setState({
            isMakingCall: false,
            isOnVideoCall: false,
        });
    };

    onAcceptCallClick = () => {
        const { notifyAnswerCall } = this.props;

        notifyAnswerCall();
    };

    uploadDocument = async (file: FormData, totalDocs: number) => {
        const { t } = this.props;
        const { id, documentCount } = this.state;
        if (!id) return;

        this.setState({ isFetching: true });

        const config = {
            headers: {
                'content-type': 'multipart/form-data',
            },
        };

        await axios.post(casualtyDocumentsURL(String(id)), file, config)
            .then(response => {
                this.getDocument(String(response.data));
                this.setState({ isFetching: false });
                if (AUX_COUNT === totalDocs - 1) {
                    this.setState({ documentCount: documentCount + 1 });
                    displayNotification(NOTIFICATION_TYPE.SUCCESS, t('casualties.form.documents.uploadSuccessNotif'));
                }
            })
            .catch(() => {
                this.setState({ isFetching: false });
                displayNotification(NOTIFICATION_TYPE.ERROR, t('casualties.form.documents.uploadErrorNotif'));
            });
        AUX_COUNT++;
    };

    getDocument = async (imageId: string) => {
        const { id } = this.state;
        await axios.get(casualtyDocumentURL(id, imageId));
    };

    getUsersStatus = (): string => {
        const { t, user } = this.props;
        const { isUserOnline } = this.state;
        
        if (isUserOnline && user && (user.role === UserRoles.ADMIN || user.role === UserRoles.EXTERNAL_ADMIN)) {
            return t('casualties.form.labels.online');
        }
        if (!isUserOnline && user && (user.role === UserRoles.ADMIN || user.role === UserRoles.EXTERNAL_ADMIN)) {
            return t('casualties.form.labels.offline');
        }

        if (isUserOnline && user && user.role === UserRoles.MECHANIC) {
            return t('casualties.form.labels.fluxeUserOnline');
        }
        if (!isUserOnline && user && user.role === UserRoles.MECHANIC) {
            return t('casualties.form.labels.fluxeUserOffline');
        }

        return '';
    };

    prepare = async () => {
        const { onlineMechanicUsers, user, onlineFluxeUsers } = this.props;
        const { id, preparing } = this.state;
        if (!id || preparing) return;
        
        this.setState({ preparing: true });

        await axios.get(casualtyURL(id))
            .then(response => {
                const { data } = response;
                const casualtyResponse: CasualtyResponse = {
                    ...data,
                };

                const casualtyRequest: CasualtyRequest = {
                    ...data,
                    insuranceCompanyId: casualtyResponse.insuranceCompany ? casualtyResponse.insuranceCompany.id : null,
                    insuranceCompanyName: casualtyResponse.insuranceCompany ? casualtyResponse.insuranceCompany.name : null,
                    mechanicName: casualtyResponse.mechanic ? casualtyResponse.mechanic.name : null,
                    mechanicId: casualtyResponse.mechanic ? casualtyResponse.mechanic.id : null,
                };

                let isUserOnline = Boolean(casualtyRequest.mechanicId && onlineMechanicUsers.includes(casualtyRequest.mechanicId));
                if (user && user.role === UserRoles.MECHANIC) {
                    isUserOnline = Boolean(casualtyRequest.mechanicId && onlineFluxeUsers.length > 0);
                }

                this.setState({
                    preparing: false,
                    casualtyRequest: { ...casualtyRequest },
                    isUserOnline,
                });
            })
            .catch(() => {
                this.setState({ preparing: false });
            });
    };

    renderItemComponent = () => {
        const { permissions, user } = this.props;
        const {
            tabItemSelected,
            casualtyRequest,
            formErrors,
            documentCount,
            messagesCount,
            hasCreateResume,
            hasNewInformativeNote,
        } = this.state;

        let { viewingDetails } = this.state;
        let role = '';

        if (casualtyRequest.status === CasualtyStatus.DONE || casualtyRequest.status === CasualtyStatus.CANCELED) {
            viewingDetails = true;
        }

        if (user) role = user.role;

        switch (tabItemSelected) {
            case CasualtyFormTabsEnum.REQUEST:
                return <CasualtyRequestTab role={role} readOnly={viewingDetails} formErrors={formErrors} casualty={casualtyRequest} onCasualtyChange={this.onCasualtyChange} />;
            case CasualtyFormTabsEnum.BUDGET:
                return (
                    <CasualtyBudgetTab
                        casualty={casualtyRequest}
                        readOnly={viewingDetails && !checkPermission(permissions, [PermissionKey.CREATE_CASUALTY_BUDGET])}
                        hasCreateResume={hasCreateResume}
                        role={role}
                    />
                );
            case CasualtyFormTabsEnum.DOCUMENTS:
                return (
                    <CasualtyDocumentsTab
                        casualty={casualtyRequest}
                        onFileSelected={(formData: FormData, docsCount: number) => this.uploadDocument(formData, docsCount)}
                        count={documentCount}
                        hasNewInformativeNotes={hasNewInformativeNote}
                        readOnly={viewingDetails && !checkPermission(permissions, [PermissionKey.CREATE_CASUALTY_DOCUMENT])}
                    />
                );
            case CasualtyFormTabsEnum.MESSAGES:
                return <CasualtyMessagesTab casualty={casualtyRequest} count={messagesCount} />;
            default:
                return <React.Fragment />;
        }
    };

    renderVideo = () => {
        const {
            t, incomingCall, incomingCallMessage, user,
        } = this.props;
        const {
            isUserOnline, isOnVideoCall, isMakingCall, casualtyRequest, pendingCandidates,
        } = this.state;

        let showIncomingCall: boolean = incomingCall;

        if (user && user.role === UserRoles.INSURANCE_USER) return <React.Fragment />;

        if (incomingCallMessage) {
            const { messageMap } = incomingCallMessage;

            if (messageMap.casualtyId !== casualtyRequest.id) {
                showIncomingCall = false;
            }
        }

        if (showIncomingCall) {
            return (
                <div className="app-screen__form__left-container__call-container incoming-call">
                    <div className="call-info">
                        <h3>{t('global.calling')}</h3>
                        <p>{casualtyRequest.mechanicName}</p>
                        <p className="small">{t('global.referring')}<b>{t('global.process', { processNumber: casualtyRequest.uid })}</b></p>
                    </div>
                    <div className="buttons">
                        <Button
                            styles="btn--light-purple btn--no-opacity "
                            text={t('global.buttons.rejectCall')}
                            callback={this.onRejectCallClick}
                        />
                        <Button
                            styles="btn--green"
                            text={t('global.buttons.answerCall')}
                            callback={this.onAcceptCallClick}
                        />
                    </div>
                </div>
            );
        }

        if (isOnVideoCall && casualtyRequest.mechanicId && casualtyRequest.id) {
            return (
                <VideoComponent
                    userId={casualtyRequest.mechanicId || null}
                    isMakingCall={isMakingCall}
                    casualtyId={casualtyRequest.id}
                    casualtyUid={casualtyRequest.uid}
                    mechanicName={casualtyRequest.mechanicName}
                    onCallTerminate={this.onCallTerminate}
                    uploadFile={(formData: FormData, docsCount: number) => this.uploadDocument(formData, docsCount)}
                    onConfigError={this.onVideoConfigError}
                    onVideoSizeChange={this.onVideoSizeChanged}
                    pendingCandidates={pendingCandidates}
                />
            );
        }

        return (
            <div className="app-screen__form__left-container__call-container">
                <SvgIcon icon={ICON.VIDEO_CAM} />
                {isUserOnline && (
                    <Button
                        text={t('casualties.form.labels.videoCallBtn')}
                        styles="btn--green"
                        callback={this.onVideoCallClick}
                    />
                )}
            </div>
        );
    };

    render() {
        const { t, permissions, user } = this.props;
        const {
            preparing,
            casualtyRequest,
            tabItems,
            tabItemSelected,
            creatingNewMessage,
            viewingDetails,
            isUserOnline,
            isFetching,
            hasModifications,
            isVideoBig,
            creatingInformativeNote,
        } = this.state;

        let dotClassName = '';
        let role = '';
        switch (casualtyRequest.status) {
            case CasualtyStatus.ONGOING:
                dotClassName = 'green-circle';
                break;
            case CasualtyStatus.DONE:
                dotClassName = 'red-circle';
                break;
            case CasualtyStatus.CANCELED:
                dotClassName = 'black-circle';
                break;
            default:
        }

        if (user) role = user.role;

        let mechanicToRoute: any = null;
        if (casualtyRequest.mechanicId && checkPermission(permissions, [PermissionKey.ALL, PermissionKey.EDIT_USER])) {
            mechanicToRoute = `${USER_MECHANIC_EDIT_ROUTE}/${casualtyRequest.mechanicId}`;
        } else if (casualtyRequest.mechanicId && checkPermission(permissions, [PermissionKey.VIEW_MECHANIC_DETAILS])) {
            mechanicToRoute = `${USER_MECHANIC_DETAILS_ROUTE}/${casualtyRequest.mechanicId}`;
        }

        let insuranceToRoute: any = null;
        if (casualtyRequest.insuranceCompanyId && checkPermission(permissions, [PermissionKey.ALL, PermissionKey.EDIT_USER])) {
            insuranceToRoute = `${USER_INSURANCE_EDIT_ROUTE}/${casualtyRequest.insuranceCompanyId}`;
        } else if (casualtyRequest.insuranceCompanyId && checkPermission(permissions, [PermissionKey.VIEW_INSURANCE_DETAILS])) {
            insuranceToRoute = `${USER_INSURANCE_DETAILS_ROUTE}/${casualtyRequest.insuranceCompanyId}`;
        }

        let addButtonText = '';
        let showAddButton = true;
        let showFromMessages = false;
        let showSaveButton = false;
        let showUploadButton = false;
        if (tabItemSelected) {
            switch (tabItemSelected) {
                case CasualtyFormTabsEnum.REQUEST:
                    showSaveButton = !viewingDetails;
                    break;
                case CasualtyFormTabsEnum.BUDGET:
                    showAddButton = !viewingDetails || checkPermission(permissions, [PermissionKey.CREATE_CASUALTY_BUDGET]);
                    addButtonText = t('casualties.form.newBudgetBtn');
                    break;
                case CasualtyFormTabsEnum.DOCUMENTS:
                    showAddButton = !viewingDetails || checkPermission(permissions, [PermissionKey.CREATE_CASUALTY_DOCUMENT]);
                    showUploadButton = !viewingDetails || checkPermission(permissions, [PermissionKey.CREATE_CASUALTY_DOCUMENT]);
                    addButtonText = t('casualties.form.createInformativeNote');
                    break;
                case CasualtyFormTabsEnum.MESSAGES:
                    showAddButton = !viewingDetails || checkPermission(permissions, [PermissionKey.CREATE_CASUALTY_MESSAGE]);
                    showFromMessages = true;
                    addButtonText = t('casualties.form.newMessageBtn');
                    break;
                default:
            }
        }
        const showCarItem = casualtyRequest.vehicleBrand && casualtyRequest.vehicleLicensePlate && casualtyRequest.vehicleModel;
        const userStatusClass = isUserOnline ? 'status online' : 'status offline';

        if (casualtyRequest.status === CasualtyStatus.CANCELED) {
            showAddButton = false;
            showSaveButton = false;
        }

        return (
            <div>
                {(preparing || isFetching) && (
                    <div className="loader-wrapper">
                        <Loader />
                    </div>
                )}
                <div className={isVideoBig ? 'app-screen__form hidden-overflow' : 'app-screen__form'}>
                    <div className="app-screen__form__left-container">
                        <div className="app-screen__form__left-container__info-container">
                            <div className="info-content">
                                <SvgIcon icon={ICON.LEFT_ARROW} callback={this.onBackClick} />
                                <div className="info">
                                    {casualtyRequest.uid && <p>{t('casualties.form.labels.process')}</p>}
                                    <h4>{casualtyRequest.uid || t('casualties.form.newProcess')}</h4>
                                </div>
                            </div>
                            <div className={`status-dot ${dotClassName}`} />
                        </div>
                        {role !== UserRoles.INSURANCE_USER && role !== UserRoles.MECHANIC
                        && casualtyRequest.status === CasualtyStatus.ONGOING && (
                        <div className="app-screen__form__left-container__buttons-container">
                            <Button
                                text={t('global.buttons.conclude')}
                                callback={() => this.onConcludeReopenClick(true)}
                                styles="btn--dark-purple"
                            />
                            <Button
                                text={t('global.buttons.cancel')}
                                callback={this.onCancelClick}
                                styles="btn--purple"
                            />
                        </div>
                        )}
                        {role !== UserRoles.INSURANCE_USER && role !== UserRoles.MECHANIC
                        && casualtyRequest.status === CasualtyStatus.CANCELED && (
                            <div className="app-screen__form__left-container__buttons-container one-button">
                                <Button
                                    text={t('global.buttons.reopen')}
                                    callback={this.onRestartClick}
                                    styles="btn--dark-purple"
                                />
                            </div>
                        )}
                        {role !== UserRoles.INSURANCE_USER
                         && role !== UserRoles.MECHANIC
                         && casualtyRequest.status === CasualtyStatus.DONE && (
                         <div className="app-screen__form__left-container__buttons-container one-button">
                             <Button
                                 text={t('global.buttons.reopen')}
                                 callback={() => this.onConcludeReopenClick(false)}
                                 styles="btn--dark-purple"
                             />
                         </div>
                        )}
                        {showSaveButton && (
                            <div className="button-container">
                                <Button
                                    text={t('global.buttons.saveModifications')}
                                    styles="btn--purple"
                                    callback={this.onSaveClick}
                                    disabled={!hasModifications}
                                />
                            </div>
                        )}
                        {role !== UserRoles.MECHANIC && casualtyRequest.status !== CasualtyStatus.CANCELED && (
                        <div className="button-container">
                            <Button
                                text={t('global.buttons.reportCasualty')}
                                styles="btn--green btn--icon-center btn--bigger"
                                icon={ICON.PDF}
                                callback={() => this.onGeneratePdfClick()}
                            />
                        </div>
                        )}
                        <div className="app-screen__form__left-container__centered-info">
                            {(casualtyRequest.insuranceCompanyId && casualtyRequest.insuranceCompanyName) && (
                                <div className="info-item">
                                    <SvgIcon icon={ICON.ACCOUNT_BOX} />
                                    <p className="label">{t('casualties.form.labels.client')}</p>
                                    <Link to={insuranceToRoute}>
                                        <p>{casualtyRequest.insuranceCompanyName}</p>
                                    </Link>
                                </div>
                            )}
                            {showCarItem && (
                                <React.Fragment>
                                    <div className="info-item">
                                        <SvgIcon icon={ICON.CAR} />
                                        <p className="label">{t('casualties.form.labels.vehicleBrand')}</p>
                                        <p>{casualtyRequest.vehicleBrand}</p>
                                    </div>
                                    <div className="info-item smaller-margin">
                                        <p className="label">{t('casualties.form.labels.vehicleModel')}</p>
                                        <p>{casualtyRequest.vehicleModel}</p>
                                    </div>
                                    <div className="info-item smaller-margin">
                                        <p className="label">{t('casualties.form.labels.vehicleLicensePlate')}</p>
                                        <p>{casualtyRequest.vehicleLicensePlate}</p>
                                    </div>
                                </React.Fragment>
                            )}
                            {(casualtyRequest.insurancePolicyNumber && casualtyRequest.insurancePolicyNumber !== '') && (
                                <div className="info-item smaller-margin">
                                    <p className="label">{t('casualties.form.labels.policy')}</p>
                                    <p>{casualtyRequest.insurancePolicyNumber}</p>
                                </div>
                            )}
                            {casualtyRequest.mechanicName && (
                                <div className="info-item">
                                    <SvgIcon icon={ICON.BUILD} />
                                    <p className="label">{t('casualties.form.labels.mechanic')}</p>
                                    <Link to={mechanicToRoute}>
                                        <p>{casualtyRequest.mechanicName}</p>
                                    </Link>
                                    <p className={userStatusClass}>
                                        {this.getUsersStatus()}
                                    </p>
                                </div>
                            )}
                        </div>
                        {this.renderVideo()}
                    </div>
                    <div className="app-screen__form__form-container">
                        <Tabs
                            tabsItems={tabItems}
                            onTabChange={this.onTabChange}
                            fromMessages={showFromMessages}
                            addButtonText={showAddButton ? addButtonText : undefined}
                            addButtonClickHandler={showAddButton ? this.onAddClickHandler : undefined}
                            addButtonStyles={tabItemSelected === CasualtyFormTabsEnum.REQUEST ? 'btn--purple' : 'btn--green'}
                            documentUploadText={t('casualties.form.attachDocuments')}
                            documentUploadFileSelected={showUploadButton ? this.onFileSelected : undefined}
                            isAddButtonDisabled={!hasModifications && tabItemSelected === CasualtyFormTabsEnum.REQUEST}
                        />
                        <div className="app-screen__form__form-container form-content">
                            {!preparing && this.renderItemComponent()}
                        </div>
                    </div>
                </div>
                {creatingNewMessage && <MessageModal casualty={casualtyRequest} onNewMessageCreated={this.onMessageCreated} onModalClose={this.onMessageModalClose} />}
                {creatingInformativeNote && <InformativeNoteModal onModalClose={this.onNoteModalClose} casualty={casualtyRequest} onNewInformativeNoteCreated={this.onNewInformativeNoteCreated} />}
            </div>
        );
    }
}

export default withRouter(withVideoContext(withAuthorizationContext(withAuthenticationContext(withTranslationContext(CasualtyFormScreen)))));
