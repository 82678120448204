/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { Component } from 'react';
import axios from 'axios';
import { get } from 'lodash';
import FormTextField from './FormTextField';
import { TranslationContextInterface, withTranslationContext } from '../controllers/translation/TranslationContext';
import FormSelectField from './FormSelectField';
import Button from './Button';
import { ICON, SvgIcon } from './SvgIcon';
import { UserRoles } from '../../constants/authorization';
import { getFormErrors, IFormError, VALIDATIONS } from '../../utils/validation';
import { userCreateURL } from '../../services/users';
import { displayNotification, NOTIFICATION_TYPE } from '../../utils/notifs';
import logo from '../../assets/images/logo_bgwhite.svg';
import { SelectOption } from '../../constants/misc';
import { ERROR_CODE } from '../../utils/errors';

interface OwnProps extends TranslationContextInterface {
    onModalClose: Function;
    onCreateSuccess: Function;
}

interface OwnState {
    name: string;
    email: string;
    permissions: string;
    formErrors: any;
}

const initialState: OwnState = {
    name: '',
    email: '',
    permissions: '',
    formErrors: {},
};

const modalOutsideId = 'modal-outside';

class NewUserModal extends Component<OwnProps, OwnState> {
    state = initialState;

    componentDidMount(): void {
        document.addEventListener('mousedown', this.handleOutsideClick);
    }

    componentWillUnmount(): void {
        document.removeEventListener('mousedown', this.handleOutsideClick);
    }

    handleOutsideClick = (e: any) => {
        const { target } = e;
        const { id } = target;

        if (id === modalOutsideId) {
            this.onClose();
        }
    };

    onInputChange = (e: React.FormEvent<HTMLInputElement>) => {
        this.setState({
            ...this.state,
            [e.currentTarget.name]: e.currentTarget.value,
        });
    };

    onClose = () => {
        const { onModalClose } = this.props;
        this.setState({ ...initialState });
        onModalClose();
    };

    onSendInvitationClick = () => {
        if (this.validateFields()) {
            const fields = {
                email: String(this.state.email).trim(),
                name: String(this.state.name).trim(),
                avatar: null,
            };

            const formData = new FormData();
            formData.append('userRequest', new Blob([JSON.stringify(fields)], {
                type: 'application/json',
            }));

            this.createUserRequest(this.state.permissions, formData);
        }
    };

    createUserRequest = async (userRole: string, fields: {}) => {
        const { onCreateSuccess, t } = this.props;

        const config = {
            headers: {
                'content-type': 'multipart/form-data',
            },
        };

        await axios.post(userCreateURL(userRole), fields, config)
            .then(() => {
                onCreateSuccess();
            })
            .catch((e): void => {
                if (e && e.response && e.response.data && e.response.data.errors) {
                    const { errorCode } = e.response.data.errors[0];
                    return displayNotification(NOTIFICATION_TYPE.ERROR, t(`errors.${ERROR_CODE[errorCode]}`));
                }
                displayNotification(NOTIFICATION_TYPE.ERROR, t('createUser.errorNotif'));
            });
    };

    validateFields = (): boolean => {
        let errors: IFormError | null = getFormErrors(this.state, VALIDATIONS.CREATE_USER_FORM);

        if (errors && Object.keys(errors).length === 0) errors = null;

        this.setState({ formErrors: errors ? { fields: errors } : errors });
        return errors === null;
    };

    render() {
        const { t } = this.props;
        const {
            name, email, permissions, formErrors,
        } = this.state;
        const options: Array<SelectOption> = [];

        options.push({
            value: '',
            label: t('createUser.labels.defaultSelectOption'),
        });

        Object.keys(UserRoles).forEach(r => {
            options.push({
                value: r,
                label: t(`enums.userRolesUppercase.${r}`),
            });
        });

        return (
            <div id={modalOutsideId} className="app-screen__modal">
                <div className="app-screen__modal__container">
                    <div className="app-screen__modal__container__box">
                        <SvgIcon callback={this.onClose} icon={ICON.CROSS} />
                        <div className="app-screen__modal__container__box__content">
                            <div className="app-screen__modal__container__box__content__header">
                                <img src={logo} alt="logo" />
                                <p>{t('createUser.title')}</p>
                            </div>
                            <FormTextField
                                containerStyles="field-container"
                                label={t('createUser.labels.name')}
                                name="name"
                                value={name}
                                onChange={this.onInputChange}
                                errors={get(formErrors, 'fields.name', null)}
                            />
                            <FormTextField
                                containerStyles="field-container"
                                label={t('createUser.labels.email')}
                                name="email"
                                value={email}
                                onChange={this.onInputChange}
                                errors={get(formErrors, 'fields.email', null)}
                            />
                            <FormSelectField
                                containerStyles="field-container"
                                options={options}
                                name="permissions"
                                value={permissions}
                                onChange={this.onInputChange}
                                label={t('createUser.labels.permissions')}
                                errors={get(formErrors, 'fields.permissions', null)}
                            />
                            <Button
                                text={t('createUser.sendInvitationBtn')}
                                styles="btn--green"
                                callback={this.onSendInvitationClick}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withTranslationContext(NewUserModal);
