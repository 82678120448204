/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { Component, Fragment } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import axios from 'axios';
import { debounce } from 'lodash';

import { DEBOUNCED_REQUEST_DELAY_MS, MatchParams, SelectOption } from '../../../constants/misc';
import { TranslationContextInterface, withTranslationContext } from '../../controllers/translation/TranslationContext';
import withSearch, { WithSearchProps } from '../../hocs/withSearch';
import FormSelectField from '../../elements/FormSelectField';
import { statisticsURL, statisticsYearsURL } from '../../../services/statistics';
import {
    StatisticQueryParams,
    StatisticItem,
    PeriodType,
    ChartObjectInfo,
    ChartDataset, UserRoleParam,
} from '../../../constants/types';
import BarChart from './BarChart';
import { UsersContext, withUsersContext } from '../../controllers/users/UsersContext';
import FormSearchableSelectField from '../../elements/FormSearchableSelectField';

interface OwnProps extends RouteComponentProps<MatchParams>, TranslationContextInterface, WithSearchProps, UsersContext {}

interface OwnState {
    year: string | null;
    insuranceCompanyId: string;
    mechanicId: string;
    isFetching: boolean;
    avgCasualtyDuration: string | null;
    avgCasualtyPrice: string | null;
    avgPreInspectionCompleteDuration: string | null;
    avgPreInspectionGlassDuration: string | null;
    numCasualties: number;
    numPreInspectionComplete: number;
    numPreInspectionGlass: number;
    insuranceCompanySearchValue: string;
    mechanicSearchValue: string;
    casualtyChartInfo: ChartObjectInfo | null;
    preInspectionCompleteChartInfo: ChartObjectInfo | null;
    preInspectionGlassChartInfo: ChartObjectInfo | null;
    insuranceOptions: Array<SelectOption>;
    mechanicOptions: Array<SelectOption>;
    yearOptions: Array<SelectOption>;
}

const initialState: OwnState = {
    year: '',
    insuranceCompanyId: '',
    mechanicId: '',
    isFetching: false,
    avgCasualtyDuration: null,
    avgCasualtyPrice: null,
    avgPreInspectionCompleteDuration: null,
    avgPreInspectionGlassDuration: null,
    casualtyChartInfo: null,
    preInspectionCompleteChartInfo: null,
    preInspectionGlassChartInfo: null,
    numCasualties: 0,
    numPreInspectionComplete: 0,
    numPreInspectionGlass: 0,
    insuranceCompanySearchValue: '',
    mechanicSearchValue: '',
    insuranceOptions: [],
    mechanicOptions: [],
    yearOptions: [],
};

class StatisticsScreen extends Component<OwnProps, OwnState> {
    state = initialState;
    
    private readonly allKeyword: string = 'keywords.all';
    
    private readonly statisticsDone: string = 'statistics.done';

    private readonly statisticsNoData: string = 'statistics.noData';

    private readonly statisticsMediumTime: string = 'statistics.mediumTime';

    private readonly debouncedRequestInsuranceCompanies: Function;

    private readonly debouncedRequestMechanics: Function;

    constructor(props: OwnProps) {
        super(props);

        this.debouncedRequestInsuranceCompanies = debounce(this.requestInsuranceOptions, DEBOUNCED_REQUEST_DELAY_MS);
        this.debouncedRequestMechanics = debounce(this.requestMechanicsOptions, DEBOUNCED_REQUEST_DELAY_MS);

        this.prepare();
    }

    prepare = (): void => {
        const year = new Date().getFullYear();
        const { t } = this.props;

        const urls = [];

        urls.push(
            statisticsYearsURL(),
            statisticsURL({ year }),
        );

        axios.all(urls.map(url => axios.get(url))).then(axios.spread((yearsResponse, statisticsResponse) => {
            const yearOptions: Array<SelectOption> = [
                {
                    value: null,
                    label: t('statistics.allYears'),
                },
            ];
            if (yearsResponse && yearsResponse.data) {
                let { min, max } = yearsResponse.data;

                while (min <= max) {
                    yearOptions.push({
                        value: `${min}`,
                        label: `${min}`,
                    });
    
                    min++;
                }
            }

            if (statisticsResponse && statisticsResponse.data) {
                this.setDatasetsFromResponse(statisticsResponse.data, {
                    ...initialState,
                    yearOptions,
                    year: String(year),
                });

                this.requestMechanicsOptions('');
                this.requestInsuranceOptions('');
            }
        })).catch(() => {
            this.setState({
                ...initialState,
            });
        });
    }

    requestMechanicsOptions = async (searchValue: string): Promise<void> => {
        const { t, getUsersOptions } = this.props;

        const { options } = await getUsersOptions({ userRole: UserRoleParam.Mechanic, _limit: 10, _q: searchValue });

        const mechanicOptions: Array<SelectOption> = [...options];
        if (mechanicOptions[0]) {
            mechanicOptions[0] = {
                ...mechanicOptions[0],
                label: t(this.allKeyword),
            };
        }

        this.setState({
            mechanicOptions,
        });
    };

    requestInsuranceOptions = async (searchValue: string): Promise<void> => {
        const { t, getUsersOptions } = this.props;

        const { options } = await getUsersOptions({ userRole: UserRoleParam.InsuranceUser, _limit: 10, _q: searchValue });

        const insuranceOptions: Array<SelectOption> = [...options];
        if (insuranceOptions[0]) {
            insuranceOptions[0] = {
                ...insuranceOptions[0],
                label: t(this.allKeyword),
            };
        }

        this.setState({
            insuranceOptions,
        });
    };

    /**
     * set the dataset info for chart
     * @param {any} data
     * @param {object} stateObject
     */
    setDatasetsFromResponse = (data: any, stateObject: object) => {
        const {
            avgCasualtyDuration,
            avgCasualtyPrice,
            avgPreInspectionCompleteDuration,
            avgPreInspectionGlassDuration,
            casualtyStatistics,
            numCasualties,
            numPreInspectionComplete,
            numPreInspectionGlass,
            preInspectionCompleteStatistics,
            preInspectionGlassStatistics,
            periodType,
        } = data;

        const casualtyChartInfo = this.buildChartInfoObject(casualtyStatistics, periodType, true);
        const preInspectionCompleteChartInfo = this.buildChartInfoObject(preInspectionCompleteStatistics, periodType);
        const preInspectionGlassChartInfo = this.buildChartInfoObject(preInspectionGlassStatistics, periodType);

        this.setState({
            ...stateObject,
            avgCasualtyDuration,
            avgCasualtyPrice,
            avgPreInspectionCompleteDuration,
            avgPreInspectionGlassDuration,
            casualtyChartInfo,
            preInspectionCompleteChartInfo,
            preInspectionGlassChartInfo,
            numCasualties,
            numPreInspectionComplete,
            numPreInspectionGlass,
        });
    }

    /**
     * create an object to help render information on chart
     * @param {Array<StatisticItem>} casualtyStatistics
     * @param {string} periodType
     * @param {boolean} hasPrice
     * @returns {ChartObjectInfo | null}
     */
    buildChartInfoObject = (statistics: Array<StatisticItem>, periodType: string, hasPrice = false): ChartObjectInfo | null => {
        if (!statistics || statistics.length === 0) return null;

        const labels: Array<string> = [];
        const datasetDuration: Array<ChartDataset> = [];
        const labelsDuration: Array<string> = [];
        const datasetPrice: Array<ChartDataset> = [];
        const labelsPrice: Array<string> = [];
        const datasetQuantity: Array<ChartDataset> = [];
        const labelsQuantity: Array<string> = [];
        const { t } = this.props;

        Object.keys(statistics).forEach(key => {
            const statisticItem = statistics[Number(key)];
            const {
                periodValue,
                avgDuration,
                avgDurationNormalized,
                avgPrice,
                avgPriceNormalized,
                quantity,
                quantityNormalized,
            } = statisticItem;

            let label = '';

            if (periodType === PeriodType.MONTH) {
                label = t(`month.${periodValue}`);
            }
            
            if (periodType === PeriodType.YEAR) {
                label = `${periodValue}`;
            }
            
            const durationItem: ChartDataset = {
                label: t('statistics.avgDuration'),
                value: avgDurationNormalized,
            };

            const labelDuration = this.renderDurationLabel(String(avgDuration));

            const quantityItem: ChartDataset = {
                label: t('statistics.quantity'),
                value: quantityNormalized,
            };

            const labelQuant = quantity ? `${quantity}` : '0';

            if (hasPrice) {
                const priceItem: ChartDataset = {
                    label: t('statistics.avgPrice'),
                    value: avgPriceNormalized,
                };
                const labelPrice = avgPrice || '0';
                datasetPrice.push(priceItem);
                labelsPrice.push(labelPrice);
            }

            labels.push(label);
            datasetDuration.push(durationItem);
            labelsDuration.push(labelDuration);
            datasetQuantity.push(quantityItem);
            labelsQuantity.push(labelQuant);
        });

        return {
            labels,
            dataset1: datasetQuantity,
            labelsDataset1: labelsQuantity,
            dataset2: datasetPrice,
            labelsDataset2: labelsPrice,
            dataset3: datasetDuration,
            labelsDataset3: labelsDuration,
        };
    }

    loadList = () => {
        const {
            year,
            mechanicId,
            insuranceCompanyId,
            isFetching,
        } = this.state;

        const queryParams: StatisticQueryParams = {};

        if (isFetching) return;

        this.setState({
            isFetching: true,
        });

        if (year) queryParams.year = year;
        if (mechanicId) queryParams.mechanicId = mechanicId;
        if (insuranceCompanyId) queryParams.insuranceCompanyId = insuranceCompanyId;

        axios.get(statisticsURL(queryParams)).then(response => {
            this.setDatasetsFromResponse(response.data, {
                isFetching: false,
            });
        }).catch(() => {
            this.setState({
                isFetching: false,
            });
        });
    }

    /**
     * handle the change of filters
     */
    onInputChange = (e: React.FormEvent<HTMLSelectElement>) => {
        const { t } = this.props;
        const { value } = e.currentTarget;
        let auxValue = value as string | null;

        if (auxValue === t('statistics.allYears')) {
            auxValue = null;
        }

        this.setState({
            ...this.state,
            [e.currentTarget.name]: auxValue,
        }, () => { this.loadList(); });
    };

    onSearchInputChange = (name: string, newValue: string): void => {
        if (name === 'insuranceCompanySearchValue') {
            this.debouncedRequestInsuranceCompanies(newValue);
        }

        if (name === 'mechanicSearchValue') {
            this.debouncedRequestMechanics(newValue);
        }

        this.setState(prevState => ({
            ...prevState,
            [name]: newValue,
        }));
    };

    onSelectChange = (name: string, optValue: string) => {
        const { state } = this;

        this.setState({
            ...state,
            [name]: optValue,
        }, this.loadList);
    };

    /**
     * render the duration for chart label in <dd> d <hh.xx> h or <hh.xx> h
     * @param {string | null} durationInMillis
     * @returns {string}
     */
    renderDurationLabel = (durationInMillis: string | null): string => {
        if (!durationInMillis) return '0h';

        const totalMinutes = Number(durationInMillis) / (1000 * 60);

        if (totalMinutes < 60) {
            return `${Math.round(totalMinutes)}min`;
        }

        const totalHours = Number(durationInMillis) / (1000 * 60 * 60);

        if (totalHours < 73) {
            return `${totalHours.toFixed(2)}h`;
        }

        const totalDays = Math.ceil(Number(durationInMillis) / (1000 * 60 * 60 * 24));

        return `${totalDays}d`;
    }

    /**
     * render the duration on format <dd>d <hh>h <mm>m
     * @param {string | null} durationInMillis
     * @returns {string}
     */
    renderDuration = (durationInMillis: string | null): string => {
        if (!durationInMillis) return '0h';

        const totalDays = Number(durationInMillis) / (1000 * 60 * 60 * 24);
        let roundDays = Math.floor(totalDays);
        const totalHours = (totalDays - roundDays) * 60;
        let roundHours = Math.floor(totalHours);
        while (roundHours > 24) {
            roundHours -= 24;
            roundDays++;
        }
        const roundMinutes = Math.round((totalHours - Math.floor(totalHours)) * 60);

        const daysString = roundDays > 0 ? `${roundDays}d` : '';
        const hourString = roundHours > 9 ? `${roundHours}h` : `0${roundHours}h`;
        const minString = roundMinutes > 9 ? `${roundMinutes}m` : `0${roundMinutes}m`;

        return `${daysString} 
        ${hourString} 
        ${minString}`;
    }

    render() {
        const {
            year,
            mechanicId,
            insuranceCompanyId,
            avgCasualtyDuration,
            avgCasualtyPrice,
            avgPreInspectionCompleteDuration,
            avgPreInspectionGlassDuration,
            casualtyChartInfo,
            numCasualties,
            numPreInspectionComplete,
            numPreInspectionGlass,
            preInspectionCompleteChartInfo,
            preInspectionGlassChartInfo,
            insuranceOptions,
            mechanicOptions,
            yearOptions,
            insuranceCompanySearchValue,
            mechanicSearchValue,
        } = this.state;

        const { t } = this.props;

        return (
            <div className="statistics" data-testid="satistics-screen-test">
                <div className="statistics__header">
                    <div className="statistics__header__filter">
                        <FormSelectField
                            options={yearOptions}
                            name="year"
                            value={year}
                            onChange={this.onInputChange}
                            label={t('statistics.filters.year')}
                        />
                    </div>
                    <div className="statistics__header__filter">
                        <FormSearchableSelectField
                            name="insuranceCompanyId"
                            inputName="insuranceCompanySearchValue"
                            value={insuranceCompanyId}
                            searchValue={insuranceCompanySearchValue}
                            onChange={this.onSelectChange}
                            onSearchInputChange={this.onSearchInputChange}
                            options={insuranceOptions}
                            label={t('statistics.filters.insurance')}
                        />
                    </div>
                    <div className="statistics__header__filter">
                        <FormSearchableSelectField
                            name="mechanicId"
                            inputName="mechanicSearchValue"
                            value={mechanicId}
                            searchValue={mechanicSearchValue}
                            onChange={this.onSelectChange}
                            onSearchInputChange={this.onSearchInputChange}
                            options={mechanicOptions}
                            label={t('statistics.filters.mechanic')}
                        />
                    </div>
                </div>
                <div className="statistics__container">
                    <div className="statistics__container__info">
                        <div className="statistics__container__info__header">
                            <div className="statistics__container__info__header__title text text-dark text-big">
                                {t('statistics.casualties')}
                            </div>
                            <div className="statistics__container__info__header__body">
                                <div className="text text-column">
                                    <div className="text-purple text-big">{numCasualties}</div>
                                    <div className="text-dark text-small">{t(this.statisticsDone)}</div>
                                </div>
                                <div className="text text-column">
                                    <div className="text-blue text-big">{avgCasualtyPrice || 0}{t('statistics.byProcess')}</div>
                                    <div className="text-dark text-small">{t('statistics.mediumValue')}</div>
                                </div>
                                <div className="text text-column">
                                    <div className="text-grey text-big">{this.renderDuration(avgCasualtyDuration)}</div>
                                    <div className="text-dark text-small">{t(this.statisticsMediumTime)}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="statistics__container__chart">
                        {casualtyChartInfo && numCasualties > 0 ? (
                            <BarChart
                                data={casualtyChartInfo}
                                hasPriceDataset
                            />
                        ) : (
                            t(this.statisticsNoData)
                        )}
                    </div>
                </div>
                {mechanicId === null && (
                    <Fragment>
                        <div className="statistics__container">
                            <div className="statistics__container__info">
                                <div className="statistics__container__info__header">
                                    <div className="statistics__container__info__header__title text text-dark text-big">
                                        {t('statistics.preInspectionsComplete')}
                                    </div>
                                    <div className="statistics__container__info__header__body">
                                        <div className="text text-column">
                                            <div className="text-purple text-big">{numPreInspectionComplete}</div>
                                            <div className="text-dark text-small">{t(this.statisticsDone)}</div>
                                        </div>
                                        <div className="text text-column">
                                            <div className="text-dark text-big">{this.renderDuration(avgPreInspectionCompleteDuration)}</div>
                                            <div className="text-dark text-small">{t(this.statisticsMediumTime)}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="statistics__container__chart">
                                {preInspectionCompleteChartInfo && numPreInspectionComplete ? (
                                    <BarChart
                                        data={preInspectionCompleteChartInfo}
                                        hasPriceDataset={false}
                                    />
                                ) : (
                                    t(this.statisticsNoData)
                                )}
                            </div>
                        </div>
                        <div className="statistics__container">
                            <div className="statistics__container__info">
                                <div className="statistics__container__info__header">
                                    <div className="statistics__container__info__header__title text text-dark text-big">
                                        {t('statistics.preInspectionsGlass')}
                                    </div>
                                    <div className="statistics__container__info__header__body">
                                        <div className="text text-column">
                                            <div className="text-purple text-big">{numPreInspectionGlass}</div>
                                            <div className="text-dark text-small">{t(this.statisticsDone)}</div>
                                        </div>
                                        <div className="text text-column">
                                            <div className="text-dark text-big">{this.renderDuration(avgPreInspectionGlassDuration)}</div>
                                            <div className="text-dark text-small">{t(this.statisticsMediumTime)}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="statistics__container__chart">
                                {preInspectionGlassChartInfo && numPreInspectionGlass > 0 ? (
                                    <BarChart
                                        data={preInspectionGlassChartInfo}
                                        hasPriceDataset={false}
                                    />
                                ) : (
                                    t(this.statisticsNoData)
                                )}
                            </div>
                        </div>
                    </Fragment>
                )}
                
            </div>
        );
    }
}

export default withSearch(withTranslationContext(withUsersContext(StatisticsScreen)));
