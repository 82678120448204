/**
 *
 * @Copyright 2022 VOID SOFTWARE, S.A.
 *
 */

import axios from 'axios';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    ApiFile,
    BrokenGlassControlDocuments,
    BrokenGlassControlResponse,
    BrokenGlassControlSearchResponse,
    ListResponse,
} from '../../../constants/types';
import {
    brokenGlassControlDocumentsURL,
    brokenGlassControlResumeURL,
    brokenGlassControlSearchURL,
    brokenGlassControlURL,
    brokenGlassControlDocumentURL,
    brokenGlassControlImportFiles,
} from '../../../services/brokenGlassControl';
import { BrokenGlassControlContextProvider } from './BrokenGlassControlContext';
import { KeyedObject } from '../../../constants/misc';

interface OwnProps {
    children: React.ReactNode;
}

export class BrokenGlassControlController extends Component<OwnProps> {
    getBrokenGlassControls = async (requestBody: object = {}): Promise<ListResponse<BrokenGlassControlSearchResponse>> => {
        try {
            const { data, headers } = await axios.post(brokenGlassControlSearchURL(), requestBody);
            const brokenGlassControls: Array<BrokenGlassControlSearchResponse> = (data as Array<BrokenGlassControlSearchResponse>).map(control => ({
                ...control,
            }));

            const totalResults: number = parseInt(headers['x-total-count']);
            
            return {
                results: brokenGlassControls,
                total: totalResults,
            };
        } catch {
            return {
                results: [],
                total: 0,
            };
        }
    }

    getBrokenGlassControlDocuments = async (id: number): Promise<BrokenGlassControlDocuments | null> => {
        try {
            const { data } = await axios.get(brokenGlassControlDocumentsURL(id));
            return data;
        } catch {
            return null;
        }
    }

    getBrokenGlassDocument = async (brokenGlassId: number, fileId: number | null): Promise<ApiFile | null> => {
        if (fileId === null) return null;

        try {
            const { data } = await axios.get(brokenGlassControlDocumentURL(brokenGlassId, fileId), { responseType: 'blob' });
            const blob = new Blob([data], { type: data.type });
            return { fileString: URL.createObjectURL(blob), id: fileId };
        } catch {
            return null;
        }
    }

    createBrokenGlassControls = async (onSuccess: () => void, onFailure: () => void, requestBody: object = {}): Promise<BrokenGlassControlResponse | null> => {
        try {
            const { data } = await axios.post(brokenGlassControlURL(), requestBody);
            const brokenGlassControl: BrokenGlassControlResponse = data;

            onSuccess();
            return brokenGlassControl;
        } catch {
            onFailure();
            return null;
        }
    }

    editBrokenGlassControl = async (onSuccess: (brokenGlassControl: BrokenGlassControlResponse) => void, onFailure: () => void, id: string | number, requestBody: KeyedObject): Promise<void> => {
        try {
            const { data } = await axios.put(brokenGlassControlURL(id), requestBody);
            const brokenGlassControl: BrokenGlassControlResponse = data;
            onSuccess(brokenGlassControl);
        } catch {
            onFailure();
        }
    }

    getBrokenGlassControlById = async (id: string | number): Promise<BrokenGlassControlResponse | null> => {
        try {
            const { data } = await axios.get(brokenGlassControlURL(id));
            const brokenGlassControl: BrokenGlassControlResponse = data;
            return brokenGlassControl;
        } catch {
            return null;
        }
    }

    resumeControlQV = async (id: number): Promise<string | null> => {
        try {
            const { data } = await axios.post(brokenGlassControlResumeURL(id), null, { responseType: 'blob' });
            return URL.createObjectURL(data);
        } catch {
            return null;
        }
    }

    reportControlQV = async (id: number): Promise<any | null> => {
        try {
            const { data, headers } = await axios.post(brokenGlassControlResumeURL(id), null, { responseType: 'blob' });
            return { data, headers };
        } catch {
            return null;
        }
    }

    importFile = async (fields: {}, onSuccess: () => void, onFailure: (errorCode: number) => void): Promise<void> => {
        try {
            const config = {
                headers: {
                    'content-type': 'multipart/form-data',
                },
            };

            await axios.post(brokenGlassControlImportFiles(), fields, config);
            onSuccess();
        } catch (err) {
            if (err.response.data) return onFailure(err.response.data.errors[0].errorCode);
            onFailure(err.response.status);
        }
    }

    render(): React.ReactNode {
        const {
            children,
        } = this.props;
        return (
            <BrokenGlassControlContextProvider
                value={{
                    getBrokenGlassControls: this.getBrokenGlassControls,
                    createBrokenGlassControls: this.createBrokenGlassControls,
                    getBrokenGlassControlById: this.getBrokenGlassControlById,
                    editBrokenGlassControl: this.editBrokenGlassControl,
                    resumeControlQV: this.resumeControlQV,
                    getBrokenGlassControlDocuments: this.getBrokenGlassControlDocuments,
                    getBrokenGlassDocument: this.getBrokenGlassDocument,
                    reportControlQV: this.reportControlQV,
                    importFile: this.importFile,
                }}
            >
                {children}
            </BrokenGlassControlContextProvider>
        );
    }
}

export const ConnectedBrokenGlassControlController = connect()(BrokenGlassControlController);
