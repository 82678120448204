/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { Component } from 'react';
import { ICON, SvgIcon } from './SvgIcon';

interface OwnProps {
    onModalClose: Function;
    containerStyle?: string;
    boxContentStyle?: string;
    boxStyle?: string;
}

const modalOutsideId: string = 'modal-outside';

class GeneralModal extends Component<OwnProps> {
    componentDidMount(): void {
        document.addEventListener('mousedown', this.handleOutsideClick);
    }

    componentWillUnmount(): void {
        document.removeEventListener('mousedown', this.handleOutsideClick);
    }

    handleOutsideClick = (e: any) => {
        const { target } = e;
        const { id } = target;

        if (id === modalOutsideId) {
            this.onClose();
        }
    };

    onClose = () => {
        const { onModalClose } = this.props;
        onModalClose();
    };

    render() {
        const { containerStyle, boxContentStyle, boxStyle } = this.props;

        const containerClass: string = containerStyle || '';
        const boxContentClass: string = boxContentStyle || '';
        const boxClass: string = boxStyle || '';

        return (
            <div id={modalOutsideId} className="app-screen__modal">
                <div className={`app-screen__modal__container general-modal ${containerClass}`}>
                    <div className={`app-screen__modal__container__box ${boxClass}`}>
                        <SvgIcon callback={this.onClose} icon={ICON.CROSS} />
                        <div className={`app-screen__modal__container__box__content ${boxContentClass}`}>
                            {this.props.children}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default GeneralModal;
