/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import { createStore, compose, applyMiddleware } from 'redux';
import ReduxThunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import { persistStore, persistCombineReducers } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import general from './reducers/general';
import authentication from './reducers/authentication';
import authorization from './reducers/authorization';
import preferences from './reducers/preferences';
import video from './reducers/video';
import message from './reducers/messages';

const isDevVersion: boolean = process.env.NODE_ENV === 'development';
const composeEnhancers = composeWithDevTools || compose;
const middlewares: any = [ReduxThunk];
const volatileReducers: any = [
    'general',
    'preferences',
    'video',
    'message',
];

const config = {
    key: 'root',
    storage,
    debug: isDevVersion,
    blacklist: volatileReducers,
};

const reducers = persistCombineReducers(config, {
    general,
    preferences,
    authentication,
    authorization,
    video,
    message,
});

export const configureStore = () => {
    const store = createStore(
        reducers,
        composeEnhancers(applyMiddleware(...middlewares)),
    );

    const persistor = persistStore(store, null);

    return { persistor, store };
};
