/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { Component } from 'react';
import axios from 'axios';
import { get } from 'lodash';
import FileSaver from 'file-saver';
import { TranslationContextInterface, withTranslationContext } from '../controllers/translation/TranslationContext';
import { ICON, SvgIcon } from './SvgIcon';
import Button from './Button';
import Loader from './Loader';
import { casualtyDocumentsURL, casualtyDocumentURL } from '../../services/casualties';
import { CasualtyRequest } from '../../constants/types';
import TableHeader from './TableHeader';
import TableCell, { TableCellActions, TableCellType } from './TableCell';
import TablePaging from './TablePaging';
import withPaging, { WithPagingProps } from '../hocs/withPaging';
import withSort, { WithSortProps } from '../hocs/withSort';
import ImageShowModal from './ImageShowModal';
import displayConfirm from './displayConfirm';
import { displayNotification, NOTIFICATION_TYPE } from '../../utils/notifs';
import GeneralModal from './GeneralModal';
import FormTextField from './FormTextField';
import { getFormErrors, IFormError, VALIDATIONS } from '../../utils/validation';

import documentIcon from '../../assets/images/document.svg';
import InformativeNoteModal from './InformativeNoteModal';

interface OwnProps extends TranslationContextInterface, WithPagingProps, WithSortProps {
    casualty: CasualtyRequest;
    onFileSelected: Function;
    count: number;
    readOnly: boolean;
    hasNewInformativeNotes?: boolean;
}

interface OwnState {
    preparing: boolean;
    isFetching: boolean;
    hasDocuments: boolean;
    documents: [];
    _limit: number;
    totalResults: number;
    actions: TableCellActions[];
    showingModal: boolean;
    imgToShow: string | null;
    onlyImages: boolean | null;
    nameEditable: boolean;
    idDocumentEditing: number | null;
    editingFileName: boolean;
    formErrors: any;
    fileName: string;
    creatingInformativeNote: boolean;
    hasData: boolean;
    documentsView: string;
    showActionsMenu: Array<boolean>;
}

const initialState: OwnState = {
    preparing: false,
    isFetching: false,
    hasDocuments: false,
    documents: [],
    _limit: 12,
    totalResults: 0,
    actions: [TableCellActions.DELETE, TableCellActions.EDIT, TableCellActions.DOWNLOAD],
    showingModal: false,
    imgToShow: null,
    onlyImages: null,
    nameEditable: false,
    idDocumentEditing: null,
    editingFileName: false,
    formErrors: {},
    fileName: '',
    creatingInformativeNote: false,
    hasData: false,
    documentsView: 'LIST',
    showActionsMenu: [],
};

/**
 * Constant values for styles reuse
 */
const classFilterItemSelected = 'filter-item selected';
const classFilterItem = 'filter-item';

class CasualtyDocumentsTab extends Component<OwnProps, OwnState> {
    state = initialState;

    componentDidMount(): void {
        const { casualty, readOnly } = this.props;

        if (casualty && casualty.id) {
            if (readOnly) {
                this.setState({
                    actions: [TableCellActions.DOWNLOAD],
                }, () => this.loadList());
            } else {
                this.setState({
                    actions: [TableCellActions.DELETE, TableCellActions.EDIT, TableCellActions.DOWNLOAD],
                }, () => this.loadList());
            }
        }
    }

    componentDidUpdate(prevProps: Readonly<OwnProps>, prevState: Readonly<OwnState>, snapshot?: any): void {
        const {
            currentPage, sortValue, sortOrder, count: prevCount, hasNewInformativeNotes,
        } = this.props;
        const {
            currentPage: oldCurrentPage, sortOrder: oldSortOrder, sortValue: oldSortValue, count, hasNewInformativeNotes: oldHasNewInformativeNotes,
        } = prevProps;

        const hasPagingChanged: boolean = currentPage !== oldCurrentPage;
        const hasCountChanged: boolean = count !== prevCount;
        const hasSortValueChanged: boolean = sortValue !== oldSortValue;
        const hasSortOrderChanged: boolean = sortOrder !== oldSortOrder;
        const hasNewInformativeNotesChanged: boolean = hasNewInformativeNotes !== oldHasNewInformativeNotes;

        if (hasCountChanged || hasPagingChanged || hasSortOrderChanged || hasSortValueChanged || hasNewInformativeNotesChanged) {
            this.loadList();
        }
    }

    onNoteModalClose = () => {
        this.setState({
            creatingInformativeNote: false,
        });
    };

    onNewInformativeNoteCreated = () => {
        this.setState({
            creatingInformativeNote: false,
        }, () => this.loadList());
    };

    onDocumentClick = async (e: any, file: any, isImage: boolean) => {
        if (!isImage) return;

        this.setState({ showingModal: true, imgToShow: file.url });
    };

    onDelete = (e: React.MouseEvent<HTMLButtonElement>, id: number, fileName: string) => {
        e.stopPropagation();
        const { t } = this.props;

        displayConfirm({
            acceptButtonText: t('global.buttons.accept'),
            onAccept: () => this.deleteDocRequest(id),
            onReject: () => {},
            rejectButtonText: t('global.buttons.reject'),
            containerStyle: 'remove-document',
            title: t('casualties.form.documents.deleteConfirmationMsg', { documentName: fileName }),
        });
    };

    onEdit = (e: React.MouseEvent<HTMLButtonElement>, id: number, oldFileName: string) => {
        e.stopPropagation();

        this.setState({ editingFileName: true, idDocumentEditing: id, fileName: oldFileName });
    };

    onInputChange = (e: React.FormEvent<HTMLInputElement>) => {
        this.setState({
            ...this.state,
            [e.currentTarget.name]: e.currentTarget.value,
        });
    };

    onNameEditAccept = () => {
        const { fileName } = this.state;

        if (this.validateFileName()) {
            this.editDocumentFileNameRequest(String(fileName).trim());
        }
    };

    onNameEditModalClose = () => {
        this.setState({ editingFileName: false, idDocumentEditing: null });
    };

    onFileDownload = async (e: React.MouseEvent<HTMLButtonElement>, file: any) => {
        e.stopPropagation();
        const { casualty } = this.props;

        axios.get(casualtyDocumentURL(String(casualty.id), file.id), {
            responseType: 'blob',
            headers: {
                Accept: 'application/octet-stream',
            },
        }).then(response => {
            if (response && response.data) {
                const blob = new Blob([response.data]);
                FileSaver.saveAs(blob, file.originalFileName);
            }
        });
    };

    onImageModalClose = () => {
        this.setState({ showingModal: false });
    };

    onFilterChange = (onlyImages: boolean | null) => {
        this.setState({ onlyImages }, () => this.loadList());
    };

    editDocumentFileNameRequest = async (newFileName: string) => {
        const { t, casualty } = this.props;
        const { idDocumentEditing } = this.state;

        this.setState({ isFetching: true });

        const config = {
            headers: {
                'Content-Type': 'application/json',
            },
        };

        await axios.put(casualtyDocumentURL(String(casualty.id), String(idDocumentEditing)), newFileName, config)
            .then(response => {
                displayNotification(NOTIFICATION_TYPE.SUCCESS, t(t('casualties.form.documents.editSuccessNotif')));
                this.setState({
                    isFetching: false,
                    editingFileName: false,
                    idDocumentEditing: null,
                }, () => {
                    this.getDocument(String(response.data.id));
                    this.loadList();
                });
            })
            .catch(() => {
                this.setState({ isFetching: false });
            });
    };

    validateFileName = (): boolean => {
        let errors: IFormError | null = getFormErrors(this.state, VALIDATIONS.DOCUMENT_EDIT);

        if (errors && Object.keys(errors).length === 0) errors = null;

        this.setState({ formErrors: errors ? { fields: errors } : errors });
        return errors === null;
    };

    deleteDocRequest = async (id: number) => {
        const { t, casualty } = this.props;

        this.setState({ isFetching: true });

        await axios.delete(casualtyDocumentURL(String(casualty.id), String(id)))
            .then(() => {
                displayNotification(NOTIFICATION_TYPE.SUCCESS, t('casualties.form.documents.deleteSuccessNotif'));

                this.setState({ isFetching: false }, () => this.loadList());
            })
            .catch(() => {
                displayNotification(NOTIFICATION_TYPE.ERROR, t('casualties.form.documents.deleteErrorNotif'));
                this.setState({ isFetching: false });
            });
    };

    loadList = async () => {
        const {
            sortValue,
            sortOrder,
            currentPage,
            casualty,
        } = this.props;
        const { isFetching, _limit, onlyImages } = this.state;

        if (isFetching) return;

        this.setState({ isFetching: true });

        try {
            let sort: string = sortValue;
            let order: string = sortOrder;

            if (!sort) {
                sort = 'createdDate';
                order = 'desc';
            }

            const { data, headers } = await axios.get(casualtyDocumentsURL(String(casualty.id), {
                _page: currentPage - 1,
                _limit,
                _sort: sort,
                _order: order,
                onlyImages,
            }));

            const newTotalResults: number = parseInt(headers['x-total-count']);
            const objectState: Array<boolean> = [];

            Object.keys(data).forEach(key => {
                const doc = data[key];
                this.getDocument(String(doc.id));
                objectState[doc.id] = false;
            });

            let hasData = true;
            if (onlyImages === null && data && data.length === 0) {
                hasData = false;
            }

            this.setState({
                documents: data,
                totalResults: newTotalResults,
                isFetching: false,
                hasDocuments: data && data.length > 0,
                showActionsMenu: objectState,
                hasData,
            });
        } catch (error) {
            this.setState({
                isFetching: false,
            });
        }
    };

    getDocument = async (docId: string) => {
        const { casualty } = this.props;
        try {
            await axios.get(casualtyDocumentURL(String(casualty.id), docId));
        } catch (e) {
            // do something
        }
    };

    onTypeViewClick = (view: string) => {
        this.setState({
            documentsView: view,
        });
    };

    openCloseActions = (id: number) => {
        const { showActionsMenu } = this.state;

        Object.keys(showActionsMenu).forEach(key => {
            if (Number(key) !== id) {
                showActionsMenu[Number(key)] = false;
            }
        });

        showActionsMenu[id] = !showActionsMenu[id];
        this.setState({ showActionsMenu });
    }

    renderTableList = () => {
        const {
            hasDocuments,
            documents,
            _limit,
            totalResults,
            actions,
            preparing,
        } = this.state;
        const {
            t,
            sortOrder,
            sortValue,
            onSortChange,
            onPagingChange,
            currentPage,
        } = this.props;

        return (
            <div>
                {preparing && (
                    <div className="loader-wrapper">
                        <Loader />
                    </div>
                )}
                <div className="app-screen__table documents-table" data-testid="casualty-document-data-test">
                    <div className="app-screen__table__content">
                        <div className="container-wrapper">
                            { this.renderFilterContainer() }
                        </div>
                        {hasDocuments ? (
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th>
                                            <TableHeader
                                                text={t('casualties.form.documents.type')}
                                                sort={sortValue}
                                                order={sortOrder}
                                                field="type"
                                                sortable={false}
                                                onSortChange={onSortChange}
                                            />
                                        </th>
                                        <th>
                                            <TableHeader
                                                text={t('casualties.form.documents.name')}
                                                sort={sortValue}
                                                order={sortOrder}
                                                field="originalFileName"
                                                sortable
                                                onSortChange={onSortChange}
                                            />
                                        </th>
                                        <th>
                                            <TableHeader
                                                text={t('casualties.form.documents.date')}
                                                sort={sortValue}
                                                order={sortOrder}
                                                field="createdDate"
                                                sortable
                                                onSortChange={onSortChange}
                                            />
                                        </th>
                                        <th>
                                            <TableHeader
                                                text=""
                                                sortable={false}
                                            />
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    { documents.map(doc => {
                                        const {
                                            id, originalFileName, createdDate, mimeType,
                                        } = doc;

                                        const isImage: boolean = String(mimeType).startsWith('image');

                                        return (
                                            <tr key={id} onClick={e => this.onDocumentClick(e, doc, isImage)}>
                                                <TableCell
                                                    type={TableCellType.ICON}
                                                    icon={isImage ? ICON.CAMERA : ICON.PASTE}
                                                    cellClass="icon-cell"
                                                />
                                                <TableCell
                                                    value={originalFileName}
                                                    cellClass="document-name-cell"
                                                />
                                                <TableCell
                                                    type={TableCellType.DATETIME}
                                                    cellClass="bold-text date-cell"
                                                    value={createdDate}
                                                />
                                                <TableCell
                                                    cellClass="actions-cell"
                                                    type={TableCellType.ACTIONS}
                                                    actions={actions}
                                                    onEdit={e => this.onEdit(e, id, originalFileName)}
                                                    onDelete={e => this.onDelete(e, id, originalFileName)}
                                                    onDownload={e => this.onFileDownload(e, doc)}
                                                />
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        ) : (
                            <div className="app-screen__form__form-container__no-content grid">
                                {t('global.noData')}
                            </div>
                        )}
                    </div>
                    {hasDocuments && (
                        <TablePaging
                            currentPage={currentPage}
                            limit={_limit}
                            totalResults={totalResults}
                            onStartChange={onPagingChange}
                        />
                    )}
                </div>
            </div>
        );
    };

    renderGrid = () => {
        const { t, currentPage, onPagingChange } = this.props;
        const {
            documents, _limit, totalResults, preparing, hasDocuments, showActionsMenu,
        } = this.state;

        return (
            <div>
                {preparing && (
                    <div className="loader-wrapper">
                        <Loader />
                    </div>
                )}
                <div className="documents-grid-container">
                    <div className="container-wrapper">
                        { this.renderFilterContainer() }
                    </div>
                    {hasDocuments ? (
                        <React.Fragment>
                            <div className="row">
                                {Object.keys(documents).map(key => {
                                    const doc = documents[Number(key)];
                                    const {
                                        id, originalFileName, mimeType, url,
                                    } = doc;

                                    const type = String(mimeType).split('/')[1];
                                    const isImage: boolean = String(mimeType).startsWith('image');
                                    let docUrl: string = url;
                                    if (!isImage) {
                                        docUrl = documentIcon;
                                    }

                                    return (
                                        <div key={id} className="col-sm-3 document-container">
                                            <div className="image-container">
                                                <img src={docUrl} alt={originalFileName} onClick={e => this.onDocumentClick(e, doc, isImage)} />
                                                <div className="menu">
                                                    <Button
                                                        key={`menu_${id}`}
                                                        callback={() => this.openCloseActions(id)}
                                                    />
                                                </div>
                                                {showActionsMenu[id] && (
                                                    <div className="menu-content">
                                                        <Button
                                                            text={t('table.actions.edit')}
                                                            key={`edit_${id}`}
                                                            callback={e => this.onEdit(e, id, originalFileName)}
                                                        />
                                                        <Button
                                                            text={t('table.actions.delete')}
                                                            key={`delete_${id}`}
                                                            callback={e => this.onDelete(e, id, originalFileName)}
                                                        />
                                                        <Button
                                                            text={t('table.actions.download')}
                                                            key={`download_${id}`}
                                                            callback={e => this.onFileDownload(e, doc)}
                                                        />
                                                    </div>
                                                )}
                                            </div>
                                            <p className="name">{originalFileName}</p>
                                            <p className="description">{t('casualties.form.documents.fileDescription', { fileType: type })}</p>
                                        </div>
                                    );
                                })}
                            </div>
                            <TablePaging
                                currentPage={currentPage}
                                limit={_limit}
                                totalResults={totalResults}
                                onStartChange={onPagingChange}
                            />
                        </React.Fragment>
                    ) : (
                        <div className="app-screen__form__form-container__no-content grid">
                            {t('global.noData')}
                        </div>
                    )}
                </div>
            </div>
        );
    };

    renderContent = () => {
        const { documentsView } = this.state;

        if (documentsView === 'LIST') {
            return this.renderTableList();
        }
        return this.renderGrid();
    };

    renderFilterContainer = () => {
        const { t } = this.props;
        const { onlyImages } = this.state;

        return (
            <React.Fragment>
                <div className="filters-container">
                    <div
                        onClick={() => this.onFilterChange(null)}
                    >
                        <SvgIcon icon={ICON.PASTE} />
                        {t('casualties.form.documents.seeAll')}
                    </div>
                    <div
                        className={onlyImages === true ? classFilterItemSelected : classFilterItem}
                        onClick={() => this.onFilterChange(true)}
                    >
                        <SvgIcon icon={ICON.CAMERA} />
                        {t('casualties.form.documents.images')}
                    </div>
                    <div
                        className={onlyImages === false ? classFilterItemSelected : classFilterItem}
                        onClick={() => this.onFilterChange(false)}
                    >
                        <SvgIcon icon={ICON.PASTE} />
                        {t('casualties.form.documents.documents')}
                    </div>
                </div>
                <div className="icons-container">
                    <SvgIcon icon={ICON.VIEW_LIST} callback={() => this.onTypeViewClick('LIST')} />
                    <SvgIcon icon={ICON.VIEW_GRID} callback={() => this.onTypeViewClick('GRID')} />
                </div>
            </React.Fragment>
        );
    }

    render() {
        const {
            t,
            casualty,
        } = this.props;
        const {
            preparing,
            isFetching,
            hasData,
            imgToShow,
            showingModal,
            creatingInformativeNote,
            editingFileName,
            fileName,
            formErrors,
        } = this.state;

        return (
            <div>
                {(preparing || isFetching) && (
                    <div className="loader-wrapper">
                        <Loader />
                    </div>
                )}
                {hasData
                    ? this.renderContent()
                    : (
                        <div className="app-screen__form__form-container__no-content grid" data-testid="casualty-document-no-data-test">
                            <div className="content">
                                <SvgIcon icon={ICON.CAR_CRASH} />
                                <h1>{t('noContent.ops')}</h1>
                                <p>{t('noContent.noDocumentsToPresent')}</p>
                            </div>
                        </div>
                    )}
                {showingModal && <ImageShowModal onModalClose={this.onImageModalClose} imgDownloadUrl={imgToShow} />}
                {creatingInformativeNote && <InformativeNoteModal onModalClose={this.onNoteModalClose} casualty={casualty} onNewInformativeNoteCreated={this.onNewInformativeNoteCreated} />}
                {editingFileName
                && (
                    <GeneralModal
                        onModalClose={this.onNameEditModalClose}
                        containerStyle="document-edit-container"
                        boxStyle="document-edit-box"
                        boxContentStyle="document-edit-box-content"
                    >
                        <FormTextField
                            label={t('casualties.form.documents.newFileName')}
                            name="fileName"
                            value={fileName}
                            onChange={this.onInputChange}
                            containerStyles="field-container"
                            errors={get(formErrors, 'fields.fileName', null)}
                        />
                        <Button
                            text={t('global.buttons.accept')}
                            callback={this.onNameEditAccept}
                            styles="btn--purple"
                        />
                    </GeneralModal>
                )}
            </div>
        );
    }
}

export default withPaging(withSort(withTranslationContext(CasualtyDocumentsTab)));
