/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import { User } from './types';

export enum SocketReceivedMessageType {
    INVALID_SESSION = 'INVALID_SESSION',
    INVALID_TOKEN = 'INVALID_TOKEN',
    INVALID_MESSAGE = 'INVALID_MESSAGE',
    INVALID_CALLEE = 'INVALID_CALLEE',
    INVALID_SDP = 'INVALID_SDP',
    UNAUTHORIZED = 'UNAUTHORIZED',
    USER_OFFLINE = 'USER_OFFLINE',
    USER_BUSY = 'USER_BUSY',
    CALL_ANSWERED = 'CALL_ANSWERED',
    CALL_DECLINED = 'CALL_DECLINED',
    INCOMING_CALL = 'INCOMING_CALL',
    INCOMING_CALL_CANCELED = 'INCOMING_CALL_CANCELED',
    ICE_CANDIDATE_FOUND = 'ICE_CANDIDATE_FOUND',
    CALL_TERMINATED = 'CALL_TERMINATED',
    JOINED = 'JOINED',
    LEFT = 'LEFT',
    USER_LIST = 'USER_LIST',
    USER_FULL_LIST = 'USER_FULL_LIST',
    TURN_CREDENTIALS = 'TURN_CREDENTIALS',
}

export enum SocketSendMessageType {
    CREATE_OFFER = 'CREATE_OFFER',
    CANCEL_OFFER = 'CANCEL_OFFER',
    ACCEPT_OFFER = 'ACCEPT_OFFER',
    DECLINE_OFFER = 'DECLINE_OFFER',
    OFFER_ICE_CANDIDATE = 'OFFER_ICE_CANDIDATE',
    TERMINATE_OFFER = 'TERMINATE_OFFER',
    GET_ONLINE_USERS = 'GET_ONLINE_USERS',
    GET_ALL_ONLINE_USERS = 'GET_ALL_ONLINE_USERS',
    GET_TURN_CREDENTIALS = 'GET_TURN_CREDENTIALS',
}

export interface SocketSendMessage {
    type: SocketSendMessageType;
}

export interface SocketReceivedMessage {
    message: string;
    messageType: SocketReceivedMessageType;
    users: Array<User> | null;
    type: RTCSdpType;
    sdp: string;
    from: string;
    candidate: RTCIceCandidateInit;
    messageMap: {
        casualtyId: number;
        casualtyUid: string;
        mechanicName: string | null;
        preInspectionId: number;
        preInspectionUid: string;
    };
    credential: string;
    username: string;
    successful: boolean;
    filename: string;
}
