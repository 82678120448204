/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { Component } from 'react';
import { TranslationContextInterface, withTranslationContext } from '../controllers/translation/TranslationContext';
import logo from '../../assets/images/logo_horizontal.svg';

/**
 * @typedef {Object} OwnProps
 * @extends {TranslationContext}
 * @property {Function} onModalClose
 * @property {string} previousGlassResult
 * @property {string} currentGlassResult
 */
interface OwnProps extends TranslationContextInterface {
    onModalClose: Function;
    previousGlassResult: string;
    currentGlassResult: string;
}

const modalOutsideId = 'modal-outside';

/**
 * shows the modal with previous and current glass evaluation result
 * @extends {Component<OwnProps>}
 */
class ReassessModal extends Component<OwnProps> {
    componentDidMount(): void {
        document.addEventListener('mousedown', this.handleOutsideClick);
    }

    componentWillUnmount(): void {
        document.removeEventListener('mousedown', this.handleOutsideClick);
    }

    /**
     * handle the outside click to close modal
     */
    handleOutsideClick = (e: any) => {
        const { target } = e;
        const { id } = target;

        if (id === modalOutsideId) {
            this.onClose();
        }
    };

    /**
     * close the modal
     */
    onClose = () => {
        const { onModalClose } = this.props;
        onModalClose();
    };

    render() {
        const { t, previousGlassResult, currentGlassResult } = this.props;

        return (
            <div>
                <div id={modalOutsideId} className="app-screen__modal" data-testid="modal-outside-test">
                    <div className="app-screen__modal__container reassess-modal">
                        <div className="border-header">
                            <img src={logo} alt="logo" />
                        </div>
                        <div className="app-screen__modal__container__box">
                            <div className="app-screen__modal__container__box__content">
                                <div className="reassess-result-container">
                                    <p className="reassess-result-container-title">{t('modalReassess.title')}</p>
                                    <p className="reassess-result-container-previous">{t('modalReassess.previousResult')}{t(`enums.preInspectionResult.${previousGlassResult}`)}</p>
                                    <p className="reassess-result-container-current">{t('modalReassess.currentResult')}{t(`enums.preInspectionResult.${currentGlassResult}`)}</p>
                                </div>
                                <div className="btn-container">
                                    <button
                                        type="button"
                                        className="btn btn--purple"
                                        onClick={this.onClose}
                                    >
                                        <span>{t('global.buttons.ok')}</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withTranslationContext(ReassessModal);
