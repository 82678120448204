/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

export const INDEX_ROUTE = '/';
export const LOGIN_ROUTE = '/login';
export const NOT_FOUND_ROUTE = '/404';
export const CASUALTIES_LIST_ROUTE = '/casualties/all';
export const CASUALTIES_ONGOING_LIST_ROUTE = '/casualties/ongoing';
export const CASUALTIES_DONE_LIST_ROUTE = '/casualties/done';
export const CASUALTIES_CANCELED_LIST_ROUTE = '/casualties/canceled';
export const CASUALTIES_EDIT_ROUTE = '/casualties/edit';
export const BUDGET_DETAIL_ROUTE = '/casualties/edit/:casualtyId/budget/:budgetId';
export const CASUALTIES_DETAILS_ROUTE = '/casualties/details';
export const CASUALTIES_CREATE_ROUTE = '/casualties/create';
export const PRE_INSPECTIONS_LIST_ROUTE = '/pre-inspections/all';
export const PRE_INSPECTIONS_GLASS_LIST_ROUTE = '/pre-inspections/glass';
export const PRE_INSPECTIONS_COMPLETE_LIST_ROUTE = '/pre-inspections/complete';
export const PRE_INSPECTIONS_EDIT_ROUTE = '/pre-inspections/edit';
export const PRE_INSPECTIONS_DETAILS_ROUTE = '/pre-inspections/details';
export const PRE_INSPECTIONS_CREATE_ROUTE = '/pre-inspections/new';
export const STATISTICS_ROUTE = '/statistics';
export const MESSAGES_RECEIVED_LIST_ROUTE = '/messages';
export const MESSAGES_SENT_LIST_ROUTE = '/messages/sent';
export const USERS_LIST_ROUTE = '/users/all';
export const USERS_ADMIN_LIST_ROUTE = '/users/admin';
export const USERS_EXTERNAL_ADMIN_LIST_ROUTE = '/users/externaladmin';
export const USERS_COLLABORATOR_LIST_ROUTE = '/users/collaborator';
export const USERS_MECHANIC_LIST_ROUTE = '/users/mechanic';
export const USERS_INSURANCE_LIST_ROUTE = '/users/insurance';
export const USERS_GLASS_PROVIDER_LIST_ROUTE = '/users/glass-providers';
export const USER_MECHANIC_EDIT_ROUTE = '/users/mechanic';
export const USER_INSURANCE_EDIT_ROUTE = '/users/insurance';
export const USER_INSURANCE_DETAILS_ROUTE = '/users/details/insurance';
export const USER_MECHANIC_DETAILS_ROUTE = '/users/details/mechanic';
export const USER_GLASS_PROVIDER_EDIT_ROUTE = '/users/glass-provider';
export const USER_GLASS_PROVIDER_DETAILS_ROUTE = '/users/details/glass-provider';
export const USERS_EDIT_ROUTE = '/users/edit';
export const RECOVER_PASSWORD_ROUTE = '/recover-password';
export const RESET_PASSWORD_ROUTE = '/reset-password';
export const REGISTER_ROUTE = '/register';
export const DISPUTES_LIST_ROUTE = '/disputes';
export const BROKEN_GLASS_CONTROL_LIST_ROUTE = '/broken-glass-controls/all';
export const BROKEN_GLASS_CONTROL_ONGOING_LIST_ROUTE = '/broken-glass-controls/ongoing';
export const BROKEN_GLASS_CONTROL_DONE_LIST_ROUTE = '/broken-glass-controls/done';
export const BROKEN_GLASS_CONTROL_PENDING_LIST_ROUTE = '/broken-glass-controls/pending';
export const BROKEN_GLASS_CONTROL_CREATE_ROUTE = '/broken-glass-controls/new';
export const BROKEN_GLASS_CONTROL_EDIT_ROUTE = '/broken-glass-controls/edit';
export const BROKEN_GLASS_CONTROL_DETAILS_ROUTE = '/broken-glass-controls/details';
