/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { Component } from 'react';
import { get } from 'lodash';
import { TranslationContextInterface, withTranslationContext } from '../controllers/translation/TranslationContext';
import logo from '../../assets/images/logo_horizontal.svg';
import FormTextField from './FormTextField';
import { getFormErrors, IFormError, VALIDATIONS } from '../../utils/validation';

/**
 * @typedef {Object} OwnProps
 * @extends {TranslationContext}
 * @property {Function} onModalClose
 * @property {Function} onClickSendReport
 * @property {string} agentEmail
 * @property {string} agentName
 */
interface OwnProps extends TranslationContextInterface {
    onModalClose: Function;
    onClickSendReport: Function;
    agentName: string;
    agentEmail: string;
}

/**
 * @typedef {Object} OwnState
 * @property {string} insuranceCompany
 * @property {string} email
 * @property {any} formErrors
 */
interface OwnState {
    insuranceCompany: string;
    email: string;
    formErrors: any;
}

const initialState: OwnState = {
    insuranceCompany: '',
    email: '',
    formErrors: {},
};

const modalOutsideId = 'modal-outside';

/**
 * shows the modal to send email to agent with or without report when a pre inspection is conclude
 * @extends {Component<OwnProps, OwnState>}
 */
class SendMailModal extends Component<OwnProps, OwnState> {
    state = initialState;

    componentDidMount(): void {
        const { agentEmail, agentName } = this.props;

        this.setState({
            insuranceCompany: agentName,
            email: agentEmail,
        });

        document.addEventListener('mousedown', this.handleOutsideClick);
    }

    componentWillUnmount(): void {
        document.removeEventListener('mousedown', this.handleOutsideClick);
    }

    /**
     * set the field values on update
     * @property {React.FormEvent<HTMLInputElement>} e
     */
    onInputChange = (e: React.FormEvent<HTMLInputElement>) => {
        this.setState({
            ...this.state,
            [e.currentTarget.name]: e.currentTarget.value,
        });
    };

    /**
     * validate form
     * @param {boolean} withReport
     * @returns {boolean}
     */
    validateFields = (withReport: boolean): boolean => {
        let errors: IFormError | null = getFormErrors(this.state, VALIDATIONS.SEND_MAIL_MODAL);

        if (errors && Object.keys(errors).length === 0) errors = null;

        if (!withReport) delete errors?.email;

        if (errors && Object.keys(errors).length === 0) errors = null;

        this.setState({ formErrors: errors ? { fields: errors } : errors });
        return errors === null;
    };

    /**
     * handle send email without report
     */
    sendWithoutReport = () => {
        const { insuranceCompany, email } = this.state;
        const { onClickSendReport } = this.props;
        if (this.validateFields(false)) {
            onClickSendReport(false, insuranceCompany, email);
            this.onClose();
        }
    };

    /**
     * handle send email and report
     */
    sendWithReport = () => {
        const { insuranceCompany, email } = this.state;
        const { onClickSendReport } = this.props;

        if (this.validateFields(true)) {
            onClickSendReport(true, insuranceCompany, email);
            this.onClose();
        }
    };

    /**
     * handle the outside click to close modal
     */
    handleOutsideClick = (e: any) => {
        const { target } = e;
        const { id } = target;

        if (id === modalOutsideId) {
            this.onClose();
        }
    };

    /**
     * close the modal
     */
    onClose = () => {
        const { onModalClose } = this.props;
        this.setState({ ...initialState });
        onModalClose();
    };

    render() {
        const { t } = this.props;
        const { insuranceCompany, email, formErrors } = this.state;
        const strTitle = t('modalSendMail.title');

        return (
            <div>
                <div id={modalOutsideId} className="app-screen__modal" data-testid="modal-outside-test">
                    <div className="app-screen__modal__container send-message-modal">
                        <div className="border-header">
                            <img src={logo} alt="logo" />
                            <p>{strTitle}</p>
                        </div>
                        <div className="app-screen__modal__container__box send-message-box">
                            <div className="app-screen__modal__container__box__content send-report-content">
                                <FormTextField
                                    containerStyles="field-new-message"
                                    label={t('modalSendMail.name')}
                                    name="insuranceCompany"
                                    value={insuranceCompany}
                                    onChange={this.onInputChange}
                                    errors={get(formErrors, 'fields.insuranceCompany', null)}
                                />
                                <FormTextField
                                    containerStyles="field-new-message"
                                    label={t('modalSendMail.email')}
                                    name="email"
                                    value={email}
                                    onChange={this.onInputChange}
                                    errors={get(formErrors, 'fields.email', null)}
                                />
                                <div className="btn-container">
                                    <button
                                        type="button"
                                        className="btn btn--dark-purple"
                                        onClick={this.sendWithoutReport}
                                        data-testid="btn-send-without-report-test"
                                    >
                                        <span>{t('modalSendMail.withoutReport')} <br />{t('modalSendMail.report')}</span>
                                    </button>
                                    <button
                                        type="button"
                                        className="btn btn--purple"
                                        onClick={this.sendWithReport}
                                        data-testid="btn-send-with-report-test"
                                    >
                                        <span>{t('modalSendMail.withReport')} <br />{t('modalSendMail.report')}</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withTranslationContext(SendMailModal);
