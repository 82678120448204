/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

/**
 * provides an object that can be accessed by key dynamically
 * @typedef KeyedObject
 * @property {any} [key:string]
 */
import { History } from 'history';
import { SocketReceivedMessage } from './socket_types';

export interface KeyedObject {
    [key: string]: any;
}

/**
 * provides an object for select options
 * @typedef SelectOption
 * @property {any} value
 * @property {any} label
 */
export type SelectOption = {
    value: any;
    label: any;
}

/**
 * for use in RouteComponentProps<MatchParams>
 * @typedef MatchParams
 * @property {string} id
 * @property {string} name
 */
export interface MatchParams {
    id?: string;
    name?: string;
    token?: string;
    role?: string;
    budgetId?: string;
    casualtyId?: string;
    glass?: string;
}

type MyHistory = History

export interface HistoryState extends MyHistory {
    answerCall?: boolean;
    pendingCandidates?: Array<SocketReceivedMessage>;
}

export type TabItem = {
    value: string;
    text: string;
    disabled?: boolean;
}

export const RESULTS_PER_PAGE = 10;

export const REMEMBERED_FLUXE_USER_KEY = 'REMEMBERED_FLUXE_USER_KEY';

export const FLUXE_MESSAGE_NAME = 'Fluxe';

export enum PreInspectionType {
    COMPLETE = 'COMPLETE',
    GLASS = 'GLASS'
}

export enum PreInspectionResult {
    DAMAGED = 'DAMAGED',
    OK = 'OK',
}

export enum BudgetDescription {
    SURCHARGE_WORK_PARTS = 'SURCHARGE_WORK_PARTS',
    DISCOUNT_WORK_PARTS = 'DISCOUNT_WORK_PARTS',
    SURCHARGE_WORK_PAINT = 'SURCHARGE_WORK_PAINT',
    DISCOUNT_WORK_PAINT = 'DISCOUNT_WORK_PAINT',
    SURCHARGE_MAT_PARTS = 'SURCHARGE_MAT_PARTS',
    DISCOUNT_MAT_PARTS = 'DISCOUNT_MAT_PARTS',
    SURCHARGE_MAT_PAINT = 'SURCHARGE_MAT_PAINT',
    DISCOUNT_MAT_PAINT = 'DISCOUNT_MAT_PAINT',
    ADITIONAL_VALUES = 'ADITIONAL_VALUES',
    TOTAL1 = 'TOTAL1',
    DISC_OVERALL = 'DISC_OVERALL',
    TOTAL_REP_COST_EXCL_VAT = 'TOTAL_REP_COST_EXCL_VAT',
    VAT_WORK = 'VAT_WORK',
    VAT_MAT = 'VAT_MAT',
    VAT_TOTAL = 'VAT_TOTAL',
    TOTAL_WITH_IVA = 'TOTAL_WITH_IVA',
    GRAND_TOTAL = 'GRAND_TOTAL',
    DEDUCTIBLE_VALUE = 'DEDUCTIBLE_VALUE'
}

export enum ImageFileType {
    FRONT_LEFT_WINDSHIELD= 'FRONT_LEFT_WINDSHIELD',
    FRONT_RIGHT_WINDSHIELD = 'FRONT_RIGHT_WINDSHIELD',
    FRONT_WINDSHIELD = 'FRONT_WINDSHIELD',
    LICENSE_PLATE_WINDSHIELD = 'LICENSE_PLATE_WINDSHIELD',
    FRONT_BODY = 'FRONT_BODY',
    RIGHT_BODY = 'RIGHT_BODY',
    LEFT_BODY = 'LEFT_BODY',
    BACK_BODY = 'BACK_BODY',
    CEILING = 'CEILING',
    INSIDE = 'INSIDE',
    IDENTIFICATION = 'IDENTIFICATION',
    INSPECTION = 'INSPECTION',
    DOCUMENT = 'DOCUMENT',
    ADDITIONAL_PHOTOS = 'ADDITIONAL_PHOTOS',
}

export enum BrokenGlassControlOrigin {
    EditPage = 'EDIT_PAGE',
    ListPage = 'LIST_PAGE',
}

export const DEBOUNCED_REQUEST_DELAY_MS = 500;

export enum ListOrder {
    Asc = 'asc',
    Desc = 'desc',
}

export enum ListQueryParamKey {
    Page = 'page',
    Sort = 'sort',
    Order = 'order',
    Search = 'search',
}

export interface ListParams {
    page: number;
    sort: string;
    order: string;
    search: string;
}
