/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

const viewAll = 'All';

const viewMessages = 'View messages';

const viewPreSurveys = 'View pre surveys';

const viewMechanicDetails = 'View mechanic details';

const createCasualities = 'Create casualties';

const viewCasualties = 'View casualties';

const viewCasualtiesDetails = 'View casualty details';

const createCasualtyBudget = 'Create casualty budget';

const createCasualtyMessage = 'Create casualty message';

const createCasualtyDocument = 'Create casualty document';

const viewProfile = 'View profile';

const viewInsuranceDetails = 'View insurance details';

const viewPreInspection = 'View pre inspections';

const viewPreInspectionDetails = 'View pre inspections details ';

const createPreInspection = 'Create pre inspection';

const editPreInspection = 'Edit pre inspection';

const viewCasualtiesReopened = 'View casualties reopened';

const viewCasualtiesCanceled = 'View casualties canceled';

const editCasualties = 'Edit casualties';

const viewDisputes = 'View disputes';

const viewUsers = 'View users';

const viewBrokenGlassControls = 'View broken glass controls';

const createBrokenGlassControls = 'Create broken glass controls';

const editBrokenGlassControls = 'Edit broken glass controls';

/**
 * @typedef {Object} RolePermission
 * @property {string} name
 * @property {number} id
 * @property {string} key
 * @property {RolePermission[]} dependencies
 */
export interface RolePermission {
    name: string;
    id: number;
    key: string;
    dependencies: RolePermission[];
}

/**
 * PermissionKey
 * @enum {string}
 */
export enum PermissionKey {
    CHANGE_LANGUAGE = 'CHANGE_LANGUAGE',
    ALL = 'ALL',
    VIEW_USERS = 'VIEW_USERS',
    CREATE_USER = 'CREATE_USER',
    EDIT_USER = 'EDIT_USER',
    VIEW_CASUALTIES = 'VIEW_CASUALTIES',
    VIEW_CASUALTIES_REOPENED = 'VIEW_CASUALTIES_REOPENED',
    VIEW_CASUALTIES_CANCELED = 'VIEW_CASUALTIES_CANCELED',
    VIEW_STATISTICS = 'VIEW_STATISTICS',
    VIEW_MESSAGES = 'VIEW_MESSAGES',
    VIEW_PRE_INSPECTIONS = 'VIEW_PRE_INSPECTIONS',
    EDIT_PRE_INSPECTIONS = 'EDIT_PRE_INSPECTIONS',
    CREATE_PRE_INSPECTIONS = 'CREATE_PRE_INSPECTIONS',
    VIEW_PRE_INSPECTIONS_DETAILS = 'VIEW_PRE_INSPECTIONS_DETAILS',
    EDIT_CASUALTIES = 'EDIT_CASUALTIES',
    CREATE_CASUALTIES = 'CREATE_CASUALTIES',
    VIEW_CASUALTIES_DETAILS = 'VIEW_CASUALTIES_DETAILS',
    CREATE_CASUALTY_DOCUMENT = 'CREATE_CASUALTY_DOCUMENT',
    CREATE_CASUALTY_MESSAGE = 'CREATE_CASUALTY_MESSAGE',
    CREATE_CASUALTY_BUDGET = 'CREATE_CASUALTY_BUDGET',
    VIEW_MECHANIC_DETAILS = 'VIEW_MECHANIC_DETAILS',
    VIEW_INSURANCE_DETAILS = 'VIEW_INSURANCE_DETAILS',
    VIEW_DISPUTES = 'VIEW_DISPUTES',
    VIEW_CASUALITIES_USERS = 'VIEW_CASUALITIES_USERS',
    VIEW_BROKEN_GLASS_CONTROLS = 'VIEW_BROKEN_GLASS_CONTROLS',
    VIEW_BROKEN_GLASS_CONTROLS_DETAILS = 'VIEW_BROKEN_GLASS_CONTROLS_DETAILS',
    CREATE_BROKEN_GLASS_CONTROLS = 'CREATE_BROKEN_GLASS_CONTROLS',
    EDIT_BROKEN_GLASS_CONTROLS = 'EDIT_BROKEN_GLASS_CONTROLS',
    BROKEN_GLASS_CONTROL_CHECK_IN = 'BROKEN_GLASS_CONTROL_CHECK_IN',
    BROKEN_GLASS_CONTROL_CHECK_OUT = 'BROKEN_GLASS_CONTROL_CHECK_OUT',
    VIEW_PROVIDER_EMAIL_IN_BROKEN_GLASS_CONTROL_LIST = 'VIEW_PROVIDER_EMAIL_IN_BROKEN_GLASS_CONTROL_LIST',
}

export enum UserRoles {
    ADMIN = 'Admin',
    COLLABORATOR = 'Collaborator',
    MECHANIC = 'Mechanic',
    INSURANCE_USER = 'InsuranceCompany',
    EXTERNAL_ADMIN = 'ExternalAdmin',
    GLASS_PROVIDER = 'GlassProvider',
}

export const adminPermissions: RolePermission[] = [
    {
        name: viewAll,
        id: 1,
        key: PermissionKey.ALL,
        dependencies: [],
    },
];

export const collaboratorPermissions: RolePermission[] = [
    {
        name: viewCasualties,
        id: 1,
        key: PermissionKey.VIEW_CASUALTIES,
        dependencies: [],
    },
    {
        name: viewMessages,
        id: 2,
        key: PermissionKey.VIEW_MESSAGES,
        dependencies: [],
    },
    {
        name: viewPreSurveys,
        id: 3,
        key: PermissionKey.VIEW_PRE_INSPECTIONS,
        dependencies: [],
    },
    {
        name: createCasualities,
        id: 4,
        key: PermissionKey.CREATE_CASUALTIES,
        dependencies: [],
    },
    {
        name: viewCasualtiesDetails,
        id: 5,
        key: PermissionKey.VIEW_CASUALTIES_DETAILS,
        dependencies: [],
    },
    {
        name: createCasualtyBudget,
        id: 6,
        key: PermissionKey.CREATE_CASUALTY_BUDGET,
        dependencies: [],
    },
    {
        name: createCasualtyMessage,
        id: 7,
        key: PermissionKey.CREATE_CASUALTY_MESSAGE,
        dependencies: [],
    },
    {
        name: createCasualtyDocument,
        id: 8,
        key: PermissionKey.CREATE_CASUALTY_DOCUMENT,
        dependencies: [],
    },
    {
        name: viewPreInspection,
        id: 9,
        key: PermissionKey.VIEW_PRE_INSPECTIONS,
        dependencies: [],
    },
    {
        name: viewPreInspectionDetails,
        id: 10,
        key: PermissionKey.VIEW_PRE_INSPECTIONS_DETAILS,
        dependencies: [],
    },
    {
        name: viewMechanicDetails,
        id: 11,
        key: PermissionKey.VIEW_MECHANIC_DETAILS,
        dependencies: [],
    },
    {
        name: viewInsuranceDetails,
        id: 12,
        key: PermissionKey.VIEW_INSURANCE_DETAILS,
        dependencies: [],
    },
    {
        name: viewCasualtiesReopened,
        id: 13,
        key: PermissionKey.VIEW_CASUALTIES_REOPENED,
        dependencies: [],
    },
    {
        name: viewCasualtiesCanceled,
        id: 14,
        key: PermissionKey.VIEW_CASUALTIES_CANCELED,
        dependencies: [],
    },
    {
        name: createPreInspection,
        id: 15,
        key: PermissionKey.CREATE_PRE_INSPECTIONS,
        dependencies: [],
    },
    {
        name: viewProfile,
        id: 16,
        key: PermissionKey.EDIT_USER,
        dependencies: [],
    },
    {
        name: viewDisputes,
        id: 17,
        key: PermissionKey.VIEW_DISPUTES,
        dependencies: [],
    },
    {
        name: editPreInspection,
        id: 18,
        key: PermissionKey.EDIT_PRE_INSPECTIONS,
        dependencies: [],
    },
    {
        name: viewBrokenGlassControls,
        id: 21,
        key: PermissionKey.VIEW_BROKEN_GLASS_CONTROLS,
        dependencies: [],
    },
    {
        name: createBrokenGlassControls,
        id: 22,
        key: PermissionKey.CREATE_BROKEN_GLASS_CONTROLS,
        dependencies: [],
    },
    {
        name: editBrokenGlassControls,
        id: 23,
        key: PermissionKey.EDIT_BROKEN_GLASS_CONTROLS,
        dependencies: [],
    },
    {
        name: editBrokenGlassControls,
        id: 24,
        key: PermissionKey.VIEW_BROKEN_GLASS_CONTROLS_DETAILS,
        dependencies: [],
    },
    {
        name: editBrokenGlassControls,
        id: 25,
        key: PermissionKey.BROKEN_GLASS_CONTROL_CHECK_IN,
        dependencies: [],
    },
    {
        name: editBrokenGlassControls,
        id: 26,
        key: PermissionKey.BROKEN_GLASS_CONTROL_CHECK_OUT,
        dependencies: [],
    },
    {
        name: editBrokenGlassControls,
        id: 27,
        key: PermissionKey.VIEW_PROVIDER_EMAIL_IN_BROKEN_GLASS_CONTROL_LIST,
        dependencies: [],
    },
];

export const mechanicPermissions: RolePermission[] = [
    {
        name: viewCasualties,
        id: 1,
        key: PermissionKey.VIEW_CASUALTIES,
        dependencies: [],
    },
    {
        name: viewMessages,
        id: 2,
        key: PermissionKey.VIEW_MESSAGES,
        dependencies: [],
    },
    {
        name: viewCasualtiesDetails,
        id: 3,
        key: PermissionKey.VIEW_CASUALTIES_DETAILS,
        dependencies: [],
    },
    {
        name: createCasualtyMessage,
        id: 4,
        key: PermissionKey.CREATE_CASUALTY_MESSAGE,
        dependencies: [],
    },
    {
        name: viewCasualtiesReopened,
        id: 5,
        key: PermissionKey.VIEW_CASUALTIES_REOPENED,
        dependencies: [],
    },
    {
        name: viewCasualtiesCanceled,
        id: 6,
        key: PermissionKey.VIEW_CASUALTIES_CANCELED,
        dependencies: [],
    },
    {
        name: viewProfile,
        id: 16,
        key: PermissionKey.EDIT_USER,
        dependencies: [],
    },
];

export const glassProviderPermissions: RolePermission[] = [
    {
        name: viewProfile,
        id: 16,
        key: PermissionKey.EDIT_USER,
        dependencies: [],
    },
    {
        name: viewBrokenGlassControls,
        id: 21,
        key: PermissionKey.VIEW_BROKEN_GLASS_CONTROLS,
        dependencies: [],
    },
    {
        name: editBrokenGlassControls,
        id: 25,
        key: PermissionKey.BROKEN_GLASS_CONTROL_CHECK_IN,
        dependencies: [],
    },
    {
        name: editBrokenGlassControls,
        id: 26,
        key: PermissionKey.BROKEN_GLASS_CONTROL_CHECK_OUT,
        dependencies: [],
    },
];

export const insuranceUserPermissions: RolePermission[] = [
    {
        name: viewCasualties,
        id: 1,
        key: PermissionKey.VIEW_CASUALTIES,
        dependencies: [],
    },
    {
        name: viewPreSurveys,
        id: 3,
        key: PermissionKey.VIEW_PRE_INSPECTIONS,
        dependencies: [],
    },
    {
        name: viewMechanicDetails,
        id: 11,
        key: PermissionKey.VIEW_MECHANIC_DETAILS,
        dependencies: [],
    },
    {
        name: viewCasualties,
        id: 4,
        key: PermissionKey.VIEW_CASUALTIES,
        dependencies: [],
    },
    {
        name: viewCasualtiesDetails,
        id: 5,
        key: PermissionKey.VIEW_CASUALTIES_DETAILS,
        dependencies: [],
    },
    {
        name: viewPreInspection,
        id: 6,
        key: PermissionKey.VIEW_PRE_INSPECTIONS,
        dependencies: [],
    },
    {
        name: viewPreInspectionDetails,
        id: 10,
        key: PermissionKey.VIEW_PRE_INSPECTIONS_DETAILS,
        dependencies: [],
    },
    {
        name: viewProfile,
        id: 16,
        key: PermissionKey.EDIT_USER,
        dependencies: [],
    },
    {
        name: viewBrokenGlassControls,
        id: 21,
        key: PermissionKey.VIEW_BROKEN_GLASS_CONTROLS,
        dependencies: [],
    },
    {
        name: editBrokenGlassControls,
        id: 24,
        key: PermissionKey.VIEW_BROKEN_GLASS_CONTROLS_DETAILS,
        dependencies: [],
    },
    {
        name: viewBrokenGlassControls,
        id: 25,
        key: PermissionKey.VIEW_PROVIDER_EMAIL_IN_BROKEN_GLASS_CONTROL_LIST,
        dependencies: [],
    },
];

export const externalAdminPermissions: RolePermission[] = [
    {
        name: viewCasualties,
        id: 1,
        key: PermissionKey.VIEW_CASUALTIES,
        dependencies: [],
    },
    {
        name: viewMessages,
        id: 2,
        key: PermissionKey.VIEW_MESSAGES,
        dependencies: [],
    },
    {
        name: createCasualities,
        id: 4,
        key: PermissionKey.CREATE_CASUALTIES,
        dependencies: [],
    },
    {
        name: viewCasualtiesDetails,
        id: 5,
        key: PermissionKey.VIEW_CASUALTIES_DETAILS,
        dependencies: [],
    },
    {
        name: createCasualtyMessage,
        id: 7,
        key: PermissionKey.CREATE_CASUALTY_MESSAGE,
        dependencies: [],
    },
    {
        name: viewPreInspection,
        id: 9,
        key: PermissionKey.VIEW_PRE_INSPECTIONS,
        dependencies: [],
    },
    {
        name: viewPreInspectionDetails,
        id: 10,
        key: PermissionKey.VIEW_PRE_INSPECTIONS_DETAILS,
        dependencies: [],
    },
    {
        name: viewCasualtiesReopened,
        id: 13,
        key: PermissionKey.VIEW_CASUALTIES_REOPENED,
        dependencies: [],
    },
    {
        name: viewCasualtiesCanceled,
        id: 14,
        key: PermissionKey.VIEW_CASUALTIES_CANCELED,
        dependencies: [],
    },
    {
        name: createPreInspection,
        id: 15,
        key: PermissionKey.CREATE_PRE_INSPECTIONS,
        dependencies: [],
    },
    {
        name: viewProfile,
        id: 16,
        key: PermissionKey.EDIT_USER,
        dependencies: [],
    },
    {
        name: viewDisputes,
        id: 17,
        key: PermissionKey.VIEW_DISPUTES,
        dependencies: [],
    },
    {
        name: editPreInspection,
        id: 18,
        key: PermissionKey.EDIT_PRE_INSPECTIONS,
        dependencies: [],
    },
    {
        name: editCasualties,
        id: 19,
        key: PermissionKey.EDIT_CASUALTIES,
        dependencies: [],
    },
    {
        name: viewUsers,
        id: 20,
        key: PermissionKey.VIEW_CASUALITIES_USERS,
        dependencies: [],
    },
    {
        name: viewBrokenGlassControls,
        id: 21,
        key: PermissionKey.VIEW_BROKEN_GLASS_CONTROLS,
        dependencies: [],
    },
    {
        name: createBrokenGlassControls,
        id: 22,
        key: PermissionKey.CREATE_BROKEN_GLASS_CONTROLS,
        dependencies: [],
    },
    {
        name: editBrokenGlassControls,
        id: 23,
        key: PermissionKey.EDIT_BROKEN_GLASS_CONTROLS,
        dependencies: [],
    },
    {
        name: editBrokenGlassControls,
        id: 24,
        key: PermissionKey.VIEW_BROKEN_GLASS_CONTROLS_DETAILS,
        dependencies: [],
    },
    {
        name: editBrokenGlassControls,
        id: 25,
        key: PermissionKey.BROKEN_GLASS_CONTROL_CHECK_IN,
        dependencies: [],
    },
    {
        name: editBrokenGlassControls,
        id: 26,
        key: PermissionKey.BROKEN_GLASS_CONTROL_CHECK_OUT,
        dependencies: [],
    },
    {
        name: viewBrokenGlassControls,
        id: 27,
        key: PermissionKey.VIEW_PROVIDER_EMAIL_IN_BROKEN_GLASS_CONTROL_LIST,
        dependencies: [],
    },
];
