/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { Component } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { debounce } from 'lodash';

import { ListQueryParamKey, TabItem } from '../../constants/misc';
import { ICON, SvgIcon } from './SvgIcon';
import Button from './Button';
import DocumentUpload from './DocumentUpload';
import {
    AuthenticationContextInterface,
    withAuthenticationContext,
} from '../controllers/authentication/AuthenticationContext';
import {
    AuthorizationContextInterface,
    withAuthorizationContext,
} from '../controllers/authorization/AuthorizationContext';
import { PermissionKey, UserRoles } from '../../constants/authorization';
import { TranslationContextInterface, withTranslationContext } from '../controllers/translation/TranslationContext';
import { checkPermission } from '../../utils/authorization';
import withQueryParams, { WithQueryParamsProps } from '../hocs/withQueryParams';

interface OwnProps extends AuthenticationContextInterface, AuthorizationContextInterface, TranslationContextInterface, RouteComponentProps, WithQueryParamsProps {
    tabsItems: TabItem[];
    onTabChange: Function;
    withSearch?: boolean;
    onSearchChange?: (event: React.FormEvent<HTMLInputElement>) => void;
    onSubmit?: (event: React.FormEvent<HTMLFormElement>) => void;
    onClearSearch?: Function;
    addButtonText?: string;
    addButtonClickHandler?: ((event: React.MouseEvent<HTMLButtonElement>) => void);
    secondAddButtonText?: string;
    secondAddButtonRole?: PermissionKey[];
    onSecondAddButtonClickHandler?: ((event: React.MouseEvent<HTMLButtonElement>) => void);
    secondButtonIcon?: string;
    addButtonStyles?: string;
    documentUploadText?: string;
    documentUploadFileSelected?: Function;
    isAddButtonDisabled?: boolean;
    tabSelected?: string;
    fromMessages?: boolean;
    btnResendInvitesClickHandler?: ((event: React.MouseEvent<HTMLButtonElement>) => void);
}

interface OwnState {
    indexTabSelected: number;
    searching: boolean;
    searchValue: string;
}

const initialState: OwnState = {
    indexTabSelected: 0,
    searching: false,
    searchValue: '',
};

class Tabs extends Component<OwnProps, OwnState> {
    state = initialState;

    private readonly triggerQueryParamChange: (search: string) => void;

    constructor(props: OwnProps) {
        super(props);

        this.triggerQueryParamChange = debounce(this.updateQuerySearchValue, 400);
    }

    componentDidMount() {
        const { user, location, onQueryParamsChange } = this.props;
        let role = '';
        if (user) role = user.role;

        if (role === UserRoles.INSURANCE_USER) {
            this.setState({
                searching: true,
            });
        }

        onQueryParamsChange(location.search);
    }

    componentDidUpdate(prevProps: Readonly<OwnProps>, prevState: Readonly<OwnState>, snapshot?: any): void {
        const { tabSelected: oldTabSelected, listParams: oldListParams } = prevProps;
        const { tabSelected, listParams } = this.props;

        const hasTabChanged = oldTabSelected && tabSelected && (tabSelected !== oldTabSelected);
        if (hasTabChanged) {
            const { tabsItems } = this.props;
            const newIndex = Object.keys(tabsItems).findIndex(k => {
                const item = tabsItems[Number(k)];
                return item.value === tabSelected;
            });

            this.setState({
                indexTabSelected: newIndex,
            });
        }

        if (listParams && listParams.search !== oldListParams?.search) {
            this.setState({
                searching: !!(listParams.search),
                searchValue: listParams.search,
            });
        }
    }

    onTabChange = (newTabIndex: number, newTabValue: string, disabled: boolean | undefined) => {
        const { onTabChange } = this.props;

        if (disabled) {
            return;
        }

        this.setState({
            indexTabSelected: newTabIndex,
        }, () => { onTabChange(newTabValue); });
    };

    onSearchClean = (): void => {
        const { onClearSearch } = this.props;

        this.updateQuerySearchValue('');

        if (onClearSearch) {
            onClearSearch();
        }

        this.setState({
            searching: false,
            searchValue: '',
        });
    }

    onSearchChange = (event: React.FormEvent<HTMLInputElement>): void => {
        const { value } = event.currentTarget;
        this.setState({
            searchValue: value,
        });

        this.triggerQueryParamChange(value);
    };

    updateQuerySearchValue = (search: string) => {
        const {
            location, history,
        } = this.props;

        const query = new URLSearchParams(location.search);
        if (search) {
            query.set(ListQueryParamKey.Page, '1');
            query.set(ListQueryParamKey.Search, search);
        } else {
            query.delete(ListQueryParamKey.Search);
        }

        history.push(`${location.pathname}?${query.toString()}`);
    }

    renderSecondAddButton = () => {
        const {
            secondButtonIcon, onSecondAddButtonClickHandler, secondAddButtonText, user, secondAddButtonRole, permissions,
        } = this.props;

        let role = '';
        if (user) role = user.role;

        if (secondAddButtonRole) {
            if (secondAddButtonText && checkPermission(permissions, secondAddButtonRole)) {
                return (
                    <Button
                        icon={secondButtonIcon || undefined}
                        iconPosition="right"
                        text={secondAddButtonText}
                        callback={onSecondAddButtonClickHandler}
                        styles="btn--green second-button"
                    />
                );
            }
        } else {
            if (secondAddButtonText && role !== UserRoles.INSURANCE_USER) {
                return (
                    <Button
                        icon={secondButtonIcon || undefined}
                        iconPosition="right"
                        text={secondAddButtonText}
                        callback={onSecondAddButtonClickHandler}
                        styles="btn--green second-button"
                    />
                );
            }
        }
    }

    render() {
        const {
            tabsItems,
            withSearch,
            documentUploadFileSelected,
            documentUploadText,
            addButtonText,
            addButtonClickHandler,
            secondAddButtonText,
            addButtonStyles,
            isAddButtonDisabled,
            fromMessages,
            user,
            btnResendInvitesClickHandler,
            t,
            permissions,
        } = this.props;

        const { indexTabSelected, searching, searchValue } = this.state;
        const showSearch = withSearch;
        let role = '';

        let showButton = true;

        if (user) role = user.role;

        if (role === UserRoles.MECHANIC) if (!fromMessages) showButton = false;

        return (
            <div className="app-screen__tabs-container" data-testid="tabs-element">
                <div className="app-screen__tabs-container__tabs-items" data-testid="tabs-items">
                    {Object.keys(tabsItems).map(key => {
                        const tabItem: TabItem = tabsItems[Number(key)];

                        const className: string = Number(key) === indexTabSelected ? 'selected' : '';
                        const classShadowRight: string = Number(key) === indexTabSelected + 1 ? 'box-shadow-right' : '';
                        const classShadowLeft: string = Number(key) === indexTabSelected - 1 ? 'box-shadow-left' : '';
                        const classDisabled: string = tabItem.disabled ? 'disabled' : '';

                        return (
                            <button
                                type="button"
                                key={tabItem.value}
                                className={`app-screen__tabs-container__tabs-items__tab-link ${className} ${classShadowRight} ${classShadowLeft} ${classDisabled}`}
                                onClick={() => this.onTabChange(Number(key), tabItem.value, tabItem.disabled)}
                            >
                                {tabItem.text}
                            </button>
                        );
                    })}
                </div>
                {showSearch && (
                    <div className="app-screen__tabs-container__search" data-testid="search-wrapper">
                        {searching && (
                            <div className="app-screen__tabs-container__search__search-field">
                                <input
                                    name="search"
                                    type="text"
                                    value={searchValue}
                                    placeholder=""
                                    onChange={this.onSearchChange}
                                    maxLength={40}
                                />
                            </div>
                        )}
                        {searching ? (
                            <SvgIcon icon={ICON.CROSS} callback={this.onSearchClean} />
                        ) : (
                            <SvgIcon
                                icon={ICON.SEARCH}
                                callback={(): void => this.setState({ searching: true })}
                            />
                        )}
                    </div>
                )}
                {(addButtonText || secondAddButtonText || btnResendInvitesClickHandler) && (
                    <div className="app-screen__tabs-container__button-container" data-testid="tabs-button-container">
                        {btnResendInvitesClickHandler && checkPermission(permissions, [PermissionKey.ALL]) && (
                            <Button
                                icon={ICON.RELOAD}
                                iconPosition="right"
                                text={t('users.btnResendInvites')}
                                callback={btnResendInvitesClickHandler}
                                styles="btn--purple resend-invites"
                                data-testid="btn-resend-invites"
                            />
                        )}
                        {this.renderSecondAddButton()}
                        {(documentUploadText && documentUploadFileSelected) && (
                            <DocumentUpload
                                onSelected={documentUploadFileSelected || (() => {})}
                                buttonText=""
                                buttonIcon={ICON.ATTACH_HORIZONTAL}
                                toolTipText={documentUploadText}
                                styles="btn--green second-button"
                                multiple
                            />
                        )}
                        {(showButton && addButtonText && role !== UserRoles.INSURANCE_USER) && (
                        <Button
                            icon={ICON.PLUS}
                            iconPosition="right"
                            text={addButtonText}
                            callback={addButtonClickHandler}
                            styles={addButtonStyles || 'btn--green'}
                            disabled={isAddButtonDisabled}
                        />
                        )}
                    </div>
                )}
            </div>
        );
    }
}

export default withAuthenticationContext(withAuthorizationContext(withTranslationContext(withRouter(withQueryParams(Tabs)))));
