/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { Component } from 'react';
import axios from 'axios';
import { isEqual, omit } from 'lodash';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { ListOrder, MatchParams, RESULTS_PER_PAGE } from '../../../constants/misc';
import { TranslationContextInterface, withTranslationContext } from '../../controllers/translation/TranslationContext';
import {
    MessageContextInterface,
    withMessageContext,
} from '../../controllers/messages/MessageContext';
import TableHeader from '../../elements/TableHeader';
import TableCell, { TableCellType } from '../../elements/TableCell';
import TablePaging from '../../elements/TablePaging';
import { Dispute } from '../../../constants/types';
import { disputesURL, disputeURL } from '../../../services/disputes';
import { PRE_INSPECTIONS_EDIT_ROUTE } from '../../../constants/routes';
import withQueryParams, { WithQueryParamsProps } from '../../hocs/withQueryParams';

interface OwnProps extends RouteComponentProps<MatchParams>, TranslationContextInterface, MessageContextInterface, WithQueryParamsProps {}

interface OwnState {
    isFetching: boolean;
    data: Dispute[];
    totalResults: number;
}

const initialState: OwnState = {
    isFetching: false,
    data: [],
    totalResults: 0,
};

class DisputesListScreen extends Component<OwnProps, OwnState> {
    state = initialState;

    componentDidMount() {
        const { location, onQueryParamsChange } = this.props;

        onQueryParamsChange(location.search);
    }

    componentDidUpdate(prevProps: Readonly<OwnProps>, prevState: Readonly<OwnState>, snapshot?: any) {
        const { listParams, location, onQueryParamsChange } = this.props;
        const { listParams: oldListParams, location: oldLocation } = prevProps;

        if (location.search !== oldLocation.search) {
            onQueryParamsChange(location.search);
        }

        if (!isEqual(omit(listParams, ['search']), omit(oldListParams, ['search']))) {
            this.loadList();
        }
    }

    onDisputeClick = (dispute: Dispute) => {
        const { isFetching } = this.state;
        const { unreadMessageAndDisputes, history } = this.props;

        if (isFetching) return;

        this.setState({
            isFetching: true,
        });

        axios.get(disputeURL(String(dispute.id))).then(() => {
            this.setState({
                isFetching: false,
            });
            history.push(`${PRE_INSPECTIONS_EDIT_ROUTE}/${dispute.preInspectionId}`);
            unreadMessageAndDisputes();
        }).catch(() => {
            history.push(`${PRE_INSPECTIONS_EDIT_ROUTE}/${dispute.preInspectionId}`);
            this.setState({
                isFetching: false,
            });
        });
    };

    loadList = async () => {
        const {
            listParams,
        } = this.props;
        const { isFetching } = this.state;

        if (isFetching) return;

        try {
            const sent = false;
            this.setState({
                isFetching: true,
            });

            const { data, headers } = await axios.get(disputesURL({
                _page: listParams ? listParams.page - 1 : 0,
                _limit: RESULTS_PER_PAGE,
                _sort: listParams?.sort || 'createdDate',
                _order: listParams?.order || ListOrder.Desc,
                sent,
            }));

            const newTotalResults: number = parseInt(headers['x-total-count']);

            this.setState({
                data,
                totalResults: newTotalResults,
                isFetching: false,
            });
        } catch (error) {
            this.setState({
                isFetching: false,
            });
        }
    };

    render() {
        const {
            t, listParams,
        } = this.props;
        const { data, totalResults } = this.state;
        const hasData: boolean = data && data.length > 0;

        return (
            <div>
                <div className="app-screen__table">
                    <div className="app-screen__table__content">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>
                                        <TableHeader
                                            text={t('disputes.list.headers.preInspectionUID')}
                                            sort={listParams?.sort}
                                            order={listParams?.order}
                                            field="preInspectionUID"
                                            sortable
                                        />
                                    </th>
                                    <th>
                                        <TableHeader
                                            text={t('disputes.list.headers.createdDate')}
                                            sort={listParams?.sort}
                                            order={listParams?.order}
                                            field="createdDate"
                                            sortable
                                        />
                                    </th>
                                </tr>
                            </thead>
                            {hasData ? (
                                <tbody>
                                    { data.map(dispute => {
                                        const {
                                            preInspectionUID, createdDate, seen,
                                        } = dispute;

                                        return (
                                            <tr key={preInspectionUID} className={seen ? '' : 'not-seen'} onClick={() => this.onDisputeClick(dispute)}>
                                                <TableCell
                                                    value={preInspectionUID}
                                                />
                                                <TableCell
                                                    type={TableCellType.DATE}
                                                    value={createdDate}
                                                />
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            ) : (
                                <tbody className="no-data">
                                    <tr>
                                        <td colSpan={5}>{t('global.noData')}</td>
                                    </tr>
                                </tbody>
                            )}
                        </table>
                        {hasData && (
                            <TablePaging
                                currentPage={listParams?.page || 0}
                                limit={RESULTS_PER_PAGE}
                                totalResults={totalResults}
                            />
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

export default withMessageContext(withRouter(withTranslationContext(withQueryParams(DisputesListScreen))));
