/**
 *
 * @Copyright 2022 VOID SOFTWARE, S.A.
 *
 */

export enum ProviderTypes {
    GLASSDRIVE = 'GLASSDRIVE',
    EXPRESSGLASS = 'EXPRESSGLASS',
    CARGLASS = 'CARGLASS',
    NEWCAR = 'NEWCAR',
    CAETANOGLASS = 'CAETANOGLASS',
}
