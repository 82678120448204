/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { Component } from 'react';
import { TranslationContextInterface, withTranslationContext } from '../controllers/translation/TranslationContext';
import { ICON, SvgIcon } from './SvgIcon';

interface OwnProps extends TranslationContextInterface {
    onModalClose: Function;
    onAnswerCall: Function;
    casualtyUid: string;
    mechanicName: string;
    preInspectionUid: string;
}

class VideocallModal extends Component<OwnProps> {
    onClose = () => {
        const {
            onModalClose,
        } = this.props;
        onModalClose();
    };

    onAnswerCall = () => {
        const {
            onAnswerCall,
        } = this.props;
        onAnswerCall();
    }

    render() {
        const {
            t,
            mechanicName,
            casualtyUid,
            preInspectionUid,
        } = this.props;

        return (
            <div className="app-screen__modal">
                <div className="app-screen__modal__incoming-call">
                    <div className="app-screen__modal__container__box incoming-call">
                        <SvgIcon callback={this.onClose} icon={ICON.CROSS} />
                        <div className="app-screen__modal__container__box__content  incoming-call">
                            <div className="call-info">
                                <h3>{t('global.calling')}</h3>
                                {mechanicName && <p>{mechanicName}</p>}
                                <p className="small">{t('global.referring')}<b>{t('global.process', { processNumber: casualtyUid || preInspectionUid })}</b></p>
                            </div>
                            <div className="icon-container" data-testid="video-call-modal-answer" onClick={this.onAnswerCall}>
                                <SvgIcon icon={ICON.VIDEO_CAM_CALLING} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withTranslationContext(VideocallModal);
