/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

export const numberToPostalCode = (num: number) => {
    if (num) {
        return String(num).replace(/(\d{4})(\d{3})/, '$1-$2');
    }

    return '';
};

export const postalCodeToNumber = (pCode: string) => {
    if (pCode.length > 0) {
        return pCode.replace('-', '');
    }

    return '';
};
