/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { Component } from 'react';
import { connect } from 'react-redux';

import { AuthorizationContextProvider } from './AuthorizationContext';

import { AppState } from '../../../reducers/types';
import { RolePermission, PermissionKey } from '../../../constants/authorization';
import { PermissionOperators, checkPermission } from '../../../utils/authorization';

/**
 * @typedef {Object} StateProps
 * @property {RolePermission[]} permissions
 */
interface StateProps {
    permissions: RolePermission[];
}

/**
 * @typedef {Object} DispatchProps
 * @property {Function} requestPermissions
 */
interface DispatchProps {
    requestPermissions: Function;
}

/**
 * @typedef {Object} OwnProps
 * @extends {StateProps}
 * @property {any} children
 */
interface OwnProps extends StateProps {
    children: any;
}

/**
 * @typedef {Object} Props
 */
type Props = StateProps & OwnProps;

/**
 * @typedef {Object} OwnState
 */
interface OwnState {}

/**
 * @typedef {Object} State
 */
type State = OwnState;

/**
 * provides store properties and actions access to its consumers
 * @extends {Component<Props, State>}
 */
export class AuthorizationController extends Component<Props, State> {
    /**
     * get user permissions
     */
    getPermissions = () => {
        const { permissions } = this.props;
        return permissions;
    };

    checkPermission = (actions: Array<PermissionKey>, operator: PermissionOperators = PermissionOperators.OR): boolean => {
        const {
            permissions,
        } = this.props;

        return checkPermission(permissions, actions, operator);
    };

    render() {
        const {
            children,
            permissions,
        } = this.props;

        return (
            <AuthorizationContextProvider
                value={{
                    permissions,
                    getPermissions: this.getPermissions,
                    checkPermission: this.checkPermission,
                }}
            >
                {children}
            </AuthorizationContextProvider>
        );
    }
}

/**
 * mapStateToProps
 * @param {AppState} state
 * @returns {StateProps}
 */
const mapStateToProps = (state: AppState): StateProps => {
    return {
        permissions: state.authorization.permissions,
    };
};

export const ConnectedAuthorizationController = connect(mapStateToProps)(AuthorizationController);
