/**
 *
 * @Copyright 2022 VOID SOFTWARE, S.A.
 *
 */

import axios from 'axios';
import { get } from 'lodash';
import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { UserRoles } from '../../../constants/authorization';
import { MatchParams } from '../../../constants/misc';
import { NOT_FOUND_ROUTE, USER_GLASS_PROVIDER_DETAILS_ROUTE } from '../../../constants/routes';
import { glassProviderUserURL, usersToggleURL } from '../../../services/users';
import { displayNotification, NOTIFICATION_TYPE } from '../../../utils/notifs';
import { getFormErrors, IFormError, VALIDATIONS } from '../../../utils/validation';
import { AuthenticationContextInterface, withAuthenticationContext } from '../../controllers/authentication/AuthenticationContext';
import { TranslationContextInterface, withTranslationContext } from '../../controllers/translation/TranslationContext';
import { VideoContextInterface, withVideoContext } from '../../controllers/video/VideoContext';
import Button from '../../elements/Button';
import displayConfirm from '../../elements/displayConfirm';
import FormTextField from '../../elements/FormTextField';
import { ICON, SvgIcon } from '../../elements/SvgIcon';

interface OwnProps extends RouteComponentProps<MatchParams>, TranslationContextInterface, VideoContextInterface, AuthenticationContextInterface {}

interface OwnState {
    id: string;
    name: string;
    email: string;
    headOfficeEmail: string;
    district: string;
    nif: string;
    contact: string;
    role: string;
    isFetching: boolean;
    preparing: boolean;
    inactive: boolean;
    formErrors: any;
    hasModifications: boolean;
    viewingDetails: boolean;
    registered: boolean;
    isEditable: boolean;
}

const initialState: OwnState = {
    id: '',
    name: '',
    email: '',
    headOfficeEmail: '',
    contact: '',
    role: '',
    district: '',
    nif: '',
    isFetching: false,
    preparing: false,
    inactive: false,
    formErrors: {},
    hasModifications: false,
    viewingDetails: false,
    registered: false,
    isEditable: false,
};

class GlassProviderEditScreen extends Component<OwnProps, OwnState> {
    state = initialState;

    componentDidMount(): void {
        const { match, user } = this.props;
        const { params, path } = match;
        const { id } = params;

        let isEditable = false;

        if (user && (user.role === UserRoles.ADMIN)) {
            isEditable = true;
        }

        if (id) {
            let viewingDetails = false;
            if (path.startsWith(USER_GLASS_PROVIDER_DETAILS_ROUTE)) {
                viewingDetails = true;
            }

            this.setState({
                id,
                viewingDetails,
                isEditable,
            }, () => { this.prepare(); });
        }
    }

    onInputChange = (e: React.FormEvent<HTMLInputElement>): void => {
        const { state } = this;
        this.setState({
            ...state,
            [e.currentTarget.name]: e.currentTarget.value,
            hasModifications: true,
        });
    };

    onBackClick = () => {
        const { history } = this.props;
        history.goBack();
    };

    onSave = () => {
        if (this.validateForm()) {
            const {
                name,
                email,
                headOfficeEmail,
                contact,
                nif,
                district,
            } = this.state;

            const fields = {
                name: String(name).trim(),
                email: String(email).trim(),
                headOfficeEmail: String(headOfficeEmail).trim(),
                contact: String(contact).trim(),
                district: String(district).trim(),
                nif: nif !== '' ? String(nif).trim() : null,
            };

            const formData = new FormData();
            formData.append('userRequest', new Blob([JSON.stringify(fields)], {
                type: 'application/json',
            }));

            this.editUserRequest(formData);
        }
    };

    onToggleClick = () => {
        const { t } = this.props;
        const { id, inactive, name } = this.state;

        let msg: string = t('editUser.deactivateMessage', { name });
        if (inactive) msg = t('editUser.activateMessage', { name });

        displayConfirm({
            acceptButtonText: t('global.buttons.accept'),
            onAccept: () => { this.toggleRequest(id); },
            onReject: () => {},
            rejectButtonText: t('global.buttons.reject'),
            containerStyle: 'remove-document',
            title: msg,
        });
    };

    toggleRequest = async (id: number | string) => {
        const { t } = this.props;
        const { inactive: oldInactive } = this.state;

        this.setState({ isFetching: true });

        await axios.post(usersToggleURL(id))
            .then(response => {
                const { inactive } = response.data;

                if (inactive) {
                    displayNotification(NOTIFICATION_TYPE.SUCCESS, t('editUser.deactivateSuccess'));
                } else {
                    displayNotification(NOTIFICATION_TYPE.SUCCESS, t('editUser.activateSuccess'));
                }

                this.setState({
                    isFetching: false,
                    inactive,
                });
            })
            .catch(() => {
                if (oldInactive) {
                    displayNotification(NOTIFICATION_TYPE.ERROR, t('editUser.deactivateError'));
                } else {
                    displayNotification(NOTIFICATION_TYPE.ERROR, t('editUser.activateError'));
                }

                this.setState({ isFetching: false });
            });
    };

    validateForm = (): boolean => {
        const { state } = this;

        let errors: IFormError | null = getFormErrors(state, VALIDATIONS.USER_EDIT_FORM);

        if (errors && Object.keys(errors).length === 0) {
            errors = null;
        }

        this.setState({ formErrors: errors ? { fields: errors } : errors });
        return errors === null;
    };

    prepare = async (): Promise<void> => {
        const { history } = this.props;
        const { id, preparing } = this.state;

        if (preparing) return;

        this.setState({ preparing: true });

        await axios.get(glassProviderUserURL(id))
            .then(response => {
                const { data } = response;

                this.setState({
                    preparing: false,
                    id: data.id,
                    name: data.name,
                    email: data.email,
                    headOfficeEmail: data.headOfficeEmail,
                    contact: data.contact || '',
                    inactive: data.inactive,
                    role: data.role,
                    district: data.district || '',
                    nif: data.nif || '',
                    registered: data.registered,
                });
            })
            .catch(error => {
                if (error.response) {
                    const { status } = error.response;
                    if (status === 403) {
                        history.push(NOT_FOUND_ROUTE);
                    }
                }
                this.setState({ preparing: false });
            });
    }

    editUserRequest = async (fields: {}): Promise<void> => {
        const { t } = this.props;
        const { id } = this.state;

        this.setState({ isFetching: true });

        const config = {
            headers: {
                'content-type': 'multipart/form-data',
            },
        };

        axios.put(glassProviderUserURL(id), fields, config)
            .then(() => {
                displayNotification(NOTIFICATION_TYPE.SUCCESS, t('editUser.successNotif'));
                this.onBackClick();
                this.setState({
                    isFetching: false,
                });
            })
            .catch(error => {
                displayNotification(NOTIFICATION_TYPE.ERROR, t('editUser.errorNotif'));
                if (error.response) {
                    this.handleResponse(error.response.data);
                }
                this.setState({
                    isFetching: false,
                });
            });
    }

    handleResponse = (formErrors: any = null) => {
        if (formErrors && Object.keys(formErrors).length > 0) {
            this.setState({
                formErrors,
                isFetching: false,
            });
        } else {
            this.setState({ isFetching: false });
        }
    };

    renderStatusDot = (): JSX.Element => {
        const { id, registered, inactive } = this.state;
        const { onlineGlassProviderUsers } = this.props;

        if (!registered) {
            return (<div className="status-dot orange-circle" />);
        }

        if (inactive) {
            return (<div className="status-dot red-circle" />);
        }

        const isOnline = onlineGlassProviderUsers.includes(Number(id));
        
        return (<div className={isOnline ? ' status-dot green-circle' : 'status-dot yellow-circle'} />);
    }

    render() {
        const {
            name,
            inactive,
            isEditable,
            hasModifications,
            formErrors,
            role,
            contact,
            email,
            headOfficeEmail,
            district,
            nif,
        } = this.state;

        const {
            t,
        } = this.props;

        return (
            <div>
                <div className="app-screen__form-edit-user">
                    <div className="app-screen__form-edit-user__top-container">
                        <div className="info-container">
                            <SvgIcon icon={ICON.LEFT_ARROW} callback={this.onBackClick} />
                            <div className="info">
                                <p className="title">{t('editUser.smallTitle')}</p>
                                <p className="user-name" data-testid="user-name">{name}</p>
                                {this.renderStatusDot()}
                            </div>
                        </div>
                        {isEditable && (
                            <div className="buttons-container" data-testid="buttons-container">
                                <Button
                                    text={t('global.buttons.saveModifications')}
                                    type="submit"
                                    styles="btn--purple"
                                    callback={this.onSave}
                                    disabled={!hasModifications}
                                />
                                <Button
                                    text={inactive ? t('global.buttons.activate') : t('global.buttons.deactivate')}
                                    callback={this.onToggleClick}
                                    styles="btn--green"
                                    iconPosition="right"
                                />
                            </div>
                        )}
                    </div>
                    <div className="app-screen__form-edit-user__container">
                        <div className="app-screen__form-edit-user__container__content">
                            {/* PERSONAL DATA */}
                            <h3>{t('editUser.subtitles.personalData')}</h3>
                            <div className="row">
                                <div className="col-sm-4">
                                    <FormTextField
                                        fieldStyles="with-roboto"
                                        label={t('editUser.labels.name')}
                                        name="name"
                                        value={name}
                                        onChange={this.onInputChange}
                                        errors={get(formErrors, 'fields.name', null)}
                                        disabled={!isEditable}
                                    />
                                </div>
                                <div className="col-sm-4">
                                    <FormTextField
                                        fieldStyles="with-roboto"
                                        label={t('editUser.labels.nif')}
                                        name="nif"
                                        value={nif}
                                        onChange={this.onInputChange}
                                        errors={get(formErrors, 'fields.nif', null)}
                                        disabled={!isEditable}
                                    />
                                </div>
                                <div className="col-sm-4">
                                    <FormTextField
                                        fieldStyles="with-roboto"
                                        label={t('editUser.labels.type')}
                                        name="role"
                                        value={t(`enums.userRoles.${role}`)}
                                        disabled
                                    />
                                </div>
                            </div>
                            {/* CONTACT */}
                            <h3 className="subtitle-with-margin">{t('editUser.subtitles.contactData')}</h3>
                            <div className="row">
                                <div className="col-sm-4">
                                    <FormTextField
                                        fieldStyles="with-roboto"
                                        label={t('editUser.labels.contact')}
                                        name="contact"
                                        value={contact}
                                        onChange={this.onInputChange}
                                        errors={get(formErrors, 'fields.contact', null)}
                                        disabled={!isEditable}
                                    />
                                </div>
                                <div className="col-sm-4">
                                    <FormTextField
                                        fieldStyles="with-roboto"
                                        label={t('editUser.labels.emailMechanicQV')}
                                        name="email"
                                        value={email}
                                        disabled
                                    />
                                </div>
                                <div className="col-sm-4">
                                    <FormTextField
                                        fieldStyles="with-roboto"
                                        label={t('editUser.labels.district')}
                                        name="district"
                                        value={district}
                                        onChange={this.onInputChange}
                                        errors={get(formErrors, 'fields.district', null)}
                                        disabled={!isEditable}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-4">
                                    <FormTextField
                                        fieldStyles="with-roboto"
                                        label={t('editUser.labels.headOfficeEmail')}
                                        name="headOfficeEmail"
                                        value={headOfficeEmail}
                                        onChange={this.onInputChange}
                                        errors={get(formErrors, 'fields.headOfficeEmail', null)}
                                        disabled={!isEditable}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withAuthenticationContext(withVideoContext(withTranslationContext(GlassProviderEditScreen)));
