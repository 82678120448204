/**
 *
 * @Copyright 2022 VOID SOFTWARE, S.A.
 *
 */

import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';

import { ListResponse, User, UserOptionsResponse } from '../../../constants/types';
import { KeyedObject, SelectOption } from '../../../constants/misc';
import { usersURL, resendInvitesURL } from '../../../services/users';
import { UsersContextProvider } from './UsersContext';

interface OwnProps {
    children: React.ReactNode;
}

export class UsersController extends Component<OwnProps> {
    getUsers = async (parameters?: KeyedObject): Promise<ListResponse<User>> => {
        try {
            const { data, headers } = await axios.get(usersURL(parameters));

            const total: number = parseInt(headers['x-total-count']);

            return {
                results: data,
                total,
            };
        } catch {
            return {
                results: [],
                total: 0,
            };
        }
    };

    getUsersOptions = async (parameters?: KeyedObject): Promise<UserOptionsResponse> => {
        const res = await this.getUsers(parameters);

        const options: Array<SelectOption> = [];
        if (res.results.length > 0) {
            options.push({
                value: '',
                label: '',
            });

            Object.keys(res.results).forEach(key => {
                const user = res.results[Number(key)];

                options.push({
                    value: user.id,
                    label: user.name,
                });
            });
        }

        return {
            options,
            users: res.results,
        };
    }

    resendInvitesEmails = async (onSuccess: () => void, onFailure: (errorCode: number) => void): Promise<void> => {
        try {
            await axios.get(resendInvitesURL());

            onSuccess();
        } catch (err) {
            if (err.response.data) onFailure(err.response.data.errors[0].errorCode);
            onFailure(err.response.status);
        }
    }

    render(): React.ReactNode {
        const { children } = this.props;

        return (
            <UsersContextProvider
                value={{
                    getUsers: this.getUsers,
                    getUsersOptions: this.getUsersOptions,
                    resendInvitesEmails: this.resendInvitesEmails,
                }}
            >
                {children}
            </UsersContextProvider>
        );
    }
}

export const ConnectedUsersController = connect()(UsersController);
