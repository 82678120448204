/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { MessageContextProvider } from './MessageContext';
import { AppState } from '../../../reducers/types';
import { requestUnreadMessagesAndDisputes } from '../../../actions/messages';
import { AuthenticationContextInterface, withAuthenticationContext } from '../authentication/AuthenticationContext';
import { UserRoles } from '../../../constants/authorization';

/**
 * @typedef {Object} StateProps
 * @property {number} unseenMessages
 * @property {number} unseenDisputes
 * @property {boolean} isFetching
 */
interface StateProps {
    unseenMessages: number;
    unseenDisputes: number;
    isFetching: boolean;
}

/**
 * @typedef {Object} DispatchProps
 * @property {Function} requestUnreadMessages
 */
interface DispatchProps {
    requestUnreadMessagesAndDisputes: Function;
}

/**
 * @typedef {Object} OwnProps
 * @property {any} children
 */
interface OwnProps {
    children: any;
}

/**
 * @typedef {Object} Props
 */
type Props = StateProps & DispatchProps & AuthenticationContextInterface & OwnProps;

/**
 * @typedef {Object} OwnState
 */
interface OwnState {}

/**
 * @typedef {Object} State
 */
type State = OwnState;

/**
 * provides store properties and actions access to its consumers
 * @extends {Component<Props, State>}
 */
export class MessageController extends Component<Props, State> {
    /**
     * calls the unreadMessages and disputes action creator
     * @param {Function} callback
     */
    unreadMessageAndDisputes = () => {
        const {
            requestUnreadMessagesAndDisputes,
            user,
        } = this.props;
        requestUnreadMessagesAndDisputes(user?.role !== UserRoles.MECHANIC);
    };

    render() {
        const {
            children,
            unseenDisputes,
            unseenMessages,
            isFetching,
        } = this.props;

        return (
            <MessageContextProvider
                value={{
                    unseenDisputes,
                    unseenMessages,
                    isFetching,
                    unreadMessageAndDisputes: this.unreadMessageAndDisputes,
                }}
            >
                {children}
            </MessageContextProvider>
        );
    }
}

/**
 * mapStateToProps
 * @param {AppState} state
 * @returns {StateProps}
 */
const mapStateToProps = (state: AppState): StateProps => {
    return {
        unseenDisputes: state.message.unseenDisputes,
        unseenMessages: state.message.unseenMessages,
        isFetching: state.message.isFetching,
    };
};

/**
 * mapDispatchToProps
 * @param {Dispatch} dispatch
 * @returns {DispatchProps}
 */
export const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>): DispatchProps => ({
    requestUnreadMessagesAndDisputes: (getDisputes: boolean) => dispatch(requestUnreadMessagesAndDisputes(getDisputes)),
});

export const ConnectedMessageController = withAuthenticationContext(connect(mapStateToProps, mapDispatchToProps)(MessageController));
