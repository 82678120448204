/**
 *
 * @Copyright 2022 VOID SOFTWARE, S.A.
 *
 */

import React, { createContext, ComponentType, FC } from 'react';
import {
    ApiFile,
    BrokenGlassControlDocuments,
    BrokenGlassControlResponse,
    BrokenGlassControlSearchResponse,
    ListResponse,
} from '../../../constants/types';
import { ConnectedBrokenGlassControlController } from './BrokenGlassControlController';
import { KeyedObject } from '../../../constants/misc';

export interface BrokenGlassControlContext {
    getBrokenGlassControls(requestBody: object): Promise<ListResponse<BrokenGlassControlSearchResponse>>;
    createBrokenGlassControls(onSuccess: () => void, onFailure: () => void, requestBody: object): Promise<BrokenGlassControlResponse | null>;
    getBrokenGlassControlById(id: string | number): Promise<BrokenGlassControlResponse | null>;
    editBrokenGlassControl(onSuccess: (brokenGlassControl: BrokenGlassControlResponse) => void, onFailure: () => void, id: string | number, requestBody: KeyedObject): Promise<void>;
    resumeControlQV(id: number): Promise<string | null>;
    reportControlQV(id: number): Promise<any | null>;
    getBrokenGlassControlDocuments(id: number): Promise<BrokenGlassControlDocuments | null>;
    getBrokenGlassDocument(id: number, fileId: number | null): Promise<ApiFile | null>;
    importFile(formData: FormData, onSuccess: () => void, onFailure: (errorCode: number) => void): Promise<void>;
}

export const brokenGlassControlContextDefaultValue = {
    getBrokenGlassControls: async (): Promise<any> => [],
    createBrokenGlassControls: async (): Promise<BrokenGlassControlResponse | null> => null,
    getBrokenGlassControlById: async (): Promise<BrokenGlassControlResponse | null> => null,
    editBrokenGlassControl: async (): Promise<void> => {},
    resumeControlQV: async (): Promise<null> => null,
    reportControlQV: async (): Promise<null> => null,
    getBrokenGlassControlDocuments: async (): Promise<null> => null,
    getBrokenGlassDocument: async (): Promise<null> => null,
    importFile: async (): Promise<void> => {},
};

const BrokenGlassControlContextInstance = createContext<BrokenGlassControlContext | null>(brokenGlassControlContextDefaultValue);

export const BrokenGlassControlContextProvider = BrokenGlassControlContextInstance.Provider;
export const BrokenGlassControlContextConsumer = BrokenGlassControlContextInstance.Consumer;

export const withBrokenGlassControlContext = <P extends object>(
    Component: ComponentType<P>,
): FC<Omit<P, keyof BrokenGlassControlContext>> => props => (
    <ConnectedBrokenGlassControlController>
        <BrokenGlassControlContextConsumer>
            { ctx => <Component {...props as P} {...ctx} />}
        </BrokenGlassControlContextConsumer>
    </ConnectedBrokenGlassControlController>
    );
