/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { Component } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { MatchParams, TabItem } from '../../../constants/misc';
import { TranslationContextInterface, withTranslationContext } from '../../controllers/translation/TranslationContext';
import { PRE_INSPECTIONS_TABS_ITEM, PreInspectionsTabsEnum } from '../../../constants/tabs';
import Tabs from '../../elements/Tabs';
import PreInspectionsListScreen from './PreInspectionsListScreen';
import {
    PRE_INSPECTIONS_COMPLETE_LIST_ROUTE,
    PRE_INSPECTIONS_GLASS_LIST_ROUTE,
    PRE_INSPECTIONS_LIST_ROUTE,
    PRE_INSPECTIONS_CREATE_ROUTE,
} from '../../../constants/routes';
import { ICON } from '../../elements/SvgIcon';

interface OwnProps extends RouteComponentProps<MatchParams>, TranslationContextInterface {}
 
interface OwnState {
    tabItemSelected: string;
    tabItems: TabItem[];
    addButtonText?: string;
    secondAddButtonText?: string;
    searchValue: string;
}

const initialState: OwnState = {
    tabItemSelected: PreInspectionsTabsEnum.ALL,
    tabItems: [],
    addButtonText: undefined,
    secondAddButtonText: undefined,
    searchValue: '',
};

class PreInspectionsScreen extends Component<OwnProps, OwnState> {
    state = initialState;

    componentDidMount(): void {
        const {
            t, match,
        } = this.props;
        const { path } = match;
        let addButtonText: string | undefined = t('preInspections.list.addGlassInspectionBtn');
        let secondAddButtonText: string | undefined = t('preInspections.list.addPreInspectionBtn');

        let tabItemSelected = PreInspectionsTabsEnum.ALL;
        if (path === PRE_INSPECTIONS_GLASS_LIST_ROUTE) {
            tabItemSelected = PreInspectionsTabsEnum.GLASS;
            secondAddButtonText = undefined;
        } else if (path === PRE_INSPECTIONS_COMPLETE_LIST_ROUTE) {
            tabItemSelected = PreInspectionsTabsEnum.COMPLETE;
            addButtonText = undefined;
        }

        const tabItems: TabItem[] = [];

        Object.keys(PRE_INSPECTIONS_TABS_ITEM).forEach(k => {
            const i = PRE_INSPECTIONS_TABS_ITEM[Number(k)];

            const item: TabItem = {
                value: i,
                text: t(`preInspections.list.tabItems.${i}`),
            };

            tabItems.push(item);
        });

        this.setState({
            tabItems,
            tabItemSelected,
            addButtonText,
            secondAddButtonText,
        });
    }

    componentDidUpdate(prevProps: Readonly<OwnProps>, prevState: Readonly<OwnState>, snapshot?: any): void {
        const {
            match: oldMatch,
        } = prevProps;
        const {
            match, t,
        } = this.props;
        const { path: oldPath } = oldMatch;
        const { path } = match;
        let addButtonText: string | undefined = t('preInspections.list.addGlassInspectionBtn');
        let secondAddButtonText: string | undefined = t('preInspections.list.addPreInspectionBtn');

        if (path !== oldPath) {
            let tabItemSelected = PreInspectionsTabsEnum.ALL;
            if (path === PRE_INSPECTIONS_GLASS_LIST_ROUTE) {
                tabItemSelected = PreInspectionsTabsEnum.GLASS;
                secondAddButtonText = undefined;
            } else if (path === PRE_INSPECTIONS_COMPLETE_LIST_ROUTE) {
                tabItemSelected = PreInspectionsTabsEnum.COMPLETE;
                addButtonText = undefined;
            }

            this.setState({
                tabItemSelected,
                addButtonText,
                secondAddButtonText,
            });
        }
    }

    onTabChange = (newTab: string) => {
        const { history } = this.props;
        switch (newTab) {
            case PreInspectionsTabsEnum.COMPLETE:
                history.push(PRE_INSPECTIONS_COMPLETE_LIST_ROUTE);
                break;
            case PreInspectionsTabsEnum.GLASS:
                history.push(PRE_INSPECTIONS_GLASS_LIST_ROUTE);
                break;
            case PreInspectionsTabsEnum.ALL:
                history.push(PRE_INSPECTIONS_LIST_ROUTE);
                break;
            default:
        }

        this.setState({
            tabItemSelected: newTab,
        });
    };

    addPreInspectionClick = () => {
        const { history } = this.props;
        history.push(`${PRE_INSPECTIONS_CREATE_ROUTE}/pre-inspection`);
    }

    addGlassInpectionClick = () => {
        const { history } = this.props;
        history.push(`${PRE_INSPECTIONS_CREATE_ROUTE}/glass`);
    }

    render() {
        const {
            tabItems, tabItemSelected, addButtonText, secondAddButtonText,
        } = this.state;

        return (
            <div>
                <Tabs
                    tabsItems={tabItems}
                    onTabChange={this.onTabChange}
                    withSearch
                    tabSelected={tabItemSelected}
                    addButtonText={addButtonText}
                    addButtonClickHandler={this.addGlassInpectionClick}
                    secondAddButtonText={secondAddButtonText}
                    secondButtonIcon={ICON.PLUS}
                    onSecondAddButtonClickHandler={this.addPreInspectionClick}
                />
                <PreInspectionsListScreen
                    tabItem={tabItemSelected}
                />
            </div>
        );
    }
}

export default withTranslationContext(withRouter(PreInspectionsScreen));
