/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { Component } from 'react';
import ErrorMessage from './ErrorMessage';
import { SelectOption } from '../../constants/misc';

interface OwnProps {
    name: string;
    value: string | number | null;
    onChange: Function;
    label?: string;
    containerStyles?: string;
    fieldStyles?: string;
    options: Array<SelectOption>;
    errors?: any;
    labelTitle?: string;
}

class FormSelectField extends Component<OwnProps> {
    renderErrors = () => {
        const { name, errors } = this.props;

        return (
            <ErrorMessage errors={errors} field={name} />
        );
    };

    render() {
        const {
            name, value, onChange, label, containerStyles, fieldStyles, options, errors, labelTitle,
        } = this.props;
        const containerClass = containerStyles || '';
        const fieldClass = fieldStyles || '';

        return (
            <div className={`field-container ${containerClass}`}>
                {label && (
                    <div
                        className="field-container__label"
                        title={labelTitle || ''}
                    >
                        {label}
                    </div>
                )}
                <div className={`field-container__select-field ${fieldClass}`}>
                    <select name={name} value={value || ''} onChange={e => onChange(e)}>
                        {Object.keys(options).map(o => {
                            const opt = options[Number(o)];

                            return (
                                <option key={opt.value} value={opt.value}> {opt.label} </option>
                            );
                        })}
                    </select>
                </div>
                <p>{errors && this.renderErrors()}</p>
            </div>
        );
    }
}

export default FormSelectField;
