/**
 *
 * @Copyright 2022 VOID SOFTWARE, S.A.
 *
 */

import React, { Component } from 'react';

import { Link, RouteComponentProps } from 'react-router-dom';
import { BrokenGlassControlOrigin, MatchParams } from '../../../constants/misc';
import {
    AuthenticationContextInterface,
    withAuthenticationContext,
} from '../../controllers/authentication/AuthenticationContext';
import {
    BrokenGlassControlContext,
    withBrokenGlassControlContext,
} from '../../controllers/brokenGlass/BrokenGlassControlContext';
import { TranslationContextInterface, withTranslationContext } from '../../controllers/translation/TranslationContext';
import { AuthorizationContextInterface, withAuthorizationContext } from '../../controllers/authorization/AuthorizationContext';
import {
    ApiFile,
    BrokenGlassControlImages,
    BrokenGlassControlResponse,
    BrokenGlassControlStatus,
} from '../../../constants/types';
import Loader from '../../elements/Loader';
import { ICON, SvgIcon } from '../../elements/SvgIcon';
import Button from '../../elements/Button';
import FormTextField from '../../elements/FormTextField';
import FormDatePicker from '../../elements/FormDatePicker';
import { displayNotification, NOTIFICATION_TYPE } from '../../../utils/notifs';
import {
    BROKEN_GLASS_CONTROL_DETAILS_ROUTE,
    USER_GLASS_PROVIDER_EDIT_ROUTE,
    USER_INSURANCE_DETAILS_ROUTE,
    USER_INSURANCE_EDIT_ROUTE,
} from '../../../constants/routes';
import { checkPermission } from '../../../utils/authorization';
import { PermissionKey } from '../../../constants/authorization';
import { getBrokenGlassControlMobileUrl, getFilemaneFromContentDisposition } from '../../../utils/misc';
import DrawImageModal from '../../elements/DrawImageModal';
import Can from '../../containers/Can';

interface OwnProps extends RouteComponentProps<MatchParams>, TranslationContextInterface, AuthenticationContextInterface, AuthorizationContextInterface, BrokenGlassControlContext {}

interface OwnState {
    id: string | null;
    isFetching: boolean;
    uid: string | null;
    status: BrokenGlassControlStatus | null;
    insuranceCompanyName: string;
    insuranceCompanyId: string | number;
    glassProviderId: string | number;
    glassProviderName: string;
    vehicleBrandLabel: string | null;
    vehicleModelLabel: string | null;
    vehicleLicensePlateLabel: string | null;
    insurancePolicyNumberLabel: string | null;
    controlDate: string | null;
    doneDate: string | null;
    glassProviderDistrict: string;
    glassProviderContact: number | null;
    glassProviderEmail: string;
    insurancePolicyNumber: string | null;
    vehicleLicensePlate: string | null;
    vehicleBrand: string | null;
    vehicleModel: string | null;
    hasModifications: boolean;
    showImageModal: boolean;
    isDetails: boolean;
    imageToShowLabel: string;
    imageToShowFile: ApiFile | null;
    checkInImages: BrokenGlassControlImages;
    checkOutImages: BrokenGlassControlImages;
    checkInAdditionalPhotos: Array<ApiFile | null>;
    checkOutAdditionalPhotos: Array<ApiFile | null>;
}

const initialState: OwnState = {
    id: null,
    isFetching: false,
    uid: null,
    status: null,
    insuranceCompanyName: '',
    insuranceCompanyId: '',
    glassProviderId: '',
    glassProviderName: '',
    vehicleBrandLabel: null,
    vehicleModelLabel: null,
    vehicleLicensePlateLabel: null,
    insurancePolicyNumberLabel: null,
    controlDate: null,
    doneDate: null,
    glassProviderDistrict: '',
    glassProviderContact: null,
    glassProviderEmail: '',
    insurancePolicyNumber: null,
    vehicleLicensePlate: null,
    vehicleBrand: null,
    vehicleModel: null,
    hasModifications: false,
    showImageModal: false,
    isDetails: false,
    imageToShowLabel: '',
    imageToShowFile: null,
    checkInAdditionalPhotos: [],
    checkOutAdditionalPhotos: [],
    checkInImages: {
        frontBody: null,
        frontRightWindshield: null,
        frontLeftWindshield: null,
        frontWindshield: null,
        serigraphy: null,
    },
    checkOutImages: {
        frontBody: null,
        frontRightWindshield: null,
        frontLeftWindshield: null,
        frontWindshield: null,
        serigraphy: null,
    },
};

class BrokenGlassControlFormScreen extends Component<OwnProps, OwnState> {
    state = { ...initialState };

    componentDidMount(): void {
        const { match } = this.props;
        const { id } = match.params;

        this.setState({
            id: id || null,
            isFetching: true,
            isDetails: match.path.startsWith(BROKEN_GLASS_CONTROL_DETAILS_ROUTE),
        }, () => {
            this.prepare();
        });
    }

    onBackClick = (): void => {
        const { history } = this.props;
        history.goBack();
    }

    onSuccessEdit = (brokenGlassControl: BrokenGlassControlResponse): void => {
        const { t } = this.props;
        displayNotification(NOTIFICATION_TYPE.SUCCESS, t('brokenGlassControl.form.editSuccessControlNotif'));

        this.fillState(brokenGlassControl);
    }

    onFailureEdit = (): void => {
        const { t } = this.props;
        displayNotification(NOTIFICATION_TYPE.ERROR, t('brokenGlassControl.form.editErrorControlNotif'));
    }
    
    onSaveChanges = async (): Promise<void> => {
        const { editBrokenGlassControl } = this.props;
        const {
            id, insurancePolicyNumber, vehicleBrand, vehicleModel, vehicleLicensePlate,
        } = this.state;

        const insuredInfo = {
            vehicleBrand,
            vehicleModel,
            vehicleLicensePlate,
            insurancePolicyNumber,
        };

        this.setState({ isFetching: true });

        await editBrokenGlassControl(this.onSuccessEdit, this.onFailureEdit, id || '', insuredInfo);

        this.setState({ isFetching: false });
    }

    onInputChange = (e: React.FormEvent<HTMLInputElement>): void => {
        const { name: inputName, value: inputValue } = e.currentTarget;

        this.setState(prevState => ({
            ...prevState,
            [inputName]: inputValue,
        }), () => this.checkHasModifications());
    }

    checkHasModifications = (): void => {
        const {
            vehicleBrandLabel,
            vehicleModelLabel,
            vehicleLicensePlateLabel,
            vehicleLicensePlate,
            vehicleBrand,
            vehicleModel,
            insurancePolicyNumber,
            insurancePolicyNumberLabel,
        } = this.state;

        const hasModifications = vehicleBrand !== vehicleBrandLabel
        || vehicleModel !== vehicleModelLabel
        || vehicleLicensePlate !== vehicleLicensePlateLabel
        || insurancePolicyNumber !== insurancePolicyNumberLabel;
        
        this.setState({
            hasModifications,
        });
    }

    reportControlQV = async (): Promise<void> => {
        const { reportControlQV, t } = this.props;
        const { id } = this.state;
        
        this.setState({ isFetching: true });

        try {
            const { data, headers } = await reportControlQV(Number(id));
            const mimetype = headers['content-type'];
            const filename = getFilemaneFromContentDisposition(headers['content-disposition']);
            const blob = new Blob([data], { type: mimetype });
            const url = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', filename);
            document.body.appendChild(link);
            link.click();
            URL.revokeObjectURL(url);
        } catch (e) {
            displayNotification(NOTIFICATION_TYPE.ERROR, t('errors.generatingResume'));
        }
        this.setState({ isFetching: false });
    }

    async prepare(): Promise<void> {
        const { getBrokenGlassControlById } = this.props;
        const { id } = this.state;

        try {
            if (id) {
                const brokenGlassControl: BrokenGlassControlResponse | null = await getBrokenGlassControlById(id);
                if (brokenGlassControl) {
                    this.fillState(brokenGlassControl);
                    this.fetchImages(Number(id));
                }
            }
        } catch {}

        this.setState({
            isFetching: false,
        });
    }

    fetchImages = async (brokenGlassControlId: number): Promise<void> => {
        const { getBrokenGlassControlDocuments, getBrokenGlassDocument } = this.props;

        const documents = await getBrokenGlassControlDocuments(brokenGlassControlId);

        if (!documents) return;

        this.setState({
            isFetching: true,
        });

        const checkInImagesToFetch: Array<number | null> = [];
        const checkOutImagesToFetch: Array<number | null> = [];
        const {
            checkInFrontBodyPhotoId, checkInSerigraphyPhotoId, checkInFrontRightWindshieldPhotoId, checkInFrontLeftWindshieldPhotoId,
            checkOutFrontWindshieldPhotoId, checkInAdditionalPhotosIds, checkOutAdditionalPhotosIds, checkInFrontWindshieldPhotoId,
            checkOutFrontBodyPhotoId, checkOutFrontLeftWindshieldPhotoId, checkOutFrontRightWindshieldPhotoId, checkOutSerigraphyPhotoId,
        } = documents;

        checkInImagesToFetch.push(
            checkInFrontBodyPhotoId, checkInFrontRightWindshieldPhotoId, checkInFrontWindshieldPhotoId,
            checkInFrontLeftWindshieldPhotoId, checkInSerigraphyPhotoId, ...checkInAdditionalPhotosIds,
        );

        checkOutImagesToFetch.push(
            checkOutFrontBodyPhotoId, checkOutFrontRightWindshieldPhotoId, checkOutFrontWindshieldPhotoId,
            checkOutFrontLeftWindshieldPhotoId, checkOutSerigraphyPhotoId, ...checkOutAdditionalPhotosIds,
        );

        const [
            checkInFrontBodyPhoto, checkInFrontRightWindshieldPhoto, checkInFrontWindshieldPhoto,
            checkInFrontLeftWindshieldPhoto, checkInSerigraphyPhoto, ...checkInAdditionalPhotos
        ] = await Promise.all(checkInImagesToFetch.map(id => getBrokenGlassDocument(brokenGlassControlId, id)));

        const [
            checkOutFrontBodyPhoto, checkOutFrontRightWindshieldPhoto, checkOutFrontWindshieldPhoto,
            checkOutFrontLeftWindshieldPhoto, checkOutSerigraphyPhoto, ...checkOutAdditionalPhotos
        ] = await Promise.all(checkOutImagesToFetch.map(id => getBrokenGlassDocument(brokenGlassControlId, id)));

        this.setState({
            isFetching: false,
            checkInAdditionalPhotos: [...checkInAdditionalPhotos],
            checkOutAdditionalPhotos: [...checkOutAdditionalPhotos],
            checkInImages: {
                frontBody: checkInFrontBodyPhoto,
                frontRightWindshield: checkInFrontRightWindshieldPhoto,
                frontLeftWindshield: checkInFrontLeftWindshieldPhoto,
                frontWindshield: checkInFrontWindshieldPhoto,
                serigraphy: checkInSerigraphyPhoto,
            },
            checkOutImages: {
                frontBody: checkOutFrontBodyPhoto,
                frontRightWindshield: checkOutFrontRightWindshieldPhoto,
                frontLeftWindshield: checkOutFrontLeftWindshieldPhoto,
                frontWindshield: checkOutFrontWindshieldPhoto,
                serigraphy: checkOutSerigraphyPhoto,
            },
        });
    }

    openImageModal = (file: ApiFile, label: string): void => {
        this.setState({
            imageToShowLabel: label,
            showImageModal: true,
            imageToShowFile: file,
        });
    }

    closeImageModal = (): void => {
        this.setState({
            showImageModal: false,
            imageToShowFile: null,
        });
    }

    fillState(brokenGlassControl: BrokenGlassControlResponse): void {
        const {
            uid, vehicleLicensePlate, vehicleBrand, vehicleModel, controlDate, doneDate, insuranceCompany, glassProvider, insurancePolicyNumber, status,
        } = brokenGlassControl;

        this.setState({
            uid,
            vehicleLicensePlate,
            vehicleBrand,
            vehicleModel,
            controlDate,
            doneDate,
            insurancePolicyNumber,
            status,
            glassProviderContact: glassProvider.contact,
            glassProviderDistrict: glassProvider.district,
            glassProviderEmail: glassProvider.email,
            glassProviderName: glassProvider.name,
            glassProviderId: glassProvider.id,
            insuranceCompanyName: insuranceCompany.name,
            insuranceCompanyId: insuranceCompany.id,
            insurancePolicyNumberLabel: insurancePolicyNumber,
            vehicleBrandLabel: vehicleBrand,
            vehicleModelLabel: vehicleModel,
            vehicleLicensePlateLabel: vehicleLicensePlate,
            hasModifications: false,
        });
    }

    renderControlStatus(): React.ReactNode {
        const { status } = this.state;

        let dotClassName = '';
        switch (status) {
            case BrokenGlassControlStatus.ONGOING:
                dotClassName = 'green-circle';
                break;
            case BrokenGlassControlStatus.DONE:
                dotClassName = 'red-circle';
                break;
            case BrokenGlassControlStatus.PENDING:
            default:
                dotClassName = 'yellow-circle';
        }

        return (
            <div className={`status-dot ${dotClassName}`} data-testid="control-status" />
        );
    }

    renderVehicleInfo(): React.ReactNode {
        const { t } = this.props;
        const { vehicleBrandLabel, vehicleModelLabel, vehicleLicensePlateLabel } = this.state;

        const showIconAboveBrand = !!vehicleBrandLabel;
        const showIconAboveModel = !showIconAboveBrand && vehicleModelLabel;
        const showIconAboveLicensePlate = !showIconAboveBrand && !showIconAboveModel && vehicleLicensePlateLabel;

        return (
            <>
                { vehicleBrandLabel && (
                    <div className="info-item" data-testid="control-vehicle-brand">
                        {showIconAboveBrand && <SvgIcon icon={ICON.CAR} />}
                        <p className="label">{t('brokenGlassControl.form.labels.vehicleBrand')}</p>
                        <p>{vehicleBrandLabel}</p>
                    </div>
                )}
                { vehicleModelLabel && (
                    <div className={`info-item ${showIconAboveBrand ? 'smaller-margin' : ''}`} data-testid="control-vehicle-model">
                        {showIconAboveModel && <SvgIcon icon={ICON.CAR} />}
                        <p className="label">{t('brokenGlassControl.form.labels.vehicleModel')}</p>
                        <p>{vehicleModelLabel}</p>
                    </div>
                )}
                { vehicleLicensePlateLabel && (
                    <div className={`info-item ${showIconAboveModel ? 'smaller-margin' : ''}`} data-testid="control-vehicle-license-plate">
                        {showIconAboveLicensePlate && <SvgIcon icon={ICON.CAR} />}
                        <p className="label">{t('brokenGlassControl.form.labels.vehicleLicensePlate')}</p>
                        <p>{vehicleLicensePlateLabel}</p>
                    </div>
                )}
            </>
        );
    }

    renderImg = (file: ApiFile | null, label: string) => {
        if (!file) return null;
        return (
            <div key={file.id} className="image-container" onClick={() => this.openImageModal(file, label)}>
                <img src={file.fileString} alt={label} />
            </div>
        );
    }

    render(): React.ReactNode {
        const {
            t, permissions, token,
        } = this.props;
        const {
            isFetching,
            uid,
            id,
            insuranceCompanyId,
            insuranceCompanyName,
            glassProviderId,
            glassProviderName,
            glassProviderDistrict,
            controlDate,
            doneDate,
            glassProviderContact,
            glassProviderEmail,
            insurancePolicyNumber,
            vehicleLicensePlate,
            vehicleBrand,
            vehicleModel,
            hasModifications,
            status,
            checkInImages,
            checkInAdditionalPhotos,
            showImageModal,
            imageToShowFile,
            imageToShowLabel,
            checkOutAdditionalPhotos,
            checkOutImages,
            isDetails,
        } = this.state;

        let insuranceToRoute: string | null = null;
        if (checkPermission(permissions, [PermissionKey.ALL, PermissionKey.VIEW_USERS])) {
            insuranceToRoute = `${USER_INSURANCE_EDIT_ROUTE}/${insuranceCompanyId}`;
        } else if (checkPermission(permissions, [PermissionKey.VIEW_INSURANCE_DETAILS])) {
            insuranceToRoute = `${USER_INSURANCE_DETAILS_ROUTE}/${insuranceCompanyId}`;
        }

        let glassProviderToRoute: string | null = null;
        if (checkPermission(permissions, [PermissionKey.ALL, PermissionKey.VIEW_USERS])) {
            glassProviderToRoute = `${USER_GLASS_PROVIDER_EDIT_ROUTE}/${glassProviderId}`;
        }

        let buttonText: string | null = null;
        let buttonStyles: string | null = null;
        let mobileUrl = '';
        if (status === BrokenGlassControlStatus.PENDING) {
            buttonText = t('brokenGlassControl.checkIn');
            buttonStyles = 'btn--purple__check-in';
            mobileUrl = '/check-in';
        } else if (status === BrokenGlassControlStatus.ONGOING) {
            buttonText = t('brokenGlassControl.checkOut');
            buttonStyles = 'btn--purple__check-out';
            mobileUrl = '/check-out';
        }

        return (
            <div data-testid="brokenGlassControlFormScreen">
                {isFetching && (
                    <div className="loader-wrapper" data-testid="loader-wrapper">
                        <Loader />
                    </div>
                )}
                {showImageModal && imageToShowFile && (
                    <DrawImageModal
                        onModalClose={this.closeImageModal}
                        imageFile={imageToShowFile}
                        imgLabel={imageToShowLabel}
                        isEditable={false}
                        imgId={imageToShowFile.id}
                    />
                )}
                <div className="app-screen__form">
                    <div className="app-screen__form__left-container">
                        <div className="app-screen__form__left-container__info-container">
                            {/* BG CONTROL INFO */}
                            <div className="info-content" data-testid="control-info-header">
                                <SvgIcon icon={ICON.LEFT_ARROW} callback={this.onBackClick} />
                                <div className="info" data-testid="control-info">
                                    {uid && <p>{t('brokenGlassControl.form.labels.process')}</p>}
                                    <h4>{uid || ''}</h4>
                                </div>
                            </div>
                            {this.renderControlStatus()}
                        </div>
                        {!isDetails && (
                            <div className="button-container" data-testid="control-save-changes-container">
                                <Button
                                    text={t('global.buttons.saveModifications')}
                                    styles="btn--purple"
                                    callback={this.onSaveChanges}
                                    disabled={!hasModifications || isFetching}
                                />
                            </div>
                        )}
                        <div className="button-container" data-testid="control-report-container">
                            <Button
                                text={t('global.buttons.reportBGControl')}
                                styles="btn--green btn--icon-center btn--bigger"
                                icon={ICON.PDF}
                                callback={(): Promise<void> => this.reportControlQV()}
                            />
                        </div>
                        <div className="app-screen__form__left-container__centered-info">
                            {/* INSURANCE NAME */}
                            <div className="info-item" data-testid="control-client-name">
                                <SvgIcon icon={ICON.ACCOUNT_BOX} />
                                <p className="label">{t('brokenGlassControl.form.labels.client')}</p>
                                {insuranceToRoute ? (
                                    <Link to={insuranceToRoute}>
                                        <p>{insuranceCompanyName}</p>
                                    </Link>
                                ) : (<p>{insuranceCompanyName}</p>)}
                            </div>
                            {/* GLASS PROVIDER NAME */}
                            <div className="info-item" data-testid="control-provider-name">
                                <p className="label">{t('brokenGlassControl.form.labels.glassProvider')}</p>
                                {glassProviderToRoute
                                    ? (
                                        <Link to={glassProviderToRoute}>
                                            <p>{glassProviderName}</p>
                                        </Link>
                                    ) : (<p>{glassProviderName}</p>)}
                            </div>
                            {/* VEHICLE INFO */}
                            { this.renderVehicleInfo() }
                            <div className="info-item" />
                        </div>
                    </div>
                    <div className="app-screen__form__form-container form-content create-edit-form">
                        <Can
                            actions={[PermissionKey.ALL, PermissionKey.BROKEN_GLASS_CONTROL_CHECK_IN, PermissionKey.BROKEN_GLASS_CONTROL_CHECK_OUT]}
                            yes={() => {
                                if (!buttonText || !status) return null;

                                return (
                                    <div className="button-container" data-testid="button-container">
                                        <form
                                            id="thisForm"
                                            method="POST"
                                            action={getBrokenGlassControlMobileUrl(status)}
                                        >
                                            <input type="hidden" name="brokenGlassControlId" value={id || ''} />
                                            <input type="hidden" name="token" value={token || ''} />
                                            <input type="hidden" name="origin" value={BrokenGlassControlOrigin.EditPage} />
                                            <input type="hidden" name="url" value={mobileUrl} />
                                            <Button
                                                type="submit"
                                                text={buttonText}
                                                styles={`btn--purple ${buttonStyles || ''}`}
                                            />
                                        </form>
                                    </div>
                                );
                            }}
                        />
                        <div className="app-screen__form__form-container form-content without-tabs pre-inspection-form ">
                            {/* INSURANCE */}
                            <h3>{t('brokenGlassControl.form.labels.client')}</h3>
                            <div className="row">
                                <div className="col-sm-3 p-l-0">
                                    <FormTextField
                                        label={t('brokenGlassControl.form.labels.insurance')}
                                        name="insuranceCompanyId"
                                        value={insuranceCompanyName}
                                        disabled
                                    />
                                </div>
                                <div className="col-sm-3" />
                                <div className="col-sm-3">
                                    <FormDatePicker
                                        label={t('brokenGlassControl.form.labels.startDate')}
                                        name="controlDate"
                                        value={controlDate ? new Date(controlDate) : null}
                                        onChange={() => {}}
                                        disabled
                                    />
                                </div>
                                <div className="col-sm-3 p-r-0">
                                    <FormDatePicker
                                        label={t('brokenGlassControl.form.labels.endDate')}
                                        name="doneDate"
                                        value={doneDate ? new Date(doneDate) : null}
                                        onChange={() => {}}
                                        disabled
                                    />
                                </div>
                            </div>
                            {/* GLASS PROVIDER */}
                            <h3>{t('brokenGlassControl.form.labels.glassProvider')}</h3>
                            <div className="row">
                                <div className="col-sm-3 p-l-0">
                                    <FormTextField
                                        label={t('brokenGlassControl.form.labels.glassProviderName')}
                                        name="glassProviderName"
                                        value={glassProviderName}
                                        disabled
                                    />
                                </div>
                                <div className="col-sm-3">
                                    <FormTextField
                                        label={t('brokenGlassControl.form.labels.glassProviderDistrict')}
                                        name="glassProviderDistrict"
                                        value={glassProviderDistrict}
                                        disabled
                                    />
                                </div>
                                <div className="col-sm-3">
                                    <FormTextField
                                        label={t('brokenGlassControl.form.labels.glassProviderContact')}
                                        name="glassProviderContact"
                                        value={glassProviderContact}
                                        disabled
                                    />
                                </div>
                                <div className="col-sm-3 p-r-0">
                                    <FormTextField
                                        label={t('brokenGlassControl.form.labels.glassProviderEmail')}
                                        name="glassProviderEmail"
                                        value={glassProviderEmail}
                                        disabled
                                    />
                                </div>
                            </div>
                            {/* INSURED */}
                            <h3>{t('brokenGlassControl.form.labels.insured')}</h3>
                            <div className="row">
                                <div className="col-sm-3 p-l-0">
                                    <FormTextField
                                        label={t('brokenGlassControl.form.labels.insurancePolicyNumber')}
                                        name="insurancePolicyNumber"
                                        value={insurancePolicyNumber}
                                        onChange={this.onInputChange}
                                        disabled={isDetails}
                                    />
                                </div>
                                <div className="col-sm-3">
                                    <FormTextField
                                        label={t('brokenGlassControl.form.labels.vehicleLicensePlate')}
                                        name="vehicleLicensePlate"
                                        value={vehicleLicensePlate}
                                        onChange={this.onInputChange}
                                        disabled={isDetails}
                                    />
                                </div>
                                <div className="col-sm-3">
                                    <FormTextField
                                        label={t('brokenGlassControl.form.labels.vehicleBrand')}
                                        name="vehicleBrand"
                                        value={vehicleBrand}
                                        onChange={this.onInputChange}
                                        disabled={isDetails}
                                    />
                                </div>
                                <div className="col-sm-3 p-r-0">
                                    <FormTextField
                                        label={t('brokenGlassControl.form.labels.vehicleModel')}
                                        name="vehicleModel"
                                        value={vehicleModel}
                                        onChange={this.onInputChange}
                                        disabled={isDetails}
                                    />
                                </div>
                            </div>
                            {/* PHOTOS BEFORE */}
                            <div className="info-area without-side-margins">
                                <div className="title">
                                    {t('brokenGlassControl.form.labels.photosBefore')}
                                </div>
                                <div className="row info-container image-row">
                                    {Object.keys(checkInImages).map(k => this.renderImg(checkInImages[k], t(`brokenGlassControl.picturesLabel.${k}`)))}
                                    {Object.keys(checkInAdditionalPhotos).map(k => this.renderImg(checkInAdditionalPhotos[Number(k)], t('brokenGlassControl.picturesLabel.additionalPhoto')))}
                                </div>
                            </div>

                            {/* PHOTOS AFTER */}
                            <div className="info-area without-side-margins">
                                <div className="title">
                                    {t('brokenGlassControl.form.labels.photosAfter')}
                                </div>
                                <div className="row info-container image-row">
                                    {Object.keys(checkOutImages).map(k => this.renderImg(checkOutImages[k], t(`brokenGlassControl.picturesLabel.${k}`)))}
                                    {Object.keys(checkOutAdditionalPhotos).map(k => this.renderImg(checkOutAdditionalPhotos[Number(k)], t('brokenGlassControl.picturesLabel.additionalPhoto')))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withAuthorizationContext(withAuthenticationContext(withTranslationContext(withBrokenGlassControlContext((BrokenGlassControlFormScreen)))));
